import React, { useState, useEffect } from 'react'
import "./HistoricalFinancialPerformance.css";
import HelpSidePanel from "../../../components/help_side_panel/HelpSidePanel";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    LineElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from "react-redux";
import api from '../../../api_interceptor/api'
import { getGraphName, updateState } from "../../../common_function/common_function";
import SmallSizedLoader from '../../../components/loader/SmallSizedLoader';

const getCurrentDate = () => {
    const currentDate = new Date();
    const options = { month: 'short', year: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);
    return formattedDate;
};
const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Actual vs Budget with Variance',
        },
    },
    scales: {
        x: {
            stacked: false,
        },
        y: {
            stacked: false,
        },
        'variance-y-axis': {
            position: 'right',
        },
    },
};

const HistoricalGraphComponent = ({monthlyUpdateKey,returnCurrentMonthData,graphDetails,monthlyUpdateList,handleChangeInput,saveData,isLoading,viewSetMonth}) => {
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [revenuedetail, setRevenue] = useState({});
    useEffect(()=>{
        setRevenue(returnCurrentMonthData(monthlyUpdateList[monthlyUpdateKey]))
    },[monthlyUpdateList])
    
    return (
       
        <div className="col-4 mb-5">
            <div className="table-div padStyle">
                <p className="graphName">{getGraphName(monthlyUpdateKey)}</p>
                {isLoading  ? (<SmallSizedLoader />) :
                (<div>
               <Bar
                    options={options}
                    data={{
                        labels,
                        datasets: [
                            {
                                label: graphDetails.budgeted?.label || 'Budgeted', // Handle possible undefined or missing properties
                                data: graphDetails.budgeted?.data || [],
                                backgroundColor: 'rgba(23, 162, 235, 0.5)',
                            },
                            {
                                label: graphDetails.actual?.label || 'Actual', // Handle possible undefined or missing properties
                                data: graphDetails.actual?.data || [],
                                backgroundColor: 'rgba(32, 99, 132, 0.5)',
                            },
                            {
                                label: 'Variance (%)',
                                type: 'line',
                                data: graphDetails.actual?.data?.map(
                                    (value, index) =>
                                        ((graphDetails.actual.data[index] - graphDetails.budgeted.data[index]) /
                                            graphDetails.budgeted.data[index]) *
                                        100
                                ) || [],
                                borderColor: 'rgba(255, 206, 86, 1)',
                                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                                yAxisID: 'variance-y-axis',
                            },
                        ],
                    }}
                />

                <div className="table-container">
                    <table className="table-container-subdiv histricalTable">
                        <thead>
                            <tr>
                                <th style={{ width: "5%" }} className="column-header-style">REPORTED ON</th>
                                <th style={{ width: "45%" }} className="column-header-style">BUDGETED</th>
                                <th style={{ width: "45%" }} className="column-header-style">ACTUAL</th>
                                <th style={{ width: "5%", display: viewSetMonth ? "none" : "table-cell" }} className="column-header-style">ADD</th>
                            </tr>
                        </thead>
                        <tbody className="hits-body">
                            <tr>
                                <td className="column-data-style">
                                    {/* <input className="textTable inputStyle" style={{ width: "100%" }} type="text" readOnly /> */}
                                    <p>{getCurrentDate()}</p>
                                </td>
                                <td className="column-data-style">
                                    <input 
                                        className="textTable inputStyle"
                                        style={{ width: "100%" }}
                                        type="number"
                                        onChange={(e) => handleChangeInput(e.target.value, setRevenue, "budgeted",monthlyUpdateKey)}
                                        value={revenuedetail.budgeted}
                                        readOnly={viewSetMonth}
                                    />
                                </td>
                                <td className="column-data-style">
                                    <input
                                        className="textTable inputStyle"
                                        style={{ width: "100%" }}
                                        type="number"
                                        onChange={(e) => handleChangeInput(e.target.value, setRevenue, "actual" , monthlyUpdateKey)}
                                        value={revenuedetail.actual}
                                        readOnly={viewSetMonth}
                                    />
                                </td>
                                <td className="column-data-style" style={{display: viewSetMonth ? "none" : "column-data-style" }} >
                                    <span className="saveBtn" onClick={(e) => saveData(revenuedetail)}>Save</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>)}
            </div>
        </div>
        
    )
}

export default HistoricalGraphComponent
