import React, { useEffect, useState, useRef } from "react";
import "./EntityDescription.css";
import imageURL from '../../../assets/images/infoIcon.svg';
import edit from '../../../assets/images/edit-icon.svg';
import remove from '../../../assets/images/remove-icon.svg';
import add from '../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../components/help_side_panel/HelpSidePanel";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import SmallSizedLoader from "../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '../../../api_interceptor/api'
import Select from 'react-select';
import Alert_Popup from "../../../components/alert_popup/Alert_Popup";
import AnalyticsImage from '../../../assets/images/Warning icon.svg';
import close from '../../../assets/images/close.png';
import { calculateDuration, updateState } from "../../../common_function/common_function";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include styles
import { Editor } from '@tinymce/tinymce-react';



export default function EntityDescription(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [minutesOfMeeting, setminutesOfMeeting] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const selectedEntity = useSelector((state) => state.user.selectedEntity);
    const [getStatusList, setStatusList] = useState([]);
    const [ImpactOnList, setImpactOnList] = useState([]);
    const [CriticalityList, setCriticalityList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [getCountData, setgetCountData] = useState(null);
    const [getCountDataShow, setgetCountDatadshow] = useState(false);
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const company_id = useSelector((state) => state.user.companyID);
    
    const [description, setDescription] = useState("");
    const [isEditable, setIsEditable] = useState(false); 
    const [isEditVisible, setIsEditVisible] = useState(true); // State to control the visibility
    const [getMeetingCadenceList, setMeetingCadenceList] = useState([]);
    const [getMeetingTypeList, setMeetingTypeList] = useState([]);

    const [key, setKey] = useState(0);
    
    const [isMeetingTypeDropdownOpen, setIsMeetingTypeDropdownOpen] = useState(false);
    const [isMeetingCadenceDropdownOpen, setIsMeetingCadenceDropdownOpen] = useState(false);
    const editorRef = useRef(null);

    const logContent = () => {
      if (editorRef.current) {
        console.log(editorRef.current.getContent());
      }
    };

    useEffect(() => {
        if (userData && userData['UserId']) {
            getEntityDescription(userData['UserId'])
        }
    },[selectedEntity])

    useEffect(() => {
        if (userData && userData['UserId']) {
            getEntityDescription(userData['UserId'])
        }
    }, [userData]);

    useEffect(() => {
        setgetCountDatadshow(!getCountDataShow)
    }, [getCountData]);

    const updateComponent = () => {
        setKey((prevKey) => prevKey + 1); // Increment key to force re-render
      };

    const getEntityDescription = (UID) => {
        updateState(
            props.setPreviewEnabled,
            userData['UserId'],
            access_details['company_division_id'],
            props.setPreviewList,
            access_details['company_division_id']
        );
        
        // setIsLoading(true);
        
        const API_URL = 'monthlymodule/api/get-entity-description';
    
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: selectedEntity.value,
            company_id: selectedEntity.value
        };
    
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
        .then((response) => {
            if (response && response.data && response.data.Entity_Description) {
                const descriptionData = response.data.Entity_Description.Description;
                debugger
                if (description !== descriptionData) {
                    setDescription(descriptionData);                
                }
                setgetCountData(response.data);
    
                setIsEditVisible(descriptionData !== null); 
                setIsEditable(false);
                updateComponent(); 
            } else {
                setDescription(null); 
                setIsEditVisible(false);
                setIsLoading(false);
                updateComponent(); 
            }
            setIsLoading(false); 
        })
        .catch((error) => {
            setIsLoading(false);
            console.error(error);
        });
    }
    
    

    const handleEdit = (index) => {
        const updatedMonthlyUpdateList = [...minutesOfMeeting];
        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);

        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }

        if (editableRows.length === 0 && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });

            console.log({ currentOperation });
            setCurrentOperation('edit');
            setminutesOfMeeting(updatedMonthlyUpdateList);
        }
    };

    const handleDelete = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            setDeleteReason(null)
            setDeletingIndex(index);
            setShowConfirmationModal(true);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };


    const addEntityDescription = (req_body,index) => {
        
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/add-entity-description';
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getEntityDescription(userData['UserId'])
                }
                else {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getEntityDescription(userData['UserId'])
                }

            })
            .catch((error) => {
                setAddClick(false)
                setIsLoading(false)
                console.error(error);
            });
    }

      // Validation start logic

      const validateMonthlyUpdate = () => {
        
        const emptyFields = [];

       
          
        if (/^\s*$/.test(description)) {
            emptyFields.push("Please enter value for description.\n");
        }
          
         
          
            
        

        return emptyFields;
    };


    // Validation end logic





    const handleSave = (index, e) => {
        // Validation 1: Dropdown should be mandatory
        

        setIsEditVisible(true); // Show the edit button after saving
        setIsEditable(true); // Exit editing mode
        
    
        

        const req_body = {
            Description: description,
            // "is_update": isAddClicked ? false : true,
            // "update_id": editedItem.ID,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        }

         // Check if it's an add operation
         if (isAddClicked) {
            const emptyFields = validateMonthlyUpdate();


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addEntityDescription(req_body, index)
                setCurrentOperation(null)
            }
        } else {
            const emptyFields = validateMonthlyUpdate();


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addEntityDescription(req_body, index)
                setCurrentOperation(null)
            }
        }
        // console.log('reqbosyuy---->>', req_body);
        // addStrategicFocus(req_body, index)
        // setCurrentOperation(null)
    };


    const handleClose = () => {
        setAddClick(false)
    
        setEditableRows([]);

        setminutesOfMeeting((prevList) => {
            return prevList.filter(item => !item.is_update);
        });
    
        const updatedMonthlyUpdateList = minutesOfMeeting.map(item => ({
            ...item,
            is_edit: false
        }));
        setminutesOfMeeting(updatedMonthlyUpdateList);
    
        if (currentOperation === 'add') {
            setminutesOfMeeting((prevList) => {
                return prevList.filter(item => !item.is_update);
            });
        }
        setCurrentOperation(null);

        setIsEditVisible(true);
        setIsEditable(false);
        getEntityDescription();
    };
    


   

   


    const handleInputChange = (index, field, value) => {
        console.log("input from date --------->>>", field, value);
        const updatedMonthlyUpdateList = [...minutesOfMeeting];
        // updatedMonthlyUpdateList[index][field] = value.label;
        // Check if the value is an object (dropdown) or a plain value (textarea, input, etc.)
        const updatedValue = typeof value === 'object' ? value.label : value;
        updatedMonthlyUpdateList[index][field] = updatedValue;
        setminutesOfMeeting(updatedMonthlyUpdateList);
    };

    const setSelectValue = (list, value) => {
        return list.find((data) => {
            return data.label === value;
        });

    }

    function formatDateString(inputDateString) {
        const inputDate = new Date(inputDateString);

        const options = { day: "numeric", month: "short", year: "numeric" };
        const formattedDate = inputDate.toLocaleDateString("en-GB", options);

        return formattedDate;
    }

       // validation popup close

       const closePopup = () => {
        setPopupOpen(false);
    };

    
  




    // dropdown scroll close start

    // const customStyles = {
    //     control: (provided) => ({
    //         ...provided,
    //         backgroundColor: 'white',
    //         marginLeft: '4px', // Change the background color of the control
    //         marginTop: 0, // Adjust the top margin
    //     }),
    //     menu: (provided) => ({
    //         ...provided,
    //         zIndex: 0,
    //         position: 'absolute',
    //         fontSize: '11px',
    //         marginTop: 0,
    //         marginBottom: 0, // Adjust the bottom margin
    //     }),
    //     option: (provided, state) => ({
    //         ...provided,
    //         backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
    //         color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
    //         height: '45px',

    //     }),
    // };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the text color of the control
            borderColor: state.isFocused ? 'black' : provided.borderColor, // Change border color when focused
            boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow, // Add box shadow when focused
            marginTop: 0, // Adjust the top margin
            '&:hover': { // Prevent blue highlight on hover
                borderColor: state.isFocused ? 'black' : provided.borderColor,
                boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow,
            }
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
                    color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
                    height: '45px',
            // '&:hover': {
            //     color: 'white', // Change text color on hover
            // },
        }),
    };

    // Add state variables for each dropdown
    const [isOwnerDropdownOpen, setIsOwnerDropdownOpen] = useState(false);
    const [isImpactOnDropdownOpen, setIsImpactOnDropdownOpen] = useState(false);
    const [isCriticalityOnDropdownOpen, setIsCriticalityDropdownOpen] = useState(false);
    const [isStatusDropdownOpen, setIsStatusrDropdownOpen] = useState(false);

    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };

    const handleToggleEdit = () => {
        setIsEditable((prev) => !prev);
        setIsEditVisible(false); // Hide the edit button when clicked
        setIsEditable(true); // Assuming you toggle editing mode here
      };

    
 
      const handleChangeText = (value) => {
        setDescription(value); // Update content state
      };

  
      const escapeHtml = (str) => {
        return str.replace(/<\/?b>|<\/?div>|<\/?p>|<\/?h[1-6]>/g, '');
    
    };
      
        const handleAdd = () => {
        }        

    // dropdown scroll close end

    return (

        <div className="parentContainer">
            {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-center'>
                                <p className="head-text">Delete Item</p>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Mention Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}
                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                    
                                />
                            </label>
                            <button onClick={() => setShowConfirmationModal(!showConfirmationModal)} className="cancel-btn">Cancel</button>
                            <button type="submit" className="confirm-btn">Confirm</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Validation popup start */}
            {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text" style={{fontSize:"20px"}}>Information</p>
                            </div>
                        </div>
                        <div className='ChatBodyDetails' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto',overflowY:'auto', padding:"0rem"}}>
                                {errorList.map((item, index) => (
                                    <div style={{ display : 'flex'}}>
                                        <div className=' d-flex mb-3' style={{width:'90%'}}>
                                            {`${index+1}) ${item}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        {/* <form>
                            <ol>
                                {errorList.map((item) =>
                                    <li className='error-text'>{item}</li>
                                )}
                            </ol>
                            <button className="close-btn" onClick={closePopup}>Close</button>
                        </form> */}
                        <button className="close-btn" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}

            {/* Validation popup end */}

            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}

            <div className="monthlyHeaderWrapper d-flex justify-content-between">
              
            
            </div>

            {isLoading ? (<SmallSizedLoader />) :
                (<div className="table-div padStyle"style={{ maxWidth: "100%", minHeight: "400px", marginBottom:"20px",overflow: "visible"}}>
                    {/* <span className="monthly-Title-Table" style={{ fontSize: "20px" }}>
                        <strong>Description</strong>
                        <div className="d-flex justify-content-end" style={{ marginTop: "20px", marginBottom: "20px", textAlign: "right" }}>
                            {isEditable ? (
                                <>
                                    <button onClick={handleSave} className="d-flex align-items-center" style={{ border: "none" }}>
                                        <span className="save-btn">Save</span>
                                    </button>
                                    <img 
                                        src={close}
                                        className="close" 
                                        alt="Close" 
                                        style={{ marginRight: "5px" }} 
                                        onClick={handleClose}
                                    />
                                </>
                            ) : (
                                <td className="main-table-content" colSpan="0.5" style={{ textAlign: 'center' }}>
                                    <button 
                                        onClick={handleToggleEdit} 
                                        style={{ border: 'none', backgroundColor: 'white', display: 'inline-block' }}
                                    >
                                        <img 
                                            src={edit}
                                            className="edit" 
                                            alt="Edit" 
                                            style={{ width: '13px', marginRight: '5px' }} 
                                        />
                                    </button>
                                </td>
                            )}
                        </div>
                    </span> */}

                    <span className="monthly-Title-Table" style={{ fontSize: "20px", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom:"15px" }}>
                    {/* {description == null ? (
                            <strong>About</strong> 
                        ) : ( */}
                            <strong>About</strong> 
                        {/* )} */}

                        {/* <div className="d-flex" style={{ gap: "10px"}}>
                            {isEditable ? (
                                <>
                                    <button onClick={handleSave} className="d-flex align-items-center" style={{ border: "none", background: "none" }}>
                                        <span className="save-btn">Save</span>
                                    </button>
                                    <img 
                                        src={close}
                                        className="close" 
                                        alt="Close" 
                                        style={{ marginRight: "5px", cursor: "pointer" }} 
                                        onClick={handleClose}
                                    />
                                </>
                            ) : (
                                <button 
                                    onClick={handleToggleEdit} 
                                    style={{ border: 'none', backgroundColor: 'white', display: 'inline-block' }}
                                >
                                    <img 
                                        src={edit}
                                        className="edit" 
                                        alt="Edit" 
                                        style={{ width: '13px', marginRight: '5px', cursor: "pointer" }} 
                                    />
                                </button>
                            )}
                        </div> */}

                        <div className="d-flex" style={{ gap: "10px" }}>
                        {!description  && !isEditable ? (
                                <button 
                                    onClick={handleToggleEdit} 
                                    style={{ border: 'none', backgroundColor: 'white', display: 'inline-block' }}
                                >
                                    <img 
                                        src={add} 
                                        className="add" 
                                        alt="Add" 
                                        style={{ width: '16px', marginRight: '5px', cursor: "pointer" }} 
                                    />
                                </button>
                            ) : isEditable ? (
                                <>
                                    <button 
                                        onClick={handleSave} 
                                        className="d-flex align-items-center" 
                                        style={{ border: "none", background: "none" }}
                                    >
                                        <span className="save-btn">Save</span>
                                    </button>
                                    <img 
                                        src={close}
                                        className="close" 
                                        alt="Close" 
                                        style={{ marginRight: "5px", cursor: "pointer" }} 
                                        onClick={handleClose}
                                    />
                                </>
                            ) : (
                                <button 
                                    onClick={handleToggleEdit} 
                                    style={{ border: 'none', backgroundColor: 'white', display: 'inline-block' }}
                                >
                                    <img 
                                        src={edit}
                                        className="edit" 
                                        alt="Edit" 
                                        style={{ width: '16px', marginRight: '5px', cursor: "pointer" }} 
                                    />
                                </button>
                            )}
                        </div>

                    </span>

                
                  

                    <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff" , zIndex:9999999999}} />


                 

                    {/* <div className="table-container">
                        <table className="table-container-subdiv open-actionTable action-summary" style={{ width: "100%", tableLayout: "fixed", minHeight:"200px" }}>
                            <tbody>
                                
        
                                <tr>
                                    <td className="main-table-content" colSpan="9.5">
                                        <textarea
                                            type="text"
                                            value={description}
                                            className={`no-border-input ${isEditable ? "editable" : ""}`}
                                            onChange={(e) => setDescription(e.target.value)}
                                            readOnly={!isEditable}
                                            style={{maxWidth:"100%", minHeight:"200px"}}
                                        />
                                    </td>
                                  

                                </tr>
                            </tbody>
                        </table>
                    </div> */}

                        <table
                            className={`table-container-subdiv open-actionTable action-summary ${isEditable ? "" : "no-border-table"}`}
                            style={{
                                width: "100%",
                                tableLayout: "fixed",
                                minHeight: "200px",
                                border: isEditable ? "1px solid #ccc" : "none" 
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td className="main-table-content" style={{ border: isEditable ? "1px solid #ccc" : "none", background:"transparent", boxShadow: !isEditable && description
                                                                                                                                                                    ? "rgb(33 168 225 / 46%) 2px 2px 6px, rgb(33 168 225 / 28%) -2px -2px 6px, rgb(33 168 225 / 40%) 0px 2px 5px"
                                                                                                                                                                    : "none", marginBottom: "20px"}} colSpan="9.5">
                                   
                                    {/* <ReactQuill
                                        value={description} // Controlled value
                                        onChange={handleChangeText} // Update state on change
                                        theme="snow" // Choose a theme: 'snow' or 'bubble'
                                    /> */}
                                 {isEditable ? (
                                        <Editor
                                            apiKey="gqq8gs6lqpm593b45c26ncdprqotmmb2nj2f4su93ffd2n1a"
                                            onInit={(evt, editor) => (editorRef.current = editor)}
                                            value={description}
                                            init={{
                                                height: 400,
                                                menubar: true,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount',
                                                ],
                                                toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                            }}
                                            onEditorChange={handleChangeText}
                                        /> )
                                         
                                        :  (
                                            description ? (
                                                <div className="readonly-content">
                                                    <div dangerouslySetInnerHTML={{ __html: description }} />
                                                </div>
                                            ) : (
                                                <div className="no-content"></div>
                                            )
                                        )}
                                 

                                    </td>
                                </tr>
                            </tbody>
                        </table>


                 
                </div>)}
        </div>
    );
};