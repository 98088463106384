import React, { useEffect, useState, useRef } from "react";
import "./ActionTrackerMRM.css";
import imageURL from '../../../assets/images/infoIcon.svg';
import edit from '../../../assets/images/edit-icon.svg';
import remove from '../../../assets/images/remove-icon.svg';
import add from '../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../components/help_side_panel/HelpSidePanel";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import SmallSizedLoader from "../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '../../../api_interceptor/api'
import Select from 'react-select';
import Alert_Popup from "../../../components/alert_popup/Alert_Popup";
import AnalyticsImage from '../../../assets/images/Warning icon.svg';
import close from '../../../assets/images/close.png';


import { updateState, formatDateTimeForChat, calculateDateDifference, formatDate, formatDateInText, compareDate} from "../../../common_function/common_function";
import CancelBluIconComment from '../../../assets/images/Cancel icon Comments.svg'
import ProfIcon from '../../../assets/images/Profile icon.svg'
import SendChatIcon from '../../../assets/images/Send chat icon.svg'
import CommementBlue from '../../../assets/images/Comment icon blue.svg'
import PaperClip from '../../../assets/images/paperclip.svg'
import EyeIcon from '../../../assets/images/Icon akar-eye.svg'
import axios from 'axios';
import DownloadFile from '../../../assets/images/DownloadFile.svg'
import DeleteFile from '../../../assets/images/DeleteFile.svg'
import RemarkBlue from '../../../assets/images/Comment History Blue.svg'
import Modal from 'react-bootstrap/Modal';
import _ from 'lodash';



export default function ActionTrackerMRM(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [actionTrackerMRMList, SetactionTrackerMRMList] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const [getStatusList, setStatusList] = useState([]);
    const [ImpactOnList, setImpactOnList] = useState([]);
    const [CriticalityList, setCriticalityList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [getCountData, setgetCountData] = useState(null);
    const [getCountDataShow, setgetCountDatadshow] = useState(false);
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const company_id = useSelector((state) => state.user.companyID);

    const [getStatusMasterList, setStatusMasterList] = useState([]);
    const [getResponsibleList, setResponsibleList] = useState([]);
    const [getFrequencyList, setFrequencyList] = useState([]);
    const [getCompletiionList, setCompletiionList] = useState([]);
    const [getCategoryList, setCategoryList] = useState([]);
    const [getPriorityList, setPriorityList] = useState([]);
    const [getDependencyList, setDependencyList] = useState([]);
    const [getRecurringList, setRecurringList] = useState([]);
    

    
    const [showOpenActionMRMComments, setshowOpenActionMRMComments] = useState(false);
    const [OpenActionMRMChatDetails, setOpenActionMRMChatDetails] = useState([]);
    const [OpenActionMRMCommentsText, setOpenActionMRMCommentsText] = useState('');
    const [OpenActionMRMIDForChatAssignedTo, setOpenActionMRMIDForChatAssignedTo] = useState(null);
    const [OpenActionMRMIDForChat, setOpenActionMRMIDForChat] = useState(null);
    const [OpenActionMRMChatDetailsHeader, setOpenActionMRMChatDetailsHeader] = useState("");
    const [StartDate, setStartDate] = useState("");
    const [DueDate, setDueDate] = useState("");
    const [duration, setDuration] = useState(0);

    // History Comment
    const [HitoryDetailsList, setHitoryDetailsList] = useState([]);
    const [showActionTrackerHistory, setshowActionTrackerHistory] = useState(false);
    const [ActionTrackerID, setActionTrackerID] = useState(null);
    const [ATHistoryComment, setATHistoryComment] = useState('');
    const [individualprojectdetails, setindividualprojectdetails] = useState(null);
    const [prevList, setPrevList] = useState([]);
    const [getProjectList, setProjectList] = useState([]);
    const [getMilestoneList, setMilestoneList] = useState([]);





    

    useEffect(() => {
        if (userData && userData['UserId']) {
            getActionTrackerMRM  (userData['UserId'])
        }
    }, [userData]);

    useEffect(() => {
        setgetCountDatadshow(!getCountDataShow)
    }, [getCountData]);

    const getActionTrackerMRM = (UID) => {
        updateState(
            props.setPreviewEnabled,
            userData['UserId'],
            access_details['company_division_id'],
            props.setPreviewList,
            access_details['company_division_id']
        );
        
        setIsLoading(true);
        
        const API_URL = 'monthlymodule/api/get-action-tracker-mrm';
    
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
    
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
        .then((response) => {
            if (response && response.data) {
                if (response.data) {
                    console.log("respdata0000--->", response.data);
                    
    
                    setgetCountData(response.data);
                    SetactionTrackerMRMList(response.data.Action_Tracker_MRM_List);
                    sethelpDetails(response.data.help_details);
    
                    getAreaOfUpdateDetails(userData['UserId'], response.data.Action_Tracker_MRM_List);
                } else {
                    sethelpDetails(response.data.help_details);
                    getAreaOfUpdateDetails(userData['UserId'], response.data.Action_Tracker_MRM_List);

                }
            } else {
                sethelpDetails(response.data.help_details);
                getAreaOfUpdateDetails(userData['UserId'], response.data.Action_Tracker_MRM_List);
                setIsLoading(false);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.error(error);
        });
    }
    
    const getAreaOfUpdateDetails = (UID, valdata) => {
        const API_URL = 'monthlymodule/api/get-open-action-items-dropdown-details';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    if (response.data) {
                        setStatusList(response.data.status_list);
                        setImpactOnList(response.data.impact_on_list);
                        setCriticalityList(response.data.criticality_list);
                        setStatusMasterList(response.data.status_master_list);
                        setResponsibleList(response.data.responsible_list);
                        setCompletiionList(response.data.completion_list);
                        setFrequencyList(response.data.frequency_list);
                        setCategoryList(response.data.category_list);
                        setPriorityList(response.data.priority_list);
                        setDependencyList(response.data.dependency_list);
                        setProjectList(response.data.project_list);
                        setRecurringList([
                            { Id : 1, Value : 'Yes'},
                            { Id : 2, Value : 'No'}
                        ])
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }
    

    const getMilestoneDropdown = (UID, valdata, selectedProjectCode) => {
        const API_URL = 'monthlymodule/api/GetMilestonesByProjectCode';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id'],
            selectedProjectCode: selectedProjectCode
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    if (response.data) {
                        setMilestoneList(response.data.milestone_list);
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const handleProjectChange = (index, selectedOption) => {
        const selectedProjectCode = selectedOption.label;
    
        getMilestoneDropdown(userData['UserId'], actionTrackerMRMList[index], selectedProjectCode);
        
        handleInputChange(index, 'Project', selectedOption);
    };

    const handleEdit = (index) => {
        // const updatedMonthlyUpdateList = [...actionTrackerMRMList];
        if (prevList.length === 0) {
            setPrevList(actionTrackerMRMList.map(item => ({ ...item }))); // Save the original state
        }

        const updatedMonthlyUpdateList = _.cloneDeep(actionTrackerMRMList);

        const ProjectName = updatedMonthlyUpdateList[index].Project;
        getMilestoneDropdown(userData['UserId'], updatedMonthlyUpdateList[index], ProjectName);


        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);

        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }

        if (editableRows.length === 0 && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });

            console.log({ currentOperation });
            setCurrentOperation('edit');
            SetactionTrackerMRMList(updatedMonthlyUpdateList);
        }
    };

    const handleDelete = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            setDeleteReason(null)
            setDeletingIndex(index);
            setShowConfirmationModal(true);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
            handleDeleteAPI(deletingIndex, deleteReason);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };

    const handleDeleteAPI = (index, reason) => {
        const editedItem = actionTrackerMRMList[index];
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/delete-action-tracker-mrm';
        const req_body = {
            "id": editedItem.ID,
            "deleted_reason": reason,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    getActionTrackerMRM  (userData['UserId'])
                    SetactionTrackerMRMList(prevList => prevList.filter((item, i) => i !== index));
                }
                else {
                    getActionTrackerMRM  (userData['UserId'])
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    const addActionTrackerMRM = (editedItem, index) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/createOrUpdateActionTracker';
        const req_body = editedItem
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                
                if (response && response.data) {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getActionTrackerMRM  (userData['UserId'])
                    addFiles(response.data.OpenActionID,response.data.historyId,'action_tracker',true,response.data.actionTrackerId)

                }
                else {
                    
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getActionTrackerMRM  (userData['UserId'])
                    addFiles(response.data.OpenActionID,response.data.historyId,'action_tracker',true,response.data.actionTrackerId)

                }

            })
            .catch((error) => {
                setAddClick(false)
                setIsLoading(false)
                console.error(error);
            });
    }
    

      // Validation start logic

      const validateMonthlyUpdate = (editedItem) => {
        const emptyFields = [];

        if (editedItem.Project === null || editedItem.Project === undefined || editedItem.Project === 0 || /^\s*$/.test(editedItem.Project)) {
            emptyFields.push("Please select project.\n");
        }
        
        if (editedItem.Milestone === null || editedItem.Milestone === undefined || editedItem.Milestone === 0 || /^\s*$/.test(editedItem.Milestone)) {
            emptyFields.push("Please select milestone.\n");
        }
        
        if (editedItem.Category === null || editedItem.Category === undefined || editedItem.Category === 0 || /^\s*$/.test(editedItem.Category)) {
            emptyFields.push("Please select category.\n");
        }
        
        if (editedItem.Priority === null || editedItem.Priority === undefined || editedItem.Priority === 0 || /^\s*$/.test(editedItem.Priority)) {
            emptyFields.push("Please select priority.\n");
        }
        
        if (editedItem.ResponsibleDoer === null || editedItem.ResponsibleDoer === undefined || editedItem.ResponsibleDoer === 0 || /^\s*$/.test(editedItem.ResponsibleDoer)) {
            emptyFields.push("Please select responsible (doer).\n");
        }
        
        
        if (editedItem.Status1 === null || editedItem.Status1 === undefined || editedItem.Status1 === 0 || /^\s*$/.test(editedItem.Status1)) {
            emptyFields.push("Please select update status.\n");
        }
        
        if (/^\s*$/.test(editedItem.StartDate)) {
            emptyFields.push("Please enter value for in the start date.\n");
        }

        if (/^\s*$/.test(editedItem.DueDate)) {
            emptyFields.push("Please enter value for in the due date.\n");
        }

        
        
      

        if (/^\s*$/.test(editedItem.RefMeeting)) {
            emptyFields.push("Please enter value for in the ref. meeting.\n");
        }
        
        return emptyFields;
    };


    // Validation end logic





    const handleSave = (index, e) => {
        const editedItem = actionTrackerMRMList[index];  // Assume this is an object
        const updatedData = { ...actionTrackerMRMList};  // Clone the object at index
        

        

        const req_body = {
            AssetProject:
            editedItem.Project && editedItem.Project.value === undefined
            ?setSelectValue(getProjectList, editedItem.Project).value
                : editedItem.Project.value,
        
            MilestoneID:
            editedItem.Milestone && editedItem.Milestone.value !== undefined
                ? editedItem.Milestone.value
                : editedItem.MilestoneID,

            Category: editedItem.Category && editedItem.Category.value === undefined 
            ? setSelectValue(getCategoryList, editedItem.Category).value 
            : editedItem.Category.value,
            Code: editedItem.Code,
            AssociatedAction: editedItem.AssociatedAction,
            ActionDescription: editedItem.ActionDescription,
            Priority: editedItem.Priority && editedItem.Priority.value === undefined 
                ? setSelectValue(getPriorityList, editedItem.Priority).value 
                : editedItem.Priority.value,
            Status1: 
                editedItem.Status1 === null || editedItem.Status1 === undefined 
                ? null 
                :  editedItem.Status1 && editedItem.Status1.value === undefined 
                ? setSelectValue(getStatusMasterList, editedItem.Status1).value 
                : editedItem.Status1.value,
            Status2: editedItem.Status2,
            ResponsibleDoer: 
                editedItem.ResponsibleDoer === null || editedItem.ResponsibleDoer === undefined 
                ? null 
                : editedItem.ResponsibleDoer && editedItem.ResponsibleDoer.value === undefined 
                ? setSelectValue(getResponsibleList, editedItem.ResponsibleDoer).value 
                : editedItem.ResponsibleDoer.value,
            Accountable: editedItem.Accountable,
            Consult: editedItem.Consult,
            Inform: editedItem.Inform,
            StartDate: editedItem.StartDate,
            DueDate: editedItem.DueDate,
            EsimatedEfforts: editedItem.EstimatedEfforts,
            SubAction: editedItem.SubAction,
            RefMeeting: editedItem.RefMeeting,
            Recurring: editedItem.Recurring == 1 ? true : editedItem.Recurring == 2 ? false :  false,
            Frequency: 
                editedItem.Frequency == null || editedItem.Frequency == undefined 
                ? null 
                : editedItem.Frequency && editedItem.Frequency.value == undefined 
                ? setSelectValue(getFrequencyList, editedItem.Frequency).value 
                : editedItem.Frequency.value,
            RecurringStartDate: editedItem.RecurringStartDate,
            RecurringEndDate: editedItem.RecurringEndDate,
            Completion: 
                editedItem.Completion == null || editedItem.Completion == undefined 
                ? null 
                : editedItem.Completion && editedItem.Completion.value == undefined 
                ? setSelectValue(getCompletiionList, editedItem.Completion).value 
                : editedItem.Completion.value,
            ActualStartDate: editedItem.ActualStartDate,
            ActualFinishDate: editedItem.ActualFinishDate,
            Remark: editedItem.Remark,
            Dependency: 
                editedItem.Dependency == null || editedItem.Dependency == undefined 
                ? null 
                : editedItem.Dependency && editedItem.Dependency.value == undefined 
                ? setSelectValue(getDependencyList, editedItem.Dependency).value 
                : editedItem.Dependency.value,
            ModuleType : "ActionTracker",
            Code: "",

            // CMOComments: editedItem.CMOComments,

            "is_update": isAddClicked ? false : true,
            "update_id": isAddClicked ? null : editedItem.ID,
            UserId: userData['UserId'],
            ID: isAddClicked ? null : editedItem.ID,
            Entity: access_details['company_division_id'],
            company_id: access_details['company_division_id'],
            IsSubmit: true,
            StatusCodeId: 1,
            CreatedOn: new Date(),
            CreatedBy: userData['UserId'],
            UpdatedOn: new Date(),
            UpdatedBy: userData['UserId'],
            SequenceID: updatedData[index].SequenceID != null 
            ? updatedData[index].SequenceID
            : null,
        
            SourceId: [updatedData[index].SourceId] != null 
            ? updatedData[index].SourceId
            : null


        }

         // Check if it's an add operation
         if (isAddClicked) {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addActionTrackerMRM(req_body, index, updatedData)
                setCurrentOperation(null)
            }
        } else {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addActionTrackerMRM(req_body, index, updatedData)
                setCurrentOperation(null)
            }
        }
        // console.log('reqbosyuy---->>', req_body);
        // addStrategicFocus(req_body, index)
        // setCurrentOperation(null)
    };


    const handleClose = (index) => {
        // Remove the index from editableRows when clicking the close icon
        setAddClick(false);
        setEditableRows([]);
        setDraftFileList([])

        

        // If the item is newly added and being closed, remove it from the list
        // if (!actionTrackerMRMList[index].is_update) {
        //     SetactionTrackerMRMList((prevList) => {
        //         const updatedList = [...prevList];
        //         updatedList.splice(index, 1);
        //         return updatedList;
        //     });
        // }

        // // Reset the is_edit flag
        // const updatedMonthlyUpdateList = [...actionTrackerMRMList];
        // updatedMonthlyUpdateList[index].is_edit = false;
        // SetactionTrackerMRMList(updatedMonthlyUpdateList);

        if (prevList.length > 0) {
            SetactionTrackerMRMList(prevList);
        }

        if (!actionTrackerMRMList[index].is_update  && prevList.length > 0) {
            SetactionTrackerMRMList(prevList);

            const updatedMonthlyUpdateList = [...prevList];
            updatedMonthlyUpdateList[index].is_edit = false;
            SetactionTrackerMRMList(prevList);
        }
         
      

        if (currentOperation === 'add') {
            SetactionTrackerMRMList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }
        setCurrentOperation(null)
    };


    const handleAdd = (index) => {
        
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...actionTrackerMRMList];
            const newItem = {
                Project: "",
                Milestone: "",
                Category: "",
                Code: "",
                AssociatedAction: "",
                ActionDescription: "",
                Priority: "",
                Status1: "",
                Status2: "",
                ResponsibleDoer: "",
                Accountable: "",
                Consult: "",
                Inform: "",
                StartDate: "",
                DueDate: "",
                EstimatedEfforts: "",
                SubAction: "",
                RefMeeting: "",
                Recurring: "",
                Frequency: "",
                RecurringStartDate: "",
                RecurringEndDate: "",
                Completion: "",
                ActualStartDate: "",
                ActualFinishDate: "",
                Remark: "",
                Dependency: "",
                company_id: access_details['company_division_id']
            };
            SetactionTrackerMRMList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, index + 1]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const handleAddNew = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...actionTrackerMRMList];
            const newItem = {
                Project: "",
                Milestone: "",
                Category: "",
                Code: "",
                AssociatedAction: "",
                ActionDescription: "",
                Priority: "",
                Status1: "",
                Status2: "",
                ResponsibleDoer: "",
                Accountable: "",
                Consult: "",
                Inform: "",
                StartDate: "",
                DueDate: "",
                EstimatedEfforts: "",
                SubAction: "",
                RefMeeting: "",
                Recurring: "",
                Frequency: "",
                RecurringStartDate: "",
                RecurringEndDate: "",
                Completion: "",
                ActualStartDate: "",
                ActualFinishDate: "",
                Remark: "",
                Dependency: "",
                company_id: access_details['company_division_id']
            };
            SetactionTrackerMRMList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, 0]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };


  
    // const handleInputChange = (index, field, value) => {
    //     
    //     console.log("input from date --------->>>", field, value);
    //     const updatedMonthlyUpdateList = [...actionTrackerMRMList];
    //     // updatedMonthlyUpdateList[index][field] = value.label;
    //     // Check if the value is an object (dropdown) or a plain value (textarea, input, etc.)
    //     const updatedValue = typeof value === 'object' ? value.label : value;
    //     updatedMonthlyUpdateList[index][field] = updatedValue;
    //     SetactionTrackerMRMList(updatedMonthlyUpdateList);
    // };
    
    const handleInputChange = (index, field, value) => {
        
        console.log("Input field change ->", field, value);
        const updatedMonthlyUpdateList = [...actionTrackerMRMList];
        updatedMonthlyUpdateList[index][field] = value; // No need for value.label in case of a select dropdown
        SetactionTrackerMRMList(updatedMonthlyUpdateList);
    };
    

    const setSelectValue = (list, value) => {
        return list.find((data) => {
            return data.label === value;
        });

    }

    function formatDateString(inputDateString) {
        const inputDate = new Date(inputDateString);

        const options = { day: "numeric", month: "short", year: "numeric" };
        const formattedDate = inputDate.toLocaleDateString("en-GB", options);

        return formattedDate;
    }

       // validation popup close

       const closePopup = () => {
        setPopupOpen(false);
    };




    // dropdown scroll close start

    // const customStyles = {
    //     control: (provided) => ({
    //         ...provided,
    //         backgroundColor: 'white',
    //         color: 'red', // Change the background color of the control
    //         marginTop: 0, // Adjust the top margin
    //     }),
    //     menu: (provided) => ({
    //         ...provided,
    //         zIndex: 0,
    //         position: 'absolute',
    //         fontSize: '11px',
    //         marginTop: 0,
    //         marginBottom: 0, // Adjust the bottom margin
    //     }),
    //     option: (provided, state) => ({
    //         ...provided,
    //         backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
    //         color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
    //         height: '45px',

    //     }),
    // };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the text color of the control
            borderColor: state.isFocused ? 'black' : provided.borderColor, // Change border color when focused
            boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow, // Add box shadow when focused
            marginTop: 0, // Adjust the top margin
            '&:hover': { // Prevent blue highlight on hover
                borderColor: state.isFocused ? 'black' : provided.borderColor,
                boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow,
            }
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
                    color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
                    height: '45px',
            // '&:hover': {
            //     color: 'white', // Change text color on hover
            // },
        }),
    };

    // Add state variables for each dropdown
    const [isOwnerDropdownOpen, setIsOwnerDropdownOpen] = useState(false);
    const [isImpactOnDropdownOpen, setIsImpactOnDropdownOpen] = useState(false);
    const [isCriticalityOnDropdownOpen, setIsCriticalityDropdownOpen] = useState(false);
    const [isStatusDropdownOpen, setIsStatusrDropdownOpen] = useState(false);
    
    const [isStatusMasterDropdownOpen, setIsStatusMasterDropdownOpen] = useState(false);
    const [isResponsibleDropdownOpen, setIsResponsibleDropdownOpen] = useState(false);
    const [isFrequencyDropdownOpen, setIsFrequencyDropdownOpen] = useState(false);
    const [isCompletiionDropdownOpen, setIsCompletiionDropdownOpen] = useState(false);
    const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
    const [isPriorityDropdownOpen, setIsPriorityDropdownOpen] = useState(false);
    const [isDependencyDropdownOpen, setIsDependencyDropdownOpen] = useState(false);
    const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
    const [isMilestoneDropdownOpen, setIsMilestoneDropdownOpen] = useState(false);


    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };

    // dropdown scroll close end

    const CloseOpenActionMRMComments = () => {
        setOpenActionMRMIDForChat(null)
        setOpenActionMRMIDForChatAssignedTo(null)
        setshowOpenActionMRMComments(false)
        setOpenActionMRMCommentsText('')
        setOpenActionMRMChatDetails([])
        setOpenActionMRMChatDetailsHeader('')

    }

   
    const AddOpenActionMRMComments = (OpenActionID,AssignedToUserIdChat) => {
        
        setIsLoading(true)
        const API_URL = 'api/add-open-action-comment-details';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            OpenActionID : OpenActionID,
            EntityID: access_details['company_division_id'],
            OpenActionComment : OpenActionMRMCommentsText,
            AssignedToUserID : AssignedToUserIdChat,//Need To change
            ActionByUserID :  userData && 'UserId' in userData ? userData['UserId'] : ""
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    

                    setOpenActionMRMIDForChat(null)
                    setOpenActionMRMIDForChatAssignedTo(null)
                    setshowOpenActionMRMComments(false)
                    setOpenActionMRMCommentsText('')
                    setOpenActionMRMChatDetails([])
                    setIsLoading(false)
                    openActionTrackerMRMComment(OpenActionID)
                    
                }
                else {
                    
                    setOpenActionMRMIDForChat(null)
                    setOpenActionMRMIDForChatAssignedTo(null)
                    setshowOpenActionMRMComments(false)
                    setOpenActionMRMCommentsText('')
                    setOpenActionMRMChatDetails([])
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setOpenActionMRMIDForChat(null)
                setOpenActionMRMIDForChatAssignedTo(null)
                setshowOpenActionMRMComments(false)
                setOpenActionMRMCommentsText('')
                setOpenActionMRMChatDetails([])
                setIsLoading(false)
                console.error(error);
            });
    }
    const openActionTrackerMRMComment = (OpenActionID,assignedUser) => {
        
      
        setOpenActionMRMIDForChat(OpenActionID)
        setOpenActionMRMIDForChatAssignedTo(assignedUser)

        setIsLoading(true)
        const API_URL = 'api/get-open-action-comment-details';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            OpenActionID : OpenActionID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setOpenActionMRMChatDetails(response.data.Comments)
                    setOpenActionMRMChatDetailsHeader(response.data.MileStoneHeader)
                    setshowOpenActionMRMComments(true)
                    setOpenActionMRMCommentsText('')
                    setIsLoading(false)
                    
                }
                else {
                    setshowOpenActionMRMComments(true)
                    setOpenActionMRMCommentsText('')
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

   
    const [errorMessages, setErrorMessages] = useState([]);
    const [draftFileList, setDraftFileList] = useState([]);
    const [totalFileCount, setTotalFileCount] = useState(0);
    const fileInputRef = useRef(null);

    const [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
    const [isFileViewerOpenModal, setisFileViewerOpenModal] = useState(false);
    
    const [isFileViewerOpenHIstory, setisFileViewerOpenHIstory] = useState(false);
    const [isFileViewerHistoryList, setisFileViewerHistoryList] = useState([]);
    const [SavedFileList, setSavedFileList] = useState([]);
    const [showConfirmationModalForDeleteProject, setshowConfirmationModalForDeleteProject] = useState(false);
    
    const [isFileError, setIsFileError] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [isPDF, setIsPDF] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [isDownload, setIsDownload] = useState(false);
    const [viewedFileName, setViewedFileName] = useState('');
    const [filePath, setFilePath] = useState('');
    const [CompleteFileData, setCompleteFileData] = useState(null);
    const [selectedFileOnserver, setselectedFileOnserver] = useState(false);
        

    
    

    const openFileSelectionPopUp = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            fileInputRef.current.click();
        }
    };


    
    const setFilesData = (event) => {
        

        const files = event.target.files;
        let tempDraftFileList = [...draftFileList];
        let tempTotalFileCount = totalFileCount;

        for (let i = 0; i < files.length; i++) {
            if (files[i].size > 16000000) {
                setErrorMessages(['File cannot upload, size is more than 15MB.']);
                alert(errorMessages[0]); // Open error message popup
            } else {
                const fileData = files[i];
                const reader = new FileReader();
                reader.onload = () => {
                    const typeResult = `${fileData.type.split("/")[0]}/*`;
                    tempDraftFileList.push({
                        name: fileData.name,
                        imageSrc: reader.result,
                        type: fileData.type,
                        fileDataDetail: fileData,
                        id: Date.now(), // unique ID, you can replace with your own logic
                        FileType: typeResult,
                        MOCType: event.target.id
                    });
                    tempTotalFileCount++;
                    setDraftFileList(tempDraftFileList);
                    setTotalFileCount(tempTotalFileCount);
                };
                reader.readAsDataURL(fileData);
            }
        }
    }

    
    const addFiles = async (OpenActionID, historyId, actionType, isSubmitted = true, actionTrackerId) => {
        
        setIsLoading(true)
        const fileUploadData = new FormData();
        draftFileList.forEach((file, i) => {
        fileUploadData.append(`file${i}`, file.fileDataDetail);
        });
        fileUploadData.append('ActionTrackerId', OpenActionID ? OpenActionID: actionTrackerId);
        fileUploadData.append('fileCount', draftFileList.length);
        fileUploadData.append('UserId', userData['UserId']);
        fileUploadData.append('Type', actionType);
        fileUploadData.append('IsSubmitted', isSubmitted);
        fileUploadData.append('historyId', historyId);

        const API_URL = 'api/uploadFilesMRM';
        api.post(API_URL, fileUploadData, {
            'Content-Type': 'multipart/form-data'
        })
    
        .then((response) => {
            
            if (response && response.data) {
                setDraftFileList([])
                setTotalFileCount(0)
                setIsLoading(false)
                
            }
            else {
                setDraftFileList([])
                setTotalFileCount(0)
                setIsLoading(false)
            }
        })
        .catch((error) => {
            setIsLoading(false)
            console.error(error);
        });;
        }

    // const addFiles = async (ATId = 0, historyId = 0, actionType, isSubmitted = true, solutionOrTrackerId = 0, OpenActionID=0) => {
    //     setIsLoading(true)
    //     const fileUploadData = new FormData();
    //     draftFileList.forEach((file, i) => {
    //     fileUploadData.append(`file${i}`, file.fileDataDetail);
    //     });
    //     fileUploadData.append('ActionTrackerId', OpenActionID);
    //     fileUploadData.append('fileCount', draftFileList.length);
    //     fileUploadData.append('UserId', userData['UserId']);
    //     fileUploadData.append('Type', actionType);
    //     fileUploadData.append('IsSubmitted', isSubmitted);
    //     fileUploadData.append('SolutionId', solutionOrTrackerId);
    //     fileUploadData.append('historyId', historyId);
    //     const API_URL = 'api/uploadFiles';
    //     api.post(API_URL, fileUploadData, {
    //         'Content-Type': 'multipart/form-data'
    //     })
    
    //     .then((response) => {
            
    //         if (response && response.data) {
    //             setDraftFileList([])
    //             setTotalFileCount(0)
    //             setIsLoading(false)
                
    //         }
    //         else {
    //             setDraftFileList([])
    //             setTotalFileCount(0)
    //             setIsLoading(false)
    //         }
    //     })
    //     .catch((error) => {
    //         setIsLoading(false)
    //         console.error(error);
    //     });;
    //     }

    
    const getAllFileDetailsbyHistoryId = (OpenActionID, isAT = false,isEdit = false) => {
        setIsLoading(true)
        const API_URL = 'api/getAllUploadedFiles/';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            // OpenActionID: OpenActionID,
            type : 'action_tracker',
            historyId : isAT ? 0 : OpenActionID,
            solutionOrTrackerId : isAT ? OpenActionID: 0,
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                
                if (response && response.data) {
                    if(isEdit){
                        setSavedFileList(response.data)
                        if(response.data.length + draftFileList.length=== 1)
                            {
                                if( draftFileList.length=== 1){
                                    openSavedFileViewerPopup(draftFileList[0],false)
                                }
                                else{
                                    openSavedFileViewerPopup(response.data[0],true)
                                }
                            }
                        else{
                            setIsFileViewerOpen(true)
                        }
                    }
                    else{

                        if(response.data.length === 1)
                        {
                            openSavedFileViewerPopup(response.data[0],true)
                        }
                        else{
                            setisFileViewerHistoryList(response.data)
                            setisFileViewerOpenHIstory(true)
    
                        }
                    }

                    setIsLoading(false)
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }
    const deleteUploadedFile = (fileId) => {
        setIsLoading(true)
        const API_URL = 'api/deleteUploadedFileMRM';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            fileId : fileId
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setSavedFileList([])
                    setIsFileViewerOpen(false)
                    setIsLoading(false)
                }
                else {
                    setSavedFileList([])
                    setIsFileViewerOpen(false)
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    
    const openSavedFileViewerPopup = (fileData,Onserver=false) => {
        
        setselectedFileOnserver(Onserver)

        setCompleteFileData(fileData)
        
        setIsFileError(false);
        setIsImage(false);
        setIsPDF(false);
        setIsVideo(false);
        setViewedFileName(Onserver ? fileData.FileName  : fileData.name);
        
        const fileName = Onserver ? fileData.FileName : fileData.name;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
        setisFileViewerOpenModal(true)
        
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);

        if (['jpeg', 'jpg', 'png', 'pdf', 'mp4'].includes(extension)) {
            switch (extension) {
                case 'pdf':
                    if (check) {
                        setIsDownload(true);
                    } else {
                        setIsPDF(true);
                        setFilePath(Onserver ? fileData.Filepath :  fileData.imageSrc);
                    }
                    break;
                case 'jpg':
                case 'png':
                case 'jpeg':
                    setIsImage(true);
                    setFilePath(Onserver ? fileData.Filepath :  fileData.imageSrc);
                    break;
                case 'mp4':
                    setIsVideo(true);
                    setFilePath(Onserver ? fileData.Filepath :  fileData.imageSrc);
                    break;
                default:
                    break;
            }

            if (isDownload) {
                setIsFileError(true);
            } else {
                setIsFileError(false);
            }

            // Assuming you are using Bootstrap for modal
            // document.getElementById('filePopUp').style.display = 'block';
            
        } else {
            setIsFileError(true);
            // document.getElementById('filePopUp').style.display = 'block';
        }
    }

    
    const handleDownloadFile = async (path, name,Onserver=false) => {
        
        var path1 =""
        if (Onserver != true) {
            path1 = path.imageSrc;
            name = path.name;
        } else {
            path1 = `${path}?fileName=${name}`;
        }

        try {
            const response = await downloadFileAPI(path1,name,Onserver);

            const data = response.data;
            const contentType = response.headers['content-type'] || 'application/octet-stream';
            const blob = new Blob([data], { type: contentType });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = name;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Something went wrong while downloading the file...');
            // Handle the error appropriately in your application
        }
    };

   



    const downloadFileAPI = async (fileUrl, fileName, Onserver) => {
        
        try {

            if (Onserver == true)

            {
                const API_URL = 'api/proxy-download';
                const response = await api.get(API_URL, {
                    params: { fileUrl: fileUrl },
                    responseType: 'blob', // Important to get the response as a Blob
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    }
                });
                return response
            }
            else 
            { 
                const response = await axios.get(fileUrl, {
                    responseType: 'arraybuffer',
                    headers: { 'Content-Type': undefined }
                });
                return response;
            }
         
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };




    
    // const downloadFileAPI = async (fileUrl) => {
    //     console.log('Downloading file from:', fileUrl); // Log URL for debugging
    //     try {
    //         const response = await axios.get(fileUrl);
    //         return response;
    //     } catch (error) {
    //         console.error('Error downloading the file:', error);
    //         throw error;
    //     }
    // };

    
    
    const deleteDraftUploadedFile = (index) => {
        const newDraftFileList = [...draftFileList];
        newDraftFileList.splice(index, 1);
        setDraftFileList(newDraftFileList);
        setTotalFileCount(newDraftFileList.length);
        // setTotalFileCount(newDraftFileList.length + savedFileList.length);
    };

    const popupRef = useRef(null);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                CloseOpenActionMRMComments(); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    
    const setFileViewerClose = () => {
        setCompleteFileData(null)
        setViewedFileName('')
        setselectedFileOnserver(false)
        setisFileViewerOpenModal(false)
        
    } 
  
     //Download file
     const downloadFile = (fileData) => {
        const filePath = fileData.imageSrc;
        const a = document.createElement('a');
        a.setAttribute('href', filePath);
        a.setAttribute('download', fileData.name);
        a.setAttribute('target', '_blank');
        a.click();
    };

    const OpenActionTrackerHistory = (ActionTrackerID) => {
        setIsLoading(true)
        const API_URL = 'api/getAllHistoryOfActionTracker';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            ActionTrackerId : ActionTrackerID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setHitoryDetailsList(response.data.History)
                    setshowActionTrackerHistory(true)
                    setActionTrackerID(ActionTrackerID)
                    setIsLoading(false)
                    
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    
    const addCommentFromActionTrackerHistory = (ActionTrackerID,AssignedToUserId) => {
        setIsLoading(true)
        const API_URL = 'api/addCommentFromActionTrackerHistory';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            ActionTrackerId : ActionTrackerID,
            ActionByUserId : userData && 'UserId' in userData ? userData['UserId'] : "",
            AssignedToUserId : AssignedToUserId,
            Remarks : ATHistoryComment,
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setshowActionTrackerHistory(false)
                    CloseActionTrackerHistory()
                    setActionTrackerID(null)
                    setHitoryDetailsList([])
                    setATHistoryComment('')
                    setIsLoading(false)
                    OpenActionTrackerHistoryPopup()
                    OpenActionTrackerHistory(ActionTrackerID)
                    
                }
                else {
                    setshowActionTrackerHistory(false)
                    CloseActionTrackerHistory()
                    setActionTrackerID(null)
                    setHitoryDetailsList([])
                    setATHistoryComment('')
                    setIsLoading(false)
                    OpenActionTrackerHistoryPopup()
                    OpenActionTrackerHistory(ActionTrackerID)

                }
            })
            .catch((error) => {
                setshowActionTrackerHistory(false)
                CloseActionTrackerHistory()
                setActionTrackerID(null)
                setHitoryDetailsList([])
                setATHistoryComment('')
                setIsLoading(false)
                console.error(error);
            });
    }

    const CloseActionTrackerHistory = () => {
        setHitoryDetailsList([])
        setshowActionTrackerHistory(false)
        setActionTrackerID(null)
    }

    const OpenActionTrackerHistoryPopup = () => {
        setshowActionTrackerHistory(true)
        

    }

    const openFileViewerPopup = () => {
        
        setIsFileViewerOpen(true);
    }

    return (

        <div className="parentContainer" style={{marginBottom:"20px"}}>
   {(showActionTrackerHistory && HitoryDetailsList.length>0) &&(<div class="popup " id="AThistoryPopup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <Modal
                    id="AThistoryPopupModal"
                    {...props}
                    size="xl"
                    className='modal-dialog-scrollable'
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={true} 
                    style={{overflowY:"hidden"}}      
                    onHide={CloseActionTrackerHistory}>
                    <Modal.Header className='customclsbtn' closeButton>
                    <Modal.Title><p className='mb-0' style={{fontSize:'20px',color:'white'}}>History</p></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            {/* <div className='mb-3 '>
                                <div className='d-flex justify-content-between col-12 roundedTopBorderradius px-2' style={{backgroundColor : '#21A8E1'}}>
                                    
                                    <img onClick={() => {CloseActionTrackerHistory()}} src={WhiteCancelicon} className='cursor-pointer' style={{height:'35px',width:'35px'}} alt="Close" />
                                </div>
                            </div> */}
                            <div className='ActiontrackerHistoryContainer px-3'>
                                <div className='row mb-2'>
                                    <div className='col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 '>
                                        <div className='d-flex'>
                                            <div className='ActionTrackerHistory-desc-title-blue'>{'History'}</div>&nbsp;
                                            <div className='ActionTrackerHistory-desc-title'>{`${HitoryDetailsList[0].Code} Created by ${HitoryDetailsList[HitoryDetailsList.length-1].ActionDoneBy} on ${formatDateInText(HitoryDetailsList[HitoryDetailsList.length-1].HistoryCreatedOn)}`}</div>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 '>
                                        <div className='d-flex justify-content-end'>
                                            <div className='ActionTrackerHistory-desc-title-general-bold' style={{color:compareDate(HitoryDetailsList[0].DueDate) == 'On Track' ? '#41A35F' : '#E91A1A'}}>{compareDate(HitoryDetailsList[0].DueDate)}</div>
                                        </div>
                                    </div>
                                </div>



                                <div class="blueContainer roundedTopBorderradius pb-2">

                                    <div class="row pt-2 ps-2  ">
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12">
                                            <div class="LabelheaderSecond">Action description</div>
                                        </div>
                                        <div class="colun1mob disnoneMob"></div>

                                        <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12 ptremove">
                                            {/* <div class="LabelheaderSecond">{HitoryDetailsList[0].ActionDescription}</div> */}
                                            <div class="LabelheaderSecond" dangerouslySetInnerHTML={{ __html:HitoryDetailsList[0].ActionDescription.replace(/\n/g, '<br>')}}/>
                                        </div>
                                    </div>

                                    <div class="row ps-2 pt-2">
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Status </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7 ">
                                            <div class="">{HitoryDetailsList[0].StatusName}</div>
                                        </div>
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Priority </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7 ">
                                            <div class="">{HitoryDetailsList[0].PriorityName}</div>
                                        </div>

                                        
                                    </div>



                                    <div class="row ps-2 pt-2">
                                     

                                     
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Entity </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7 ">
                                            <div class="">{HitoryDetailsList[0].EntityName}</div>
                                        </div>
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Asset/Project </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7">
                                            <div class="">{individualprojectdetails?individualprojectdetails.ProjectName : HitoryDetailsList[0].ProjectName}</div>
                                        </div>
                                    </div>


                                 

                                    <div class="row  ps-2 pt-2">
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                                <div class="">Start Date </div>
                                        </div>
                                        <div class="colun1 "></div>
                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7 ">
                                            <div class="">{formatDateInText(HitoryDetailsList[0].StartDate)}</div>
                                        </div>
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Due date  </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7  ">
                                            <div class="">{formatDateInText(HitoryDetailsList[0].DueDate)}</div>
                                        </div>
                                    </div>

                                    <div class="row  ps-2 pt-2">
                                     
                                    <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Responsible  </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7  ">
                                            <div class="">{HitoryDetailsList[0].userName}</div>
                                        </div>
                                    </div>
                                    <div class="row  ps-2 pt-2">
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Category </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7 ">
                                            <div class="">{HitoryDetailsList[0].CategoryName}</div>
                                        </div>
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Attachment </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7">
                                            <div className="ViewAttachFile" style={{border: 'none', backgroundColor: '#fff'}}>
                                                <div class="custom-upload floatleft d-flex justify-content-evenly " style={{width: '100%'}} onClick={() => getAllFileDetailsbyHistoryId(HitoryDetailsList[(HitoryDetailsList.length)-1].HistoryID)}>
                                                    <img title="View" src={EyeIcon} style={{width: '22px'}} alt="Alternate Text" />
                                                    <div >View </div>
                                                    <div>|&nbsp;{HitoryDetailsList[(HitoryDetailsList.length)-1].AttachedFileCount}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                 


                                    
                                    


                                </div>

                                {/* ---------------------------------------History List View Starts--------------------------------------*/}

                                <div class="mt-3">
                                        {HitoryDetailsList.map((item, index) => (
                                        <div class="table-data-ld d-flex ">
                                            <div class="d-flex flex-column timeline-container">
                                                <div className={item.historyStatusName == 'New' ? 'timeline' : item.historyStatusName == 'Completed' ? 'timeline-grey' : item.historyStatusName == 'Cancel' ? 'timeline-red' : item.historyStatusName == 'Comment' ? 'timeline-blue' : 'timeline-orange' }>
                                                </div>
                                                {HitoryDetailsList.length != index+1  ? 
                                                    <div class="borderstr">
                                                    </div>
                                                :null}
                                            </div>
                                            <div id="historylist" className="w-100 grayAT py-2 ms-2 mb-2">
                                                <div class="row  ps-2">
                                                    <div class="d-flex flex-column" className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 d-flex flex-column' >
                                                        <div class="font-weight-700 hcolor">{item.ActionDoneBy}</div>
                                                        <div class="capsm"><span style={{fontSize:'14px'}} class="graycolor">{item.StatusOfHistory + ' ' +  formatDateInText(item.HistoryCreatedOn)}</span></div>
                                                    </div>

                                                    {HitoryDetailsList.length > 0 && index == 0 && !(item.historyStatusName == 'Completed' || item.historyStatusName == 'Cancel') ? 
                                                        (<div class="   col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12  pe-4    ptremove ">
                                                            <div class=" justify-content-end  d-flex PendingbyContainer">
                                                                <div class="Pendingby d-flex justify-content-center "><span>{item.DaysSinceCreatedOn}</span> </div>
                                                            </div>
                                                        </div>):
                                                    null}
                                                    <div class="row ps-2  pt-2">
                                                        <div class=" col-12 ">
                                                            {/* <div style={{width: '95%'}} class="remarkPreTag ps-1 font-weight-700">{item.HistoryRemark}</div> */}
                                                            <div  style={{width: '95%'}} class="remarkPreTag ps-1 font-weight-700" dangerouslySetInnerHTML={{ __html:item.HistoryRemark.replace(/\n/g, '<br>')}}/>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="row ps-2 pt-2">


                                                    <div class="col-12  mb-2 ">
                                                        <div class="ViewAttachFile">

                                                            <div class="custom-upload floatleft d-flex justify-content-evenly" style={{width: '100%'}} onClick={() => getAllFileDetailsbyHistoryId(item.HistoryID)}>
                                                                <img title="View" src={EyeIcon} style={{width: '22px'}} alt="Alternate Text" />
                                                                <div style={{cursor: 'pointer', fontSize: '14px'}} class="ptfm optw">View </div>
                                                                <div>|&nbsp;{item.AttachedFileCount}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>))}
                                    </div>

                                    {/* ---------------------------------------History List View Ends --------------------------------------*/}
                            </div>
                        </Modal.Body>
                    <Modal.Footer>
                        <div className='py-2  w-100'>
                            <p className='mb-0' style={{fontSize:'16px'}}>Comment</p>
                            <div className='d-flex'>
                                <div style={{width:'95%'}}>
                                    <textarea placeholder='Enter your comments' className='normalSelectStyle'  style={{padding:'0.4rem',width:'100%', maxHeight:'400px'}} name="ATHistoryComment"  value={ATHistoryComment}  onChange={(e) => setATHistoryComment(e.target.value)}/>
                                </div>
                                <div  style={{width:'5%', display:'flex', justifyContent:'end', alignItems:'center'}} >
                                    <div onClick={() => {addCommentFromActionTrackerHistory(HitoryDetailsList[0].ID,HitoryDetailsList[0].ActionAssignedToId)}}  className="cursor-pointer d-flex px-2 py-1">
                                        <img title="Save" src={SendChatIcon} className='inner-iconSize me-3 'style={{height:'25px',width:'25px'}} alt="Save" />
                                        {/* <span style={{fontWeight: 600,color: 'white'}}> {'Save'}</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>)}

    {showOpenActionMRMComments && (
                <div className="popup">
                    <div ref={popupRef} className="popup-inner-Chat">
                        {/* <div className='mx-2'> */}
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between align-items-center col-12 '>
                                    <span style={{fontSize:'20px', color:'#21A8E1'}}>Comments</span>
                                    <img title="Cancel" onClick={() => CloseOpenActionMRMComments()} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.8 rem'}} alt="Add Milestone" />
                                </div>
                            </div>
                            <div>
                                <hr style={{color:'#21A8E1'}}/>
                            </div>
                            <div className='ChatBodyDetails' style={{maxHeight:'68vh',minHeight:'68vh',overflowY:'auto'}}>
                                {OpenActionMRMChatDetails.map((item) => (
                                    <div style={{ display : 'flex',justifyContent : item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start'}}>
                                        <div className=' d-flex mb-4' style={{justifyContent: item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start',width:'90%'}}>
                                            {item.ActionByUserId != userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Add Milestone" /> : null}
                                            <div className='custom-bubblewidth'>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}} className= {item.ActionByUserId == userData['UserId'] ? 'chatbackgroundmyself me-3 w-100' : 'chatbackgroundother me-3 w-100'}>
                                                    {/* <span style={{fontSize:'14px'}}>{item.Comment}</span> */}
                                                    <div  style={{fontSize:'14px'}} dangerouslySetInnerHTML={{ __html:item.OpenActionComment.replace(/\n/g, '<br>')}}/>
                                                </div>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}}  className= 'd-flex  justify-content-between me-3 gap-2  w-100'>
                                                    <span style={{fontSize:'12px'}}>{item.ActionByUserIdName}</span>
                                                    <span style={{fontSize:'12px',display:'flex',flexShrink:'0'}}>{formatDateTimeForChat(item.CreatedOn)}</span>
                                                </div>
                                            </div>
                                            {item.ActionByUserId == userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px',marginLeft:'0.7rem'}} alt="Add Milestone" /> : null}
                                        </div>
                                    </div>
                                ))}
                                {OpenActionMRMChatDetails.length == 0? <div className='d-flex justify-content-center'>No comments</div>:null}
                            </div>
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between col-12 mb-2'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={ProfIcon} className='cursor-pointer me-1' style={{height:'25px',width:'25px'}} alt="Add Comments" /> 
                                    </div>
                                    <input
                                        style={{width : '90%',padding:'0.4rem'}}
                                        type="text"
                                        name="Milestonecommenttext"
                                        placeholder='Write a comment'
                                        className='normalSelectStyle'
                                        value={OpenActionMRMCommentsText}
                                        onChange={(e) => setOpenActionMRMCommentsText(e.target.value)}
                                    />
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <img title="Send" onClick={() => AddOpenActionMRMComments(OpenActionMRMIDForChat,OpenActionMRMIDForChatAssignedTo)} src={SendChatIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Send Icon" /> 
                                        </div>
                                 
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            )}

{isFileViewerOpenHIstory && (
    <div className="popup" style={{ zIndex: 5000 }}>
        <div className="popup-inner-xl">
            <div className="row m-2">
                <div className="d-flex justify-content-between align-items-center col-12">
                    <span style={{ fontSize: '20px', color: '#21A8E1' }}>View Attachments</span>
                    <img
                        title="Close"
                        onClick={() => setisFileViewerOpenHIstory(false)}
                        src={CancelBluIconComment}
                        className="cursor-pointer"
                        style={{ height: '40px', width: '40px'}}
                        alt="Close"
                    />
                </div>
            </div>
            <div>
                <hr style={{ color: '#21A8E1' }} />
            </div>
            <div className="ChatBodyDetails" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                {isFileViewerHistoryList.length > 0 ? (
                    isFileViewerHistoryList.map((item, index) => (
                        <div key={index} className="justify-content-between mb-4" style={{ display: 'flex' }}>
                            <div className="d-flex gap-2 w-100">
                                <div>{`${index + 1}) `}</div>
                                <div className="d-flex justify-content-between gap-2 w-100">
                                    <div>{item.FileName}</div>
                                    <div className="d-flex align-items-center gap-2">
                                        <img
                                            title="View"
                                            onClick={() => openSavedFileViewerPopup(item, true)}
                                            src={EyeIcon}
                                            style={{ height: '17px', marginTop: '2px' }}
                                            className="cursor-pointer"
                                            alt="view"
                                        />
                                        <img
                                            title="Download"
                                            onClick={() => handleDownloadFile(item.Filepath, item.FileName, true)}
                                            src={DownloadFile}
                                            style={{ height: '22px' }}
                                            className="cursor-pointer"
                                            alt="download"
                                        />
                                        <img
                                            title="Delete"
                                            onClick={() => deleteDraftUploadedFile(index)}
                                            src={DeleteFile}
                                            style={{ height: '22px' }}
                                            className="cursor-pointer"
                                            alt="delete"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No files available</p>
                )}
            </div>
        </div>
    </div>
)}



{/* {isFileViewerOpenHIstory && (
    <div className="popup" style={{ zIndex: 5000 }}>
        <div className="popup-inner-xl">
            {/* Header Section */}
            {/* <div className='row m-2'>
                <div className='d-flex justify-content-between align-items-center col-12'>
                    <span style={{ fontSize: '20px', color: '#21A8E1' }}>View Attachments</span>
                    <img
                        title="Close"
                        onClick={() => setisFileViewerOpenHIstory(false)}
                        src={CancelBluIconComment}
                        className='cursor-pointer'
                        style={{ height: '40px', width: '40px', padding: '0.5rem' }}
                        alt="Close"
                    />
                </div>
            </div> */}

            {/* Divider */}
            {/* <div><hr style={{ color: '#21A8E1' }} /></div> */}

            {/* File List Section */}
            {/* <div className='ChatBodyDetails' style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                {Array.isArray(isFileViewerHistoryList) && isFileViewerHistoryList.length > 0 ? (
                    isFileViewerHistoryList.map((item, index) => (
                        <div key={index} className='justify-content-between mb-4' style={{ display: 'flex' }}>
                            <div className='d-flex gap-2 w-100'>
                                <div>{`${index + 1}) `}</div>
                                <div className='d-flex justify-content-between gap-2 w-100'>
                                    <div>{item.FileName}</div>
                                    <div className='d-flex align-items-center gap-2'>
                                        <img
                                            title="View"
                                            onClick={() => openSavedFileViewerPopup(item, true)}
                                            src={EyeIcon}
                                            style={{ height: '17px', marginTop: '2px' }}
                                            className="d-flex center cursor-pointer"
                                            alt="eye"
                                        />
                                        <img
                                            title='Download'
                                            onClick={() => handleDownloadFile(item.Filepath, item.FileName, true)}
                                            src={DownloadFile}
                                            style={{ height: '22px' }}
                                            className="cursor-pointer"
                                            alt="download"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No files available</p>
                )}
            </div>
        </div>
    </div>
)} */} 


            
            {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-center'>
                                <p className="head-text">Delete Item</p>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Mention Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}
                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                />
                            </label>
                            <button onClick={() => setShowConfirmationModal(!showConfirmationModal)} className="cancel-btn">Cancel</button>
                            <button type="submit" className="confirm-btn">Confirm</button>
                        </form>
                    </div>
                </div>
            )}

                 {/* Validation popup start */}
         {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text" style={{fontSize:"20px"}}>Information</p>
                            </div>
                        </div>
                        <div className='ChatBodyDetails' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto',overflowY:'auto', padding:"0rem"}}>
                                {errorList.map((item, index) => (
                                    <div style={{ display : 'flex'}}>
                                        <div className=' d-flex mb-3' style={{width:'90%'}}>
                                            {`${index+1}) ${item}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        <button className="close-btn" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
             {/* File Viewer */}
             {isFileViewerOpen && (
                    <div className="popup" style={{zIndex:1000}}>
                        <div className="popup-inner-xl">
                                <div className='row m-2'>
                                    <div className='d-flex justify-content-between align-items-center col-12 '>
                                        <span style={{fontSize:'20px', color:'#21A8E1'}}>View Attachments</span>
                                        <img title='Close' onClick={() => setIsFileViewerOpen(false)} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px'}} alt="Close" />
                                    </div>
                                </div>
                                <div>
                                    <hr style={{color:'#21A8E1'}}/>
                            </div>
                            <div className='ChatBodyDetails' style={{ maxHeight: '70vh', minHeight: '70vh', minHeight: 'auto', overflowY: 'auto' }}>
                                {SavedFileList.map((item, index) => (
                                    <div className='justify-content-between mb-4' style={{ display: 'flex' }}>
                                        <div className='d-flex gap-2 w-100'>
                                            <div>
                                                {`${index + 1}) `}
                                            </div>
                                            <div className='d-flex justify-content-between gap-2 w-100'>
                                                <div>
                                                    {`${item.FileName}`}
                                                </div>

                                                <div className='d-flex align-items-center gap-2'>
                                                    <img title='View' onClick={() => openSavedFileViewerPopup(item,true)} src={EyeIcon} style={{ height: '17px', marginTop: '2px' }} className="d-flex center cursor-pointer" alt="eye" />
                                                    <img title="Download" onClick={() => handleDownloadFile(item.Filepath,item.FileName,true)} src={DownloadFile} style={{ height: '22px' }} className="cursor-pointer" alt="download" />
                                                    <img title="Delete" onClick={() => deleteUploadedFile(item.ID)} src={DeleteFile} style={{ height: '22px' }} className="cursor-pointer" alt="delete" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                                {draftFileList.map((item, index) => (
                                    <div className='justify-content-between mb-4' style={{ display: 'flex' }}>
                                        <div className='d-flex gap-2 w-100'>
                                            <div>
                                                {`${SavedFileList.length + (index + 1)}) `}
                                            </div>
                                            <div className='d-flex justify-content-between gap-2 w-100'>
                                                <div>
                                                    {`${item.name}`}
                                                </div>

                                                <div className='d-flex align-items-center gap-2'>
                                                    <img title='View' onClick={() => openSavedFileViewerPopup(item,false)} src={EyeIcon} style={{ height: '17px', marginTop: '2px' }} className="d-flex center cursor-pointer" alt="eye" />
                                                    <img title='Download' onClick={() => handleDownloadFile(item,null,false)} src={DownloadFile} style={{ height: '22px' }} className="cursor-pointer" alt="download" />
                                                    <img title='Delete' onClick={() => deleteDraftUploadedFile(index)} src={DeleteFile} style={{ height: '22px' }} className="cursor-pointer" alt="delete" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
            )}

               {/* Opened File changes */}
               {isFileViewerOpenModal && (
                <div className="popup" style={{zIndex:6000}}>
                    <div className="popup-inner-xl">
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between align-items-center col-12 '>
                                    <span style={{fontSize:'20px', color:'#21A8E1'}}>File Viewer</span>
                                    <div>
                                        <img
                                            src={DownloadFile}
                                            title="Download"
                                            onClick={() => selectedFileOnserver ? handleDownloadFile(filePath,viewedFileName,true) : handleDownloadFile(CompleteFileData,null,false) }
                                            className="cursor-pointer fileDownloadInFilePopup"
                                            alt="Download"
                                        />
                                        {/* <img
                                            src={DeleteFile}
                                            title="Delete file"
                                            className="cursor-pointer fileDownloadInFilePopup ms-2"
                                            alt="Download"
                                        /> */}
                                        <img title='Close' onClick={() => setFileViewerClose()} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px'}} alt="Close" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <hr style={{color:'#21A8E1'}}/>
                            </div>
                            <div className='ChatBodyDetails' style={{ maxHeight: '65vh', minHeight: '70vh', minHeight: 'auto', overflowY: 'auto' }}>
                                {/* {isFileError && <div className="alert alert-danger">File Error</div>}
                                {isImage && <img src={filePath} alt="file" />}
                                {isPDF && (
                                <object id="objPDFTyepFile" data={filePath + '#toolbar=0'} type="application/pdf" width="100%" height="500px">
                                    <p>Your browser does not support PDFs.</p>
                                </object>
                                )}
                                {isVideo && <video src={filePath} controls />} */}
                                <div className="container" hidden={isFileError}>
                                    {isImage && (
                                        <div className="row">
                                        <img src={filePath} className="img-fluid" alt="file" />
                                        </div>
                                    )}
                                    {isPDF && (
                                        <div className="row">
                                        <object data={filePath + '#toolbar=0'} type="application/pdf" id="objPDFTyepFile" style={{ height: '66vh' }}>
                                            <p className="object">
                                            Your web browser doesn't have a PDF plugin.
                                            Instead you can <a style={{ color: 'blue', textDecoration: 'underline' }} href={filePath} download>
                                                Click here to download the PDF file.
                                            </a>
                                            </p>
                                        </object>
                                        </div>
                                    )}
                                    {isVideo && (
                                        <div className="row">
                                        <video controls="controls">
                                            <source src={filePath} type="video/mp4" />
                                        </video>
                                        </div>
                                    )}
                                    </div>
                                    <div className="container" hidden={!isFileError}>
                                    <div>
                                        File cannot be viewed. Please download the file.
                                    </div>
                                    </div>
                                </div>
                    </div>
                </div>
            )}

            {/* Validation popup end */}

            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}

            <div className="monthlyHeaderWrapper d-flex justify-content-between">
                <span className="monthly-Title-Table-header">Open Action Items</span>
             
            </div>

            {isLoading ? (<SmallSizedLoader />) :
                (<div className="table-div padStyle ">
                    <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff" , zIndex:9999999999}} />
                    <div className="table-container">
                        <table className="table-container-subdiv open-actionTable action-summary-mrm">
                            <thead className="action-head">
                                <tr>
                                <th style={{ width: "1%" }} className="column-header-style fixed-column sr-column">Sr. No.</th>
                                <th style={{ width: "3%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                    <div>Project  
                                    <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>

                                    </div>
                                </div>
                                </th>
                                <th style={{ width: "3%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                    <div>Milestone  
                                    <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>

                                    </div>
                                </div>
                                </th>
                                <th style={{ width: "3%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                    <div>Category  
                                    <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>

                                    </div>
                                </div>
                                </th>

                                <th style={{ width: "3%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Code</div>
                                </div>
                                </th>
                              
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Associated Action</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Action Description</div>
                                        
                                        </div>
                                </th>

                                <th style={{ width: "3%" }} className="column-header-style">
                                    <div className="d-flex justify-content-between">
                                        <div>Priority
                                            <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>
                                        </div>
                                    </div>
                                </th>

                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Update Status
                                <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>
                                </div>
                                  </div>
                                </th>
                                <th style={{ width: "3%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Overall Status</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Responsible (Doer) <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span></div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Accountable</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Consult</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Inform</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "2%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Start Date <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span></div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "2%" }} className="column-header-style">
                                    <div className="d-flex justify-content-between">
                                        <div>Due Date
                                        <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>
                                            
                                        </div>
                                    </div>
                                </th>
                                <th style={{ width: "2%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Duration (Auto Calculate)</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "2%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Estimate Efforts (Man Hours)</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Sub Action</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Ref. Meeting
                                <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>

                                </div>
                                        
                                        </div>
                                </th>
                            
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Attach Files</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "3%" }} className="column-header-style">
                                    <div className="d-flex justify-content-between">
                                        <div>Recurring
                                            {/* <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span> */}
                                        </div>
                                    </div>
                                </th>
                                <th style={{ width: "3%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Recurring Frequency</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "2%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Recurring Start Date</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "2%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Recurring Finish Date</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "3%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>% Completion</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "2%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Actual Start Date</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "2%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Actual Finish Date</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Remarks</div>
                                        
                                        </div>
                                </th>
                                <th style={{ width: "3%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Dependency</div>
                                        
                                        </div>
                                </th>
                                {/* <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>CMO Comments</div>
                                        
                                        </div>
                                </th> */}
                            
                                <th style={{ width: "0%", display: props.ismonth ? "none" : "table-cell" }} className="column-header-style bg-wh fixed-column actions-column ">Actions</th>
                                        </tr>
                            </thead>
                            <tbody className="action-body">
                            {actionTrackerMRMList.length > 0 ? (
                                actionTrackerMRMList.map((item, index) =>
                                    
                                    <tr key={index} className={editableRows.includes(index) ? "editable-row" : ""}>
                                        {/* # */}
                                        <td className="column-data-style fixed-column sr-column">
                                            {index + 1}
                                        </td>

                                         {/* Project */}
                                         <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                               <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                               <Select
                                                    id={index}
                                                    options={getProjectList}
                                                    value={setSelectValue(getProjectList, actionTrackerMRMList[index].Project)}
                                                    styles={customStyles}
                                                    menuPosition={'fixed'}
                                                    maxMenuHeight={100}
                                                    onChange={(e) => handleProjectChange(index, e)}
                                                    closeMenuOnScroll={() => !isSelectHovered}
                                                    onMenuOpen={() => setIsProjectDropdownOpen(true)}
                                                    onMenuClose={() => setIsProjectDropdownOpen(false)}
                                                    menuIsOpen={isProjectDropdownOpen}
                                                    menuPortalTarget={null}
                                               />
                                           </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_Project}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                                 <Select
                                                                        id={index}
                                                                        options={getProjectList}
                                                                        value={setSelectValue(getProjectList, actionTrackerMRMList[index].Project)}
                                                                        styles={customStyles}
                                                                        menuPosition={'fixed'}
                                                                        maxMenuHeight={100}
                                                                        onChange={(e) => handleProjectChange(index, e)}
                                                                        closeMenuOnScroll={() => !isSelectHovered}
                                                                        onMenuOpen={() => setIsProjectDropdownOpen(true)}
                                                                        onMenuClose={() => setIsProjectDropdownOpen(false)}
                                                                        menuIsOpen={isProjectDropdownOpen}
                                                                        menuPortalTarget={null}
                                                                />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                         {/* Milestone */}
                                         <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                               <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                               <Select
                                                    id={index}
                                                    options={getMilestoneList}
                                                    value={setSelectValue(getMilestoneList, actionTrackerMRMList[index].Milestone)}
                                                    styles={customStyles}
                                                    menuPosition={'fixed'}
                                                    maxMenuHeight={100}
                                                    onChange={
                                                        (e) => handleInputChange(index, 'Milestone', e)
                                                    }
                                                    closeMenuOnScroll={() => !isSelectHovered}
                                                    onMenuOpen={() => setIsMilestoneDropdownOpen(true)}
                                                    onMenuClose={() => setIsMilestoneDropdownOpen(false)}
                                                    menuIsOpen={isMilestoneDropdownOpen}
                                                    menuPortalTarget={null}
                                               />
                                           </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_Milestone}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                                 <Select
                                                                    id={index}
                                                                    options={getMilestoneList}
                                                                    value={setSelectValue(getMilestoneList, actionTrackerMRMList[index].Milestone)}
                                                                    styles={customStyles}
                                                                    menuPosition={'fixed'}
                                                                    maxMenuHeight={100}
                                                                    onChange={
                                                                        (e) => handleInputChange(index, 'Milestone', e)
                                                                    }
                                                                    closeMenuOnScroll={() => !isSelectHovered}
                                                                    onMenuOpen={() => setIsMilestoneDropdownOpen(true)}
                                                                    onMenuClose={() => setIsMilestoneDropdownOpen(false)}
                                                                    menuIsOpen={isMilestoneDropdownOpen}
                                                                    menuPortalTarget={null}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                            {/* Category*/}
                                            <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                               <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                               <Select
                                                    id={index}
                                                    options={getCategoryList}
                                                    value={setSelectValue(getCategoryList, actionTrackerMRMList[index].Category)}
                                                    styles={customStyles}
                                                    menuPosition={'fixed'}
                                                    maxMenuHeight={100}
                                                    onChange={
                                                        (e) => handleInputChange(index, 'Category', e)
                                                    }
                                                    closeMenuOnScroll={() => !isSelectHovered}
                                                    onMenuOpen={() => setIsCategoryDropdownOpen(true)}
                                                    onMenuClose={() => setIsCategoryDropdownOpen(false)}
                                                    menuIsOpen={isCategoryDropdownOpen}
                                                    menuPortalTarget={null}
                                               />
                                           </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_Category}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                               <Select
                                                                    id={index}
                                                                    options={getCategoryList}
                                                                    value={setSelectValue(getCategoryList, actionTrackerMRMList[index].Category)}
                                                                    styles={customStyles}
                                                                    menuPosition={'fixed'}
                                                                    maxMenuHeight={100}
                                                                    onChange={
                                                                        (e) => handleInputChange(index, 'Category', e)
                                                                    }
                                                                    closeMenuOnScroll={() => !isSelectHovered}
                                                                    onMenuOpen={() => setIsCategoryDropdownOpen(true)}
                                                                    onMenuClose={() => setIsCategoryDropdownOpen(false)}
                                                                    menuIsOpen={isCategoryDropdownOpen}
                                                                    menuPortalTarget={null}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                        {/* Code */}
                                        <td className="column-data-style">
                                         
                                                <div>
                                                    <p>{item.Previous_Code}</p>
                                                    {/* {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Code} onChange={(e) => handleInputChange(index, 'Code', e.target.value)} />
                                                        : ""
                                                    } */}
                                                </div> 
                                            
                                        </td>

                                        {/* Associated Action*/}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                            
                                            <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.AssociatedAction} onChange={(e) => handleInputChange(index, 'AssociatedAction', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_AssociatedAction}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.AssociatedAction} onChange={(e) => handleInputChange(index, 'AssociatedAction', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* Action Description */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                            
                                            <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.ActionDescription} onChange={(e) => handleInputChange(index, 'ActionDescription', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_ActionDescription}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.ActionDescription} onChange={(e) => handleInputChange(index, 'ActionDescription', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* Priority */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                               <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <Select
                                                    id={index}
                                                    options={getPriorityList}  
                                                    value={setSelectValue(getPriorityList, actionTrackerMRMList[index].Priority)} 
                                                    styles={customStyles}
                                                    menuPosition={'fixed'}
                                                    maxMenuHeight={100}
                                                    onChange={(e) => handleInputChange(index, 'Priority', e)}  
                                                    closeMenuOnScroll={() => !isSelectHovered}
                                                    onMenuOpen={() => setIsPriorityDropdownOpen(true)} 
                                                    onMenuClose={() => setIsPriorityDropdownOpen(false)}
                                                    menuIsOpen={isPriorityDropdownOpen}
                                                    menuPortalTarget={null}
                                                />

                                           </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_Priority}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={getPriorityList}  
                                                                value={setSelectValue(getPriorityList, actionTrackerMRMList[index].Priority)} 
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={(e) => handleInputChange(index, 'Priority', e)}  
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setIsPriorityDropdownOpen(true)} 
                                                                onMenuClose={() => setIsPriorityDropdownOpen(false)}
                                                                menuIsOpen={isPriorityDropdownOpen}
                                                                menuPortalTarget={null}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                   

                                           {/* Status 1 */}
                                           <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                               <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                               <Select
                                                   id={index}
                                                   options={getStatusMasterList}
                                                   value={setSelectValue(getStatusMasterList, actionTrackerMRMList[index].Status1)}
                                                   styles={customStyles}
                                                   menuPosition={'fixed'}
                                                   maxMenuHeight={100}
                                                   onChange={
                                                       (e) => handleInputChange(index, 'Status1', e)
                                                   }
                                                   closeMenuOnScroll={() => !isSelectHovered}
                                                   onMenuOpen={() => setIsStatusMasterDropdownOpen(true)}
                                                   onMenuClose={() => setIsStatusMasterDropdownOpen(false)}
                                                   menuIsOpen={isStatusMasterDropdownOpen}
                                                   menuPortalTarget={null}
                                               />
                                           </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_Status1}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={getStatusMasterList}
                                                                value={setSelectValue(getStatusMasterList, actionTrackerMRMList[index].Status1)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'Status1', e)
                                                                }
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setIsStatusMasterDropdownOpen(true)}
                                                                onMenuClose={() => setIsStatusMasterDropdownOpen(false)}
                                                                menuIsOpen={isStatusMasterDropdownOpen}
                                                                menuPortalTarget={null}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                        {/* Overall Status */}
                                        <td className="column-data-style" >
                                                <div style={{display:"flex", justifyContent:"center"}}>
                                                    {editableRows.includes(index)  ? (
                                                        <div style={{color: item.Status1 === 'Completed'
                                                            ? 'black' : compareDate(item.DueDate) == 'Delayed' ? 'red' : compareDate(item.DueDate) == 'On Track'  ? '#41a35f' : 'black'}} className='mb-3 mt-3 itemAlignCenterCommon'>{item.DueDate && item.DueDate !=null && item.Status1 != 'Completed' ?  compareDate(item.DueDate) : "-"}</div>)
                                                        :
                                                        (<div style={{ color: item.Status1 === 'Completed'
                                                            ? 'black' :compareDate(item.DueDate) == 'Delayed' ? 'red' : compareDate(item.DueDate) == 'On Track'  ? '#41a35f' : 'black'}} className='mb-3 mt-3'>{item.DueDate && item.DueDate !=null && item.Status1 != 'Completed' ?  compareDate(item.DueDate) : "-"}</div>)
                                                    }
                                                </div>
                                        </td>

                                        {/* Responsible (Doer) */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                               <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                               <Select
                                                   id={index}
                                                   options={getResponsibleList}
                                                   value={setSelectValue(getResponsibleList, actionTrackerMRMList[index].ResponsibleDoer)}
                                                   styles={customStyles}
                                                   menuPosition={'fixed'}
                                                   maxMenuHeight={100}
                                                   onChange={
                                                       (e) => handleInputChange(index, 'ResponsibleDoer', e)
                                                   }
                                                   closeMenuOnScroll={() => !isSelectHovered}
                                                   onMenuOpen={() => setIsResponsibleDropdownOpen(true)}
                                                   onMenuClose={() => setIsResponsibleDropdownOpen(false)}
                                                   menuIsOpen={isResponsibleDropdownOpen}
                                                   menuPortalTarget={null}
                                               />
                                           </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_ResponsibleDoer}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                               <Select
                                                                    id={index}
                                                                    options={getResponsibleList}
                                                                    value={setSelectValue(getResponsibleList, actionTrackerMRMList[index].ResponsibleDoer)}
                                                                    styles={customStyles}
                                                                    menuPosition={'fixed'}
                                                                    maxMenuHeight={100}
                                                                    onChange={
                                                                        (e) => handleInputChange(index, 'ResponsibleDoer', e)
                                                                    }
                                                                    closeMenuOnScroll={() => !isSelectHovered}
                                                                    onMenuOpen={() => setIsResponsibleDropdownOpen(true)}
                                                                    onMenuClose={() => setIsResponsibleDropdownOpen(false)}
                                                                    menuIsOpen={isResponsibleDropdownOpen}
                                                                    menuPortalTarget={null}
                                                                />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                         {/* Accountable */}
                                         <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.Accountable} onChange={(e) => handleInputChange(index, 'Accountable', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{(item.Previous_Accountable)}</p>
                                                    {item.is_edit ?
                                                        <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.Accountable} onChange={(e) => handleInputChange(index, 'Accountable', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                         {/* Consult */}
                                         <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.Consult} onChange={(e) => handleInputChange(index, 'Consult', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{(item.Previous_Consult)}</p>
                                                    {item.is_edit ?
                                                        <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.Consult} onChange={(e) => handleInputChange(index, 'Consult', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                         {/* Inform */}
                                         <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.Inform} onChange={(e) => handleInputChange(index, 'Inform', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{(item.Previous_Inform)}</p>
                                                    {item.is_edit ?
                                                        <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.Inform} onChange={(e) => handleInputChange(index, 'Inform', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                         {/* Start Date */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input 
                                                    className="tableInput inputStyle" 
                                                    style={{ width: "100%" }} 
                                                    type="date" 
                                                    value={item.StartDate ? item.StartDate.slice(0, 10) : ''} 
                                                    onChange={(e) => handleInputChange(index, 'StartDate', e.target.value || null)} 
                                                />
                                            ) : (
                                                <div>
                                                <p>{item.Previous_StartDate ? formatDate(item.Previous_StartDate) : null}</p>
                                                    {item.is_edit ? (
                                                        <input 
                                                            className="tableInput inputStyle" 
                                                            style={{ width: "100%" }} 
                                                            type="date" 
                                                            value={item.StartDate ? item.StartDate.slice(0, 10) : ''} 
                                                            onChange={(e) => handleInputChange(index, 'StartDate', e.target.value || null)} 
                                                        />
                                                    ) : ""}
                                                </div>
                                            )}
                                        </td>

                                       {/* Due Date  */}
                                       <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput inputStyle" style={{ width: "100%" }} type="date" value={item.DueDate ? item.DueDate.slice(0, 10) : ''} onChange={(e) => handleInputChange(index, 'DueDate', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{formatDate(item.Previous_DueDate)}</p>
                                                    {item.is_edit ?
                                                        <input className="tableInput inputStyle" style={{ width: "100%" }} type="date" value={item.DueDate ? item.DueDate.slice(0, 10) : ''} onChange={(e) => handleInputChange(index, 'DueDate', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                         {/* Duration (Auto Calculate) */}
                                         <td className="column-data-style">
                                           <p>
                                                {/* {duration} */}
                                                {calculateDateDifference(item.StartDate, item.DueDate)}
                                           </p>
                                           
                                        </td>

                                            {/* Estimated Efforts (Man Hours) */}
                                            <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.EstimatedEfforts} onChange={(e) => handleInputChange(index, 'EstimatedEfforts', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{(item.Previous_EstimatedEfforts)}</p>
                                                    {item.is_edit ?
                                                        <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.EstimatedEfforts} onChange={(e) => handleInputChange(index, 'EstimatedEfforts', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                           {/* Sub Action */}
                                           <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.SubAction} onChange={(e) => handleInputChange(index, 'SubAction', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{(item.Previous_SubAction)}</p>
                                                    {item.is_edit ?
                                                        <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.SubAction} onChange={(e) => handleInputChange(index, 'SubAction', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                           {/* Ref. Meeting */}
                                           <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.RefMeeting} onChange={(e) => handleInputChange(index, 'RefMeeting', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{(item.Previous_RefMeeting)}</p>
                                                    {item.is_edit ?
                                                        <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.RefMeeting} onChange={(e) => handleInputChange(index, 'RefMeeting', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                           {/* Attach Files */}
                                            {/* <td style={{ fontSize: '10px', borderBottom: '1px solid #ccc' }} className='w-10'>
                                           {editableRows.includes(index) ? (

                                                <div className='d-flex mt-2 mb-2'>
                                                    <div className="d-flex justify-content-center">
                                                        <div className="floatleft mt-3 mb-3" style={{ width: '100%' }}>
                                                          <p>  <img 
                                                                src={PaperClip} 
                                                                style={{ height: '13px', cursor: 'pointer' }} 
                                                                title='Upload' 
                                                                onClick={() => openFileSelectionPopUp()} 
                                                                alt="Upload" 
                                                            />
                                                            &nbsp;&nbsp;
                                                            <span onClick={() => openFileSelectionPopUp()} style={{ cursor: 'pointer' }}>Upload</span>
                                                            <input 
                                                                style={{ display: 'none' }} 
                                                                type="file" 
                                                                id={`trackerFiles-${index}`} // Unique ID for each file input
                                                                name='file' 
                                                                onChange={(e) => setFilesData(e)} // Pass index to the function
                                                                ref={fileInputRef} 
                                                                multiple 
                                                            />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-center ms-2" style={{ cursor: 'pointer' }}>
                                                        <div className="floatleft mt-3 mb-3" style={{ width: '100%' }}>
                                                            <img 
                                                                title='View' 
                                                                onClick={() => getAllFileDetailsbyHistoryId(item.ID, true, true)} 
                                                                src={EyeIcon} 
                                                                style={{ height: '17px', cursor: 'pointer' }} 
                                                                className="sysImage" 
                                                                alt="View" 
                                                            />
                                                            &nbsp;&nbsp;
                                                            <span onClick={() => getAllFileDetailsbyHistoryId(item.ID, true, true)} style={{ cursor: 'pointer' }}>View</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                                     ) 
                                                                    :
                                                                    (<div className="d-flex justify-content-center " >
                                                                        <div className="mt-3 mb-3">
                                                                            <img title='View' onClick={() =>  getAllFileDetailsbyHistoryId(item.ID,true)} src={EyeIcon} style={{ height: '17px' , cursor:'pointer'}} className="sysImage" alt="Alternate Text" />&nbsp;&nbsp;
                                                                            <span onClick={() =>  getAllFileDetailsbyHistoryId(item.ID,true)} style={{ cursor: 'pointer' }}>View</span><span style={{ cursor: 'pointer' }}></span>
                                                                        </div>
                                                                    </div>)
                                                                    } 
                                            </td> */}

                                            <td style={{ fontSize: '10px', borderBottom: '1px solid #ccc' }} className='w-10'>
                                            {editableRows.includes(index) || item.is_edit ? (
                                                // Editable Row - Show both Upload and View icons with functionality when item.is_edit is true
                                                <div className='d-flex mt-2 mb-2' style={{justifyContent:"center"}}>
                                                    {/* Upload Section */}
                                                    <div className="d-flex justify-content-center" >
                                                        <div className="floatleft mt-3 mb-3" style={{ width: '100%' }}>
                                                            <p>
                                                                <img 
                                                                    src={PaperClip} 
                                                                    style={{ height: '13px', cursor: 'pointer' }} 
                                                                    title='Upload' 
                                                                    onClick={() => openFileSelectionPopUp()} 
                                                                    alt="Upload" 
                                                                />
                                                                &nbsp;&nbsp;
                                                                <span onClick={() => openFileSelectionPopUp()} style={{ cursor: 'pointer' }}>Upload</span>
                                                                <input 
                                                                    style={{ display: 'none' }} 
                                                                    type="file" 
                                                                    id={`trackerFiles-${index}`} 
                                                                    name='file' 
                                                                    onChange={(e) => setFilesData(e)} 
                                                                    ref={fileInputRef} 
                                                                    multiple 
                                                                />
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* View Section in Editable Mode */}
                                                    <div className="d-flex justify-content-center ms-2" style={{ cursor: 'pointer' }}>
                                                        <div className="floatleft mt-3 mb-3" style={{ width: '100%' }}>
                                                            <img 
                                                                title='View' 
                                                                onClick={() => isAddClicked 
                                                                    ? openFileViewerPopup()
                                                                    : getAllFileDetailsbyHistoryId(item.ID, true, true)
                                                                }
                                                                src={EyeIcon} 
                                                                style={{ height: '17px', cursor: 'pointer' }} 
                                                                className="sysImage" 
                                                                alt="View" 
                                                            />
                                                            &nbsp;&nbsp;
                                                            <span 
                                                                onClick={() => isAddClicked 
                                                                    ? openFileViewerPopup()
                                                                    : getAllFileDetailsbyHistoryId(item.ID, true, true)
                                                                } 
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                View
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            ) : (
                                                // Non-editable Row - Display only the View icon without interactions
                                                <div className="d-flex justify-content-center ms-2" style={{ cursor: 'pointer' }}>
                                                        <div className="mt-3 mb-3" style={{ width: '100%', display:"flex", justifyContent:"center" }}>
                                                            <img 
                                                                title='View' 
                                                                onClick={() => getAllFileDetailsbyHistoryId(item.ID, true, true)} 
                                                                src={EyeIcon} 
                                                                style={{ height: '17px', cursor: 'pointer' }} 
                                                                className="sysImage" 
                                                                alt="View" 
                                                            />
                                                            &nbsp;&nbsp;
                                                            <span onClick={() => getAllFileDetailsbyHistoryId(item.ID, true, true)} style={{ cursor: 'pointer' }}>View</span>
                                                        </div>
                                                    </div>
                                            )}
                                        </td>


                               
                                          {/* Recurring */}
                                            <td style={{ fontSize: '14px', borderBottom: '1px solid #ccc' }} className='w-7'>
                                                {editableRows.includes(index) ? (
                                                    <div className="SelectDropdownContainer mb-3 mt-3">
                                                        {/* Dropdown for Recurring */}
                                                        <select
                                                            className='normalSelectStyle'
                                                            style={{ customStyles }}
                                                            name="Recurring"
                                                            value={item.Recurring == true ? 1 : 2}
                                                            onChange={(e) => handleInputChange(index, "Recurring", e.target.value)}  // Ensures Recurring is updated
                                                        >
                                                            <option value={0}>{'---Select---'}</option>
                                                            {getRecurringList.map((option) => (
                                                                <option key={option.Id} value={option.Id}>
                                                                    {option.Value}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p>{item.Previous_Recurring == true ? 'Yes' : 'No'}</p>
                                                        {item.is_edit ? (
                                                           <div className="SelectDropdownContainer mb-3 mt-3">
                                                           <select
                                                               className='normalSelectStyle'
                                                               style={{ customStyles }}
                                                               name="Recurring"
                                                               value={item.Recurring == true ? 1 : 2}
                                                               onChange={(e) => handleInputChange(index, "Recurring", e.target.value)}  // Ensures Recurring is updated
                                                           >
                                                               <option value={0}>{'---Select---'}</option>
                                                               {getRecurringList.map((option) => (
                                                                   <option key={option.Id} value={option.Id}>
                                                                       {option.Value}
                                                                   </option>
                                                               ))}
                                                           </select>
                                                       </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                )}
                                            </td>





                                          {/* Recurring Frequency */}
                                          <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                               <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                               <Select
                                                   id={index}
                                                   options={getFrequencyList}
                                                   value={setSelectValue(getFrequencyList, actionTrackerMRMList[index].Frequency)}
                                                   styles={customStyles}
                                                   menuPosition={'fixed'}
                                                   maxMenuHeight={100}
                                                   onChange={
                                                       (e) => handleInputChange(index, 'Frequency', e)
                                                   }
                                                   closeMenuOnScroll={() => !isSelectHovered}
                                                   onMenuOpen={() => setIsFrequencyDropdownOpen(true)}
                                                   onMenuClose={() => setIsFrequencyDropdownOpen(false)}
                                                   menuIsOpen={isFrequencyDropdownOpen}
                                                   menuPortalTarget={null}
                                               />
                                           </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_Frequency}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={getFrequencyList}
                                                                value={setSelectValue(getFrequencyList, actionTrackerMRMList[index].Frequency)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'Frequency', e)
                                                                }
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setIsFrequencyDropdownOpen(true)}
                                                                onMenuClose={() => setIsFrequencyDropdownOpen(false)}
                                                                menuIsOpen={isFrequencyDropdownOpen}
                                                                menuPortalTarget={null}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                         
                                     {/* Recurring Start Date */}
                                                <td className="column-data-style">
                                                    {editableRows.includes(index) ? (
                                                        <input 
                                                            className="tableInput inputStyle" 
                                                            style={{ width: "100%" }} 
                                                            type="date" 
                                                            value={item.RecurringStartDate ? item.RecurringStartDate.slice(0, 10) : ''} 
                                                            onChange={(e) => handleInputChange(index, 'RecurringStartDate', e.target.value || null)} 
                                                        />
                                                    ) : (
                                                        <div>
                                                          <p>{item.Previous_RecurringStartDate ? formatDate(item.Previous_RecurringStartDate) : null}</p>
                                                            {item.is_edit ? (
                                                                <input 
                                                                    className="tableInput inputStyle" 
                                                                    style={{ width: "100%" }} 
                                                                    type="date" 
                                                                    value={item.RecurringStartDate ? item.RecurringStartDate.slice(0, 10) : ''} 
                                                                    onChange={(e) => handleInputChange(index, 'RecurringStartDate', e.target.value || null)} 
                                                                />
                                                            ) : ""}
                                                        </div>
                                                    )}
                                                </td>

                                                {/* Recurring Finish Date */}
                                                <td className="column-data-style">
                                                    {editableRows.includes(index) ? (
                                                        <input 
                                                            className="tableInput inputStyle" 
                                                            style={{ width: "100%" }} 
                                                            type="date" 
                                                            value={item.RecurringEndDate ? item.RecurringEndDate.slice(0, 10) : ''} 
                                                            onChange={(e) => handleInputChange(index, 'RecurringEndDate', e.target.value || null)} 
                                                        />
                                                    ) : (
                                                        <div>
                                                         <p>{item.Previous_RecurringEndDate ? formatDate(item.Previous_RecurringEndDate) : null}</p>
                                                            {item.is_edit ? (
                                                                <input 
                                                                    className="tableInput inputStyle" 
                                                                    style={{ width: "100%" }} 
                                                                    type="date" 
                                                                    value={item.RecurringEndDate ? item.RecurringEndDate.slice(0, 10) : ''} 
                                                                    onChange={(e) => handleInputChange(index, 'RecurringEndDate', e.target.value || null)} 
                                                                />
                                                            ) : ""}
                                                        </div>
                                                    )}
                                                </td>


                                           {/* % Completion */}
                                           <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                               <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                               <Select
                                                   id={index}
                                                   options={getCompletiionList}
                                                   value={setSelectValue(getCompletiionList, actionTrackerMRMList[index].Completion)}
                                                   styles={customStyles}
                                                   menuPosition={'fixed'}
                                                   maxMenuHeight={100}
                                                   onChange={
                                                       (e) => handleInputChange(index, 'Completion', e)
                                                   }
                                                   closeMenuOnScroll={() => !isSelectHovered}
                                                   onMenuOpen={() => setIsCompletiionDropdownOpen(true)}
                                                   onMenuClose={() => setIsCompletiionDropdownOpen(false)}
                                                   menuIsOpen={isCompletiionDropdownOpen}
                                                   menuPortalTarget={null}
                                               />
                                           </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_Completion}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                               <Select
                                                                    id={index}
                                                                    options={getCompletiionList}
                                                                    value={setSelectValue(getCompletiionList, actionTrackerMRMList[index].Completion)}
                                                                    styles={customStyles}
                                                                    menuPosition={'fixed'}
                                                                    maxMenuHeight={100}
                                                                    onChange={
                                                                        (e) => handleInputChange(index, 'Completion', e)
                                                                    }
                                                                    closeMenuOnScroll={() => !isSelectHovered}
                                                                    onMenuOpen={() => setIsCompletiionDropdownOpen(true)}
                                                                    onMenuClose={() => setIsCompletiionDropdownOpen(false)}
                                                                    menuIsOpen={isCompletiionDropdownOpen}
                                                                    menuPortalTarget={null}
                                                                />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                    {/* Actual Start Date */}
                                            <td className="column-data-style">
                                                {editableRows.includes(index) ? (
                                                    <input 
                                                        className="tableInput inputStyle" 
                                                        style={{ width: "100%" }} 
                                                        type="date" 
                                                        value={item.ActualStartDate ? item.ActualStartDate.slice(0, 10) : ''} 
                                                        onChange={(e) => handleInputChange(index, 'ActualStartDate', e.target.value || null)} 
                                                    />
                                                ) : (
                                                    <div>
                                                      <p>{item.Previous_ActualStartDate ? formatDate(item.Previous_ActualStartDate) : null}</p>
                                                        {item.is_edit ? (
                                                            <input 
                                                                className="tableInput inputStyle" 
                                                                style={{ width: "100%" }} 
                                                                type="date" 
                                                                value={item.ActualStartDate ? item.ActualStartDate.slice(0, 10) : ''} 
                                                                onChange={(e) => handleInputChange(index, 'ActualStartDate', e.target.value || null)} 
                                                            />
                                                        ) : ""}
                                                    </div>
                                                )}
                                            </td>

                                            {/* Actual Finish Date */}
                                            <td className="column-data-style">
                                                {editableRows.includes(index) ? (
                                                    <input 
                                                        className="tableInput inputStyle" 
                                                        style={{ width: "100%" }} 
                                                        type="date" 
                                                        value={item.ActualFinishDate ? item.ActualFinishDate.slice(0, 10) : ''} 
                                                        onChange={(e) => handleInputChange(index, 'ActualFinishDate', e.target.value || null)} 
                                                    />
                                                ) : (
                                                    <div>
                                                      <p>{item.Previous_ActualFinishDate ? formatDate(item.Previous_ActualFinishDate) : null}</p>
                                                        {item.is_edit ? (
                                                            <input 
                                                                className="tableInput inputStyle" 
                                                                style={{ width: "100%" }} 
                                                                type="date" 
                                                                value={item.ActualFinishDate ? item.ActualFinishDate.slice(0, 10) : ''} 
                                                                onChange={(e) => handleInputChange(index, 'ActualFinishDate', e.target.value || null)} 
                                                            />
                                                        ) : ""}
                                                    </div>
                                                )}
                                            </td>


                                              {/* Remarks*/}
                                              <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Remark} onChange={(e) => handleInputChange(index, 'Remark', e.target.value)} />
                                            ) : (
                                                <div>
                                                    {item.Previous_Remark && (
                                                        <pre className="preStyleFormat" >
                                                            {item.Previous_Remark}
                                                        </pre>
                                                    )}
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Remark} onChange={(e) => handleInputChange(index, 'Remark', e.target.value)} />
                                                        : ""
                                                    }
                                                </div> 
                                            )}
                                        </td>

                                           {/* Dependency */}
                                           <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                               <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                               <Select
                                                   id={index}
                                                   options={  
                                                        getDependencyList.filter(
                                                        (items) =>
                                                            items.MilestoneID === actionTrackerMRMList[index].Milestone.value &&
                                                            items.ProjectID === actionTrackerMRMList[index].Project.value
                                                    )
                                                    
                                                    }
                                                   value={setSelectValue(getDependencyList, actionTrackerMRMList[index].Dependency)}
                                                   styles={customStyles}
                                                   menuPosition={'fixed'}
                                                   maxMenuHeight={100}
                                                   onChange={
                                                       (e) => handleInputChange(index, 'Dependency', e)
                                                   }
                                                   closeMenuOnScroll={() => !isSelectHovered}
                                                   onMenuOpen={() => setIsDependencyDropdownOpen(true)}
                                                   onMenuClose={() => setIsDependencyDropdownOpen(false)}
                                                   menuIsOpen={isDependencyDropdownOpen}
                                                   menuPortalTarget={null}
                                               />
                                           </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_Dependency}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                             <Select
                                                                id={index}
                                                                options={
                                                                    item.ProjectID != null && item.MilestoneID != null
                                                                        ? getDependencyList.filter((items) =>
                                                                            items.label != item.Code &&
                                                                            items.MilestoneID == item.MilestoneID &&
                                                                            items.ProjectID == item.ProjectID
                                                                            
                                                                        )
                                                                        : []
                                                                }
                                                                value={setSelectValue(getDependencyList, actionTrackerMRMList[index].Dependency)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={(e) => handleInputChange(index, 'Dependency', e)}
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setIsDependencyDropdownOpen(true)}
                                                                onMenuClose={() => setIsDependencyDropdownOpen(false)}
                                                                menuIsOpen={isDependencyDropdownOpen}
                                                                menuPortalTarget={null}
                                                            />

                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                                
                                       {/* CMO Comments */}
                                       {/* <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.CMOComments} onChange={(e) => handleInputChange(index, 'CMOComments', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{(item.Previous_CMOComments)}</p>
                                                    {item.is_edit ?
                                                <input className="tableInput inputStyle" style={{ width: "100%" }} type="text" value={item.CMOComments} onChange={(e) => handleInputChange(index, 'CMOComments', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td> */}

                                        {/* ACTION */}
                                        <td className={index % 2 === 0 ? "column-data-style bg-ylw" : "column-data-style bg-wh"} style={{display: props.ismonth ? "none" : "column-data-style" }}>
                                            {editableRows.includes(index) ? (
                                                <div className="d-flex justify-content-around">
                                                    <div className="d-flex justify-content-center">
                                                        <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                        <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ width: "100px" }}>
                                                    {!item.is_edit ?
                                                        <div className="d-flex justify-content-around">
                                                            <div>
                                                                <img src={edit} className="edit" alt="Edit" style={{ width: "13px" }} onClick={() => handleEdit(index)} />
                                                            </div>
                                                            {/* <div>
                                                                <img src={remove} className="delete" alt="Delete" style={{ width: "13px" }} onClick={() => handleDelete(index)} />
                                                            </div> */}
                                                             <div>
                                                                <img src={add} className="add" alt="Add" style={{ width: "13px" }} onClick={() => handleAdd(index, "add")} />
                                                            </div>
                                                            {/* <div>
                                                                <img src={CommementBlue} className="comment" alt="Comment" style={{ width: "13px" }} onClick={() => openActionTrackerMRMComment(item.ID, item.responsibleDoer)} />
                                                            </div>  */}
                                                            <div>
                                                            <img onClick={() => OpenActionTrackerHistory(item.ID)} style={{ width: "16px" }}  title='History' src={RemarkBlue} alt="History Icon" /> 
                                                            </div> 
                                                        </div>
                                                        :
                                                        <div className="d-flex justify-content-around">
                                                            <div className="d-flex justify-content-center">
                                                                <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                                <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                            </div>
                                                        </div>
                                                     }
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                    ))
                                    : (
                                        <tr>
                                            <td colSpan="10" className="text-center">
                                                <div className="add">
                                                    <div className="add-btn" onClick={handleAddNew} style={{width:"100%"}}>
                                                        <span>Add New</span>
                                                    </div>
                                                </div>
                                                {/* <div style={{width:"85%"}}>
                                                    NO ACTION TRACKER AVAILABLE TO VIEW
                                                </div> */}
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>)}
        </div>
    );
};