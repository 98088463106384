//Add User login details in redux
export const setUserDetails = (userData) => {
    return {
        type: 'SET_USER_DETAILS',
        payload: userData,
    };
};

//Clears all the User login details from redux
export const clearUserDetails = () => {
    return {
        type: 'CLEAR_USER_DETAILS',
    };
};

//Add role and Module data in redux
export const setUserModuleAccessDetails = (userData) => {
    return {
        type: 'SET_USER_MODULE_ACCESS_DETAILS',
        payload: userData,
    };
};

//Clears all the User Access Module details from redux
export const clearUserModuleAccessDetails = () => {
    return {
        type: 'CLEAR_USER_MODULE_ACCESS_DETAILS',
    };
};

//Adds company id details from redux
export const setCompanyIDDetails = (id) => {
    return {
        type: 'SET_COMPANY_ID',
        payload: id
    };
};

//Adds Entity id details from redux
export const setEntityIDDetails = (id) => {
    return {
        type: 'SET_Entity_ID',
        payload: id
    };
};

//Adds division id details from redux
export const setDivisionIDDetails = (div_id) => {
    return {
        type: 'SET_DIVISION_ID',
        payload: div_id

    };
};

//Adds division id details from redux
export const setDivisionList = (div_list) => {
    return {
        type: 'SET_DIVISION_LIST',
        payload: div_list

    };
};


//Adds division id details from redux
export const setEmailIdRedux = (div_list) => {
    return {
        type: 'SET_EMAIL_ID',
        payload: div_list

    };
};

export const setPasswordRedux = (div_list) => {
    return {
        type: 'SET_PASSWORD',
        payload: div_list

    };
};


export const setIsRemeberMeCheckedRedux = (div_list) => {
    
    return {
        type: 'SET_REMEMBER_ME',
        payload: div_list

    };
};


export const clearEmailIdRedux = () => {
    return {
        type: 'CLEAR_EMAIL_ID' 

    };
};

export const clearPasswordRedux = () => {
    return {
        type: 'CLEAR_PASSWORD' 

    };
};


export const clearIsRemeberMeCheckedRedux = () => {
    return {
        type: 'CLEAR_REMEMBER_ME' 

    };
};
