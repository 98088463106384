import React, { useEffect, useState } from "react";
import "./PeopleKPI.css";
import imageURL from '../../../assets/images/infoIcon.svg';
import edit from '../../../assets/images/edit-icon.svg';
import remove from '../../../assets/images/remove-icon.svg';
import add from '../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../components/help_side_panel/HelpSidePanel";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import SmallSizedLoader from "../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '../../../api_interceptor/api'
import Select from 'react-select';
import Alert_Popup from "../../../components/alert_popup/Alert_Popup";
import AnalyticsImage from '../../../assets/images/Warning icon.svg';
import close from '../../../assets/images/close.png';
import { updateState, roundToTwoDecimals } from "../../../common_function/common_function";
import _ from 'lodash';



export default function PeopleKPI(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [peopleKPIList, setpeopleKPIList] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const [getStatusList, setStatusList] = useState([]);
    const [ImpactOnList, setImpactOnList] = useState([]);
    const [CriticalityList, setCriticalityList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [getCountData, setgetCountData] = useState(null);
    const [getCountDataShow, setgetCountDatadshow] = useState(false);
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const company_id = useSelector((state) => state.user.companyID);
    const [prevList, setPrevList] = useState([]);

    

    useEffect(() => {
        if (userData && userData['UserId']) {
            getPeopleKPI(userData['UserId'])
        }
    }, [userData]);

    useEffect(() => {
        setgetCountDatadshow(!getCountDataShow)
    }, [getCountData]);

    const getPeopleKPI = (UID) => {
        updateState(
            props.setPreviewEnabled,
            userData['UserId'],
            access_details['company_division_id'],
            props.setPreviewList,
            access_details['company_division_id']
        );
        
        setIsLoading(true);
        
        const API_URL = 'monthlymodule/api/get-people-KPI';
    
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
    
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
        .then((response) => {
            if (response && response.data) {
                if (response.data) {
                    console.log("respdata0000--->", response.data);
    
                    setgetCountData(response.data);
                    setpeopleKPIList(response.data.People_KPI_List);
                    sethelpDetails(response.data.help_details);
    
                    getAreaOfUpdateDetails(userData['UserId'], response.data.People_KPI_List);
                } else {
                    sethelpDetails(response.data.help_details);
                    getAreaOfUpdateDetails(userData['UserId'], response.data.People_KPI_List);
                }
            } else {
                sethelpDetails(response.data.help_details);
                getAreaOfUpdateDetails(userData['UserId'], response.data.People_KPI_List);
                setIsLoading(false);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.error(error);
        });
    }
    
    const getAreaOfUpdateDetails = (UID, valdata) => {
        const API_URL = 'monthlymodule/api/get-open-action-items-dropdown-details';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setStatusList(response.data.status_list);
                        setImpactOnList(response.data.impact_on_list);
                        setCriticalityList(response.data.criticality_list);
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const handleEdit = (index) => {
        // const updatedMonthlyUpdateList = [...peopleKPIList];

        if (prevList.length === 0) {
            setPrevList(peopleKPIList.map(item => ({ ...item }))); // Save the original state
        }

        const updatedMonthlyUpdateList = _.cloneDeep(peopleKPIList);
        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);

        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }

        if (editableRows.length === 0 && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });

            console.log({ currentOperation });
            setCurrentOperation('edit');
            setpeopleKPIList(updatedMonthlyUpdateList);
        }
    };

    const handleDelete = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            setDeleteReason(null)
            setDeletingIndex(index);
            setShowConfirmationModal(true);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
            handleDeleteAPI(deletingIndex, deleteReason);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };

    const handleDeleteAPI = (index, reason) => {
        const editedItem = peopleKPIList[index];
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/delete-people-KPI';
        const req_body = {
            "id": editedItem.ID,
            "deleted_reason": reason,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    getPeopleKPI(userData['UserId'])
                    setpeopleKPIList(prevList => prevList.filter((item, i) => i !== index));
                }
                else {
                    getPeopleKPI(userData['UserId'])
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    const addPeopleKPI = (editedItem, index) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/add-People-KPI';
        const req_body = editedItem;
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getPeopleKPI(userData['UserId'])
                }
                else {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getPeopleKPI(userData['UserId'])
                }

            })
            .catch((error) => {
                setAddClick(false)
                setIsLoading(false)
                console.error(error);
            });
    }

      // Validation start logic

      const validateMonthlyUpdate = (editedItem) => {
        

        // Validation 1: Dropdown should be mandatory
        const emptyFields = [];

      
            const budgetStr = String(editedItem.Budget);
            const hasDecimal = budgetStr.includes('.');
            
            if (hasDecimal) {
                const [wholePart, decimalPart = ''] = budgetStr.split('.');
                if (wholePart.length > 6) {
                    emptyFields.push("Value cannot exceed 6 digits.\n");
                }
            } else {
                if (budgetStr.length > 6) {
                    emptyFields.push("Value cannot exceed 6 digits.\n");
                }
            }
            
        
        
       
            const actualStr = String(editedItem.Actual);
            const hasDecimalActual = actualStr.includes('.');
            
            if (hasDecimal) {
                const [wholePart, decimalPart = ''] = actualStr.split('.');
                if (wholePart.length > 6) {
                    emptyFields.push("Value cannot exceed 6 digits.\n");
                }
            } else {
                if (actualStr.length > 6) {
                    emptyFields.push("Value cannot exceed 6 digits.\n");
                }
            }

        
            if (
                (editedItem.Employee == null || /^\s*$/.test(editedItem.Employee)) &&
                (editedItem.Budget == null || /^\s*$/.test(editedItem.Budget)) &&
                (editedItem.Actual == null || /^\s*$/.test(editedItem.Actual)) &&
                (editedItem.Reasons == null || /^\s*$/.test(editedItem.Reasons)) &&
                (editedItem.Action == null || /^\s*$/.test(editedItem.Action)) &&
                (editedItem.ActionOwner == null || /^\s*$/.test(editedItem.ActionOwner))
            ) {
                emptyFields.push("Please enter value for at least one field.\n");
            }
        


        // if (/^\s*$/.test(editedItem.Employee)) {
        //     emptyFields.push("Please enter value for KPI.\n");
        // }
        
        // if (/^\s*$/.test(editedItem.Budget)) {
        //     emptyFields.push("Please enter value for budget.\n");
        // }
        
        // if (/^\s*$/.test(editedItem.Actual)) {
        //     emptyFields.push("Please enter value for actual.\n");
        // }
        
        // if (/^\s*$/.test(editedItem.Reasons)) {
        //     emptyFields.push("Please enter value for reasons.\n");
        // }
        
        // if (/^\s*$/.test(editedItem.Action)) {
        //     emptyFields.push("Please enter value for action.\n");
        // }
        
        // if (/^\s*$/.test(editedItem.ActionOwner)) {
        //     emptyFields.push("Please enter value for action owner.\n");
        // }
        
        

        return emptyFields;
    };


    // Validation end logic





    const handleSave = (index, e) => {
        const editedItem = peopleKPIList[index];
        // Validation 1: Dropdown should be mandatory


    //     const isAllFieldsEmpty = !editedItem.Employees &&
    //     !editedItem.Budget &&
    //     !editedItem.Actual &&
    //     !editedItem.Reasons &&
    //     !editedItem.Action &&
    //     !editedItem.ActionOwner;

    // if (isAllFieldsEmpty) {
    //     console.log("No fields filled. Not creating a new row.");
    //     return; 
    // }

        const req_body = {

            Employees: editedItem.Employees,
            Budget: roundToTwoDecimals(editedItem.Budget),
            Actual: roundToTwoDecimals(editedItem.Actual),
            Reasons: editedItem.Reasons,
            Action: editedItem.Action,
            ActionOwner: editedItem.ActionOwner,
            
            "is_update": isAddClicked ? false : true,
            "update_id": isAddClicked ? null : editedItem.ID,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        }

         // Check if it's an add operation
         if (isAddClicked) {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addPeopleKPI(req_body, index)
                setCurrentOperation(null)
            }
        } else {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addPeopleKPI(req_body, index)
                setCurrentOperation(null)
            }
        }
        // console.log('reqbosyuy---->>', req_body);
        // addStrategicFocus(req_body, index)
        // setCurrentOperation(null)
    };


    const handleClose = (index) => {
        // Remove the index from editableRows when clicking the close icon
        setEditableRows([]);
        setAddClick(false);
        // If the item is newly added and being closed, remove it from the list
        // if (!peopleKPIList[index].is_update) {
        //     setpeopleKPIList((prevList) => {
        //         const updatedList = [...prevList];
        //         updatedList.splice(index, 1);
        //         return updatedList;
        //     });
        // }

        // // Reset the is_edit flag
        // const updatedMonthlyUpdateList = [...peopleKPIList];
        // updatedMonthlyUpdateList[index].is_edit = false;
        // setpeopleKPIList(updatedMonthlyUpdateList);

        if (prevList.length > 0) {
            setpeopleKPIList(prevList);
        }

        if (!peopleKPIList[index].is_update && prevList.length > 0) {
            setpeopleKPIList(prevList);

            const updatedMonthlyUpdateList = [...prevList];
            updatedMonthlyUpdateList[index].is_edit = false;
            setpeopleKPIList(prevList);
        }
         
      


        if (currentOperation === 'add') {
            setpeopleKPIList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }
        setCurrentOperation(null)
    };


    const handleAdd = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...peopleKPIList];
            const newItem = {
                Employees: "",
                Budget: "",
                Actual: "",
                Reasons: "",
                Action: "",
                ActionOwner: "",
                company_id: access_details['company_division_id']
            };
            setpeopleKPIList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, index + 1]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const handleAddNew = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...peopleKPIList];
            const newItem = {
                Employees: "",
                Budget: "",
                Actual: "",
                Reasons: "",
                Action: "",
                ActionOwner: "",
                company_id: access_details['company_division_id']
            };
            setpeopleKPIList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, 0]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };


    const handleInputChange = (index, field, value) => {
        console.log("input from date --------->>>", field, value);
        const updatedMonthlyUpdateList = [...peopleKPIList];
        // updatedMonthlyUpdateList[index][field] = value.label;
        // Check if the value is an object (dropdown) or a plain value (textarea, input, etc.)
        const updatedValue = typeof value === 'object' ? value.label : value;
        updatedMonthlyUpdateList[index][field] = updatedValue;
        setpeopleKPIList(updatedMonthlyUpdateList);
    };

    const setSelectValue = (list, value) => {
        return list.find((data) => {
            return data.label === value;
        });

    }

    function formatDateString(inputDateString) {
        const inputDate = new Date(inputDateString);

        const options = { day: "numeric", month: "short", year: "numeric" };
        const formattedDate = inputDate.toLocaleDateString("en-GB", options);

        return formattedDate;
    }

       // validation popup close

       const closePopup = () => {
        setPopupOpen(false);
    };

    
    const calculateVarianceForFinancial = (budgetedAmount, actualAmount) => {
        try {
            const variancePercentage = actualAmount - budgetedAmount
            return Math.round(variancePercentage * 100) / 100; // Round to two decimal places
        } catch (error) {
            if (error instanceof TypeError) {
                return null;
            }
            throw error;
        }
    };




    // dropdown scroll close start

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the background color of the control
            marginTop: 0, // Adjust the top margin
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
            color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
            height: '45px',

        }),
    };

    // Add state variables for each dropdown
    const [isOwnerDropdownOpen, setIsOwnerDropdownOpen] = useState(false);
    const [isImpactOnDropdownOpen, setIsImpactOnDropdownOpen] = useState(false);
    const [isCriticalityOnDropdownOpen, setIsCriticalityDropdownOpen] = useState(false);
    const [isStatusDropdownOpen, setIsStatusrDropdownOpen] = useState(false);

    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };

    // dropdown scroll close end

    return (

        <div className="parentContainer">
            {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-center'>
                                <p className="head-text">Delete Item</p>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Mention Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}
                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                />
                            </label>
                            <button onClick={() => setShowConfirmationModal(!showConfirmationModal)} className="cancel-btn">Cancel</button>
                            <button type="submit" className="confirm-btn">Confirm</button>
                        </form>
                    </div>
                </div>
            )}

                 {/* Validation popup start */}
         {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text" style={{fontSize:"20px"}}>Information</p>
                            </div>
                        </div>
                        <div className='ChatBodyDetails' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto',overflowY:'auto', padding:"0rem"}}>
                                {errorList.map((item, index) => (
                                    <div style={{ display : 'flex'}}>
                                        <div className=' d-flex mb-3' style={{width:'90%'}}>
                                            {`${index+1}) ${item}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        <button className="close-btn" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
            {/* Validation popup end */}

            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}

            <div className="monthlyHeaderWrapper d-flex justify-content-between">
            
                <div>
                    <span style={{ fontSize: "13px", display: "block" }}>Annexures (For Information/ Additional Reading)</span>
                    <span className="monthly-Title-Table-header" style={{ display: "block" }}>People Updates</span>
                </div>

            </div>

            {isLoading ? (<SmallSizedLoader />) :
                (<div className="table-div padStyle ">
                    <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff" , zIndex:9999999999}} />
                    <div className="table-container">
                        <table className="table-container-subdiv open-actionTable action-summary" style={{maxWidth:"100%"}}>
                            <thead className="action-head">
                                <tr>
                                    <th style={{ width: "1%" }} className="column-header-style fixed-column sr-column">Sr. No.</th>
                                    <th style={{ width: "3%" }} className="column-header-style">KPI</th>
                                    <th style={{ width: "1%" }} className="column-header-style">Budget</th>
                                    <th style={{ width: "1%" }} className="column-header-style">Actual</th>
                                    <th style={{ width: "1%" }} className="column-header-style">Variance</th>
                                    <th style={{ width: "5%" }} className="column-header-style">Reasons</th>
                                    <th style={{ width: "5%" }} className="column-header-style">Actions</th>
                                    <th style={{ width: "5%" }} className="column-header-style">Action Owner</th>
                                    <th style={{ width: "0%", display: props.ismonth ? "none" : "table-cell" }} className="column-header-style bg-wh fixed-column actions-column">Actions</th>
                                    </tr>
                            </thead>
                            <tbody className="action-body">
                            {peopleKPIList.length > 0 ? (
                                peopleKPIList.map((item, index) =>
                                    <tr key={index} className={editableRows.includes(index) ? "editable-row" : ""}>
                                        {/* Sr.no  */}
                                        <td className="column-data-style fixed-column sr-column">
                                            {index + 1}
                                        </td>
                           
                                        {/* EMPLOYEE  */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Employees} onChange={(e) => handleInputChange(index, 'Employees', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_Employees}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Employees} onChange={(e) => handleInputChange(index, 'Employees', e.target.value)} />
                                                        : ""
                                                    }
                                                </div> 
                                            )}
                                        </td>

                                        {/* Budgeted*/}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input
                                                    className="tableInput budgetInput"
                                                    style={{ width: "100%", textAlign: "right" }}
                                                    type="number"
                                                    value={item.Budget}
                                                    onChange={(e) => handleInputChange(index, 'Budget', e.target.value)}
                                                />
                                            ) : (
                                                <div>
                                                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                                                <p>
                                                                    {item.Previous_Budget !== null 
                                                                    ? (Math.round(item.Previous_Budget * 100) / 100).toFixed(2) 
                                                                    : item.Previous_Budget}
                                                                </p>
                                                    </div>
                                                    {item.is_edit ? (
                                                        <input
                                                            className="tableInput budgetInput"
                                                            style={{ width: "100%", textAlign: "right" }}
                                                            type="number"
                                                            value={item.Budget}
                                                            onChange={(e) => handleInputChange(index, 'Budget', e.target.value)}
                                                        />
                                                    ) : ""}
                                                </div>
                                            )}
                                        </td>


                                        {/* Actual */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput budgetInput" style={{ width: "100%", textAlign: "right" }} type="number" value={item.Actual} onChange={(e) => handleInputChange(index, 'Actual', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                                         <p>
                                                                {item.Previous_Actual !== null 
                                                                ? (Math.round(item.Previous_Actual * 100) / 100).toFixed(2) 
                                                                : item.Previous_Actual}
                                                         </p>
                                                    </div>
                                                    {item.is_edit ?
                                                        <input className="tableInput budgetInput" style={{ width: "100%", textAlign: "right" }} type="number" value={item.Actual} onChange={(e) => handleInputChange(index, 'Actual', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                         {/* Variance */}
                                        <td className="column-data-style">
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                                <p>
                                                     {`${calculateVarianceForFinancial(item.Budget, item.Actual)}`}
                                                </p>
                                                </div>
                                            </div>
                                        </td>

                                        {/* REASONS */}
                                        
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Reasons} onChange={(e) => handleInputChange(index, 'Reasons', e.target.value)} />
                                            ) : (
                                                <div>
                                                    {/* <p>{item.Previous_Reasons}</p> */}
                                                    {item.Previous_Reasons && (
                                                        <pre className="preStyleFormat" >
                                                            {item.Previous_Reasons}
                                                        </pre>
                                                          )}
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Reasons} onChange={(e) => handleInputChange(index, 'Reasons', e.target.value)} />
                                                        : ""
                                                    }
                                                </div> 
                                            )}
                                        </td>


                                    {/* ACTIONS */} 
                                    <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Action} onChange={(e) => handleInputChange(index, 'Action', e.target.value)} />
                                            ) : (
                                                <div>
                                                    {/* <p>{item.Previous_Action}</p> */}
                                                    {item.Previous_Action && (
                                                        <pre className="preStyleFormat" >
                                                            {item.Previous_Action}
                                                        </pre>
                                                          )}
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Action} onChange={(e) => handleInputChange(index, 'Action', e.target.value)} />
                                                        : ""
                                                    }
                                                </div> 
                                            )}
                                        </td>


                                    {/* ACTION OWNER */} 
                                    <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.ActionOwner} onChange={(e) => handleInputChange(index, 'ActionOwner', e.target.value)} />
                                            ) : (
                                                <div>
                                                    {/* <p>{item.Previous_ActionOwner}</p> */}
                                                    {item.Previous_ActionOwner && (
                                                        <pre className="preStyleFormat" >
                                                            {item.Previous_ActionOwner}
                                                        </pre>
                                                          )}
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.ActionOwner} onChange={(e) => handleInputChange(index, 'ActionOwner', e.target.value)} />
                                                        : ""
                                                    }
                                                </div> 
                                            )}
                                        </td>


                                        {/* ACTION */}
                                        <td className={index % 2 === 0 ? "column-data-style bg-ylw" : "column-data-style bg-wh"} style={{display: props.ismonth ? "none" : "column-data-style" }}>
                                            {editableRows.includes(index) ? (
                                                <div className="d-flex justify-content-around">
                                                    <div className="d-flex justify-content-center">
                                                        <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                        <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ width: "100px" }}>
                                                    {!item.is_edit ?
                                                        <div className="d-flex justify-content-around">
                                                            <div>
                                                                <img src={edit} className="edit" alt="Edit" style={{ width: "13px" }} onClick={() => handleEdit(index)} />
                                                            </div>
                                                            <div>
                                                                <img src={remove} className="delete" alt="Delete" style={{ width: "13px" }} onClick={() => handleDelete(index)} />
                                                            </div>
                                                            <div>
                                                                <img src={add} className="add" alt="Add" style={{ width: "13px" }} onClick={() => handleAdd(index, "add")} />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="d-flex justify-content-around">
                                                            <div className="d-flex justify-content-center">
                                                                <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                                <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                    ))
                                    : (
                                        <tr>
                                            <td colSpan="10" className="text-center">
                                                <div className="add">
                                                    <div className="add-btn" onClick={handleAddNew}>
                                                        <span>Add New</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>)}
        </div>
    );
};