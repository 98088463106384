import React, { useState } from 'react';
import './PdfViewerModal.css'

const PdfViewerModal = (props) => {
    const handleClose = () => {
        props.onClose();
    };
    const pdfUrl = 'https://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf';

    return (
        <div className="popup">
            <div className="popupModal-inner">
                <span className="close" onClick={() => {
                    console.log("hi");
                    handleClose()
                    }}>
                    &times;
                </span>
                <iframe
                    title="PDF Viewer"
                    width="100%"
                    height="90%"
                    src={`https://docs.google.com/gview?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
                    frameBorder="0"
                    className='iframe-style'
                >
                    This browser does not support PDFs. Please download the PDF to view it.
                </iframe>
            </div>
        </div>
    );
};

export default PdfViewerModal;