import React, { useEffect, useState } from "react";
import "./LegalUpdate.css";
import imageURL from '../../../assets/images/infoIcon.svg';
import edit from '../../../assets/images/edit-icon.svg';
import remove from '../../../assets/images/remove-icon.svg';
import add from '../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../components/help_side_panel/HelpSidePanel";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import SmallSizedLoader from "../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '../../../api_interceptor/api'
import Select from 'react-select';
import Alert_Popup from "../../../components/alert_popup/Alert_Popup";
import close from '../../../assets/images/close.png';
import { updateState } from "../../../common_function/common_function";
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';


export default function LegalUpdate(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [legalUpdatesList, setlegalUpdatesList] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const [competitionAreaList, setCompetitionAreaList] = useState([]);
    const [ourCompetitiveAdvantager, setOurCompetitiveAdvantager] = useState([]);
    const [CompetitorCompetitiveAdvantager, setCompetitorCompetitiveAdvantager] = useState([]);
    const [categorySpentBycategory_spent_by_client_nameList, setcategorySpentBycategory_spent_by_client_nameList] = useState([]);
    const [isClientInReachList, setIsClientInReachList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const company_id = useSelector((state) => state.user.companyID);
    const [prevList, setPrevList] = useState([]);


    const currentDate = new Date();
    let month = currentDate.getMonth(); 
    let year = currentDate.getFullYear();
    
    if (month == 0) {
        month = 11; 
        year--;     
    } else {
        month--;   
    }
    
    const monthName = new Date(year, month).toLocaleString('default', { month: 'long' });


    useEffect(() => {
        if (userData && userData['UserId']) {
            getLegalUpdate(userData['UserId'])
        }
    }, [userData]);

    const getLegalUpdate = (UID) => {
        updateState(props.setPreviewEnabled,userData['UserId'],access_details['company_division_id'],props.setPreviewList,access_details['company_division_id'])
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-legal-update';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setlegalUpdatesList(response.data.Update_List)
                        sethelpDetails(response.data.help_details)
                        getAreaOfUpdateDetails(userData['UserId'], response.data.Update_List)

                    }
                    else {
                        sethelpDetails(response.data.help_details)
                        getAreaOfUpdateDetails(userData['UserId'], response.data.Update_List)
                        setIsLoading(false)
                    }
                }
                else {
                    getAreaOfUpdateDetails(userData['UserId'], response.data.Update_List)
                    sethelpDetails(response.data.help_details)

                    setIsLoading(false)
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const getAreaOfUpdateDetails = (UID, valdata) => {
        const API_URL = 'monthlymodule/api/get-competition-dropdown-details';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setCompetitionAreaList(response.data.competition_area_list);
                        setcategorySpentBycategory_spent_by_client_nameList(response.data.category_spent_by_client_list);
                        setOurCompetitiveAdvantager(response.data.our_competitive_advantage);
                        setCompetitorCompetitiveAdvantager(response.data.our_competitive_advantage);
                        setIsClientInReachList(
                            [
                                { label: "Yes", value: 1 },
                                { label: "No", value: 0 },
                            ]
                        );
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const handleEdit = (index) => {
        // const updatedMonthlyUpdateList = [...legalUpdatesList];

        if (prevList.length === 0) {
            setPrevList(legalUpdatesList.map(item => ({ ...item }))); // Save the original state
        }

        const updatedMonthlyUpdateList = _.cloneDeep(legalUpdatesList);
        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);

        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }

        if (editableRows.length === 0 && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });

            console.log({ currentOperation });
            setCurrentOperation('edit');
            setlegalUpdatesList(updatedMonthlyUpdateList);
        }
    };

    const handleDelete = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            setDeleteReason(null)
            setDeletingIndex(index);
            setShowConfirmationModal(true);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
            handleDeleteAPI(deletingIndex, deleteReason);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };

    const handleDeleteAPI = (index, reason) => {
        const editedItem = legalUpdatesList[index];
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/delete-legal-update';
        const req_body = {
            "id": editedItem.ID,
            "deleted_reason": reason,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    getLegalUpdate(userData['UserId'])
                    setlegalUpdatesList(prevList => prevList.filter((item, i) => i !== index));

                    navigateTo("monthly/financial-hits-misses");
                    navigateTo("monthly/legal-updates");
                }
                else {
                    getLegalUpdate(userData['UserId'])

                    navigateTo("monthly/financial-hits-misses");
                    navigateTo("monthly/legal-updates");
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    
    const navigate = useNavigate();

    const navigateTo = (module) => {
    navigate('/' + module);
    };

    const addLegalUpdate = (editedItem, index) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/add-legal-update';
        const req_body = editedItem;
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getLegalUpdate(userData['UserId'])

                    navigateTo("monthly/financial-hits-misses");
                    navigateTo("monthly/legal-updates");
                }
                else {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getLegalUpdate(userData['UserId'])

                    navigateTo("monthly/financial-hits-misses");
                    navigateTo("monthly/legal-updates");
                }

            })
            .catch((error) => {
                setAddClick(false)
                setIsLoading(false)
                console.error(error);
            });
    }

    const getVal = (val) => {
        if (val == true) {
            return 1
        }
        if (val == false) {
            return 0
        }
        if (val == 0) {
            return 0
        }
        if (val == 1) {
            return 1
        }
    }

    const getdropdownValYesNo = (editedItem) => {
        if (editedItem.is_client_in_reach_out_list && editedItem.is_client_in_reach_out_list.value == undefined) {
            return setSelectValue(isClientInReachList, setDropDownTrueFalse(editedItem.is_client_in_reach_out_list)).value
        }
        else if (editedItem.is_client_in_reach_out_list == false) {
            return 0
        }
        else {
            return getVal(editedItem.is_client_in_reach_out_list.value)
        }
    }

    // Validation start logic

 
    const validateMonthlyUpdate = (editedItem) => {
   
        const emptyFields = [];

        // Validation 1: Dropdown should be mandatory
        if (/^\s*$/.test(editedItem.Description)) {
            emptyFields.push("Please enter value for description.\n");
        }

        if (/^\s*$/.test(editedItem.Owner)) {
            emptyFields.push("Please enter value for owner.\n");
        }

        if (/^\s*$/.test(editedItem.Update)) {
            emptyFields.push(`Please enter value for update for ${monthName.toLowerCase()}'${year}\n`);
        }


        return emptyFields;
    };


    // Validation end logic

    const handleSave = (index, e) => {
        const editedItem = legalUpdatesList[index];
        // Validation 1: Dropdown should be mandatory

        const req_body = {
            Description: editedItem.Description,
            Owner: editedItem.Owner,
            Update: editedItem.Update,
            "is_update": isAddClicked ? false : true,
            "update_id": isAddClicked ? null : editedItem.ID,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        }
        // Check if it's an add operation
        if (isAddClicked) {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addLegalUpdate(req_body, index)
                setCurrentOperation(null)
            }
        } else {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addLegalUpdate(req_body, index)
                setCurrentOperation(null)
            }
        }


    };


    const handleClose = (index) => {
        // Remove the index from editableRows when clicking the close icon
        setEditableRows([]);
        setAddClick(false)
        // If the item is newly added and being closed, remove it from the list
        // if (!legalUpdatesList[index].is_update) {
        //     setlegalUpdatesList((prevList) => {
        //         const updatedList = [...prevList];
        //         updatedList.splice(index, 1);
        //         return updatedList;
        //     });
        // }

        // // Reset the is_edit flag
        // const updatedMonthlyUpdateList = [...legalUpdatesList];
        // updatedMonthlyUpdateList[index].is_edit = false;
        // setlegalUpdatesList(updatedMonthlyUpdateList);

        if (prevList.length > 0) {
            setlegalUpdatesList(prevList);
        }

        if (!legalUpdatesList[index].is_update && prevList.length > 0) {
            setlegalUpdatesList(prevList);

            const updatedMonthlyUpdateList = [...prevList];
            updatedMonthlyUpdateList[index].is_edit = false;
            setlegalUpdatesList(prevList);
        }
         
     


        if (currentOperation === 'add') {
            setlegalUpdatesList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }
        setCurrentOperation(null)
    };

    const handleAdd = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...legalUpdatesList];
            const newItem = {
                Description: "",  
                Owner: "",
                Update: "",
                company_id: access_details['company_division_id']
            };
            setlegalUpdatesList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, index + 1]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const handleAddNew = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...legalUpdatesList];
            const newItem = {
                Description: "",  
                Owner: "",
                Update: "",
                company_id: access_details['company_division_id']
            };
            setlegalUpdatesList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, 0]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const handleInputChange = (index, field, value) => {
        console.log("Index ------------>>", index, field, value);
        const updatedMonthlyUpdateList = [...legalUpdatesList];
        updatedMonthlyUpdateList[index][field] = value;
        setlegalUpdatesList(updatedMonthlyUpdateList);
    };

    const setSelectValue = (list, value) => {
        console.log("List ---- >> Value ----->>>>", list, value);
        return list.find((data) => {
            return data.label === value;
        });

    }
    const setDropDownTrueFalse = (val) => {
        if (val == true) {
            return "Yes"
        }
        else if (val == false) {
            return "No"
        }
        else {
            return ""
        }
    }

    // validation popup close

    const closePopup = () => {
        setPopupOpen(false);
    };



    // dropdown scroll close start

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the background color of the control
            marginTop: 0, // Adjust the top margin
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
            color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
            height: '45px',

        }),
    };

    // Add state variables for each dropdown
    const [isCompetitorsAreaDropdownOpen, setIsCompetitorsAreaDropdownOpen] = useState(false);
    const [isCategorySpentDropdownOpen, setIsCategorySpentDropdownOpen] = useState(false);
    const [isReachOutDropdownOpen, setIsReachOutDropdownOpen] = useState(false);
    const [CompetitorCompetitiveDropdownOpen, setCompetitorCompetitiveDropdownOpen] = useState(false);
    const [OurCompetitorDropdownOpen, setOurCompetitorDropdownOpen] = useState(false);
    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };

    // dropdown scroll close end

    return (
        <div className="parentContainer" style={{marginBottom:"20px"}}>
            {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-center'>
                                <p className="head-text">Delete Item</p>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Mention Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}
                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                />
                            </label>
                            <button onClick={() => setShowConfirmationModal(!showConfirmationModal)} className="cancel-btn">Cancel</button>
                            <button type="submit" className="confirm-btn">Confirm</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Validation popup start */}
         {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text" style={{fontSize:"20px"}}>Information</p>
                            </div>
                        </div>
                        <div className='ChatBodyDetails' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto',overflowY:'auto', padding:"0rem"}}>
                                {errorList.map((item, index) => (
                                    <div style={{ display : 'flex'}}>
                                        <div className=' d-flex mb-3' style={{width:'90%'}}>
                                            {`${index+1}) ${item}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        <button className="close-btn" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
            {/* Validation popup end */}

            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}
            <div className="monthlyHeaderWrapper">
                <span className="monthly-Title-Table-header"></span>
                {!isLoading && <HelpSidePanel content={"Information on what is happening in the ecosystem as a whole"} />}
            </div>
            {isLoading ? (<SmallSizedLoader />) :
                (<div className="table-div padStyle" >
                    <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff"  , zIndex:9999999999}} />
                    <div className="table-container" style={{ maxWidth: "50%" }}>
                        <table className="table-container-subdiv competition-table" style={{ maxWidth: "100%" }}>
                            <thead className="competition-head">
                                <tr>
                                <th style={{ width: "1%" }} className="column-header-style fixed-column sr-column">Sr. No.</th>
                                <th style={{ width: "5%" }} className="column-header-style">Description <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span></th>
                                <th style={{ width: "5%" }} className="column-header-style">Owner <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span></th>
                                <th style={{ width: "5%" }} className="column-header-style">Update For {monthName}'{year} <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span></th>
                                <th style={{ width: "0%", display: props.ismonth ? "none" : "table-cell" }} className="column-header-style bg-wh">Actions</th>
                                    </tr>
                            </thead>
                            <tbody className="competition-body">
                            {legalUpdatesList.length > 0 ? (
                                legalUpdatesList.map((item, index) =>
                                    <tr key={index} className={editableRows.includes(index) ? "editable-row" : ""}>
                                      
                                        {/* #  */}
                                        <td className="column-data-style fixed-column sr-column ">
                                            {index + 1}
                                        </td>

                                        {/* DESCRIPTION*/}
                                        <td className="column-data-style">
                                        {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Description} onChange={(e) => handleInputChange(index, 'Description', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_Description}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Description} onChange={(e) => handleInputChange(index, 'Description', e.target.value)} />
                                                        : ""
                                                    }
                                                </div> 
                                            )}
                                        </td>

                                        {/* OWNER */}
                                        <td className="column-data-style">
                                        {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Owner} onChange={(e) => handleInputChange(index, 'Owner', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_Owner}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Owner} onChange={(e) => handleInputChange(index, 'Owner', e.target.value)} />
                                                        : ""
                                                    }
                                                </div> 
                                            )}
                                        </td>

                                        {/* UPDATE FOR MAR'24 */}

                                        <td className="column-data-style">
                                        {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Update} onChange={(e) => handleInputChange(index, 'Update', e.target.value)} />
                                            ) : (
                                                <div>
                                                    {/* <p>{item.Previous_Update}</p> */}
                                                    {item.Previous_Update && (
                                                        <pre className="preStyleFormat" >
                                                            {item.Previous_Update}
                                                        </pre>
                                                          )}
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Update} onChange={(e) => handleInputChange(index, 'Update', e.target.value)} />
                                                        : ""
                                                    }
                                                </div> 
                                            )}

                                        </td>

                                        {/* ACTIONS */}
                                        <td className={index % 2 === 0 ? "column-data-style bg-ylw " : "column-data-style bg-wh "} style={{display: props.ismonth ? "none" : "column-data-style" }}>
                                            {editableRows.includes(index) ? (
                                                <div className="d-flex justify-content-around">
                                                    <div className="d-flex justify-content-center">
                                                        <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                        <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ width: "100px" }}>
                                                    {!item.is_edit ?
                                                        <div className="d-flex justify-content-around">
                                                            <div>
                                                                <img src={edit} className="edit" alt="Edit" style={{ width: "13px" }} onClick={() => handleEdit(index)} />
                                                            </div>
                                                            <div>
                                                                <img src={remove} className="delete" alt="Delete" style={{ width: "13px" }} onClick={() => handleDelete(index)} />
                                                            </div>
                                                            <div>
                                                                <img src={add} className="add" alt="Add" style={{ width: "13px" }} onClick={() => handleAdd(index, "add")} />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="d-flex justify-content-around">
                                                            <div className="d-flex justify-content-center">
                                                                <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                                <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                     ))
                                     : (
                                         <tr>
                                             <td colSpan="10" className="text-center">
                                                 <div className="add">
                                                     <div className="add-btn" onClick={handleAddNew}>
                                                         <span>Add New</span>
                                                     </div>
                                                 </div>
                                             </td>
                                         </tr>
                                     )}
                            </tbody>
                        </table>
                    </div>
                </div>)}
        </div>
    );
};