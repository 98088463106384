import React from "react";
import "./Alert_Popup.css";

export default function Alert_Popup(props) {
    const closePressed = () => {
        props.onClosePress()
    }
    return (
        <div className="popup">
            <div className="popup-inner">
                <div className='row mb-3'>
                    <div className='col-12 text-center'>
                        <h4>Alert!</h4>
                    </div>
                </div>
                <form onSubmit={closePressed}>
                    <label className='label-text1'>
                        {props.msg}
                    </label>
                    <div className='col-12 text-center' >
                        <button style={{ 
                            backgroundColor :props.type == "Warning" ? " #FFC008" : props.type == "Error" ? "#21A8E1" : "#005273"
                        }} type="submit">Close</button>
                    </div>
                </form>
            </div>
        </div>
    );
}