import React, { useEffect, useState } from "react";
import "./PointsDiscussed.css";
import imageURL from '../../../assets/images/infoIcon.svg';
import edit from '../../../assets/images/edit-icon.svg';
import remove from '../../../assets/images/remove-icon.svg';
import add from '../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../components/help_side_panel/HelpSidePanel";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import SmallSizedLoader from "../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '../../../api_interceptor/api'
import Select from 'react-select';
import Alert_Popup from "../../../components/alert_popup/Alert_Popup";
import AnalyticsImage from '../../../assets/images/Warning icon.svg';
import close from '../../../assets/images/close.png';
import { updateState, formatDate } from "../../../common_function/common_function";
import _ from 'lodash';

export default function PointsDiscussed(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [pointsDiscussedList, SetpointsDiscussedList] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const [getStatusList, setStatusList] = useState([]);
    const [ImpactOnList, setImpactOnList] = useState([]);
    const [CriticalityList, setCriticalityList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [getCountData, setgetCountData] = useState(null);
    const [getCountDataShow, setgetCountDatadshow] = useState(false);
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const company_id = useSelector((state) => state.user.companyID);

    const [prevList, setPrevList] = useState([]);
    

    useEffect(() => {
        if (userData && userData['UserId']) {
            getPointsDiscussed  (userData['UserId'])
        }
    }, [userData]);

    useEffect(() => {
        setgetCountDatadshow(!getCountDataShow)
    }, [getCountData]);

    const getPointsDiscussed = (UID) => {
        updateState(
            props.setPreviewEnabled,
            userData['UserId'],
            access_details['company_division_id'],
            props.setPreviewList,
            access_details['company_division_id']
        );
        
        setIsLoading(true);
        
        const API_URL = 'monthlymodule/api/get-points-discussed';
    
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
    
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
        .then((response) => {
            if (response && response.data) {
                if (response.data) {
                    console.log("respdata0000--->", response.data);
    
                    setgetCountData(response.data);
                    SetpointsDiscussedList(response.data.Points_Discussed_List);
                    sethelpDetails(response.data.help_details);
    
                    getAreaOfUpdateDetails(userData['UserId'], response.data.Points_Discussed_List);
                } else {
                    sethelpDetails(response.data.help_details);
                    getAreaOfUpdateDetails(userData['UserId'], response.data.Points_Discussed_List);
                }
            } else {
                sethelpDetails(response.data.help_details);
                getAreaOfUpdateDetails(userData['UserId'], response.data.Points_Discussed_List);
                setIsLoading(false);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.error(error);
        });
    }
    
    const getAreaOfUpdateDetails = (UID, valdata) => {
        
        const API_URL = 'monthlymodule/api/get-open-action-items-dropdown-details';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setStatusList(response.data.status_list);
                        setImpactOnList(response.data.impact_on_list);
                        setCriticalityList(response.data.criticality_list);
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

   
    
    const handleEdit = (index) => {
        ;
    
        // Store the original list only when starting to edit the first item
        if (prevList.length === 0) {
            setPrevList(pointsDiscussedList.map(item => ({ ...item }))); // Save the original state
        }
    
        const updatedMonthlyUpdateList = _.cloneDeep(pointsDiscussedList);
    
        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);
    
        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }
    
        if (editableRows.length === 0 && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });
    
            console.log({ currentOperation });
            setCurrentOperation('edit');
            SetpointsDiscussedList(updatedMonthlyUpdateList);
        }
    };
    

    const handleDelete = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            setDeleteReason(null)
            setDeletingIndex(index);
            setShowConfirmationModal(true);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
            handleDeleteAPI(deletingIndex, deleteReason);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };

    const handleDeleteAPI = (index, reason) => {
        const editedItem = pointsDiscussedList[index];
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/delete-points-discussed';
        const req_body = {
            "id": editedItem.ID,
            "deleted_reason": reason,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    getPointsDiscussed  (userData['UserId'])
                    SetpointsDiscussedList(prevList => prevList.filter((item, i) => i !== index));
                }
                else {
                    getPointsDiscussed  (userData['UserId'])
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    const addPointsDiscussed = (editedItem, index) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/add-points-discussed';
        const req_body = editedItem;
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getPointsDiscussed  (userData['UserId'])
                }
                else {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getPointsDiscussed  (userData['UserId'])
                }

            })
            .catch((error) => {
                setAddClick(false)
                setIsLoading(false)
                console.error(error);
            });
    }

      // Validation start logic

      const validateMonthlyUpdate = (editedItem) => {
        
        const emptyFields = [];

        // Validation 1: Dropdown should be mandatory

        if (/^\s*$/.test(editedItem.PointsDiscussed1)) {
            emptyFields.push("Please enter value for points discussed.\n");
        }

        if (/^\s*$/.test(editedItem.ActionRequired)) {
            emptyFields.push("Please enter value for action required.\n");
        }
        if (/^\s*$/.test(editedItem.ActionOwner)) {
            emptyFields.push("Please enter value for action owner.\n");
        }

        if (!editedItem.ImpactOnID) {
            emptyFields.push("Please select impact on.\n");
        }


        
        if (!editedItem.ClosureDate) {
            emptyFields.push("Please select due date.\n");
        }

        if (!editedItem.CriticalityID) {
            emptyFields.push("Please select criticality.\n");
        }
       
        if (/^\s*$/.test(editedItem.Remarks)) {
            emptyFields.push("Please enter value for remarks.\n");
        }      
        

        return emptyFields;
    };


    // Validation end logic





    const handleSave = (index, e) => {
        const editedItem = pointsDiscussedList[index];
        

        const req_body = {
            PointsDiscussed1: editedItem.PointsDiscussed1,  
            ActionRequired: editedItem.ActionRequired,
            ActionOwner: editedItem.ActionOwner,
            ImpactOnID: editedItem.ImpactOnID && editedItem.ImpactOnID.value == undefined ? setSelectValue(ImpactOnList, editedItem.ImpactOnID).value : editedItem.ImpactOnID.value,
            ClosureDate: editedItem.ClosureDate,
            CriticalityID: editedItem.CriticalityID && editedItem.CriticalityID.value == undefined ? setSelectValue(CriticalityList, editedItem.CriticalityID).value : editedItem.CriticalityID.value,
            Remarks: editedItem.Remarks,

            "is_update": isAddClicked ? false : true,
            "update_id": isAddClicked ? null : editedItem.ID,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        }

         // Check if it's an add operation
         if (isAddClicked) {

            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addPointsDiscussed(req_body, index)
                setCurrentOperation(null)
            }
        } else {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addPointsDiscussed(req_body, index)
                setCurrentOperation(null)
            }
        }
        // console.log('reqbosyuy---->>', req_body);
        // addStrategicFocus(req_body, index)
        // setCurrentOperation(null)
    };

    const handleClose = (index) => {
        ;
    
        // Remove the index from editableRows when clicking the close icon
        setEditableRows([]);
        setAddClick(false);
    
        // If prevList has data, restore pointsDiscussedList to the original state
        if (prevList.length > 0) {
            SetpointsDiscussedList(prevList);
        }
    
        // If the item is newly added and being closed, remove it from the list
        // if (!pointsDiscussedList[index].is_update) {
        //     SetpointsDiscussedList((prevList) => {
        //         const updatedList = [...prevList];
        //         updatedList.splice(index, 1);
        //         return updatedList;
        //     });
        // }

        if (!pointsDiscussedList[index].is_update && prevList.length > 0) {
            SetpointsDiscussedList(prevList);

            const updatedMonthlyUpdateList = [...prevList];
            updatedMonthlyUpdateList[index].is_edit = false;
            SetpointsDiscussedList(prevList);
        }
         

        

        // Reset the is_edit flag for the current item
        // const updatedMonthlyUpdateList = [...pointsDiscussedList];
        // updatedMonthlyUpdateList[index].is_edit = false;
        // SetpointsDiscussedList(updatedMonthlyUpdateList);
    
        // If the current operation is 'add', remove the item at the specified index
        if (currentOperation == 'add') {
            SetpointsDiscussedList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }
    
        // Reset the current operation
        setCurrentOperation(null);
    };
    
    
    

    const handleAdd = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...pointsDiscussedList];
            const newItem = {
                PointsDiscussed1: "", 
                ActionRequired: "",
                ActionOwner: "",
                ImpactOnID: "",
                ClosureDate: "",
                CriticalityID: "",
                Remarks: "",
                company_id: access_details['company_division_id']
            };
            SetpointsDiscussedList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, index + 1]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const handleAddNew = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...pointsDiscussedList];
            const newItem = {
                PointsDiscussed1: "", 
                ActionRequired: "",
                ActionOwner: "",
                ImpactOnID: "",
                ClosureDate: "",
                CriticalityID: "",
                Remarks: "",
                company_id: access_details['company_division_id']
            };
            SetpointsDiscussedList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, 0]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };


    const handleInputChange = (index, field, value) => {
        console.log("input from date --------->>>", field, value);
        const updatedMonthlyUpdateList = [...pointsDiscussedList];
        // updatedMonthlyUpdateList[index][field] = value.label;
        // Check if the value is an object (dropdown) or a plain value (textarea, input, etc.)
        const updatedValue = typeof value === 'object' ? value.label : value;
        updatedMonthlyUpdateList[index][field] = updatedValue;
        SetpointsDiscussedList(updatedMonthlyUpdateList);
    };

    const setSelectValue = (list, value) => {
        
        return list.find((data) => {
            return data.label === value;
        });

    }

    function formatDateString(inputDateString) {
        const inputDate = new Date(inputDateString);

        const options = { day: "numeric", month: "short", year: "numeric" };
        const formattedDate = inputDate.toLocaleDateString("en-GB", options);

        return formattedDate;
    }

       // validation popup close

       const closePopup = () => {
        setPopupOpen(false);
    };




    // dropdown scroll close start

    // const customStyles = {
    //     control: (provided) => ({
    //         ...provided,
    //         backgroundColor: 'white',
    //         color: 'red', // Change the background color of the control
    //         marginTop: 0, // Adjust the top margin
    //     }),
    //     menu: (provided) => ({
    //         ...provided,
    //         zIndex: 0,
    //         position: 'absolute',
    //         fontSize: '11px',
    //         marginTop: 0,
    //         marginBottom: 0, // Adjust the bottom margin
    //     }),
    //     option: (provided, state) => ({
    //         ...provided,
    //         backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
    //         color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
    //         height: '45px',

    //     }),
    // };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the text color of the control
            borderColor: state.isFocused ? 'black' : provided.borderColor, // Change border color when focused
            boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow, // Add box shadow when focused
            marginTop: 0, // Adjust the top margin
            '&:hover': { // Prevent blue highlight on hover
                borderColor: state.isFocused ? 'black' : provided.borderColor,
                boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow,
            }
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
                    color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
                    height: '45px',
            // '&:hover': {
            //     color: 'white', // Change text color on hover
            // },
        }),
    };
    
    
    

    // Add state variables for each dropdown
    const [isOwnerDropdownOpen, setIsOwnerDropdownOpen] = useState(false);
    const [isImpactOnDropdownOpen, setIsImpactOnDropdownOpen] = useState(false);
    const [isCriticalityOnDropdownOpen, setIsCriticalityDropdownOpen] = useState(false);
    const [isStatusDropdownOpen, setIsStatusrDropdownOpen] = useState(false);

    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };

    // dropdown scroll close end

    return (

        <div className="parentContainer">
            {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-center'>
                                <p className="head-text">Delete Item</p>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Mention Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}
                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                />
                            </label>
                            <button onClick={() => setShowConfirmationModal(!showConfirmationModal)} className="cancel-btn">Cancel</button>
                            <button type="submit" className="confirm-btn">Confirm</button>
                        </form>
                    </div>
                </div>
            )}

                 {/* Validation popup start */}
         {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text" style={{fontSize:"20px"}}>Information</p>
                            </div>
                        </div>
                        <div className='ChatBodyDetails' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto',overflowY:'auto', padding:"0rem"}}>
                                {errorList.map((item, index) => (
                                    <div style={{ display : 'flex'}}>
                                        <div className=' d-flex mb-3' style={{width:'90%'}}>
                                            {`${index+1}) ${item}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        <button className="close-btn" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}

            {/* Validation popup end */}

            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}

            <div className="monthlyHeaderWrapper d-flex justify-content-between">
                <span className="monthly-Title-Table-header">Points Discussed
                    <span className="monthly-Title-Table-header-subheader">  (Actions Identified from this meeting) </span> 
                </span>
             
            </div>

            {isLoading ? (<SmallSizedLoader />) :
                (<div className="table-div padStyle " style={{ maxWidth: "100%" }}>
                    <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff" , zIndex:9999999999}} />
                    <div className="table-container">
                        <table className="table-container-subdiv open-actionTable action-summary" style={{maxWidth:"100%"}}>
                            <thead className="action-head">
                                <tr>
                                <th style={{ width: "1%" }} className="column-header-style fixed-column sr-column">Sr. No.</th>
                                <th style={{ width: "5%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                            <div>Points Discussed   
                                                <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                          
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Short Description on area of discussion"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                </th>
                                <th style={{ width: "5%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                            <div>Action Required (If Any) <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Mention Specific Actions that need to be taken on this point"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                </th>
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                            <div>Action Owner <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Enter initials of the person"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                </th>
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                            <div>Impact On <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Choose from dropdown"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                </th>
                                <th style={{ width: "3%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                            <div>Due Date <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="DD/MM/YYYY format"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                </th>
                                <th style={{ width: "3%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                            <div>Criticality <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Choose from dropdown"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                </th>
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                            <div>Remarks <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Additional Remarks"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                </th>
                                <th style={{ width: "0%", display: props.ismonth ? "none" : "table-cell", overflow:"hidden", whiteSpace:"nowrap"}} className="column-header-style bg-wh fixed-column actions-column ">Actions</th>

                                </tr>
                            </thead>
                            <tbody className="action-body">
                            {pointsDiscussedList.length > 0 ? (
                                pointsDiscussedList.map((item, index) =>
                                    
                                    <tr key={index} className={editableRows.includes(index) ? "editable-row" : ""}>
                                        {/* # */}
                                        <td className="column-data-style fixed-column sr-column">
                                            {index + 1}
                                        </td>

                                        {/* POINTS DISCUSSED */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.PointsDiscussed1} onChange={(e) => handleInputChange(index, 'PointsDiscussed1', e.target.value)} />
                                            ) : (
                                                <div>
                                            {item.Previous_PointsDiscussed && (
                                                        <pre className="preStyleFormat" >
                                                            {item.Previous_PointsDiscussed}
                                                        </pre>
                                                    )}
                                            {item.is_edit ? (
                                                <textarea 
                                                    className="tableInput" 
                                                    style={{ width: "100%" }} 
                                                    type="text" 
                                                    value={item.PointsDiscussed1} 
                                                    onChange={(e) => handleInputChange(index, 'PointsDiscussed1', e.target.value)} 
                                                />
                                            ) : null}
                                            </div>
                                                )}
                                        </td>

                                        {/* ACTION REQUIRED(IF ANY) */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <textarea 
                                                    className="tableInput" 
                                                    style={{ width: "100%" }} 
                                                    type="text" 
                                                    value={item.ActionRequired} 
                                                    onChange={(e) => handleInputChange(index, 'ActionRequired', e.target.value)} 
                                                />
                                            ) : (
                                                <div>
                                                    {item.Previous_ActionRequired  && (
                                                        <pre className="preStyleFormat" >
                                                            {item.Previous_ActionRequired}
                                                        </pre>
                                                    )}
                                                    {item.is_edit ? (
                                                        <textarea 
                                                            className="tableInput" 
                                                            style={{ width: "100%" }} 
                                                            type="text" 
                                                            value={item.ActionRequired} 
                                                            onChange={(e) => handleInputChange(index, 'ActionRequired', e.target.value)} 
                                                        />
                                                    ) : null}
                                                </div>
                                            )}
                                        </td>

                                        {/* ACTION OWNER */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                            
                                            <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.ActionOwner} onChange={(e) => handleInputChange(index, 'ActionOwner', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_ActionOwner}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.ActionOwner} onChange={(e) => handleInputChange(index, 'ActionOwner', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* IMPACT ON */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                               <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                               <Select
                                                   id={index}
                                                   options={ImpactOnList}
                                                   value={setSelectValue(ImpactOnList, pointsDiscussedList[index].ImpactOnID)}
                                                   styles={customStyles}
                                                   menuPosition={'fixed'}
                                                   maxMenuHeight={100}
                                                   onChange={
                                                       (e) => handleInputChange(index, 'ImpactOnID', e)
                                                   }
                                                   closeMenuOnScroll={() => !isSelectHovered}
                                                   onMenuOpen={() => setIsImpactOnDropdownOpen(true)}
                                                   onMenuClose={() => setIsImpactOnDropdownOpen(false)}
                                                   menuIsOpen={isImpactOnDropdownOpen}
                                                   menuPortalTarget={null}
                                               />
                                           </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_ImpactOnID}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={ImpactOnList}
                                                                value={setSelectValue(ImpactOnList, pointsDiscussedList[index].ImpactOnID)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'ImpactOnID', e)
                                                                }
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setIsImpactOnDropdownOpen(true)}
                                                                onMenuClose={() => setIsImpactOnDropdownOpen(false)}
                                                                menuIsOpen={isImpactOnDropdownOpen}
                                                                menuPortalTarget={null}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                        {/* Closure Date  */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput inputStyle" style={{ width: "100%" }} type="date" value={item.ClosureDate? item.ClosureDate.slice(0, 10) : ''} onChange={(e) => handleInputChange(index, 'ClosureDate', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{formatDate(item.Previous_ClosureDate)}</p>
                                                    {item.is_edit ?
                                                        <input className="tableInput inputStyle" style={{ width: "100%" }} type="date" value={item.ClosureDate? item.ClosureDate.slice(0, 10) : ''} onChange={(e) => handleInputChange(index, 'ClosureDate', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                        {/* Criticality */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                               <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                               <Select
                                                   id={index}
                                                   options={CriticalityList}
                                                   value={setSelectValue(CriticalityList, pointsDiscussedList[index].CriticalityID)}
                                                   styles={customStyles}
                                                   menuPosition={'fixed'}
                                                   maxMenuHeight={100}
                                                   onChange={
                                                       (e) => handleInputChange(index, 'CriticalityID', e)
                                                   }
                                                   closeMenuOnScroll={() => !isSelectHovered}
                                                   onMenuOpen={() => setIsCriticalityDropdownOpen(true)}
                                                   onMenuClose={() => setIsCriticalityDropdownOpen(false)}
                                                   menuIsOpen={isCriticalityOnDropdownOpen}
                                                   menuPortalTarget={null}
                                               />
                                           </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_CriticalityID}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={CriticalityList}
                                                                value={setSelectValue(CriticalityList, pointsDiscussedList[index].CriticalityID)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'CriticalityID', e)
                                                                }
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setIsCriticalityDropdownOpen(true)}
                                                                onMenuClose={() => setIsCriticalityDropdownOpen(false)}
                                                                menuIsOpen={isCriticalityOnDropdownOpen}
                                                                menuPortalTarget={null}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                         {/* REMARKS */}
                                         <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <textarea
                                                    className="tableInput"
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    value={item.Remarks}
                                                    onChange={(e) => handleInputChange(index, 'Remarks', e.target.value)}
                                                />
                                            ) : (
                                                <div>
                                                    {item.Previous_Remarks && (
                                                        <pre className="preStyleFormat" >
                                                            {item.Previous_Remarks}
                                                        </pre>
                                                    )}
                                                    {item.is_edit ? (
                                                        <textarea
                                                            className="tableInput"
                                                            style={{ width: "100%" }}
                                                            type="text"
                                                            value={item.Remarks}
                                                            onChange={(e) => handleInputChange(index, 'Remarks', e.target.value)}
                                                        />
                                                    ) : null}
                                                </div>
                                            )}
                                        </td>


                                        {/* ACTION */}
                                        <td className={index % 2 === 0 ? "column-data-style bg-ylw" : "column-data-style bg-wh"} style={{display: props.ismonth ? "none" : "column-data-style" }}>
                                            {editableRows.includes(index) ? (
                                                <div className="d-flex justify-content-around">
                                                    <div className="d-flex justify-content-center">
                                                        <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                        <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ width: "100px" }}>
                                                    {!item.is_edit ?
                                                        <div className="d-flex justify-content-around">
                                                            <div>
                                                                <img src={edit} className="edit" alt="Edit" style={{ width: "13px" }} onClick={() => handleEdit(index)} />
                                                            </div>
                                                            <div>
                                                                <img src={remove} className="delete" alt="Delete" style={{ width: "13px" }} onClick={() => handleDelete(index)} />
                                                            </div>
                                                            <div>
                                                                <img src={add} className="add" alt="Add" style={{ width: "13px" }} onClick={() => handleAdd(index, "add")} />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="d-flex justify-content-around">
                                                            <div className="d-flex justify-content-center">
                                                                <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                                <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                            //     <div style={{ width: "100px" }}> 
                                            //     {!item.is_edit ? (
                                            //         <div className="d-flex justify-content-around" style={{ width: "100%" }}>
                                            //             <div style={{ flex: "0 0 33%" }}>
                                            //                 <img src={edit} className="edit" alt="Edit" style={{ width: "13px" }} onClick={() => handleEdit(index)} />
                                            //             </div>
                                            //             <div style={{ flex: "0 0 33%" }}> 
                                            //                 <img src={remove} className="delete" alt="Delete" style={{ width: "13px" }} onClick={() => handleDelete(index)} />
                                            //             </div>
                                            //             <div style={{ flex: "0 0 33%" }}> 
                                            //                 <img src={add} className="add" alt="Add" style={{ width: "13px" }} onClick={() => handleAdd(index, "add")} />
                                            //             </div>
                                            //         </div>
                                            //     ) : (
                                            //         <div className="d-flex justify-content-around" style={{ width: "100%" }}>
                                            //             <div className="d-flex justify-content-center" style={{ flex: "0 0 100%" }}> {/* Make this full width */}
                                            //                 <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                            //                 <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                            //             </div>
                                            //         </div>
                                            //     )}
                                            // </div>

                                            )}
                                        </td>
                                    </tr>
                                    ))
                                    : (
                                        <tr>
                                            <td colSpan="10" className="text-center">
                                                <div className="add">
                                                    <div className="add-btn" onClick={handleAddNew}>
                                                        <span>Add New</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>)}
        </div>
    );
};