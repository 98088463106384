import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    LineElement
} from 'chart.js';
import "./AopGraph.css";
import imageURL from '../../../../assets/images/infoIcon.svg';
import edit from '../../../../assets/images/edit-icon.svg';
import remove from '../../../../assets/images/remove-icon.svg';
import add from '../../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../../components/help_side_panel/HelpSidePanel";

import SmallSizedLoader from "../../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '../../../../api_interceptor/api'
import Select from 'react-select';
import Alert_Popup from "../../../../components/alert_popup/Alert_Popup";
import close from '../../../../assets/images/close.png';
import { updateState } from "../../../../common_function/common_function";

import { Bar } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Actual vs Budget with Variance',
        },
    },
    scales: {
        x: {
            stacked: false,
        },
        y: {
            stacked: false,
        },
    },
    indexAxis: 'y', // Set the index axis to 'y' for a horizontal bar chart
};

const labels = ['CLIENT1', 'CLIENT2', 'CLIENT3', 'CLIENT4', 'CLIENT6', 'TOTAL'];
const actualData = [5, 5, 3, 6, 4, 7];
const budgetData = [5, 3, 4, 5, 6, 9];
const data = {
    labels,
    datasets: [
        {
            label: 'Actual',
            data: budgetData,
            backgroundColor: 'rgba(23, 162, 235, 0.5)',
        },
        {
            label: 'AOP - YTD (E)',
            data: actualData,
            backgroundColor: 'rgba(32, 99, 132, 0.5)',
        },
        {
            label: 'AOP 24-25 (E)',
            data: actualData,
            backgroundColor: '#FED888',
        }
    ],
};
export default function AopGraph(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [monthlyUpdateList, setmonthlyUpdateList] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const [competitionAreaList, setCompetitionAreaList] = useState([]);
    const [categorySpentBycategory_spent_by_client_nameList, setcategorySpentBycategory_spent_by_client_nameList] = useState([]);
    const [isClientInReachList, setIsClientInReachList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [graphDataLabel, setGraphDataLabel] = useState([]);
    const company_id = useSelector((state) => state.user.companyID);


    useEffect(() => {
        if (userData && userData['UserId']) {
            getMonthlyUpdate(userData['UserId'])
        }
    }, [userData]);

    const getMonthlyUpdate = (UID) => {
        updateState(props.setPreviewEnabled, userData['UserId'], access_details['company_division_id'], props.setPreviewList,company_id && 'value' in company_id ? company_id['value'] : "")
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-aop-clients-graph';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: company_id && 'value' in company_id ? company_id['value'] : ""
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setmonthlyUpdateList(response.data.aop_client_graph_list)
                        setGraphData(response.data.graph_data)
                        setGraphDataLabel(response.data.graph_data?.label || []);
                        setIsLoading(false)
                    }
                    else {
                        setGraphData(response.data.graph_data)
                        setGraphDataLabel(response.data.graph_data?.label || []);
                        setIsLoading(false)
                    }
                }
                else {
                    setGraphData(response.data.graph_data)
                        setGraphDataLabel(response.data.graph_data?.label || []);
                    setIsLoading(false)
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const handleEdit = (index) => {
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);

        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }

        if (editableRows.length === 0 && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });

            console.log({ currentOperation });
            setCurrentOperation('edit');
            setmonthlyUpdateList(updatedMonthlyUpdateList);
        }
    };

    const handleDelete = (index) => {
        if (editableRows.length === 0) {
            setDeleteReason(null)
            setDeletingIndex(index);
            setShowConfirmationModal(true);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
            handleDeleteAPI(deletingIndex, deleteReason);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };

    const handleDeleteAPI = (index, reason) => {
        const editedItem = monthlyUpdateList[index];
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/delete-aspirational-clients';
        const req_body = {
            "id": editedItem.id,
            "deleted_reason": reason,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: company_id && 'value' in company_id ? company_id['value'] : ""
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    getMonthlyUpdate(userData['UserId'])
                    setmonthlyUpdateList(prevList => prevList.filter((item, i) => i !== index));
                }
                else {
                    getMonthlyUpdate(userData['UserId'])
                    setmonthlyUpdateList(prevList => prevList.filter((item, i) => i !== index));
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    const editMontlyUpdate = (editedItem, index) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/add-aop-clients-graph';
        const req_body = editedItem;
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMonthlyUpdate(userData['UserId'])
                }
                else {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMonthlyUpdate(userData['UserId'])
                }

            })
            .catch((error) => {
                setAddClick(false)
                setIsLoading(false)
                console.error(error);
            });
    }

    const getVal = (val) => {
        if (val == true) {
            return 1
        }
        if (val == false) {
            return 0
        }
        if (val == 0) {
            return 0
        }
        if (val == 1) {
            return 1
        }
    }

    const getdropdownValYesNo = (editedItem) => {
        if (editedItem.current && editedItem.current.value == undefined) {
            return setSelectValue(isClientInReachList, setDropDownTrueFalse(editedItem.current)).value
        }
        else if (editedItem.current == false) {
            return 0
        }
        else {
            return getVal(editedItem.current.value)
        }
    }

    // Validation start logic

    const validateMonthlyUpdate = (editedItem) => {
        const emptyFields = [];

        // Validation 1: Dropdown should be mandatory


        if (/^\s*$/.test(editedItem.client_name)) {
            emptyFields.push("Please enter value for  for client name\n");
        }

        if (/^\s*$/.test(editedItem.actual)) {
            emptyFields.push("Please enter value for actual\n");
        }

        if (/^\s*$/.test(editedItem.aop_ytd)) {
            emptyFields.push("Please enter value for  for aop ytd\n");
        }

        if (/^\s*$/.test(editedItem.aop_year)) {
            emptyFields.push("Please enter value for  for aop year\n");
        }


        return emptyFields;
    };


    // Validation end logic

    // const calculateTotal = (field) => {
    //     return monthlyUpdateList.reduce((sum, item) => sum + (parseInt(item[field]) || 0), 0);
    // };

    const calculateTotal = (field) => {
    if (!monthlyUpdateList || monthlyUpdateList.length === 0) {
        return 0; // Return 0 or another default value when the list is undefined or empty
    }

    return monthlyUpdateList.reduce((sum, item) => sum + (parseInt(item[field]) || 0), 0);
};


    const handleSave = (index, e) => {
        const editedItem = monthlyUpdateList[index];
        // Validation 1: Dropdown should be mandatory

        const req_body = {
            "client_name": editedItem.client_name,
            "actual": parseInt(editedItem.actual),
            "aop_ytd": parseInt(editedItem.aop_ytd),
            "aop_year": parseInt(editedItem.aop_year),
            "is_update": isAddClicked ? false : true,
            "update_id": isAddClicked ? null : editedItem.id,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: company_id && 'value' in company_id ? company_id['value'] : ""
        }

        // Check if it's an add operation
        if (isAddClicked) {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                editMontlyUpdate(req_body, index)
                setCurrentOperation(null)
            }
        } else {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                editMontlyUpdate(req_body, index)
                setCurrentOperation(null)
            }
        }


    };


    const handleClose = (index) => {
        // Remove the index from editableRows when clicking the close icon
        setEditableRows([]);
        setAddClick(false);

        // If the item is newly added and being closed, remove it from the list
        if (!monthlyUpdateList[index].is_update) {
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }

        // Reset the is_edit flag
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        updatedMonthlyUpdateList[index].is_edit = false;
        setmonthlyUpdateList(updatedMonthlyUpdateList);

        if (currentOperation === 'add') {
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }
        setCurrentOperation(null)
    };


    const handleAddNew = (index) => {
        // const updatedMonthlyUpdateList = [...monthlyUpdateList];
        if (!monthlyUpdateList || !monthlyUpdateList.length) {
            // If monthlyUpdateList is undefined or empty, initialize it with an empty array
            setmonthlyUpdateList([]);
        }
        const newItem = {
            client_name: "",
            actual: "",
            aop_ytd: "",
            aop_year: "",
        };
        setmonthlyUpdateList((prevList) => {
            const updatedList = [...prevList];
            updatedList.splice(index + 1, 0, newItem);
            return updatedList;
        });
        setEditableRows([...editableRows, 0]);
        setCurrentOperation('add');
        setAddClick(true); // Set isAddClicked to true for add operation
    };

    const handleAdd = (index) => {
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        const newItem = {
            client_name: "",
            actual: "",
            aop_ytd: "",
            aop_year: "",
        };
        setmonthlyUpdateList((prevList) => {
            const updatedList = [...prevList];
            updatedList.splice(index + 1, 0, newItem);
            return updatedList;
        });
        setEditableRows([...editableRows, index + 1]);
        setCurrentOperation('add');
        setAddClick(true); // Set isAddClicked to true for add operation
    };

    const handleInputChange = (index, field, value) => {
        console.log("Index ------------>>", index, field, value);
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        updatedMonthlyUpdateList[index][field] = value;
        setmonthlyUpdateList(updatedMonthlyUpdateList);
    };

    const setSelectValue = (list, value) => {
        console.log("List ---- >> Value ----->>>>", list, value);
        return list.find((data) => {
            return data.label === value;
        });

    }
    const setDropDownTrueFalse = (val) => {
        if (val == true) {
            return "Yes"
        }
        else if (val == false) {
            return "No"
        }
        else {
            return ""
        }
    }

    function formatCurrency(amount) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return formatter.format(amount);
    }

    // validation popup close

    const closePopup = () => {
        setPopupOpen(false);
    };

    // dropdown scroll close start

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the background color of the control
            marginTop: 0, // Adjust the top margin
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
            color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
            height: '45px',

        }),
    };

    // Add state variables for each dropdown
    const [isCurrenttDropdownOpen, setIsCurrentDropdownOpen] = useState(false);

    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };

    // dropdown scroll close end
    const calculatePipeline = (monthlyUpdateList) => {
        const totalActual = monthlyUpdateList.reduce((sum, item) => sum + (item.actual || 0), 0);
        const totalAopYtd = monthlyUpdateList.reduce((sum, item) => sum + (item.aop_ytd || 0), 0);
        const totalAopYear = monthlyUpdateList.reduce((sum, item) => sum + (item.aop_year || 0), 0);

        return totalActual + totalAopYtd + totalAopYear;
    };

    function getCurrentYear() {
        const currentYear = new Date().getFullYear(); // Get the current year
        const shortYear = currentYear.toString().slice(2); // Get the last two digits of the year
        const nextShortYear = (currentYear + 1).toString().slice(2); // Get the last two digits of the next year
        return `${shortYear}-${nextShortYear}`; // Format the year range
    }

    return (
        <div className="parentContainer">

            {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>
                            <div className='col-6 text-start'>
                                {/* <h4>Forget Pasword</h4> */}
                            </div>
                            <div className='col-6 text-end'>
                                <a onClick={() => {
                                    setShowConfirmationModal(!showConfirmationModal)
                                }}>< i class="fa fa-times" aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}

                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                />
                            </label>
                            <button type="submit">Delete</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Validation popup start */}
            {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text">Information</p>
                            </div>
                        </div>
                        <form>
                            <ol>
                                {errorList.map((item) =>
                                    <li className='error-text'>{item}</li>
                                )}
                            </ol>
                            <button className="close-btn" onClick={closePopup}>Close</button>
                        </form>
                    </div>
                </div>
            )}
            {/* Validation popup end */}

            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}
            <div className="monthlyHeaderWrapper d-flex justify-content-between">
                {/* <span className="monthly-Title-Table-header">{"Aspirational Clients (outside of AOP)"}</span> */}
                <span className="monthly-Title-Table-header"></span>
                <div className="total">
                    <span className="total-text">Pipeline</span>
                    <span className="pipeline-number">{calculateTotal('actual') + calculateTotal('aop_ytd') + calculateTotal('aop_year')}</span>

                </div>

            </div>
            {isLoading ? (<SmallSizedLoader />) :
                (<div className="table-div padStyle margin-Btm">
                    <div className="row">
                        <div className="col-6">
                            <div>
                                <p class="graphName">AOP vs Actual YTD (by Client)</p>
                                <Bar options={options} data={{
                                    labels: graphDataLabel || [],
                                    datasets: [
                                        {
                                            label: (graphData?.client_data?.actual?.label.toUpperCase() || []),
                                            data: graphData?.client_data?.actual?.data || [],
                                            backgroundColor: '#005273',
                                        },
                                        {
                                            label: (graphData?.client_data?.aop_ytd?.label.toUpperCase() + ' ' + "(E)" || []),
                                            data: graphData?.client_data?.aop_ytd?.data || [],
                                            backgroundColor: '#E66969',
                                        },
                                        {
                                            label: (graphData?.client_data?.aop_year?.label.toUpperCase() + ' ' + "(E)" + ' ' + getCurrentYear() || []),
                                            data: graphData?.client_data?.aop_year?.data || [],
                                            backgroundColor: '#FED888',
                                        }
                                    ],
                                }} />
                            </div>
                        </div>
                        <div className="col-6">
                            <table className="table-container-subdiv open-actionTable hits-table">
                                <thead className="hits-head graphTable">
                                    <tr>
                                        <th style={{ width: "15%" }} className="column-header-style fixed-column sr-column elevated-column-left">CLIENT NAME</th>
                                        <th style={{ width: "10%" }} className="column-header-style">ACTUAL</th>
                                        <th style={{ width: "10%" }} className="column-header-style">AOP YTD</th>
                                        <th style={{ width: "15%" }} className="column-header-style">AOP YEAR</th>
                                        <th style={{ width: "2%", display: props.ismonth ? "none" : "table-cell" }} className="column-header-style bg-wh fixed-column actions-column elevated-column-right">ACTIONS</th>
                                    </tr>
                                </thead>
                                <tbody className="hits-body">
                                {(monthlyUpdateList ?? []).length > 0 ? (
                                        monthlyUpdateList.map((item, index) =>
                                            <tr key={index} className={editableRows.includes(index) ? "editable-row" : ""}>


                                                {/* Action Item  */}
                                                <td className="column-data-style">
                                                    {editableRows.includes(index) ? (
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.client_name} onChange={(e) => handleInputChange(index, 'client_name', e.target.value)} />
                                                    ) : (
                                                        <div>
                                                            {/* <p>{item.previous_client}</p> */}
                                                            {item.is_edit ?
                                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.client_name} onChange={(e) => handleInputChange(index, 'client_name', e.target.value)} />
                                                                : <p>{item.client_name}</p>
                                                            }
                                                        </div>
                                                    )}
                                                </td>
                                                {/* Imapct On */}
                                                <td className="column-data-style">
                                                    {editableRows.includes(index) ? (
                                                        <input className="tableInput budgetInput" style={{ width: "100%" }} type="number" value={item.actual} onChange={(e) => handleInputChange(index, 'actual', e.target.value)} />
                                                    ) : (
                                                        <div>
                                                            {/* <p>{item.previous_annual_spend}</p> */}
                                                            {item.is_edit ?
                                                                <input className="tableInput budgetInput" style={{ width: "100%" }} type="number" value={item.actual} onChange={(e) => handleInputChange(index, 'actual', e.target.value)} />
                                                                : <p>{item.actual}</p>
                                                            }
                                                        </div>
                                                    )}
                                                </td>
                                                <td className="column-data-style">
                                                    {editableRows.includes(index) ? (
                                                        <input className="tableInput budgetInput" style={{ width: "100%" }} type="number" value={item.aop_ytd} onChange={(e) => handleInputChange(index, 'aop_ytd', e.target.value)} />
                                                    ) : (
                                                        <div>
                                                            {/* <p>{item.previous_budget}</p> */}
                                                            {item.is_edit ?
                                                                <input className="tableInput budgetInput" style={{ width: "100%" }} type="number" value={item.aop_ytd} onChange={(e) => handleInputChange(index, 'aop_ytd', e.target.value)} />
                                                                : <p>{item.aop_ytd}</p>
                                                            }
                                                        </div>
                                                    )}
                                                </td>

                                                <td className="column-data-style">
                                                    {editableRows.includes(index) ? (
                                                        <input className="tableInput budgetInput" style={{ width: "100%" }} type="number" value={item.aop_year} onChange={(e) => handleInputChange(index, 'aop_year', e.target.value)} />
                                                    ) : (
                                                        <div>
                                                            {/* <p>{item.previous_budget}</p> */}
                                                            {item.is_edit ?
                                                                <input className="tableInput budgetInput" style={{ width: "100%" }} type="number" value={item.aop_year} onChange={(e) => handleInputChange(index, 'aop_year', e.target.value)} />
                                                                : <p>{item.aop_year}</p>
                                                            }
                                                        </div>
                                                    )}
                                                </td>
                                                {/* Owner  */}

                                                <td className={index % 2 === 0 ? "column-data-style bg-ylw  elevated-column-right" : "column-data-style bg-wh elevated-column-right"} style={{display: props.ismonth ? "none" : "column-data-style" }}>
                                                    {editableRows.includes(index) ? (
                                                        <div className="d-flex justify-content-around">
                                                            <div className="d-flex justify-content-center">
                                                                <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                                <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            {!item.is_edit ?
                                                                <div className="d-flex justify-content-around">
                                                                    <div>
                                                                        <img src={edit} className="edit" alt="Edit" style={{ width: "13px" }} onClick={() => handleEdit(index)} />
                                                                    </div>

                                                                    <div>
                                                                        <img src={add} className="add" alt="Add" style={{ width: "13px" }} onClick={() => handleAdd(index, "add")} />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="d-flex justify-content-around">
                                                                    <div className="d-flex justify-content-center">
                                                                        <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                                        <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                        : (
                                            <tr>
                                                <td colSpan="6" className="text-center">
                                                    <div className="add">
                                                        <div className="add-btn" onClick={handleAddNew}>
                                                            <span>Add New</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    <tr className="hits-head">
                                        <td className="column-header-style">
                                            <p>Total</p>
                                        </td>
                                        <td className="column-header-style">
                                            <p>{calculateTotal('actual')}</p>
                                        </td>
                                        <td className="column-header-style">
                                            <p>{calculateTotal('aop_ytd')}</p>
                                        </td>
                                        <td className="column-header-style">
                                            <p>{calculateTotal('aop_year')}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff" , zIndex:9999999999 }} /> */}

                </div>)}
        </div>
    );
}