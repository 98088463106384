import React from "react";
import "./Loader.css";

export default function SmallSizedLoader() {
    return (
        <div >
        <div>
            <div className="loading-spinner">
                <div className="loading-spinner2">
                </div>
            </div>
        </div>
        </div>
    );
} 