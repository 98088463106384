import React, { useEffect, useState } from "react";
import "./FinancialPerformanceYTD.css";
import imageURL from '../../../../assets/images/infoIcon.svg';
import edit from '../../../../assets/images/edit-icon.svg';
import remove from '../../../../assets/images/remove-icon.svg';
import add from '../../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../../components/help_side_panel/HelpSidePanel";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import SmallSizedLoader from "../../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '.././../../../api_interceptor/api'
import Select from 'react-select';
import Alert_Popup from "../../../../components/alert_popup/Alert_Popup";
import close from '../../../../assets/images/close.png';
import { updateState, roundToTwoDecimals } from "../../../../common_function/common_function";


export default function FinancialPerformanceYTD(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [monthlyUpdateList, setmonthlyUpdateList] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const [getHeaderList, setHeaderList] = useState([]);
    const [OwnerList, setOwnerList] = useState([]);
    const [getReasonList, setReasonList] = useState([]);
    const [getRiskList, setRiskList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [getCountData, setgetCountData] = useState(null);
    const [getCountDataShow, setgetCountDatadshow] = useState(false);
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const company_id = useSelector((state) => state.user.companyID);

    useEffect(() => {
        if (userData && userData['UserId']) {
            getMonthlyUpdate(userData['UserId'])
        }
    }, [userData]);

    useEffect(() => {
        setgetCountDatadshow(!getCountDataShow)
    }, [getCountData]);

    const getMonthlyUpdate = (UID) => {
        updateState(props.setPreviewEnabled, userData['UserId'], access_details['company_division_id'], props.setPreviewList,access_details['company_division_id'])
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-financial-performance-ytd';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data.help_details && (response.data.help_details).length > 0) {
                        console.log("respdata0000--->", response.data);
                        setgetCountData(response.data)
                        setmonthlyUpdateList(response.data.financial_performance_list)
                        sethelpDetails(response.data.help_details)
                        getAreaOfUpdateDetails(userData['UserId'], response.data.financial_performance_list)

                    }
                    else {
                        sethelpDetails(response.data.help_details)
                        getAreaOfUpdateDetails(userData['UserId'], response.data.financial_performance_list)
                        setIsLoading(false)
                    }
                }
                else {
                    sethelpDetails(response.data.help_details)
                    getAreaOfUpdateDetails(userData['UserId'], response.data.financial_performance_list)
                    setIsLoading(false)
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const getAreaOfUpdateDetails = (UID, valdata) => {
        const API_URL = 'monthlymodule/api/get-financial-performance-dropdown-details';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setOwnerList(response.data.owner_list);
                        setHeaderList(response.data.header_list);
                        // setReasonList(response.data.reason_list);
                        // setRiskList(response.data.risk_list);
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const handleAddNew = (index) => {
       
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...monthlyUpdateList];
            const newItem = {
                description: "",
                impact_and_result: "",
                owner: "",
            };
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(0, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, 0]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const handleClose = (index) => {
        // Remove the index from editableRows when clicking the close icon
        setEditableRows([]);
        setAddClick(false)
        // If the item is newly added and being closed, remove it from the list
        if (!monthlyUpdateList[index].is_update) {
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }

        // Reset the is_edit flag
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        updatedMonthlyUpdateList[index].is_edit = false;
        setmonthlyUpdateList(updatedMonthlyUpdateList);

        if (currentOperation === 'add') {
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }
        setCurrentOperation(null)
        getMonthlyUpdate()
    };

    const handleEdit = (index) => {
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);

        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }

        if (editableRows.length === 0 && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });

            console.log({ currentOperation });
            setCurrentOperation('edit');
            setmonthlyUpdateList(updatedMonthlyUpdateList);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
            handleDeleteAPI(deletingIndex, deleteReason);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };

    function getPreviousYearMonth() {
        // Get the current date
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // January is 0
        const currentYear = currentDate.getFullYear();
        
        // Get the previous year
        const previousYear = currentYear - 1;
        
        // Determine the previous month
        const previousMonthIndex = currentMonth - 1;
        const months = [
            'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
            'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
        ];
        let previousMonth;
        if (previousMonthIndex < 0) {
            previousMonth = 'DEC';
        } else {
            previousMonth = months[previousMonthIndex];
        }
        
        // Format and return the result
        return `${previousMonth}'${previousYear.toString().slice(2)}`;
        }

    const handleDeleteAPI = (index, reason) => {
        const editedItem = monthlyUpdateList[index];
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/delete-open-action-items-ytd';
        const req_body = {
            "id": editedItem.id,
            "deleted_reason": reason,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    getMonthlyUpdate(userData['UserId'])
                }
                else {
                    getMonthlyUpdate(userData['UserId'])
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    const editMontlyUpdate = (editedItem, index) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/add-financial-performance-ytd';
        const req_body = editedItem;
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMonthlyUpdate(userData['UserId'])
                }
                else {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMonthlyUpdate(userData['UserId'])
                }

            })
            .catch((error) => {
                setAddClick(false)
                setIsLoading(false)
                console.error(error);
            });
    }

    // Validation start logic

    const validateMonthlyUpdate = (editedItem) => {
        const emptyFields = [];

        // Validation 1: Dropdown should be mandatory
       
        // if (!editedItem.header) {
        //     emptyFields.push("Please select header.\n");
        // }
        if (editedItem.budget === null || editedItem.budget === undefined) {
        } else {
            const budgetStr = String(editedItem.budget);
            const hasDecimal = budgetStr.includes('.');
            
            if (hasDecimal) {
                const [wholePart, decimalPart = ''] = budgetStr.split('.');
                if (wholePart.length > 6) {
                    emptyFields.push("Value cannot exceed 6 digits.\n");
                }
            } else {
                if (budgetStr.length > 6) {
                    emptyFields.push("Value cannot exceed 6 digits.\n");
                }
            }
        }
        
        if (editedItem.actual === null || editedItem.actual === undefined) {
        } else {
            const actualStr = String(editedItem.actual);
            const hasDecimal = actualStr.includes('.');
            
            if (hasDecimal) {
                const [wholePart, decimalPart = ''] = actualStr.split('.');
                if (wholePart.length > 6) {
                    emptyFields.push("Value cannot exceed 6 digits.\n");
                }
            } else {
                if (actualStr.length > 6) {
                    emptyFields.push("Value cannot exceed 6 digits.\n");
                }
            }
        }
        
        if (editedItem.previous_year_actual === null || editedItem.previous_year_actual === undefined) {
        } else {
            const previousYearActualStr = String(editedItem.previous_year_actual);
            const hasDecimal = previousYearActualStr.includes('.');
            
            if (hasDecimal) {
                const [wholePart, decimalPart = ''] = previousYearActualStr.split('.');
                if (wholePart.length > 6) {
                    emptyFields.push("Value cannot exceed 6 digits.\n");
                }
            } else {
                if (previousYearActualStr.length > 6) {
                    emptyFields.push("Value cannot exceed 6 digits.\n");
                }
            }
        }
        
        

        // if (/^\s*$/.test(editedItem.open_since_date)) {
        //     emptyFields.push("Please enter value for open since date.\n");
        // }

        // if (/^\s*$/.test(editedItem.revised_due_date)) {
        //     emptyFields.push("Please enter value for revised due date.\n");
        // }

        // if (!editedItem.owner_name) {
        //     emptyFields.push("Please select owner name.\n");
        // }

        return emptyFields;
    };


    // Validation end logic

    const handleSave = (index, e) => {
        const editedItem = monthlyUpdateList[index];
        // Validation 1: Dropdown should be mandatory
        
        const req_body = {
            header: editedItem.header,
            budget: roundToTwoDecimals(editedItem.budget),
            actual: roundToTwoDecimals(editedItem.actual),
            previous_year_actual: roundToTwoDecimals(editedItem.previous_year_actual),
            // mitigitive_actions: editedItem.mitigitive_actions,
            due_date: "",
            open_since_date: editedItem.open_since_date,
            revised_due_date: editedItem.revised_due_date,
            owner: editedItem.owner_name,
            is_update: isAddClicked ? false : true,
            update_id: isAddClicked ? null : editedItem.id,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        }

        // Check if it's an add operation
        if (isAddClicked) {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                editMontlyUpdate(req_body, index)
                setCurrentOperation(null)
            }
        } else {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                editMontlyUpdate(req_body, index)
                setCurrentOperation(null)
            }
        }


    };


    // const handleAdd = (index) => {
    //     const updatedMonthlyUpdateList = [...monthlyUpdateList];
    //     const newItem = {
    //         action_item: "",  // Set default value based on the clicked row
    //         owner_id: "",
    //         impact_on_id: "",
    //         raised_on: "",
    //         closure_date: "",
    //         status_id: "",
    //         update_for_the_month: "",
    //     };
    //     updatedMonthlyUpdateList.splice(index + 1, 0, newItem);
    //     setmonthlyUpdateList(updatedMonthlyUpdateList);
    //     setEditableRows([...editableRows, index + 1]);
    //     setAddClick(true)
    // };

    const handleInputChange = (index, field, value) => {
        console.log("input from date --------->>>", field, value);
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        // updatedMonthlyUpdateList[index][field] = value;
        const updatedValue = typeof value === 'object' ? value.label : value;
        updatedMonthlyUpdateList[index][field] = updatedValue;
        setmonthlyUpdateList(updatedMonthlyUpdateList);
    };

    const setSelectValue = (list, value) => {
        return list.find((data) => {
            return data.label === value;
        });

    }
    const calculateVariancePercentage = (budgetedAmount, actualAmount) => {
        try {
            const variancePercentage =
                ((actualAmount - budgetedAmount) / budgetedAmount) * 100;
            return (Math.round(variancePercentage * 100) / 100).toFixed(2); 
        } catch (error) {
            if (error instanceof TypeError) {
                return null;
            }
            throw error;
        }
    };
    const calculateVarianceForFinancial = (budgetedAmount, actualAmount) => {
        try {
            const variancePercentage = actualAmount - budgetedAmount
            return (Math.round(variancePercentage * 100) / 100).toFixed(2); 
        } catch (error) {
            if (error instanceof TypeError) {
                return null;
            }
            throw error;
        }
    };
    function formatDateString(inputDateString) {
        const inputDate = new Date(inputDateString);

        const options = { day: "numeric", month: "short", year: "numeric" };
        const formattedDate = inputDate.toLocaleDateString("en-GB", options);

        return formattedDate;
    }




    // validation popup close

    const closePopup = () => {
        setPopupOpen(false);
    };



    // dropdown scroll close start

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the background color of the control
            marginTop: 0, // Adjust the top margin
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
            color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
            height: '45px',

        }),
    };

    // Add state variables for each dropdown
    const [isHeaderDropdownOpen, setIsHeaderDropdownOpen] = useState(false);

    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };

    function getCurrentYear() {
        const currentYear = new Date().getFullYear(); // Get the current year
        const shortYear = currentYear.toString().slice(2); // Get the last two digits of the year
        const nextShortYear = (currentYear + 1).toString().slice(2); // Get the last two digits of the next year
        return `${shortYear}-${nextShortYear}`; // Format the year range
    }

    // dropdown scroll close end
    

    return (

        <div className="parentContainer" style={{marginBottom:"20px"}}>
            {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>
                            <div className='col-6 text-start'>
                                {/* <h4>Forget Pasword</h4> */}
                            </div>
                            <div className='col-6 text-end'>
                                <a onClick={() => {
                                    setShowConfirmationModal(!showConfirmationModal)
                                }}>< i class="fa fa-times" aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}

                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                />
                            </label>
                            <button type="submit">Delete</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Validation popup start */}
         {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text" style={{fontSize:"20px"}}>Information</p>
                            </div>
                        </div>
                        <div className='ChatBodyDetails' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto',overflowY:'auto', padding:"0rem"}}>
                                {errorList.map((item, index) => (
                                    <div style={{ display : 'flex'}}>
                                        <div className=' d-flex mb-3' style={{width:'90%'}}>
                                            {`${index+1}) ${item}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        <button className="close-btn" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
            {/* Validation popup end */}

            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}

            <div className="monthlyHeaderWrapper d-flex justify-content-between">
                <span className="monthly-Title-Table-header">{'Financial Performance' + ' (FY ' +getCurrentYear()+ ')'}</span>
                {!isLoading && <HelpSidePanel content={"Month to date and Year to date update on Budget-Actual-Variance across various Financial Parameters"} />}
            </div>

            {isLoading ? (<SmallSizedLoader />) :
                (<div className="table-div padStyle ">
                    <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff", zIndex: 9999999999 }} />
                    <div className="table-container">
                        <table className={monthlyUpdateList.length > 0?  "table-container-subdiv open-actionTable financial-performance" : "table-container-subdiv open-actionTable"} style={{maxWidth:"100%", border:"none"}}>
                            <thead className="financial-head">
                                <tr>
                                    <th style={{ width: "9%" }} className="column-header-style-Financial-YTD fixed-column sr-column">HEADER</th>
                                    <th style={{ width: "5%" }} className="column-header-style-Financial-YTD">BUDGET</th>
                                    <th style={{ width: "5%" }} className="column-header-style-Financial-YTD">ACTUAL</th>
                                    <th style={{ width: "5%" }} className="column-header-style-Financial-YTD">VARIANCE</th>
                                    <th style={{ width: "5%" }} className="column-header-style-Financial-YTD">VARIANCE(%)</th>
                                    <th style={{ width: "5%" }} className="column-header-style-Financial-YTD">{`${getPreviousYearMonth()}(ACT)`}</th>
                                    <th style={{ width: "5%" }} className="column-header-style-Financial-YTD">GROWTH(%)</th>
                                    <th style={{ width: "8%", display: props.ismonth ? "none" : "table-cell" }} className="column-header-style-Financial-YTD bg-wh fixed-column actions-column">ACTIONS</th>

                                    <th style={{ width: "8%", visibility: "hidden" }} className="column-header-style-Financial">REASONS - HITS</th>
                                    <th style={{ width: "8%", visibility: "hidden" }} className="column-header-style-Financial">REASONS - MISSES</th>

                                    {/* <th style={{ width: "20%" }} className="column-header-style">
                                        <div className="d-flex justify-content-between">
                                            <div>MITIGATIVE ACTIONS</div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Owner of the task or update"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>

                                            </div>
                                        </div>
                                    </th> */}
                                    {/* <th style={{ width: "10%" }} className="column-header-style">OPEN SINCE DATE</th>
                                    <th style={{ width: "10%" }} className="column-header-style">REVISED DUE DATE</th>
                                    <th style={{ width: "20%" }} className="column-header-style">OWNER</th> */}
                                </tr>
                            </thead>
                            <tbody className="financial-body">
                            {monthlyUpdateList.length > 0 ? (
                                monthlyUpdateList.map((item, index) =>
                                    <tr key={index} className={editableRows.includes(index) ? "editable-row" : ""}>
                                        {/* Header
                                        <td className="column-data-style fixed-column sr-column elevated-column-left">
                                            {editableRows.includes(index) ? (
                                                <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.header} onChange={(e) => handleInputChange(index, 'header', e.target.value)} />
                                                
                                            ) : (
                                                <div>
                                                    <p>{item.previous_header}</p>
                                                    {item.is_edit ?
                                                        <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.header} onChange={(e) => handleInputChange(index, 'header', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td> */}

                                        {/* Header */}
                                        <td className="column-data-style fixed-column sr-column ">
                                            {editableRows.includes(index) ? (
                                                <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Select
                                                        id={index}
                                                        options={getHeaderList}
                                                        value={setSelectValue(getHeaderList, monthlyUpdateList[index].header)}
                                                        styles={customStyles}
                                                        menuPosition={'fixed'}
                                                        maxMenuHeight={100}
                                                        onChange={
                                                            (e) => handleInputChange(index, 'header', e)
                                                        }
                                                        closeMenuOnScroll={() => !isSelectHovered}
                                                        onMenuOpen={() => setIsHeaderDropdownOpen(true)}
                                                        onMenuClose={() => setIsHeaderDropdownOpen(false)}
                                                        menuIsOpen={isHeaderDropdownOpen}
                                                        menuPortalTarget={null}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{item.previous_header}</p>
                                                    {/* {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={getHeaderList}
                                                                value={setSelectValue(getHeaderList, monthlyUpdateList[index].header)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'header', e)
                                                                }
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setIsHeaderDropdownOpen(true)}
                                                                onMenuClose={() => setIsHeaderDropdownOpen(false)}
                                                                menuIsOpen={isHeaderDropdownOpen}
                                                                menuPortalTarget={null}
                                                            />
                                                        </div>
                                                        : ""
                                                    } */}
                                                </div>
                                            )}
                                        </td>

                                        {/* Budgetted*/}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput budgetInput" style={{ width: "100%" }} type="number" value={item.budget} onChange={(e) => handleInputChange(index, 'budget', e.target.value)} />
                                            ) : (
                                                <div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end" }}>
                                                    <p style={{ margin: 0 }}>
                                                        {item.previous_budget !== null 
                                                        ? (Math.round(item.previous_budget * 100) / 100).toFixed(2) 
                                                        : item.previous_budget}
                                                    </p>
                                                    {item.previous_budget !== null && ["%GM to Rev", "%SGNA to Rev"].includes(item.header) && (
                                                        <span style={{ marginLeft: '2px' }}>%</span>
                                                    )}
                                                </div>

                                                    {item.is_edit ?
                                                        <input className="tableInput budgetInput" style={{ width: "100%", textAlign:"right" }} type="number" value={item.budget} onChange={(e) => handleInputChange(index, 'budget', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* Actual */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput budgetInput" style={{ width: "100%" }} type="number" value={item.actual} onChange={(e) => handleInputChange(index, 'actual', e.target.value)} />
                                            ) : (
                                                <div>
                                                    {/* <p>{item.previous_actual}</p> */}
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end" }}>
                                                        <p style={{ margin: 0 }}>
                                                            {item.previous_actual !== null 
                                                            ? (Math.round(item.previous_actual * 100) / 100).toFixed(2) 
                                                            : item.previous_actual}
                                                        </p>
                                                        {item.previous_actual !== null && ["%GM to Rev", "%SGNA to Rev"].includes(item.header) && (
                                                            <span style={{ marginLeft: '2px' }}>%</span>
                                                        )}
                                                    </div>

                                                    {item.is_edit ?
                                                        <input className="tableInput budgetInput" style={{ width: "100%", textAlign:"right" }} type="number" value={item.actual} onChange={(e) => handleInputChange(index, 'actual', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                        {/* Variance*/}
                                        <td className="column-data-style">
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end" }}>
                                                <p style={{ margin: 0 }}>
                                                    {(isNaN(calculateVarianceForFinancial(item.budget, item.actual))) 
                                                        ? '-' 
                                                        : calculateVarianceForFinancial(item.budget, item.actual)}
                                                </p>
                                                {["%GM to Rev", "%SGNA to Rev"].includes(item.header) && 
                                                    item.budget !== null && 
                                                    item.actual !== null && (
                                                    <span style={{ marginLeft: '4px' }}>%</span>
                                                )}
                                            </div>
                                        </td>

                                        {/* <td className="column-data-style">
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <p style={{ margin: 0 }}>
                                                {["%GM to Rev", "%SGNA to Rev"].includes(item.header) ? (
                                                    '-'
                                                ) : (
                                                    item.budget !== null && item.actual !== null ? (
                                                        isNaN(calculateVarianceForFinancial(item.budget, item.actual)) 
                                                            ? '0' 
                                                            : !isFinite(calculateVarianceForFinancial(item.budget, item.actual)) 
                                                            ? '-' 
                                                            : `${calculateVarianceForFinancial(item.budget, item.actual)}`
                                                    ) : '-'
                                                )}
                                            </p>
                                            {["%GM to Rev", "%SGNA to Rev"].includes(item.header) && 
                                                    item.budget !== null && 
                                                    item.actual !== null && 
                                                    calculateVarianceForFinancial(item.budget, item.actual) !== '-' && (
                                                    <span style={{ marginLeft: '4px' }}>%</span>
                                                )}
                                        </div>
                                        </td> */}


                                        {/* Variance in (%) */}
                                        <td className="column-data-style">
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <p style={{ margin: 0 }}>
                                                    {["%GM to Rev", "%SGNA to Rev"].includes(item.header) ? (
                                                        '-'
                                                    ) : (
                                                        item.budget !== null && item.actual !== null ? (
                                                            isNaN(calculateVariancePercentage(item.budget, item.actual)) 
                                                                ? '0%' 
                                                                : !isFinite(calculateVariancePercentage(item.budget, item.actual)) 
                                                                ? '-' 
                                                                : `${calculateVariancePercentage(item.budget, item.actual)} %`
                                                        ) : '-'
                                                    )}
                                                </p>
                                            </div>
                                        </td>

                                        {/* <td className="column-data-style">
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <p style={{ margin: 0 }}>
                                                {["%GM to Rev", "%SGNA to Rev"].includes(item.header) ? (
                                                        '-'
                                                    ) : (
                                                        item.budget !== null && item.actual !== null ? (
                                                            isNaN(calculateVariancePercentage(item.budget, item.actual)) 
                                                                ? '0%' 
                                                                : !isFinite(calculateVariancePercentage(item.budget, item.actual)) 
                                                                ? '-' 
                                                                : `${calculateVariancePercentage(item.budget, item.actual)} %`
                                                        ) : '-'
                                                    )}
                                                </p>
                                            </div>
                                        </td> */}

                                      
                                        {/* Previous Actual */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input
                                                    className="tableInput budgetInput"
                                                    style={{ width: "100%" }}
                                                    type="number"
                                                    value={item.previous_year_actual}
                                                    onChange={(e) => handleInputChange(index, 'previous_year_actual', e.target.value)}
                                                />
                                            ) : (
                                                <div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                        <p style={{ margin: 0 }}>
                                                                {item.previous_previous_year_actual !== null 
                                                                ? (Math.round(item.previous_previous_year_actual * 100) / 100).toFixed(2) 
                                                                : item.previous_previous_year_actual}
                                                        </p>
                                                        {item.previous_previous_year_actual !== null && ["%GM to Rev", "%SGNA to Rev"].includes(item.header) && (
                                                            <span style={{ marginLeft: '2px' }}>%</span>
                                                        )}
                                                    </div>
                                                    {item.is_edit && (
                                                        <input
                                                            className="tableInput budgetInput"
                                                            style={{ width: "100%", marginTop: '4px', textAlign:"right" }}
                                                            type="number"
                                                            value={item.previous_year_actual}
                                                            onChange={(e) => handleInputChange(index, 'previous_year_actual', e.target.value)}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </td>


                                        {/* Growth in (%) */}
                                      
                                                {/* <p>
                                                    {isNaN(calculateVariancePercentage(item.previous_year_actual, item.actual)) || 
                                                    !isFinite(calculateVariancePercentage(item.previous_year_actual, item.actual)) 
                                                        ? '0%' 
                                                        : `${calculateVariancePercentage(item.previous_year_actual, item.actual)} %`}
                                                </p> */}

                                        <td className="column-data-style">
                                            <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                                                <p style={{ margin: 0 }}>
                                                    {item.header && ["%GM to Rev", "%SGNA to Rev"].includes(item.header)
                                                        ? '-'
                                                        : (isNaN(calculateVariancePercentage(item.previous_year_actual, item.actual)) 
                                                            ? '-' 
                                                            : !isFinite(calculateVariancePercentage(item.previous_year_actual, item.actual)) 
                                                            ? '-' 
                                                            : calculateVariancePercentage(item.previous_year_actual, item.actual) === 0 
                                                            ? '-' 
                                                            : `${calculateVariancePercentage(item.previous_year_actual, item.actual)} %`)
                                                    }
                                                </p>
                                            </div>
                                        </td>

                                      
                                        {/* Migitative Actions  */}
                                        {/* <td className="column-data-style update-month">
                                            {editableRows.includes(index) ? (
                                                <div> */}
                                                    {/* {item && item.previous_mitigitive_actions && item.previous_mitigitive_actions !=null && item.previous_mitigitive_actions !=undefined && item.previous_mitigitive_actions!="" ? <label className={index == 0 ? "update-message-bottom": "update-message-top"}>{item.previous_mitigitive_actions}</label>: null}  */}
                                                    {/* <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.mitigitive_actions} onChange={(e) => handleInputChange(index, 'mitigitive_actions', e.target.value)} />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{item.is_edit ? item.previous_mitigitive_actions : item.mitigitive_actions}</p>
                                                    {item.is_edit ?
                                                        <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.mitigitive_actions} onChange={(e) => handleInputChange(index, 'mitigitive_actions', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td> */}

                                        {/* <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput budgetInput" style={{ width: "100%" }} type="date" value={item.open_since_date} onChange={(e) => handleInputChange(index, 'open_since_date', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{formatDateString(item.previous_open_since_date)}</p>
                                                    {item.is_edit ?
                                                        <input className="tableInput budgetInput" style={{ width: "100%" }} type="date" value={item.open_since_date} onChange={(e) => handleInputChange(index, 'open_since_date', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td> */}
                                        {/* <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput budgetInput" style={{ width: "100%" }} type="date" value={item.revised_due_date} onChange={(e) => handleInputChange(index, 'revised_due_date', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{formatDateString(item.previous_revised_due_date)}</p>
                                                    {item.is_edit ?
                                                        <input className="tableInput budgetInput" style={{ width: "100%" }} type="date" value={item.revised_due_date} onChange={(e) => handleInputChange(index, 'revised_due_date', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td> */}
                                        {/* Owner */}
                                        {/* <td className="column-data-style fixed-column sr-column elevated-column-left">
                                            {editableRows.includes(index) ? (
                                                <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.owner_name} onChange={(e) => handleInputChange(index, 'owner_name', e.target.value)} />
                                                
                                            ) : (
                                                <div>
                                                    <p>{item.previous_owner_name}</p>
                                                    {item.is_edit ?
                                                        <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.owner_name} onChange={(e) => handleInputChange(index, 'owner_name', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td> */}
                                        
                                        <td className={index % 2 === 0 ? "column-data-style bg-ylw" : "column-data-style bg-wh"} style={{display: props.ismonth ? "none" : "column-data-style" }}>
                                            {editableRows.includes(index) ? (
                                                <div className="d-flex justify-content-around">
                                                    <div className="d-flex justify-content-center">
                                                        <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                        <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    {!item.is_edit ?
                                                        <div className="d-flex justify-content-around">
                                                            <div>
                                                            {(item.header !== "%GM to Rev"  && item.header !== "%SGNA to Rev")  && ( 
                                                                <img src={edit} className="edit" alt="Edit" style={{ width: "13px" }} onClick={() => handleEdit(index)} />
                                                            )}
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="d-flex justify-content-around">
                                                            <div className="d-flex justify-content-center">
                                                                <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                                <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                    ))
                                    : (
                                        <tr>
                                            <td colSpan="12" className="text-center">
                                                <div className="add">
                                                    <div className="add-btn" onClick={handleAddNew}>
                                                        <span>Add New</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>)}
        </div>
    );
}