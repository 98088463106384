import React, { useEffect, useRef, useState } from "react";
import "./RisksAndDecisions.css";
import imageURL from '../../../assets/images/infoIcon.svg';
import edit from '../../../assets/images/edit-icon.svg';
import remove from '../../../assets/images/remove-icon.svg';
import add from '../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../components/help_side_panel/HelpSidePanel";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import SmallSizedLoader from "../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '../../../api_interceptor/api'
import Select from 'react-select';
import Alert_Popup from "../../../components/alert_popup/Alert_Popup";
import AnalyticsImage from '../../../assets/images/EyeView.svg';
import AttachImage from '../../../assets/images/attach-file.png';
import Dropdown from '../../../assets/images/Icon ionic-ios-arrow-down@2x.png';
import Download from '../../../assets/images/Download.svg';
import close from '../../../assets/images/close.png';

import { updateState, formatDateTimeForChat, formatDate} from "../../../common_function/common_function";
import CancelBluIconComment from '../../../assets/images/Cancel icon Comments.svg'
import ProfIcon from '../../../assets/images/Profile icon.svg'
import SendChatIcon from '../../../assets/images/Send chat icon.svg'
import CommementBlue from '../../../assets/images/Comment icon blue.svg'
import _ from 'lodash';


export default function RisksAndDecisions(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [monthlyUpdateList, setmonthlyUpdateList] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const [getDecisionRiskList, setDecisionRiskList] = useState([]);
    const [getDecisionMakerList, setDecisionMakerList] = useState([]);
    const [getDecisionImpactList, setDecisionImpactList] = useState([]);
    const [getCriticalityList, setCriticalityList] = useState([]);
    const [getDecisionList, setDecisionList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [getCountData, setgetCountData] = useState(null);
    const [getCountDataShow, setgetCountDatadshow] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('View All');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filePath, setFilePath] = useState("");
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const company_id = useSelector((state) => state.user.companyID);

    const [showDecisionComments, setshowDecisionComments] = useState(false);
    const [DecisionMRMChatDetails, setDecisionChatDetails] = useState([]);
    const [DecisionCommentsText, setDecisionCommentsText] = useState('');
    const [DecisionIDForChatAssignedTo, setDecisionIDForChatAssignedTo] = useState(null);
    const [DecisionIDForChat, setDecisionIDForChat] = useState(null);
    const [DecisionChatDetailsHeader, setDecisionChatDetailsHeader] = useState("");
    const [prevList, setPrevList] = useState([]);

    


    const toggleDropdown = () => {
        setIsOpen(!isOpen);

    };

    const selectOption = (option) => {
        setSelectedOption(option);
        console.log("--------------DD Value =---------->>>", selectedOption);
        setIsOpen(!isOpen);
        getMonthlyUpdate(userData['UserId'], option)
    };
    const fileInputRef = useRef(null);

    const handleIconClick = () => {
        fileInputRef.current.click();
    };
    const selectedDescisionSummaryFilter = (selectedOption_val) => {
        console.log("Inside ----->>>", selectedOption_val);
        if (selectedOption_val == "View All") {
            return ""
        }
        else if (selectedOption_val == "Decision") {
            return 1
        }
        else if (selectedOption_val == "Risk") {
            return 2
        }
        else {
            return ""
        }
    }

    useEffect(() => {
        if (userData && userData['UserId']) {
            getMonthlyUpdate(userData['UserId'], true)
        }
    }, [userData]);

    useEffect(() => {
        setgetCountDatadshow(!getCountDataShow)
    }, [getCountData]);

    const getMonthlyUpdate = (UID, val) => {
        updateState(props.setPreviewEnabled, userData['UserId'], access_details['company_division_id'], props.setPreviewList, access_details['company_division_id'])
        setIsLoading(true)
        setgetCountDatadshow(true)
        const API_URL = 'monthlymodule/api/get-descision-summary';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            descision_id: val == true ? "" : selectedDescisionSummaryFilter(val),
            company_id: access_details['company_division_id']
        };

        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data.help_details && (response.data.help_details).length > 0) {
                        console.log("respdata0000--->", response.data);
                        setgetCountData(response.data)
                        setmonthlyUpdateList(response.data.descision_summary_list)
                        sethelpDetails(response.data.help_details)
                        getAreaOfUpdateDetails(response.data.descision_summary_list)

                    }
                    else {
                        sethelpDetails(response.data.help_details)
                        getAreaOfUpdateDetails(response.data.descision_summary_list)
                        setIsLoading(false)
                        setgetCountDatadshow(false)
                        setmonthlyUpdateList([])

                    }
                }
                else {
                    setIsLoading(false)
                    setgetCountDatadshow(false)

                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
                setgetCountDatadshow(false)
            });
    }

    const getAreaOfUpdateDetails = () => {
        const API_URL = 'monthlymodule/api/get-descision-summary-dropdown-details';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setDecisionRiskList(response.data.descision_risk_list);
                        // setOwnerList(response.data.owner_list);
                        setDecisionMakerList(response.data.descision_maker_list);
                        setDecisionImpactList(response.data.risk_descision_impact_list);
                        setCriticalityList(response.data.criticality_list);
                        setDecisionList(response.data.descision_list);
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const handleEdit = (index) => {
        // const updatedMonthlyUpdateList = [...monthlyUpdateList];

        if (prevList.length === 0) {
            setPrevList(monthlyUpdateList.map(item => ({ ...item }))); // Save the original state
        }

        const updatedMonthlyUpdateList = _.cloneDeep(monthlyUpdateList);

        
        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);

        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }

        if (editableRows.length === 0 && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });

            console.log({ currentOperation });
            setCurrentOperation('edit');
            setmonthlyUpdateList(updatedMonthlyUpdateList);
        }
    };

    const handleDelete = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            setDeleteReason(null)
            setDeletingIndex(index);
            setShowConfirmationModal(true);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
            handleDeleteAPI(deletingIndex, deleteReason);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };

    const handleDeleteAPI = (index, reason) => {
        const editedItem = monthlyUpdateList[index];
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/delete-descision-summary';
        const req_body = {
            "id": editedItem.id,
            "deleted_reason": reason,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    getMonthlyUpdate(userData['UserId'], true)
                }
                else {
                    getMonthlyUpdate(userData['UserId'], true)
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    const editMontlyUpdate = (editedItem, index) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/add-descision-summary';
        const req_body = editedItem;
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMonthlyUpdate(userData['UserId'], true)
                }
                else {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMonthlyUpdate(userData['UserId'], true)
                }

            })
            .catch((error) => {
                setAddClick(false)
                setIsLoading(false)
                console.error(error);
            });
    }

    // Validation start logic

    const validateMonthlyUpdate = (editedItem) => {
        const emptyFields = [];

        // Validation 1: Dropdown should be mandatory
        if (/^\s*$/.test(editedItem.description)) {
            emptyFields.push("Please enter value for decision required.\n");
        }

        if (/^\s*$/.test(editedItem.descision_and_risk)) {
            emptyFields.push("Please enter value for risk (if any).\n");
        }

        // if (!editedItem.pre_read_path) {
        //     emptyFields.push("Please select file.\n");
        // }

        if  (/^\s*$/.test(editedItem.descision_maker)) {
            emptyFields.push("Please enter value for decision maker.\n");
        }

        if (!editedItem.impact) {
            emptyFields.push("Please select impact on.\n");
        }

      

        if (!editedItem.descision_due_date) {
            emptyFields.push("Please select due date.\n");
        }

        if (!editedItem.criticality) {
            emptyFields.push("Please select criticality.\n");
        }

        if (!editedItem.descision_taken) {
            emptyFields.push("Please select decision status.\n");
        }

        if (/^\s*$/.test(editedItem.associated_action_item)) {
            emptyFields.push("Please enter value for decision update.\n");
        }

        // if (/^\s*$/.test(editedItem.associated_action_item_descision)) {
        //     emptyFields.push("Please enter value for associated action item.\n");
        // }

        // if (!editedItem.owner_name) {
        //     emptyFields.push("Please select owner name.\n");
        // }

        // if (/^\s*$/.test(editedItem.action_item_due_date)) {
        //     emptyFields.push("Please enter value for action item due date.\n");
        // }

        return emptyFields;
    };


    // Validation end logic

    const handleSave = (index, e) => {
        const editedItem = monthlyUpdateList[index];
        // Validation 1: Dropdown should be mandatory
        const req_body = {
            description: editedItem.description,
            descision_and_risk_id: editedItem.descision_and_risk,
            descision_maker: editedItem.descision_maker,
            impact_id: editedItem.impact && editedItem.impact.value == undefined ? setSelectValue(getDecisionImpactList, editedItem.impact).value : editedItem.impact.value,
            criticality_id: editedItem.criticality && editedItem.criticality.value == undefined ? setSelectValue(getCriticalityList, editedItem.criticality).value : editedItem.criticality.value,
            descision_due_date: editedItem.descision_due_date,
            descision_taken_id: editedItem.descision_taken && editedItem.descision_taken.value == undefined ? setSelectValue(getDecisionList, editedItem.descision_taken).value : editedItem.descision_taken.value,
            associated_action_item: editedItem.associated_action_item,
            associated_action_item_descision: editedItem.associated_action_item_descision,
            owner: editedItem.owner_name,
            "is_update": isAddClicked ? false : true,
            "update_id": isAddClicked ? null : editedItem.id,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        }

        // Check if it's an add operation
        if (isAddClicked) {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                editMontlyUpdate(req_body, index)
                setCurrentOperation(null)
            }
        } else {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                editMontlyUpdate(req_body, index)
                setCurrentOperation(null)
            }
        }

    };


    const handleClose = (index) => {
        // Remove the index from editableRows when clicking the close icon
        setEditableRows([]);
        setAddClick(false);
        // If the item is newly added and being closed, remove it from the list
        // if (!monthlyUpdateList[index].is_update) {
        //     setmonthlyUpdateList((prevList) => {
        //         const updatedList = [...prevList];
        //         updatedList.splice(index, 1);
        //         return updatedList;
        //     });
        // }

        // // Reset the is_edit flag
        // const updatedMonthlyUpdateList = [...monthlyUpdateList];
        // updatedMonthlyUpdateList[index].is_edit = false;
        // setmonthlyUpdateList(updatedMonthlyUpdateList);

        if (prevList.length > 0) {
            setmonthlyUpdateList(prevList);
        }

        if (!monthlyUpdateList[index].is_update  && prevList.length > 0) {
            setmonthlyUpdateList(prevList);

            const updatedMonthlyUpdateList = [...prevList];
            updatedMonthlyUpdateList[index].is_edit = false;
            setmonthlyUpdateList(prevList);
        }
         
     

        if (currentOperation === 'add') {
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }
        setCurrentOperation(null)
    };

    const handleAdd = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...monthlyUpdateList];
            const newItem = {
                description: "",
                descision_and_risk: "",
                pre_read_path: "",
                descision_maker: "",
                impact: "",
                criticality: "",
                descision_due_date: "",
                descision_taken: "",
                associated_action_item: "",
                associated_action_item_descision:"",
                owner: "",
                action_item_due_date: "",
                company_id: access_details['company_division_id']
            }
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, index + 1]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const handleAddNew = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...monthlyUpdateList];
            const newItem = {
                description: "",
                descision_and_risk: "",
                pre_read_path: "",
                descision_maker: "",
                impact: "",
                criticality: "",
                descision_due_date: "",
                descision_taken: "",
                associated_action_item: "",
                associated_action_item_descision : "",
                owner: "",
                action_item_due_date: "",
                company_id: access_details['company_division_id']
            }
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, 0]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const calculateDueDate = (dueDate_calc) => {
        let daysToAdd;
    
        // Assuming your criticality values are strings
        switch (dueDate_calc) {
            case "High": // High
                daysToAdd = 7;
                break;
            case "Medium": // Medium
                daysToAdd = 14;
                break;
            case "Low": // Low
                daysToAdd = 30;
                break;
            default:
                daysToAdd = 0;
        }
    
        const currentDate = new Date();
        let dueDate = new Date(currentDate);
    
        // Skip Sundays
        while (daysToAdd > 0) {
            dueDate.setDate(dueDate.getDate() + 1);
            if (dueDate.getDay() !== 0) {
                daysToAdd--;
            }
        }
    
        const formattedDueDate = dueDate.toISOString().split('T')[0]; // Convert to yyyy-mm-dd format
        console.log("------------------->>>", formattedDueDate);
        return formattedDueDate;
    };
    

    const handleInputChange = (index, field, value, type) => {





        if (type == "file") {
            console.log("File Data--------->>>", field, value, type);
            const updatedMonthlyUpdateList = [...monthlyUpdateList];
            updatedMonthlyUpdateList[index][field] = value;
            // const updatedValue = typeof value === 'object' ? value.label : value;
            // updatedMonthlyUpdateList[index][field] = updatedValue;
            setmonthlyUpdateList(updatedMonthlyUpdateList);
        }
        else {
            const updatedMonthlyUpdateList = [...monthlyUpdateList];
            // updatedMonthlyUpdateList[index][field] = value;
            const updatedValue = typeof value === 'object' ? value.label : value;
            updatedMonthlyUpdateList[index][field] = updatedValue;
            setmonthlyUpdateList(updatedMonthlyUpdateList);
        }
    };

    const handleFileInputChange = async (index, field, fileInput) => {
        const file = fileInput.files[0];

        if (file) {
            const base64Data = await readFileAsBase64(file);
            const fileExtension = getFileExtension(file.name);
            const fileName = file.name;
            handleInputChange(index, field, { base64Data, fileExtension, fileName }, "file");
        }
    };

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const getFileExtension = (fileName) => {
        return fileName.split('.').pop();
    };

    // const setSelectValue = (list, value) => {
    //     return list.find((data) => {
    //         return data.label === value;
    //     });

    // }

    const setSelectValue = (list, value) => {
        if (Array.isArray(list)) {
            return list.find((data) => {
                return data.label === value;
            });
        }
        return null; // or handle the case when list is not an array
    };


    function formatDateString(inputDateString) {
        const inputDate = new Date(inputDateString);

        const options = { day: "numeric", month: "short", year: "numeric" };
        const formattedDate = inputDate.toLocaleDateString("en-GB", options);

        return formattedDate;
    }




    const openModal = (val, index) => {

        setIsModalOpen(val)
        setFilePath(monthlyUpdateList[index].pre_read_path)
        // console.log("aaaaaaaaaaaaaaaaaaaaaaaaa",a);
    };

    // const viewFile = (url) => {
    //     var parsedUrl = new URL(url);
    //     return parsedUrl.pathname.split('/').pop();
    // }
    const viewFile = (url) => {
        try {
            var parsedUrl = new URL(url);
            return parsedUrl.pathname.split('/').pop();
        } catch (error) {
            console.error('Invalid URL:', url);
            return null; // or handle the error in an appropriate way
        }
    }

    const isSupportedFile = (filename) => {
        let FileName = filename.split('/').pop()
        const extension = FileName.split('.').pop().toLowerCase();

        return ['pdf', 'png', 'jpeg'].includes(extension);
    }

    const DownloadFile = (FilePath) => {
        try {
            let FileName = FilePath.split('/').pop()
            FilePath = FilePath + "?fileName=" + FileName;
            var a = document.createElement("a");
            a.setAttribute('href', FilePath);
            a.setAttribute('download', FileName);
            a.setAttribute('target', '_blank');
            a.click();
        } catch (error) {
            console.error('Error while constructing download link:', error);
            // Handle the error in an appropriate way
        }
    }


    // validation popup close

    const closePopup = () => {
        setPopupOpen(false);
    };


    // dropdown scroll close start

    // const customStyles = {
    //     control: (provided) => ({
    //         ...provided,
    //         backgroundColor: 'white',
    //         color: 'red', // Change the background color of the control
    //         marginTop: 0, // Adjust the top margin
    //     }),
    //     menu: (provided) => ({
    //         ...provided,
    //         zIndex: 0,
    //         position: 'absolute',
    //         fontSize: '11px',
    //         marginTop: 0,
    //         marginBottom: 0, // Adjust the bottom margin
    //     }),
    //     option: (provided, state) => ({
    //         ...provided,
    //         backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
    //         color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
    //         height: '45px',

    //     }),
    // };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the text color of the control
            borderColor: state.isFocused ? 'black' : provided.borderColor, // Change border color when focused
            boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow, // Add box shadow when focused
            marginTop: 0, // Adjust the top margin
            '&:hover': { // Prevent blue highlight on hover
                borderColor: state.isFocused ? 'black' : provided.borderColor,
                boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow,
            }
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
                    color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
                    height: '45px',
            // '&:hover': {
            //     color: 'white', // Change text color on hover
            // },
        }),
    };

    // Add state variables for each dropdown
    const [isDecisionRiskDropdownOpen, setIsDecisionRiskDropdownOpen] = useState(false);
    const [isDecisionMakerDropdownOpen, setIsDecisionMakerDropdownOpen] = useState(false);
    const [isImpactDropdownOpen, setIsImpactDropdownOpen] = useState(false);
    const [isCriticalityDropdownOpen, setIsCriticalityDropdownOpen] = useState(false);
    const [isDecisionTakenDropdownOpen, setIsDecisionTakenDropdownOpen] = useState(false);
    const [isActionItemDropdownOpen, setIsActionItemDropdownOpen] = useState(false);

    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };


    const CloseDecisionComments = () => {
        setDecisionIDForChat(null)
        setDecisionIDForChatAssignedTo(null)
        setshowDecisionComments(false)
        setDecisionCommentsText('')
        setDecisionChatDetails([])
        setDecisionChatDetailsHeader('')

    }

    const AddDecisionComments = (DecisionID,AssignedToUserIdChat) => {
        
        setIsLoading(true)
        const API_URL = 'api/add-Decision-comment-details';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            DecisionID : DecisionID,
            EntityID: access_details['company_division_id'],
            DecisionComment : DecisionCommentsText,
            AssignedToUserID : AssignedToUserIdChat,//Need To change
            ActionByUserID :  userData && 'UserId' in userData ? userData['UserId'] : ""
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    
                    setDecisionIDForChat(null)
                    setDecisionIDForChatAssignedTo(null)
                    setshowDecisionComments(false)
                    setDecisionCommentsText('')
                    setDecisionChatDetails([])
                    setIsLoading(false)
                    openDecisionComment(DecisionID)
                    
                }
                else {
                    
                    setDecisionIDForChat(null)
                    setDecisionIDForChatAssignedTo(null)
                    setshowDecisionComments(false)
                    setDecisionCommentsText('')
                    setDecisionChatDetails([])
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setDecisionIDForChat(null)
                setDecisionIDForChatAssignedTo(null)
                setshowDecisionComments(false)
                setDecisionCommentsText('')
                setDecisionChatDetails([])
                setIsLoading(false)
                console.error(error);
            });
    }

    const openDecisionComment = (DecisionID,assignedUser) => {
        

        setDecisionIDForChat(DecisionID)
        setDecisionIDForChatAssignedTo(assignedUser)
      
        setIsLoading(true)
        const API_URL = 'api/get-decision-comment-details';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            DecisionID : DecisionID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setDecisionChatDetails(response.data.Comments)
                    setDecisionChatDetailsHeader(response.data.DecisionHeader)
                    setshowDecisionComments(true)
                    setDecisionCommentsText('')
                    setIsLoading(false)
                    
                }
                else {
                    setshowDecisionComments(true)
                    setDecisionCommentsText('')
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const popupRef = useRef(null);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                CloseDecisionComments(); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
  

    // dropdown scroll close end

    return (

        <div className="parentContainer" style={{marginBottom:"20px"}}>

           
             
        
            
            <div className="monthlyHeaderWrapper">
                <div>
                    <span className="monthly-Title-Table-header" style={{ fontSize: "23px", marginBottom:"10px" }}>
                        <strong>Decisions & Support Required</strong>
                    </span>
                </div>
                    <span>Decisions and Support Required from Management/ Promoters</span>
            </div>
               
    {showDecisionComments && (
                <div className="popup">
                    <div ref={popupRef} className="popup-inner-Chat">
                        {/* <div className='mx-2'> */}
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between align-items-center col-12 '>
                                    <span style={{fontSize:'20px', color:'#21A8E1'}}>Comments</span>
                                    <img title="Cancel" onClick={() => CloseDecisionComments()} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.8 rem'}} alt="Add Milestone" />
                                </div>
                            </div>
                            <div>
                                <hr style={{color:'#21A8E1'}}/>
                            </div>
                            <div className='ChatBodyDetails' style={{maxHeight:'68vh',minHeight:'68vh',overflowY:'auto'}}>
                                {DecisionMRMChatDetails.map((item) => (
                                    <div style={{ display : 'flex',justifyContent : item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start'}}>
                                        <div className=' d-flex mb-4' style={{justifyContent: item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start',width:'90%'}}>
                                            {item.ActionByUserId != userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Add Milestone" /> : null}
                                            <div className='custom-bubblewidth'>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}} className= {item.ActionByUserId == userData['UserId'] ? 'chatbackgroundmyself me-3 w-100' : 'chatbackgroundother me-3 w-100'}>
                                                    {/* <span style={{fontSize:'14px'}}>{item.Comment}</span> */}
                                                    <div  style={{fontSize:'14px'}} dangerouslySetInnerHTML={{ __html:item.DecisionComment.replace(/\n/g, '<br>')}}/>
                                                </div>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}}  className= 'd-flex  justify-content-between me-3 gap-2  w-100'>
                                                    <span style={{fontSize:'12px'}}>{item.ActionByUserIdName}</span>
                                                    <span style={{fontSize:'12px',display:'flex',flexShrink:'0'}}>{formatDateTimeForChat(item.CreatedOn)}</span>
                                                </div>
                                            </div>
                                            {item.ActionByUserId == userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px',marginLeft:'0.7rem'}} alt="Add Milestone" /> : null}
                                        </div>
                                    </div>
                                ))}
                                {DecisionMRMChatDetails.length == 0? <div className='d-flex justify-content-center'>No comments</div>:null}
                            </div>
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between col-12 mb-2'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={ProfIcon} className='cursor-pointer me-1' style={{height:'25px',width:'25px'}} alt="Add Comments" /> 
                                    </div>
                                    <input
                                        style={{width : '90%',padding:'0.4rem'}}
                                        type="text"
                                        name="Milestonecommenttext"
                                        placeholder='Write a comment'
                                        className='normalSelectStyle'
                                        value={DecisionCommentsText}
                                        onChange={(e) => setDecisionCommentsText(e.target.value)}
                                    />
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <img title="Send" onClick={() => AddDecisionComments(DecisionIDForChat,DecisionIDForChatAssignedTo)} src={SendChatIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Send Icon" /> 
                                        </div>
                                 
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            )}



            {isModalOpen &&
                <div className="popup">
                    <div className="popup-inner viewPopup">
                        <div className='row mb-3'>
                            <div className='col-6 text-start'>
                                <span className="head-popText">View File</span>
                            </div>
                            <div className='col-6 text-end'>
                                <a onClick={() => {
                                    setIsModalOpen(!isModalOpen)
                                    setFilePath("")
                                }}>< i className="fa fa-times" aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <div className="modal-body">

                            {isSupportedFile(filePath) ? <iframe title="PDF Viewer" width="100%" height="100%" src={filePath} frameBorder="0" className=''>
                                This browser does not support PDFs. Please download the PDF to view it.
                            </iframe>

                                :
                                <div className="row">
                                    <div className="col-11">
                                        <p>File can not view, please download file.</p>
                                    </div>
                                    <div className="col-1">
                                        <img src={Download} alt="eye icon" className="downloadIcon" onClick={() => {
                                            DownloadFile(filePath)
                                        }} />
                                    </div>
                                </div>}



                        </div>
                    </div>
                </div>
            }



            {
                showConfirmationModal && (
                    <div className="popup">
                        <div className="popup-inner">
                            <div className='row mb-3'>

                                <div className='col-12 text-center'>
                                    <p className="head-text">Delete Item</p>
                                </div>
                            </div>
                            <form onSubmit={handleDeleteConfirmation}>
                                <label className='label-text'>
                                    Mention Reason:
                                    <input className='mt-2'
                                        type="text"
                                        placeholder="Enter your reason"
                                        value={deleteReason}
                                        required
                                        onChange={(e) => setDeleteReason(e.target.value)}
                                    />
                                </label>
                                <button onClick={() => setShowConfirmationModal(!showConfirmationModal)} className="cancel-btn">Cancel</button>
                                <button type="submit" className="confirm-btn">Confirm</button>
                            </form>
                        </div>
                    </div>
                )
            }

            {/* Validation popup start */}
         {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text" style={{fontSize:"20px"}}>Information</p>
                            </div>
                        </div>
                        <div className='ChatBodyDetails' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto',overflowY:'auto', padding:"0rem"}}>
                                {errorList.map((item, index) => (
                                    <div style={{ display : 'flex'}}>
                                        <div className=' d-flex mb-3' style={{width:'90%'}}>
                                            {`${index+1}) ${item}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        <button className="close-btn" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
            {/* Validation popup end */}

            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}
            {/* <div>
                <p className="monthlyHeaderWrapper disclaimer">Note: Please add decisions required to be taken by other stakeholders (except Mr. Shete) under “Open Action Items” section.</p>
            </div> */}
            <div className="monthlyHeaderWrapper d-flex justify-content-between">
                <div className="decision">
                    {/* <span className="monthly-Title-Table-header">Decision Summary</span>
                    <div className={`custom-dropdown viewCustome ${isOpen ? 'open' : ''}`} onClick={toggleDropdown}>
                        <span id="selectedValue" className="selectValue">{selectedOption}</span>
                        <div className="dropdown-icon dropIcon"><img src={Dropdown} alt="" className="viewDrop" /></div>
                        <div className="dropdown-list dropList riskDrop">
                            <div className="dropdown-item" onClick={() => selectOption('View All')}>View All</div>
                            <div className="dropdown-item" onClick={() => selectOption('Risk')}>Risk</div>
                            <div className="dropdown-item" onClick={() => selectOption('Decision')}>Descision</div>
                        </div>
                    </div> */}
                </div>
                {!getCountDataShow ? (<div className="sechduleList d-flex justify-content-between">
                    <div className="sechduleData d-flex">
                        <div className="dataText">
                            <span>Due this month</span>
                        </div>
                        <div className="dataNumber">
                            {(getCountData?.count_due_this_month || getCountData?.count_due_this_month === 0) ? <span>{getCountData.count_due_this_month}</span> : "-"}
                        </div>
                    </div>
                    <div className="sechduleData d-flex">
                        <div className="dataText">
                            <span>Overdue</span>
                        </div>
                        <div className="overdue-dataNumber">
                            {(getCountData?.count_overdue || getCountData?.count_overdue === 0) ? <span>{getCountData.count_overdue}</span> : "-"}
                        </div>
                    </div>
                    <div className="sechduleData d-flex">
                        <div className="dataText">
                            <span>Not due this month</span>
                        </div>
                        <div className="dataNumber">
                            {(getCountData?.count_not_due_this_month || getCountData?.count_not_due_this_month === 0) ? <span>{getCountData.count_not_due_this_month}</span> : "-"}
                        </div>
                    </div>
                    <div className="total d-flex">
                        <div className="dataText">
                            <span>Total</span>
                        </div>
                        <div className="total-dataNumber">
                            {(getCountData?.total || getCountData?.total === 0) ? <span>{getCountData.total}</span> : "-"}
                        </div>
                    </div>
                </div>) : null}
                {!isLoading && <HelpSidePanel content={"Decisions and Support Required from Management/ Promoters"} />}
            </div>
            {/* <p className="monthlyHeaderWrapper disclaimer">Note: Please add decisions required to be taken by other stakeholders (except Mr. Shete) under “Open Action Items” section.</p> */}

            {
                isLoading ? (<SmallSizedLoader />) :
                    (<div className="table-div padStyle">
                        <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff", zIndex: 9999999999 }} />
                        <div className="table-container">
                            <table className="table-container-subdiv open-actionTable risk-decisions"  style={{maxWidth:'150%'}}>
                                <thead className="riskDec-head">
                                    <tr>
                                    <th style={{ width: "2%" }} className="column-header-style fixed-column sr-column">Sr. No.</th>
                                    <th style={{ width: "6%" }} className="column-header-style">Decision Required
                                         <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                    </th>
                                    <th style={{ width: "6%" }} className="column-header-style">Risk (If Any) 
                                            <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </th>
                                    <th style={{ width: "10%" }} className="column-header-style">Decision Maker   
                                          <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                          </th>

                                        <th style={{ width: "8%" }} className="column-header-style">
                                            <div className="d-flex justify-content-between">
                                                <div>Impact On<span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                                <div>
                                                    <a
                                                        data-tooltip-id="my-tooltip"
                                                        data-tooltip-content="Owner of the task or update"
                                                        data-tooltip-place="bottom">
                                                        <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                    </a>

                                                </div>
                                            </div>
                                        </th>
                                        <th style={{ width: "10%" }} className="column-header-style">Due Date 
                                            <span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </th>
                                        <th style={{ width: "10%" }} className="column-header-style">
                                            <div className="d-flex justify-content-between">
                                                <div >Criticality<span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                                <div>
                                                    <a
                                                        data-tooltip-id="my-tooltip"
                                                        data-tooltip-content="What does it impact?"
                                                        data-tooltip-place="bottom">
                                                        <img className="me-1" src={imageURL} alt="info" style={{ width: "15px" }}></img>
                                                    </a>
                                                </div>

                                            </div>
                                        </th>
                                     
                                        <th style={{ width: "10%" }} className="column-header-style">Decision Status<span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span></th>
                                        <th style={{ width: "10%" }} className="column-header-style">Decision Update<span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span></th>
                                        <th style={{ width: "10%" }} className="column-header-style">Action Item</th>
                                        <th style={{ width: "10%" }} className="column-header-style">Action Owner</th>
                                        <th style={{ width: "0%", display: props.ismonth ? "none" : "table-cell" }} className="column-header-style bg-wh fixed-column actions-column">Actions</th>
                                        </tr>
                                </thead>
                                <tbody className="riskDec-body">
                                {monthlyUpdateList.length > 0 ? (
                                    monthlyUpdateList.map((item, index) =>
                                        <tr key={index} className={editableRows.includes(index) ? "editable-row" : ""}>
                                            {/* Date*/}
                                            {/* <td className="column-data-style">
                                                <p>{formatDateString(new Date().toLocaleDateString())}</p>
                                            </td> */}

                                            {/* Sr.no  */}
                                            <td className="column-data-style fixed-column sr-column ">
                                                <p>{index + 1}</p>
                                            </td>

                                            {/* Description*/}
                                            <td className="column-data-style">
                                                {editableRows.includes(index) ? (
                                                    <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.description} onChange={(e) => handleInputChange(index, 'description', e.target.value, "")} />
                                                ) : (
                                                    <div>
                                                        {item.previous_description && (
                                                        <pre className="preStyleFormat" >
                                                            {item.previous_description}
                                                        </pre>
                                                    )}
                                                        {item.is_edit ?
                                                            <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.description} onChange={(e) => handleInputChange(index, 'description', e.target.value, "")} />
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td>

                                            {/* Descision / Risk */}
                                            <td className="column-data-style">
                                                {editableRows.includes(index) ? (
                                                    <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.descision_and_risk} onChange={(e) => handleInputChange(index, 'descision_and_risk', e.target.value, "")} />
                                                ) : (
                                                    <div>
                                                        <p>{item.previous_descision_and_risk}</p>
                                                        {item.is_edit ?
                                                            <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.descision_and_risk} onChange={(e) => handleInputChange(index, 'descision_and_risk', e.target.value, "")} />
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td>
                                            {/* <td className="column-data-style">
                                                {editableRows.includes(index) ? (
                                                    <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Select
                                                            id={index}
                                                            options={getDecisionRiskList}
                                                            value={setSelectValue(getDecisionRiskList, monthlyUpdateList[index].descision_and_risk)}
                                                            styles={customStyles}
                                                            menuPosition={'fixed'}
                                                            maxMenuHeight={100}
                                                            onChange={
                                                                (e) => handleInputChange(index, 'descision_and_risk', e, "")
                                                            }
                                                            closeMenuOnScroll={() => !isSelectHovered}
                                                            onMenuOpen={() => setIsDecisionRiskDropdownOpen(true)}
                                                            onMenuClose={() => setIsDecisionRiskDropdownOpen(false)}
                                                            menuIsOpen={isDecisionRiskDropdownOpen}
                                                            menuPortalTarget={null}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p>{item.previous_descision_and_risk}</p>
                                                        {item.is_edit ?
                                                            <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                                <Select
                                                                    id={index}
                                                                    options={getDecisionRiskList}
                                                                    value={setSelectValue(getDecisionRiskList, monthlyUpdateList[index].descision_and_risk)}
                                                                    styles={customStyles}
                                                                    menuPosition={'fixed'}
                                                                    maxMenuHeight={100}
                                                                    onChange={
                                                                        (e) => handleInputChange(index, 'descision_and_risk', e, "")
                                                                    }
                                                                    closeMenuOnScroll={() => !isSelectHovered}
                                                                    onMenuOpen={() => setIsDecisionRiskDropdownOpen(true)}
                                                                    onMenuClose={() => setIsDecisionRiskDropdownOpen(false)}
                                                                    menuIsOpen={isDecisionRiskDropdownOpen}
                                                                    menuPortalTarget={null}
                                                                />
                                                            </div>
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td> */}
                                            {/* Pre-Read*/}
                                            {/* <td className="column-data-style">
                                                {editableRows.includes(index) ? (
                                                    <div>
                                                        <input className="tableInput"
                                                            style={{ width: "100%", display: 'none' }}
                                                            ref={fileInputRef}
                                                            type="file"
                                                            onChange={(e) => handleFileInputChange(index, 'pre_read_path', e.target)}
                                                        />
                                                        <div className="uploadBtn" onClick={handleIconClick}>
                                                            <div style={{ width: '16px' }} >
                                                                <img src={AttachImage} className='img-fluid' alt="analytics" />
                                                            </div>
                                                            <p className="uploadText">Upload</p>
                                                        </div>
                                                        <div>
                                                            {typeof (monthlyUpdateList[index].pre_read_path) == "string" ? "" : `${monthlyUpdateList[index].pre_read_path.fileName}`}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {!item.is_edit ?
                                                            <div>
                                                                <div className="ViewAttachFile">
                                                                    <div className="custom-upload floatleft" onClick={() => { openModal(true, index) }}>
                                                                        <img src={AnalyticsImage} alt="eye icon" />
                                                                        <span id="SetFileList" className="view-icon">View</span>
                                                                    </div>

                                                                </div>
                                                                <p>{viewFile(item.pre_read_path)}</p>
                                                            </div>
                                                            :
                                                            <div>
                                                                <div className="ViewAttachFile">
                                                                    <div className="custom-upload floatleft" onClick={() => { openModal(true, index) }}>
                                                                        <img src={AnalyticsImage} alt="eye icon" />
                                                                        <span id="SetFileList" className="view-icon">View</span>
                                                                    </div>

                                                                </div>
                                                                <p>{viewFile(item.pre_read_path)}</p>
                                                                <div>
                                                                    <input className="tableInput"
                                                                        style={{ width: "100%", display: 'none' }}
                                                                        ref={fileInputRef}
                                                                        type="file"
                                                                        onChange={(e) => handleFileInputChange(index, 'pre_read_path', e.target)}
                                                                    />
                                                                    <div className="uploadBtn" onClick={handleIconClick}>
                                                                        <div style={{ width: '16px' }} >
                                                                            <img src={AttachImage} className='img-fluid' alt="analytics" />
                                                                        </div>
                                                                        <p className="uploadText">Upload</p>
                                                                    </div>
                                                                    <div>
                                                                        {typeof (monthlyUpdateList[index].pre_read_path) == "string" ? "" : `${monthlyUpdateList[index].pre_read_path.fileName}`}
                                                                    </div>
                                                                </div>



                                                            </div>
                                                        }
                                                    </>
                                                )}
                                            </td> */}
                                            {/* Descision Maker */}
                                            {/* <td className="column-data-style">
                                                {editableRows.includes(index) ? (

                                                    <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.descision_maker} onChange={(e) => handleInputChange(index, 'descision_maker', e.target.value, "")} />
                                                ) : (
                                                    <div>
                                                        <p>{item.previous_descision_maker}</p>
                                                        {item.is_edit ?
                                                            <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.descision_maker} onChange={(e) => handleInputChange(index, 'descision_maker', e.target.value, "")} />
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td> */}

                                            {/* <td className="column-data-style">
                                                {editableRows.includes(index) ? (
                                                    <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Select
                                                            id={index}
                                                            options={getDecisionMakerList}
                                                            value={setSelectValue(getDecisionMakerList, monthlyUpdateList[index].descision_maker)}
                                                            styles={customStyles}
                                                            menuPosition={'fixed'}
                                                            maxMenuHeight={100}
                                                            onChange={
                                                                (e) => handleInputChange(index, 'descision_maker', e, "")
                                                            }
                                                            closeMenuOnScroll={() => !isSelectHovered}
                                                            onMenuOpen={() => setIsDecisionMakerDropdownOpen(true)}
                                                            onMenuClose={() => setIsDecisionMakerDropdownOpen(false)}
                                                            menuIsOpen={isDecisionMakerDropdownOpen}
                                                            menuPortalTarget={null}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p>{item.previous_descision_maker}</p>
                                                        {item.is_edit ?
                                                            <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                                <Select
                                                                    id={index}
                                                                    options={getDecisionMakerList}
                                                                    value={setSelectValue(getDecisionMakerList, monthlyUpdateList[index].descision_maker)}
                                                                    styles={customStyles}
                                                                    menuPosition={'fixed'}
                                                                    maxMenuHeight={100}
                                                                    onChange={
                                                                        (e) => handleInputChange(index, 'descision_maker', e, "")
                                                                    }
                                                                    closeMenuOnScroll={() => !isSelectHovered}
                                                                    onMenuOpen={() => setIsDecisionMakerDropdownOpen(true)}
                                                                    onMenuClose={() => setIsDecisionMakerDropdownOpen(false)}
                                                                    menuIsOpen={isDecisionMakerDropdownOpen}
                                                                    menuPortalTarget={null}
                                                                />
                                                            </div>
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td> */}
                                                 {/* DECISION MAKER */}
                                                <td  className="column-data-style">
                                                {editableRows.includes(index) ? (
                                                    <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.descision_maker} onChange={(e) => handleInputChange(index, 'descision_maker', e.target.value, "")} />
                                                ) : (
                                                    <div>
                                                        <p>{item.previous_descision_maker}</p>
                                                        {item.is_edit ?
                                                            <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.descision_maker} onChange={(e) => handleInputChange(index, 'descision_maker', e.target.value, "")} />
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td>
                                            {/* Impact */}
                                            <td className="column-data-style">
                                                {editableRows.includes(index) ? (
                                                    <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Select
                                                            id={index}
                                                            options={getDecisionImpactList}
                                                            value={setSelectValue(getDecisionImpactList, monthlyUpdateList[index].impact)}
                                                            styles={customStyles}
                                                            menuPosition={'fixed'}
                                                            maxMenuHeight={100}
                                                            onChange={
                                                                (e) => handleInputChange(index, 'impact', e, "")
                                                            }
                                                            closeMenuOnScroll={() => !isSelectHovered}
                                                            onMenuOpen={() => setIsImpactDropdownOpen(true)}
                                                            onMenuClose={() => setIsImpactDropdownOpen(false)}
                                                            menuIsOpen={isImpactDropdownOpen}
                                                            menuPortalTarget={null}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p>{item.previous_impact}</p>
                                                        {item.is_edit ?
                                                            <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                                <Select
                                                                    id={index}
                                                                    options={getDecisionImpactList}
                                                                    value={setSelectValue(getDecisionImpactList, monthlyUpdateList[index].impact)}
                                                                    styles={customStyles}
                                                                    menuPosition={'fixed'}
                                                                    maxMenuHeight={100}
                                                                    onChange={
                                                                        (e) => handleInputChange(index, 'impact', e, "")
                                                                    }
                                                                    closeMenuOnScroll={() => !isSelectHovered}
                                                                    onMenuOpen={() => setIsImpactDropdownOpen(true)}
                                                                    onMenuClose={() => setIsImpactDropdownOpen(false)}
                                                                    menuIsOpen={isImpactDropdownOpen}
                                                                    menuPortalTarget={null}
                                                                />
                                                            </div>
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td>

                                         

                                            {/* Descision Due Date  */}
                                            {/* <td className="column-data-style">
                                                
                                                <div>
                                                    <p>{calculateDueDate(monthlyUpdateList[index].criticality)}</p>
                                                    
                                                </div>
                                               
                                            </td> */}

                                            <td className="column-data-style">
                                                {editableRows.includes(index) ? (
                                                    <input className="tableInput budgetInput" style={{ width: "100%" }} type="date" value={item.descision_due_date ? item.descision_due_date.slice(0, 10) : ''} onChange={(e) => handleInputChange(index, 'descision_due_date', e.target.value, "")} />
                                                ) : (
                                                    <div>
                                                        <p>{formatDate(item.previous_descision_due_date)}</p>
                                                        {item.is_edit ?
                                                            <input className="tableInput budgetInput" style={{ width: "100%" }} type="date" value={item.descision_due_date ? item.descision_due_date.slice(0, 10) : ''} onChange={(e) => handleInputChange(index, 'descision_due_date', e.target.value, "")} />
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td>

                                               {/* Criticality */}
                                               <td className="column-data-style">
                                                {editableRows.includes(index) ? (
                                                    <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Select
                                                            id={index}
                                                            options={getCriticalityList}
                                                            value={setSelectValue(getCriticalityList, monthlyUpdateList[index].criticality)}
                                                            styles={customStyles}
                                                            menuPosition={'fixed'}
                                                            maxMenuHeight={100}
                                                            onChange={
                                                                (e) => handleInputChange(index, 'criticality', e, "")
                                                            }
                                                            closeMenuOnScroll={() => !isSelectHovered}
                                                            onMenuOpen={() => setIsCriticalityDropdownOpen(true)}
                                                            onMenuClose={() => setIsCriticalityDropdownOpen(false)}
                                                            menuIsOpen={isCriticalityDropdownOpen}
                                                            menuPortalTarget={null}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p>{item.previous_criticality}</p>
                                                        {item.is_edit ?
                                                            <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                                <Select
                                                                    id={index}
                                                                    options={getCriticalityList}
                                                                    value={setSelectValue(getCriticalityList, monthlyUpdateList[index].criticality)}
                                                                    styles={customStyles}
                                                                    menuPosition={'fixed'}
                                                                    maxMenuHeight={100}
                                                                    onChange={
                                                                        (e) => handleInputChange(index, 'criticality', e, "")
                                                                    }
                                                                    closeMenuOnScroll={() => !isSelectHovered}
                                                                    onMenuOpen={() => setIsCriticalityDropdownOpen(true)}
                                                                    onMenuClose={() => setIsCriticalityDropdownOpen(false)}
                                                                    menuIsOpen={isCriticalityDropdownOpen}
                                                                    menuPortalTarget={null}
                                                                />
                                                            </div>
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td>

                                            {/* Descision Taken */}
                                            <td className="column-data-style">
                                                {editableRows.includes(index) ? (
                                                    <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Select
                                                            id={index}
                                                            options={getDecisionList}
                                                            value={setSelectValue(getDecisionList, monthlyUpdateList[index].descision_taken)}
                                                            styles={customStyles}
                                                            menuPosition={'fixed'}
                                                            maxMenuHeight={100}
                                                            onChange={
                                                                (e) => handleInputChange(index, 'descision_taken', e, "")
                                                            }
                                                            closeMenuOnScroll={() => !isSelectHovered}
                                                            onMenuOpen={() => setIsDecisionTakenDropdownOpen(true)}
                                                            onMenuClose={() => setIsDecisionTakenDropdownOpen(false)}
                                                            menuIsOpen={isDecisionTakenDropdownOpen}
                                                            menuPortalTarget={null}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p>{item.previous_descision_taken}</p>
                                                        {item.is_edit ?
                                                            <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                                <Select
                                                                    id={index}
                                                                    options={getDecisionList}
                                                                    value={setSelectValue(getDecisionList, monthlyUpdateList[index].descision_taken)}
                                                                    styles={customStyles}
                                                                    menuPosition={'fixed'}
                                                                    maxMenuHeight={100}
                                                                    onChange={
                                                                        (e) => handleInputChange(index, 'descision_taken', e, "")
                                                                    }
                                                                    closeMenuOnScroll={() => !isSelectHovered}
                                                                    onMenuOpen={() => setIsDecisionTakenDropdownOpen(true)}
                                                                    onMenuClose={() => setIsDecisionTakenDropdownOpen(false)}
                                                                    menuIsOpen={isDecisionTakenDropdownOpen}
                                                                    menuPortalTarget={null}
                                                                />
                                                            </div>
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td>

                                            {/* Descision Update  */}
                                            <td className="column-data-style update-month">
                                                {editableRows.includes(index) ? (
                                                    <div>
                                                        {/* {item && item.previous_associated_action_item && item.previous_associated_action_item != null && item.previous_associated_action_item != undefined && item.previous_associated_action_item != "" ? <label className={index == 0 ? "update-message-bottom" : "update-message-top"}>{item.previous_associated_action_item}</label> : null} */}
                                                        <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.associated_action_item} onChange={(e) => handleInputChange(index, 'associated_action_item', e.target.value, "")} />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {/* /* <p>{item.is_edit ? item.previous_associated_action_item : item.associated_action_item}</p> */ }
                                                        {item.previous_associated_action_item && (
                                                        <pre className="preStyleFormat" >
                                                            {item.previous_associated_action_item}
                                                        </pre>
                                                          )}
                                                        {item.is_edit ?
                                                            <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.associated_action_item} onChange={(e) => handleInputChange(index, 'associated_action_item', e.target.value, "")} />
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td>

                                            {/* Associated Action Item  */}
                                            <td className="column-data-style update-month">
                                                {editableRows.includes(index) ? (
                                                    <div>
                                                        {/* {item && item.previous_associated_action_item && item.previous_associated_action_item != null && item.previous_associated_action_item != undefined && item.previous_associated_action_item != "" ? <label className={index == 0 ? "update-message-bottom" : "update-message-top"}>{item.previous_associated_action_item}</label> : null} */}
                                                        <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.associated_action_item_descision} onChange={(e) => handleInputChange(index, 'associated_action_item_descision', e.target.value, "")} />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {/* <p>{item.is_edit ? item.previous_associated_action_item_descision : item.associated_action_item_descision}</p> */}
                                                        {item.previous_associated_action_item_descision && (
                                                        <pre className="preStyleFormat" >
                                                            {item.previous_associated_action_item_descision}
                                                        </pre>
                                                          )}
                                                        {item.is_edit ?
                                                            <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.associated_action_item_descision} onChange={(e) => handleInputChange(index, 'associated_action_item_descision', e.target.value, "")} />
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td>

                                            {/* Owner  */}
                                            <td className="column-data-style">
                                                {editableRows.includes(index) ? (

                                                    <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.owner_name} onChange={(e) => handleInputChange(index, 'owner_name', e.target.value, "")} />
                                                ) : (
                                                    <div>
                                                        <p>{item.previous_owner_name}</p>
                                                        {item.is_edit ?
                                                            <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.owner_name} onChange={(e) => handleInputChange(index, 'owner_name', e.target.value, "")} />
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td>

                                            {/* Action Item due date */}
                                            {/* <td className="column-data-style">
                                                {editableRows.includes(index) ? (
                                                    <input className="tableInput budgetInput" style={{ width: "100%" }} type="date" value={item.action_item_due_date} onChange={(e) => handleInputChange(index, 'action_item_due_date', e.target.value, "")} />
                                                ) : (
                                                    <div>
                                                        <p>{formatDateString(item.previous_action_item_due_date)}</p>
                                                        {item.is_edit ?
                                                            <input className="tableInput budgetInput" style={{ width: "100%" }} type="date" value={item.action_item_due_date} onChange={(e) => handleInputChange(index, 'action_item_due_date', e.target.value, "")} />
                                                            : ""
                                                        }
                                                    </div>
                                                )}
                                            </td> */}

                                            <td className={index % 2 === 0 ? "column-data-style bg-ylw " : "column-data-style bg-wh "} style={{display: props.ismonth ? "none" : "column-data-style" }}>
                                                {editableRows.includes(index) ? (
                                                    <div className="d-flex justify-content-around">
                                                        <div className="d-flex justify-content-center">
                                                            <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                            <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div style={{ width: "100px" }}>
                                                        {!item.is_edit ?
                                                            <div className="d-flex justify-content-around">
                                                                <div>
                                                                    <img src={edit} className="edit" alt="Edit" style={{ width: "13px" }} onClick={() => handleEdit(index)} />
                                                                </div>
                                                                <div>
                                                                    <img src={remove} className="delete" alt="Delete" style={{ width: "13px" }} onClick={() => handleDelete(index)} />
                                                                </div>
                                                                <div>
                                                                    <img src={add} className="add" alt="Add" style={{ width: "13px" }} onClick={() => handleAdd(index, "add")} />
                                                                </div>
                                                                <div>
                                                                <img src={CommementBlue} className="comment" alt="Comment" style={{ width: "13px" }} onClick={() => openDecisionComment(item.id, item.AssignedTo)} />
                                                                {/* <img src={CommementBlue} className="comment" alt="Comment" style={{ width: "13px" }} onClick={() => openDecisionComment(item.ID, item.AssignedToUserID)} /> */}
                                                            </div>
                                                            </div>
                                                            :
                                                            <div className="d-flex justify-content-around">
                                                                <div className="d-flex justify-content-center">
                                                                    <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                                    <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                        ))
                                        : (
                                            <tr>
                                                <td colSpan="14" className="text-center">
                                                    <div className="add">
                                                        <div className="add-btn" onClick={handleAddNew}>
                                                            <span>Add New</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div>)
            }
        </div >
    );
}