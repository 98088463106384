import React, { useEffect, useState } from "react";
import "./MarketTable.css";
import imageURL from '../../../assets/images/infoIcon.svg';
import edit from '../../../assets/images/edit-icon.svg';
import remove from '../../../assets/images/remove-icon.svg';
import add from '../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../components/help_side_panel/HelpSidePanel";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import SmallSizedLoader from "../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '../../../api_interceptor/api'
import Alert_Popup from "../../../components/alert_popup/Alert_Popup";
import close from '../../../assets/images/close.png';
import { updateState } from "../../../common_function/common_function";
import MarketGraph from "./market_graph/MarketGraph";
import Select from 'react-select';

export default function MarketTable(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [monthlyUpdateList, setmonthlyUpdateList] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const [areaOfUpdateList, setAreaOfUpdateList] = useState([]);
    const [OwnerList, setOwnerList] = useState([]);
    const [ImpactOnList, setImpactOnList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const company_id = useSelector((state) => state.user.companyID);
    const [KeyUpdateArea, setKeyUpdateArea] = useState([]);

    useEffect(() => {
        if (userData && userData['UserId']) {
            getMonthlyUpdate(userData['UserId'])
        }
    }, [userData]);

    const getMonthlyUpdate = (UID) => {
        updateState(props.setPreviewEnabled,userData['UserId'],access_details['company_division_id'],props.setPreviewList,access_details['company_division_id'],props.ismonth)
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-market';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data.help_details && (response.data.help_details).length > 0) {
                        setmonthlyUpdateList(response.data.market_list)
                        sethelpDetails(response.data.help_details)
                        getAreaOfUpdateDetails(userData['UserId'], response.data.monthly_data_list)
                        setIsLoading(false)
                    }
                    else {
                        sethelpDetails(response.data.help_details)
                        getAreaOfUpdateDetails(userData['UserId'], response.data.monthly_data_list)
                        setIsLoading(false)
                    }
                }
                else {
                    sethelpDetails(response.data.help_details)
                    getAreaOfUpdateDetails(userData['UserId'], response.data.monthly_data_list)
                    setIsLoading(false)
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const getAreaOfUpdateDetails = (UID, valdata) => {
        const API_URL = 'monthlymodule/api/get-competition-dropdown-details'
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setKeyUpdateArea(response.data.key_update_in_area_of_update);
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const handleEdit = (index) => {
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);
 
        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }
 
        if (editableRows.length === 0  && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });
 
            console.log({currentOperation});
            setCurrentOperation('edit');
            setmonthlyUpdateList(updatedMonthlyUpdateList);
        }
    };

    const handleDelete = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            setDeleteReason(null)
            setDeletingIndex(index);
            setShowConfirmationModal(true);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
            handleDeleteAPI(deletingIndex, deleteReason);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };

    const handleDeleteAPI = (index, reason) => {
        const editedItem = monthlyUpdateList[index];
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/delete-market';
        const req_body = {
            "id": editedItem.id,
            "deleted_reason": reason,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    getMonthlyUpdate(userData['UserId'])
                    setmonthlyUpdateList(prevList => prevList.filter((item, i) => i !== index));
                }
                else {
                    getMonthlyUpdate(userData['UserId'])
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    const editMontlyUpdate = (editedItem, index) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/add-market';
        const req_body = editedItem;
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMonthlyUpdate(userData['UserId'])
                }
                else {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMonthlyUpdate(userData['UserId'])
                }

            })
            .catch((error) => {
                setAddClick(false)
                setIsLoading(false)
                console.error(error);
            });
    }

    // Validation start logic

    const validateMonthlyUpdate = (editedItem) => {
        const emptyFields = [];

        if (!editedItem.key_update_in_the_area_of) {
            emptyFields.push("Please select key update in the area of\n");
        }


        if (/^\s*$/.test(editedItem.monthly_update)) {
            emptyFields.push("Please enter value for monthly updates\n");
        }

        return emptyFields;
    };


    // Validation end logic

    const handleSave = (index, e) => {
        const editedItem = monthlyUpdateList[index];
        // Validation 1: Dropdown should be mandatory

        const req_body = {
            "key_update_in_the_area_of": editedItem.key_update_in_the_area_of,
            "monthly_update": editedItem.monthly_update,
            "is_update": isAddClicked ? false : true,
            "update_id": isAddClicked ? null : editedItem.id,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        }
        
        
        // Check if it's an add operation
        if (isAddClicked) {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                editMontlyUpdate(req_body, index)
                setCurrentOperation(null)
            }
        } else {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                editMontlyUpdate(req_body, index)
                setCurrentOperation(null)
            }
        }

    };


    const handleClose = (index) => {
        // Remove the index from editableRows when clicking the close icon
        setEditableRows([]);
        setAddClick(false);
        // If the item is newly added and being closed, remove it from the list
        if (!monthlyUpdateList[index].is_update) {
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }
    
        // Reset the is_edit flag
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        updatedMonthlyUpdateList[index].is_edit = false;
        setmonthlyUpdateList(updatedMonthlyUpdateList);

        if (currentOperation === 'add') {
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }
        setCurrentOperation(null)
        getMonthlyUpdate()
    };
   
    // const handleClose = (index) => {
    //     // Remove the index from editableRows when clicking the close icon
    //     setEditableRows([]);
    //     setAddClick(false);
    
    //     // Check if the index exists in the monthlyUpdateList
    //     if (monthlyUpdateList[index]) {
    //         // If the item is newly added and being closed, remove it from the list
    //         if (monthlyUpdateList[index].is_update != undefined || monthlyUpdateList[index].is_update){
    //             setmonthlyUpdateList((prevList) => {
    //                 const updatedList = [...prevList];
    //                 updatedList.splice(index, 1);
    //                 return updatedList;
    //             });
    //         } else {
    //             // Reset the textarea to null for the closed item
    //             const updatedMonthlyUpdateList = [...monthlyUpdateList];
    //             updatedMonthlyUpdateList[index].monthly_update = null; // Set to null
    //             updatedMonthlyUpdateList[index].is_edit = false; // Reset is_edit flag
    //             setmonthlyUpdateList(updatedMonthlyUpdateList);
    //         }
    //     }
    
    //     if (currentOperation === 'add') {
    //         setmonthlyUpdateList((prevList) => {
    //             const updatedList = [...prevList];
    //             updatedList.splice(index, 1);
    //             return updatedList;
    //         });
    //     }
    
    //     setCurrentOperation(null);
    // };
    
    

    const handleAdd = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...monthlyUpdateList];
            const newItem = {
                key_update_in_the_area_of: "",
                monthly_update: "",
            };
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, index + 1]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const handleAddNew = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...monthlyUpdateList];
            const newItem = {
                key_update_in_the_area_of: "",
                monthly_update: "",
            };
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, 0]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const handleInputChange = (index, field, value) => {
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        updatedMonthlyUpdateList[index][field] = value;
        setmonthlyUpdateList(updatedMonthlyUpdateList);
    };

    const setSelectValue = (list, value) => {
        return list.find((data) => {
            return data.label === value;
        });

    }

     // validation popup close

     const closePopup = () => {
        setPopupOpen(false);
    };


    
    // dropdown scroll close start

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the background color of the control
            marginTop: 0, // Adjust the top margin
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
            color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
            height: '45px',

        }),
    };

    // Add state variables for each dropdown
    const [isKeyUpdateAreaDropdownOpen, setKeyUpdateAreaDropdownOpen] = useState(false);
    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };

    // dropdown scroll close end

    return (

        <div className="parentContainer">
            {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-center'>
                                <p className="head-text">Delete Item</p>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Mention Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}
                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                />
                            </label>
                            <button onClick={() => setShowConfirmationModal(!showConfirmationModal)} className="cancel-btn">Cancel</button>
                            <button type="submit" className="confirm-btn">Confirm</button>
                        </form>
                    </div>
                </div>
            )}

                 {/* Validation popup start */}
         {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text" style={{fontSize:"20px"}}>Information</p>
                            </div>
                        </div>
                        <div className='ChatBodyDetails' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto',overflowY:'auto', padding:"0rem"}}>
                                {errorList.map((item, index) => (
                                    <div style={{ display : 'flex'}}>
                                        <div className=' d-flex mb-3' style={{width:'90%'}}>
                                            {`${index+1}) ${item}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        <button className="close-btn" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
            {/* Validation popup end */}


            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}
            <div className="monthlyHeaderWrapper">
                <span className="monthly-Title-Table-header">Ecosystem</span>
                {!isLoading && <HelpSidePanel content={"Information on what is happening in the ecosystem as a whole"} />}
            </div>
            {/* <MarketGraph setPreviewList={props.setPreviewList} setPreviewEnabled={props.setPreviewEnabled} ismonth={props.ismonth}/>
            <div className="monthlyHeaderWrapper">
                <span className="monthly-Title-Table-header">EcoSystem</span>
                {!isLoading && <HelpSidePanel content={"Information on what is happening in the ecosystem as a whole"} />}
            </div> */}
            {isLoading ? (<SmallSizedLoader />) :
                (<div className="table-div padStyle ">
                    <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff" , zIndex:9999999999 }} />
                    <div className="table-container">
                        <table className="table-container-subdiv">
                            <thead>
                                <tr>
                                <th style={{ width: "20%" }} className="column-header-style">Area Of Update</th>
                                <th style={{ width: "60%" }} className="column-header-style">Monthly Updates</th>
                                <th style={{ width: "0%", display: props.ismonth ? "none" : "table-cell" }} className="column-header-style bg-wh">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {monthlyUpdateList.length > 0 ? (
                                monthlyUpdateList.map((item, index) =>
                                    <tr key={index} className={editableRows.includes(index) ? "editable-row" : ""}>
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                            //     <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.key_update_in_the_area_of} onChange={(e) => handleInputChange(index, 'key_update_in_the_area_of', e.target.value)} />
                                            // ) : (
                                            //     <div>
                                            //         <p>{item.previous_key_update_in_the_area_of}</p>
                                            //         {item.is_edit ?
                                            //             <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.key_update_in_the_area_of} onChange={(e) => handleInputChange(index, 'key_update_in_the_area_of', e.target.value)} />
                                            //             : ""
                                            //         }
                                            //     </div>
                                                <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Select
                                                        id={index}
                                                        options={KeyUpdateArea}
                                                        value={setSelectValue(KeyUpdateArea, monthlyUpdateList[index].key_update_in_the_area_of)}
                                                        styles={customStyles}
                                                        menuPosition={'fixed'}
                                                        maxMenuHeight={100}
                                                        onChange={
                                                            (e) => handleInputChange(index, 'key_update_in_the_area_of', e)
                                                        }
                                                        onMenuOpen={() => setKeyUpdateAreaDropdownOpen(true)}
                                                        onMenuClose={() => setKeyUpdateAreaDropdownOpen(false)}
                                                        menuIsOpen={isKeyUpdateAreaDropdownOpen}
                                                        menuPortalTarget={document.body}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p> {item.previous_key_update_in_the_area_of}</p>
                                                    {/* {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={KeyUpdateArea}
                                                                value={setSelectValue(KeyUpdateArea, monthlyUpdateList[index].key_update_in_the_area_of)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'key_update_in_the_area_of', e)
                                                                }
                                                                onMenuOpen={() => setKeyUpdateAreaDropdownOpen(true)}
                                                                onMenuClose={() => setKeyUpdateAreaDropdownOpen(false)}
                                                                menuIsOpen={isKeyUpdateAreaDropdownOpen}
                                                                menuPortalTarget={document.body}
                                                            />
                                                        </div>
                                                        : ""
                                                    } */}
                                                </div>
                                            )}
                                        </td>
                                        <td className="column-data-style update-month">
                                            {editableRows.includes(index) ? (
                                                <div>
                                                    {/* {item && item.previous_update_for_the_month && item.previous_update_for_the_month != null && item.previous_update_for_the_month != undefined && item.previous_update_for_the_month != "" ? <label className={index == 0 ? "update-message-bottom" : "update-message-top"}>{item.previous_update_for_the_month}</label> : null} */}
                                                    <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.monthly_update} onChange={(e) => handleInputChange(index, 'monthly_update', e.target.value)} />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{item.is_edit ? item.previous_monthly_update : item.monthly_update}</p>
                                                    {item.is_edit ?
                                                        <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.monthly_update} onChange={(e) => handleInputChange(index, 'monthly_update', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        <td className={index % 2 === 0 ? "column-data-style bg-ylw" : "column-data-style bg-wh"} style={{display: props.ismonth ? "none" : "column-data-style" }}>
                                            {editableRows.includes(index) ? (
                                                <div className="d-flex justify-content-around">
                                                    <div className="d-flex justify-content-around">
                                                        <div className="d-flex justify-content-center">
                                                            <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                            <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ width: "100px" }}>
                                                    {!item.is_edit ?
                                                        <div className="d-flex justify-content-around">
                                                            <div>
                                                                <img src={edit} className="edit" alt="Edit" style={{ width: "13px" }} onClick={() => handleEdit(index)} />
                                                            </div>
                                                            {/* <div>
                                                                <img src={remove} className="delete" alt="Delete" style={{ width: "13px" }} onClick={() => handleDelete(index)} />
                                                            </div>
                                                            <div>
                                                                <img src={add} className="add" alt="Add" style={{ width: "13px" }} onClick={() => handleAdd(index, "add")} />
                                                            </div> */}
                                                        </div>
                                                        :
                                                        <div className="d-flex justify-content-around">
                                                            <div className="d-flex justify-content-around">
                                                                <div className="d-flex justify-content-center">
                                                                    <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                                    <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                    ))
                                    : (
                                        <tr>
                                            <td colSpan="3" className="text-center">
                                                <div className="add">
                                                    <div className="add-btn" onClick={handleAddNew}>
                                                        <span>Add New</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>)}
        </div>
    );
}