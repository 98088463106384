import axios from 'axios';
import { useDispatch } from 'react-redux';
import { clearUserDetails, clearUserModuleAccessDetails } from '../redux/action/Actions';
import { persistor } from '../redux/store/Store';

// For Local
// export const BASE_URL = 'https://localhost:44361/'
// export const BASE_URL_MONTHLY = 'http://127.0.0.1:8000/'
// For Live
// ----------------------------export const BASE_URL = 'https://planetxpro.planetcp.com/APIServer/'
export const BASE_URL = 'https://dxpanalytics.rkfoodland.com/APIServer/'
export const BASE_URL_MONTHLY = 'https://3a9d-206-1-13-26.ngrok-free.app/'


export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
      }
      return "";
  }

  export const deleteAllCookies = async () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  
    // Assuming `persistor.purge()` returns a Promise
    // await persistor.purge();
  
    // localStorage.clear();
    console.log("inside delete cookie -------->", cookies);
    window.location.href = "/";
  };


const instance = axios.create({
  baseURL: BASE_URL,
});


instance.interceptors.request.use(request => {
  // Change the Base URl For Monthly Update

  if (request.url.includes('monthlymodule/')) {
    request.baseURL = BASE_URL;
    request.url = request.url.replace('monthlymodule/', '');
  }


  console.log('------------------API ------------------->>',request.url,'------------------------\n',request);
  if (request.url == 'api/login' || request.url == 'api/forget-password' || request.url == 'api/validate-otp' || request.url == 'api/reset-password' || request.url == 'api/auth/redirect' || request.url.includes('api/auth/index')){
    return request
  }
  else{
    if (getCookie("isLogin") == "" || getCookie("isLogin"== null) || getCookie("isLogin"== undefined || "isLogin"==false)){
        deleteAllCookies()
        const dispatch = useDispatch();
        dispatch(clearUserDetails());
        dispatch(clearUserModuleAccessDetails());
        // persistor.purge();
        // localStorage.clear();
        window.location.href = "/"
      }
      else{
        return request
      }
  }
});


instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      // deleteAllCookies()
      // const dispatch = useDispatch();
      // dispatch(clearUserDetails());
      // dispatch(clearUserModuleAccessDetails());
      // persistor.purge();
      // localStorage.clear();
      // window.location.href = "/"
    }
    return Promise.reject(error);
  }
);


export default instance;

