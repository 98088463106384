import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './modules/login/Login';
import Landing from './modules/landing/Landing';
import api, { deleteAllCookies, getCookie, setCookie } from '../src/api_interceptor/api'
import { useDispatch } from 'react-redux';
import { clearUserDetails, clearUserModuleAccessDetails, setUserDetails } from './redux/action/Actions';
import { encodeBase64 } from './common_function/common_function';
import { persistor } from './redux/store/Store';
import Dashboard from './modules/dashboard/Dashboard';
import Milestone from './modules/milestone/Milestone';
import MyActionTracker from './modules/action_tracker/MyActionTracker';
import MonthlyUpdates from './modules/monthly_updates/MonthlyUpdates';
import ReportViewer from './components/report_module/ReportViewer';
import PrivacyPolicy from './components/privacy_policy/PrivacyPolicy';
// import MonthlyUpdates from './modules/monthly_updates/MonthlyUpdates';
// import ViewUpdateHistory from './modules/view_update_history/ViewUpdateHistory';
// import FinalPreview from './modules/final_preview/FinalPreview';




const App = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showPopUp, setshowPopUp] = useState(false);
  const dispatch = useDispatch();

  useEffect(()=>{
    console.log('calling useEffect');
    let isLoginvalCookie = getCookie("isLogin") 
    if (isLoginvalCookie){
      setIsLoggedIn(true);
    }
    else{
      setIsLoggedIn(false);
    }    
  },[]);



  const logoutHandler = () => {
    
    deleteAllCookies()
    setIsLoggedIn(false);
    dispatch(clearUserDetails());
    // dispatch(clearUserModuleAccessDetails());
    // persistor.purge();
    // localStorage.clear()
    window.location.href = "/"
  }

  return (
    <>
      <Router>
          <Routes>
          {!isLoggedIn && <Route exact path="/" element={<Login showPopUpModal={showPopUp} />}></Route> }
          {isLoggedIn && <Route exact path="/" element={<Dashboard/>}></Route>}
          <Route path="/myprojects" element={<Landing onLogout={logoutHandler}/>} />
          <Route path="/myprojects/:paramProjectId" element={<Landing onLogout={logoutHandler}/>} />
          <Route path="/mymilestones" element={<Milestone onLogout={logoutHandler}/>}/>
          <Route path="/mymilestones/:paramMilestoneId" element={<Milestone onLogout={logoutHandler}/>}/>
          <Route path="/myactions" element={<MyActionTracker onLogout={logoutHandler}/>} />
          <Route path="/monthly/*" element={<MonthlyUpdates onLogout={logoutHandler}/>} />
          <Route path="/report" element={<ReportViewer />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          {/* <Route path="/viewupdatehistory/" element={<ViewUpdateHistory />} />
          <Route path="/finalpreview/" element={<FinalPreview />} /> */}
          </Routes>          
    </Router>
    </>
  );
};

export default App;