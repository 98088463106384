import api from '../api_interceptor/api'

export const  encodeBase64 = (data)  => {
    const bytes = new TextEncoder().encode(data);
    return window.btoa(String.fromCharCode.apply(null, bytes));
}

export const isValidEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-]+.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
}

export const getCurrency = (companyId) => { 
    const currencyMap  = {
        0:"-",
        1:"USD",
        2:"INR",
        3:"EUR",
        4:"GBP",
        5:"CAD"
    }
    return currencyMap[companyId];
}

export const getGraphName = (companyId) => { 
    const currencyMap  = {
        revenue:"REVENUE",
        sigma_cost:"SIGMA (COST)",
        re_investment:"RE-INVESTMENTS",
        net_margin:"NET MARGIN",
        gross_margin:"GROSS MARGIN",
        cash:"CASH"
    }
    return currencyMap[companyId];
}


export const updateState = (stateData, user_id, comp_div_id, setPreviewList,comp_id) =>{
    const API_URL = 'monthlymodule/api/is-preview-enabled';
    const req_body = {
        "user_id" : user_id,
        "company_division_id" : comp_div_id,
        "year" : "",
        "month" : "",
        "company_id" : comp_id,
    };
    api.post(API_URL, req_body, {
        'Content-Type': 'application/json'
    })
        .then((response) => {
            if (response && response.data) {
                stateData(response.data.is_preview_enable)
                setPreviewList(response.data.preview_list)
            }
            else {
                stateData(true)
                setPreviewList({})
            }
        })
        .catch((error) => {
            console.error(error);
        });    
}

export const convertToDateFormatDDMMYYYY = (dateString) => {
    if(dateString == null){
        return '-'
    }
    else{
        const date = new Date(dateString);
        const day = (`0${date.getDate()}`).slice(-2);
        const month = (`0${date.getMonth() + 1}`).slice(-2); // Months are zero-based, so add 1
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    }
};


// export const formatDate = (dateString) => {
//     if (!dateString) return '';
//     const date = new Date(dateString);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const day = String(date.getDate()).padStart(2, '0');
//     return `${year}-${month}-${day}`;
// };

export const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); 
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

export const formatDateAT = (date) => {
    if (!date) return ''; 

    if (typeof date === 'string' && date.length >= 10) {
        return date.slice(0, 10);
    }

    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
};

export const handleFormatDate = (date) => {
    if (!date) return ''; 

    if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
    }

    const d = new Date(date);
    if (isNaN(d.getTime())) return ''; 

    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
};

  
export const compareDate = (dateString) => {
    const providedDate = new Date(dateString);
    const today = new Date();

    // Reset the time part for both dates to compare only the date part
    providedDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if (providedDate >= today) {
        return "On Track";
    } else {
        return "Delayed";
    }
};

export const formatDateInText = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
};

export const formatDateTimeForChat = (dateTimeString) => {
    const currentTime = new Date();
    const providedTime = new Date(dateTimeString);

    const differenceInMilliseconds = currentTime - providedTime;
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);

    if (differenceInSeconds < 60) {
        return 'Just now';
    } else if (differenceInMinutes < 60) {
        return `${differenceInMinutes} mins ago`;
    } else if (differenceInHours < 24) {
        return `${differenceInHours} hours ago`;
    } else {
        return `${differenceInDays} days ago`;
    }
};

export const formatDateTimeNotification = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear().toString().slice(-2);

    return `${day} ${month}’${year}`;
};

export const formatDtaeForrecurring = (dateTimeString) => {
    // Split the date and time
    
    if(dateTimeString != "" && dateTimeString !=null){
        const datePart = dateTimeString.split(' ')[0]; // Extract the date part
        // Split the date part by '-'
        const [day, month, year] = datePart.split('-');
        // Return the formatted date
        return `${day}-${month}-${year}`;
    }
    else{
        return ""
    }
};

export const calculateDateDifference = (startDate, dueDate) => {
    // Check if either date is null or empty

    if (!startDate || !dueDate) {
        return '-';
    }

    // Convert date strings to Date objects
    const startDateObj = new Date(startDate);
    const dueDateObj = new Date(dueDate);

    // Check if the date objects are valid
    if (isNaN(startDateObj) || isNaN(dueDateObj)) {
        return '-';
    }

    // Calculate the difference in time
    const timeDifference = dueDateObj - startDateObj;

    // Convert time difference from milliseconds to days
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return dayDifference;
};

export const calculateDuration = (startTime, endTime) => {

    // Parse startTime and endTime into Date objects
    const startDate = new Date(`1970-01-01T${startTime}:00`);
    const endDate = new Date(`1970-01-01T${endTime}:00`);

    // Calculate duration in milliseconds
    const durationInMs = endDate - startDate;

    // Convert to minutes
    const durationInMinutes = durationInMs / 60000;
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;

    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`; // Ensures minutes are always two digits
};

export const roundToTwoDecimals = (num) => {
    return Math.round(num * 100) / 100;
};