import React, { useState } from "react";
import "./HelpSidePanel.css";
import arrowImg from '../../assets/images/arrow-down.svg'

export default function HelpSidePanel(props) {
  const [isStyleApplied, setStyleApplied] = useState(false);

  const toggleHelp = () => {
    setStyleApplied(!isStyleApplied);
  };

  return (
    <div id="absoluteKid" className={isStyleApplied ? "absoluteKid mystyle" : "absoluteKid"} >
      <div onClick={toggleHelp} className={isStyleApplied ?"helpButtonOpen": "helpButton" }>
        <img src={arrowImg} alt="info" style={{ width: "12px",transform: "rotate(90deg)",marginBottom:"0.3rem", marginRight : "0.5rem"}}></img>
        <span className="mr 1">Help</span>
      </div>
      <div className="helpDiv">
        <div className="content">
        <span>{props.content}</span>
        </div>
      </div>
    </div>
  );
}