import React, { useState, useEffect , useRef} from 'react';
import Header from '../../components/header/Header';
import api, { BASE_URL } from '../../api_interceptor/api'
import Loader from '../../components/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyIDDetails, setDivisionIDDetails, setUserModuleAccessDetails } from '../../redux/action/Actions';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
// import ActionTracker from './MyActionTracker';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EditIcons from '../../assets/images/Edit icon.svg'
import Remark from '../../assets/images/Comment History.svg'
import EditIconsBlue from '../../assets/images/Edit icon Blue.svg'
import RemarkBlue from '../../assets/images/Comment History Blue.svg'
import DownloadArrow from '../../assets/images/Dropdown icon.svg'
import EditActionTracker from '../../assets/images/Edit icon Action Tracker.svg'
import RemarkActionTracker from '../../assets/images/Comment History Action Tracker.svg'
import DeleteIcon from '../../assets/images/Delete.svg'
import DeleteIconMilestone from '../../assets/images/Delete Milestone.svg'
import SaveAsDraftMilestone from '../../assets/images/Save Draft Milestone.svg'
import CloseAction from '../../assets/images/Cancel cross icon.svg'
import SaveMilestone from '../../assets/images/Save Milestone.svg'
import ActionIcon from '../../assets/images/Action icon.svg'
import ProjectIcon from '../../assets/images/Project icon.svg'
import MileStoneIcon from '../../assets/images/Milestone icon.svg'
import CommentWhite from '../../assets/images/Comment white.svg'
import CommementBlue from '../../assets/images/Comment icon blue.svg'
import CancelBluIconComment from '../../assets/images/Cancel icon Comments.svg'
import WhiteCancelicon from '../../assets/images/White Cancel icon.svg'
import ProfIcon from '../../assets/images/Profile icon.svg'
import EyeIcon from '../../assets/images/Icon akar-eye.svg'
import WhiteSaveIcon from '../../assets/images/Icon feather-save.svg'
import SendChatIcon from '../../assets/images/Send chat icon.svg'
import PaperClip from '../../assets/images/paperclip.svg'
import DownloadFile from '../../assets/images/DownloadFile.svg'
import DeleteFile from '../../assets/images/DeleteFile.svg'
import DeleteProject from '../../assets/images/Delete Project.svg'
import Downloadprojectreport from '../../assets/images/ViewProjectReport.svg'
import { calculateDateDifference, compareDate, convertToDateFormatDDMMYYYY, formatDate, handleFormatDate,formatDateAT, formatDateInText, formatDateTimeForChat, formatDtaeForrecurring } from '../../common_function/common_function';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import MilestoneHeader from '../../components/header/MilestoneHeader';
import ActionTrackerHeader from '../../components/header/ActionTrackerHeader';




const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
};

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block"}}
            onClick={onClick}
        />
    );
};


const RecurringOnDayList = [
    { Id: 0, Value: "Sunday" },
    { Id: 1, Value: "Monday" },
    { Id: 2, Value: "Tuesday" },
    { Id: 3, Value: "Wednesday" },
    { Id: 4, Value: "Thursday" },
    { Id: 5, Value: "Friday" },
    { Id: 6, Value: "Saturday" }
]



const MyActionTracker = (props) => {
    
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const company_id = useSelector((state) => state.user.companyID);
    const [landingModuleList, setlandingModuleList] = useState([]);
    const [landingDivisionDetails, setlandingDivisionDetails] = useState([]);
    const [selectedMilestone, setSelectedMilestone] = useState(null);
    const [dataProject, setDataProject] = useState([]);
    const [showAddProject, setshowAddProject] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Monthly');
    const dropdownRef = useRef(null);
    const [EntityProject, setEntityProject] = useState(null);
    const [ProjectName, setProjectName] = useState('');
    const [InitiativeDescription, setInitiativeDescription] = useState('');
    const [Rationale, setRationale] = useState('');
    const [Constraints, setConstraints] = useState('');
    const [OutcomesKPIs, setOutcomesKPIs] = useState('');
    const [PotentialRisk, setPotentialRisk] = useState('');
    const [ResourcesNeeded, setResourcesNeeded] = useState('');
    const [CostAndInvestments, setCostAndInvestments] = useState('');
    const [DescisionSupportfromCMO, setDescisionSupportfromCMO] = useState('');
    const [ResponsibleDoer, setResponsibleDoer] = useState(null);
    const [Accountable, setAccountable] = useState('');
    const [Consult, setConsult] = useState('');
    const [ContextAndConnect, setContextAndConnect] = useState('');
    const [Inform, setInform] = useState('');
    const [Frequency, setFrequency] = useState(null);
    const [ProjectStatus, setProjectStatus] = useState(null);
    const [Projectdate, setProjectdate] = useState(null);
    const [individualprojectdetails, setindividualprojectdetails] = useState(null);
    const [CardSelected, setCardSelected] = useState(null);
    const [editProject, seteditProject] = useState(false);
    const [projectRemark, setprojectRemark] = useState("");
    const [milestoneDetails, setMilestoneDetails] = useState([]);
    const [newMilestone, setNewMilestone] = useState(null);
    const [editingMilestone, setEditingMilestone] = useState(null);
    const [editMilestoneclicked, seteditMilestoneclicked] = useState(false);
    const [addMilestoneclicked, setaddMilestoneclicked] = useState(false);
    const [showConfirmationModalForDeleteMilestone, setshowConfirmationModalForDeleteMilestone] = useState(false);
    const [milestoneDeleteID, setmilestoneDeleteID] = useState(null);
    const [ActionTrackerData, setActionTrackerData] = useState([]);
    const [GlobalMilestoneIDForActionTracker, setGlobalMilestoneIDForActionTracker] = useState(null);
    const [selectedEntity, setselectedEntity] = useState([]);
    const [selectedProject, setselectedProject] = useState([]);
    const [selectedStatus, setselectedStatus] = useState([]);
    const [selectedmilestoneFilter, setselectedmilestoneFilter] = useState([]);
    const [actiontrackerFilter, setactiontrackerFilter] = useState([]);
    const [EntityList, setEntityList] = useState([]);
    const [ProjectList, setProjectList] = useState([]);
    const [ProjectStatusList, setProjectStatusList] = useState([]);
    const [MileStoneStatusList, setMileStoneStatusList] = useState([]);
    const [UserMasterList, setUserMasterList] = useState([]);
    const [FrequencyList, setFrequencyList] = useState([]);
    const [CategoryList, setCategoryList] = useState([]);
    const [PriorityList, setPriorityList] = useState([]);
    const [StatusList, setStatusList] = useState([]);
    const [ActionTrackerList, setActionTrackerList] = useState([]);
    const [RecurringList, setRecurringList] = useState([]);
    const [showActionTrackerHistory, setshowActionTrackerHistory] = useState(false);
    const [ActionTrackerID, setActionTrackerID] = useState(null);
    const [ATHistoryComment, setATHistoryComment] = useState('');
    const [HitoryDetailsList, setHitoryDetailsList] = useState([]);
    const [showProjectComments, setshowProjectComments] = useState(false);
    const [ProjectCommentsText, setProjectCommentsText] = useState('');
    const [ChatDetails, setChatDetails] = useState([]);

    const [showMilestoneComments, setshowMilestoneComments] = useState(false);
    const [MilestoneCommentsText, setMilestoneCommentsText] = useState('');
    const [MilestoneChatDetails, setMilestoneChatDetails] = useState([]);
    const [MileStoneIDForChat, setMileStoneIDForChat] = useState(null);
    const [MileStoneIDForChatAssignedTo, setMileStoneIDForChatAssignedTo] = useState(null);
    const [ErrorMsg, setErrorMsg] = useState([]);
    const [showErrorMsg, setshowErrorMsg] = useState(false);
    const [isFileViewerOpenHIstory, setisFileViewerOpenHIstory] = useState(false);
    const [isFileViewerHistoryList, setisFileViewerHistoryList] = useState([]);
    const [SavedFileList, setSavedFileList] = useState([]);
    const [showConfirmationModalForDeleteProject, setshowConfirmationModalForDeleteProject] = useState(false);
    const [deleteProjectID, setdeleteProjectID] = useState(null);
    const [EntityWiseUserList, setEntityWiseUserList] = useState([]);
    const [CompletionList, setCompletionList] = useState([]);
    const [MilestoneProgressStatusList, setMilestoneProgressStatusList] = useState([]);
    const [TotalMileStoneList, setTotalMileStoneList] = useState([]);
    const [TotalActionTrackerListAssignedToUser, setTotalActionTrackerListAssignedToUser] = useState([]);
    const [ActionTrtackerIDForDraftDeletion, setActionTrtackerIDForDraftDeletion] = useState(null);
    const [ActionTrtackerIDForDraftDeletionModal, setActionTrtackerIDForDraftDeletionModal] = useState(false);
    const [FinalMilestoneDetails, setFinalMilestoneDetails] = useState([]);
    const [TotalMilestoneListForAT, setTotalMilestoneListForAT] = useState([]);
    const [TotalProjectsAssignedToUserForAT, setTotalProjectsAssignedToUserForAT] = useState([]);
    const [showAlertForMilestone, setshowAlertForMilestone] = useState(false);

    const [showDependencyCompleteAlert, setshowDependencyCompleteAlert] = useState(false);
    const [showDependencyCompleteAlertMsg, setshowDependencyCompleteAlertMsg] = useState("");


    const chatBodyRef = useRef(null);

    useEffect(() => {
        document.title = "My Actions";
    }, []);
    // useEffect(() => {
    //     
    //     console.log('----------------------->>>>>>Chat details',ChatDetails);
       
    // }, [ChatDetails]);



    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the background color of the control
            marginTop: 0, // Adjust the top margin
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
            color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
            height: '45px',

        }),
    };
    const handleAddMilestone = () => {
        setaddMilestoneclicked(true)
        setNewMilestone({
            MileStoneName: '',
            MileStoneDescription: '',
            AssignedTo: '',
            Dudate: '',
            Progress: '',
            totalActions: 0,
            MileStoneStatus: null,
            ID: null, // Temporary ID for the new milestone
        });
    };

    const addHandleNone = () => {

    }

    const CloseActionTrackerDependencyPopup = () => {
        setshowDependencyCompleteAlert(false)
        setshowDependencyCompleteAlertMsg("")

    }


    const handleSaveMilestone = (isSubmitVal,ProjectIDParam) => {
        
        var erroLngth = []
                
        if (editingMilestone) {
            if ((editingMilestone.MileStoneName == null || editingMilestone.MileStoneName == "")) {
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter milestone name.']);
                erroLngth.push('Please enter milestone name.')
            }
    
            if (isSubmitVal && (editingMilestone.MileStoneStatus == null || editingMilestone.MileStoneStatus == "" || editingMilestone.MileStoneStatus == 0)) {
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select milestone status.']);
                erroLngth.push('Please select milestone status.')
            }

            // if (isSubmitVal && (editingMilestone.MileStoneDescription == null || editingMilestone.MileStoneDescription == "")) {
            //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter milestone description.']);
            //     erroLngth.push('Please enter milestone description.')
            // }
    
            if (isSubmitVal && (editingMilestone.AssignedTo == null || editingMilestone.AssignedTo == "" || editingMilestone.AssignedTo == 0)) {
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select assigned to.']);
                erroLngth.push('Please select assigned to.')
            }
            if (isSubmitVal && (editingMilestone.Dudate == null || editingMilestone.Dudate == "")) {
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select due date.']);
                erroLngth.push('Please select due date.')
            }
            if (isSubmitVal && (editingMilestone.Progress == null || editingMilestone.Progress == "" || editingMilestone.Progress == 0)) {
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select progress.']);
                erroLngth.push('Please select progress.')
            }
           
            // if (editingMilestone.Progress != null || editingMilestone.Progress != "") {
            //     if( editingMilestone.Progress>=0 && editingMilestone.Progress<=100){

            //     }
            //     else{
            //         setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter value between 0 to 100 in progress.']);
            //         erroLngth.push('Please enter value between 0 to 100 in progress.')
            //     }
            // }
            if (erroLngth.length > 0) {
                setshowErrorMsg(true)
                return
            }
            if (erroLngth.length == 0){
                
                addorupdateMilestoneDetails("edit",isSubmitVal,ProjectIDParam)
                setEditingMilestone(null);
                seteditMilestoneclicked(false)
            }
        } else {
            if ((newMilestone.MileStoneName == null || newMilestone.MileStoneName == "")) {
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter milestone name.']);
                erroLngth.push('Please enter milestone name.')
            }

            if (isSubmitVal && (newMilestone.MileStoneStatus == null || newMilestone.MileStoneStatus == ""|| newMilestone.MileStoneStatus == 0)) {
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select milestone status']);
                erroLngth.push('Please select milestone status')
            }
    
            // if (isSubmitVal && (newMilestone.MileStoneDescription == null || newMilestone.MileStoneDescription == "")) {
            //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter milestone description.']);
            //     erroLngth.push('Please enter milestone description.')
            // }
    
            if (isSubmitVal && (newMilestone.AssignedTo == null || newMilestone.AssignedTo == "" || newMilestone.AssignedTo == 0)) {
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select assigned to.']);
                erroLngth.push('Please select assigned to.')
            }
            if (isSubmitVal && (newMilestone.Dudate == null || newMilestone.Dudate == "")) {
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select due date.']);
                erroLngth.push('Please select due date.')
            }
            if (isSubmitVal && (newMilestone.Progress == null || newMilestone.Progress == ""|| newMilestone.Progress == 0)) {
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select progress']);
                erroLngth.push('Please select progress')
            }
            // if ((newMilestone.Progress != null || newMilestone.Progress != "")) {
            //     if( newMilestone.Progress>=0 && newMilestone.Progress<=100){

            //     }
            //     else{
            //         setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter value between 0 to 100 in progress.']);
            //         erroLngth.push('Please enter value between 0 to 100 in progress.')
            //     }
            // }
            if (erroLngth.length > 0) {
                setshowErrorMsg(true)
                return
            }
            if (erroLngth.length == 0){
                
                addorupdateMilestoneDetails("add",isSubmitVal)
                setNewMilestone(null);
                setaddMilestoneclicked(false)
            }
        }
    };

    const handleCloseMilestone = () => {
        if (editingMilestone) {
            setEditingMilestone(null);
            seteditMilestoneclicked(false)
        } else {
            setNewMilestone(null);
            setaddMilestoneclicked(false)
        }
    };

    const handleInputChange = (e, milestone, setMilestone) => {
        const { name, value } = e.target;
        setMilestone({ ...milestone, [name]: value });
    };

    const handleEditMilestone = (milestone) => {
        seteditMilestoneclicked(true)
        setEditingMilestone(milestone);
    };
    

    useEffect(() => {
        // setTimeout(() => {
            getAllMasterDetails()
        // }, 1000);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            // getAllMasterDetails()
            // setselectedProject([])
            // setselectedStatus([])
            getProjectDetailsByUserID();
        }, 500);
    }, [selectedEntity]);

    useEffect(() => {
        setTimeout(() => {
            getProjectDetailsByUserID()
        }, 500);
    }, [selectedProject]);

    useEffect(() => {
        setTimeout(() => {
            getProjectDetailsByUserID()
        }, 500);
    }, [selectedStatus]);

    useEffect(() => {
        setTimeout(() => {
            getProjectDetailsByUserID()
        }, 500);
    }, [selectedmilestoneFilter]);

    useEffect(() => {
        setTimeout(() => {
            getProjectDetailsByUserID()
        }, 500);
    }, [actiontrackerFilter]);

    const getAllMasterDetails = () => {
        setIsLoading(true)
        const API_URL = 'api/get-all-master-details';
        const req_body = {
            UserId: userData['UserId'],
            IsAdmin : userData['IsAdmin']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
        .then((response) => {
            
            if (response && response.data) {
                dispatch(setCompanyIDDetails(response.data.EntityList));
                setEntityList(response.data.EntityList)
                setProjectList(response.data.ProjectList)
                setProjectStatusList(response.data.ProjectStatusList)
                setMileStoneStatusList(response.data.MileStoneStatusList)
                setMileStoneStatusList(response.data.MileStoneStatusList)
                setUserMasterList(response.data.UserMasterList)
                setFrequencyList(response.data.FrequencyList)
                setCategoryList(response.data.CategoryList)
                setPriorityList(response.data.PriorityList)
                setStatusList(response.data.StatusList)
                setActionTrackerList(response.data.ActionTrackerList)
                setEntityWiseUserList(response.data.EntityWiseUserList)
                setCompletionList(response.data.CompletionList)
                setMilestoneProgressStatusList(response.data.MilestoneProgressStatusList)
                setTotalMileStoneList(response.data.TotalMileStoneList)
                setTotalActionTrackerListAssignedToUser(response.data.TotalActionTrackerListAssignedToUser)
                setTotalMilestoneListForAT(response.data.TotalMilestoneListForAT)
                setTotalProjectsAssignedToUserForAT(response.data.TotalProjectsAssignedToUserForAT)
                setRecurringList([
                    { Id : 1, Value : 'Yes'},
                    { Id : 2, Value : 'No'}
                ])
                setIsLoading(false)
                // getProjectDetailsByUserID();
            }
            else {
                setIsLoading(false)
                // getProjectDetailsByUserID();

            }
        })
        .catch((error) => {
            setIsLoading(false)
            console.error(error);
        });
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: Math.min(dataProject.length, 5),
        slidesToScroll: Math.min(dataProject.length, 5),
        swipeToSlide: false,
        accessibility :true,
        arrows:true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    const getProjectDetailsByUserID = () => {
        
        setIsLoading(true)
        const API_URL = 'api/getAllActionTrackerFilterDetails';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            PositionID: userData && 'PositionID' in userData ? userData['PositionID'] : "",
            AdvancedFilterProjects: selectedProject, 
            AdvancedFilterEntity: selectedEntity,
            AdvancedFilterProjectStatus : selectedStatus,
            AdvancedFilterMilestone : selectedmilestoneFilter,
            AdvancedFilterActiontracker : actiontrackerFilter
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data && response.data.ActionTrackerlist && response.data.ActionTrackerlist.length > 0) {
                    setFinalMilestoneDetails(response.data.ActionTrackerlist)
                    setIsLoading(false)
                }
                else {
                    response && response.data &&  response.data.IsShowPopup ? setshowAlertForMilestone(true) : setshowAlertForMilestone(false)
                    setFinalMilestoneDetails([])
                    setDataProject([]);
                    // projectClicked(0)
                    setindividualprojectdetails(null)
                    setIsLoading(false)
                    setEditingMilestone(null);
                    seteditMilestoneclicked(false)
                    setNewMilestone(null);
                    setaddMilestoneclicked(false)
                }
                //  if (EntityList.length == 1){
                //     setselectedEntity([EntityList[0].Id])
                // }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const getMileStoneByProjectID = (ProjectID) => {
        setIsLoading(true)
        const API_URL = 'api/getAllMilestoneByProjectId';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            ProjectId: ProjectID,
            PositionID: userData['PositionID']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data && response.data.MIlestoneDetails && response.data.MIlestoneDetails.length > 0) {
                    setMilestoneDetails(response.data.MIlestoneDetails)
                    setIsLoading(false)
                }
                else {
                    setMilestoneDetails([])
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const addorupdateMilestoneDetails = (type, isSubmitVal,ProjectIDParam) => {
        
        setIsLoading(true)
        const API_URL = 'api/createOrUpdateMilestone';
        const req_body = type == 'add' ? {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            Code  : "",
            EntityID  : 1, // need to change
            ProjectID  : ProjectIDParam,
            IsSubmit : isSubmitVal,
            CreatedOn : new Date(),
            CreatedBy : userData['UserId'],
            UpdatedOn : new Date(),
            UpdatedBy : userData['UserId'],
            StatusCodeId : 1,
            Remark  : "New",
            ...newMilestone
        } : 
        {
            ...editingMilestone,
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            Code  : "",
            EntityID  : 1, // need to change
            ProjectID  : ProjectIDParam,
            IsSubmit : isSubmitVal,
            CreatedOn : new Date(),
            CreatedBy : userData['UserId'],
            UpdatedOn : new Date(),
            UpdatedBy : userData['UserId'],
            StatusCodeId : 1,
            Remark  : "New"
        }
        
        ;
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    getProjectDetailsByUserID()
                    setIsLoading(false)
                }
                else {
                    getProjectDetailsByUserID()
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                getProjectDetailsByUserID()
                setIsLoading(false)
                console.error(error);
            });
    }

    const getUserRole = (div_id) => {
        setIsLoading(true)
        const API_URL = '/get-user-role';
        const req_body = {
            user_id: userData && 'user_id' in userData ? userData['user_id'] : "",
            company_id: company_id && 'value' in company_id ? company_id['value'] : "",
            division_id: div_id
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data && response.data.module_acccess && (response.data.module_acccess).length > 0) {
                    setlandingModuleList(response.data.module_acccess)
                    setIsLoading(false)
                    dispatch(setUserModuleAccessDetails(response.data));
                }
                else {
                    let a = []
                    setlandingModuleList(a)
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const logOutEvent = e =>{
        props.onLogout();
    }

    const navigateTo = (module) =>{
        navigate('/'+ module)
    }
    
    const toggleDropdown = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const selectOption = (option) => {
        dispatch(setDivisionIDDetails(option));
        setSelectedOption(option.label);
        setIsOpen(!isOpen);
        toggleDropdown()
        getUserRole(option.value)
    };

    const setData = (val) => {
        setlandingDivisionDetails(val);
        if (val.length > 0) {
            selectOption(val[0]);
        }
    }
    const closeDropdownOnClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', closeDropdownOnClickOutside);

        return () => {
            document.removeEventListener('click', closeDropdownOnClickOutside);
        };
    }, []);
    const projectClicked =(dta) =>{
        console.log('Inside project --------------------------->>>>', dta);
        setMilestoneDetails([])
        setIsLoading(true)
        const API_URL = 'api/getAllProjectsByuserId';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            AdvancedFilterProjects: dta == null ||  dta == undefined? [] :  [dta], //Need to change as per filter
            AdvancedFilterEntity: []//Need to change as per filter
        };
        console.log('Inside project REQBDY--------------------------->>>>', req_body);

        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data && response.data.ProjectDetails && response.data.ProjectDetails.length>0 ) {
                    setindividualprojectdetails(response.data.ProjectDetails[0]);
                    setCardSelected(response.data.ProjectDetails[0].ID)
                    getMileStoneByProjectID(response.data.ProjectDetails[0].ID)
                    setIsLoading(false)

                    setEditingMilestone(null);
                    seteditMilestoneclicked(false)
                    setNewMilestone(null);
                    setaddMilestoneclicked(false)
                }
                else {
                    setIsLoading(false)
                    setindividualprojectdetails([]);
                    setEditingMilestone(null);
                    seteditMilestoneclicked(false)
                    setNewMilestone(null);
                    setaddMilestoneclicked(false)
                }
            })
            .catch((error) => {
                setEditingMilestone(null);
                seteditMilestoneclicked(false)
                setNewMilestone(null);
                setaddMilestoneclicked(false)
                setIsLoading(false)
                console.error(error);
            });
    }

    const openActionTrackermodal =(dta,type) =>{
        
        // if(type=='addAction'){
        //     setSelectedMilestone(null)
        // }
        setGlobalMilestoneIDForActionTracker(dta)
        if(dta == (type=='addAction' ? null : selectedMilestone)){
            setSelectedMilestone(null);
        }
        else{
            setSelectedMilestone(dta != null || dta != undefined ? dta : null);
        }
        // setActionTrackerData([])
        setIsLoading(true)
        const API_URL = 'api/getAllActionTrackerByMileStoneId';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            EntityID: 1, //Needs to be  changed
            MilestoneID: dta,
            PositionID :  userData['PositionID']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data && response.data.ActionTrackers && response.data.ActionTrackers.length > 0) {
                    // setActionTrackerData(response.data.ActionTrackers)
                    setIsLoading(false)
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });

    }


    const closevalidateProjectDetails = () =>{
        setErrorMsg([])
        setshowErrorMsg(false)

    }

    const addorUpdateProject = (type, isSubmit) =>{
        var errormsglngth = []
        if (EntityProject == null || EntityProject == "") {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select entity.']);
            errormsglngth.push('Please select entity.')
        }
        if (ProjectName == null || ProjectName == "") {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter project name.']);
            errormsglngth.push('Please enter project name.')
        }
        if (isSubmit && (ContextAndConnect == null || ContextAndConnect == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter context and connect.']);
            errormsglngth.push('Please enter context and connect.')
        }
        if (isSubmit && (InitiativeDescription == null || InitiativeDescription == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter initiative description.']);
            errormsglngth.push('Please enter initiaative description.')
        }
        if (isSubmit && (InitiativeDescription != null || InitiativeDescription != "")) {
            if(InitiativeDescription.length > 180){
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Charecter limit for initiative description is 180.']);
                errormsglngth.push('Charecter limit for initiative description is 180.')
            }
        }
        if (isSubmit && (ResponsibleDoer == null || ResponsibleDoer == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select responsibleDoer.']);
            errormsglngth.push('Please select responsibleDoer.')
        }
        // if (isSubmit && (Frequency == null || Frequency == "")) {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select review frequency.']);
        //     errormsglngth.push('Please select review frequency.')
        // }
        // if (isSubmit && (Projectdate == null || Projectdate == "")) {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select review date.']);
        //     errormsglngth.push('Please select review date.')
        // }
        if (isSubmit && (ProjectStatus == null || ProjectStatus == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select project status.']);
            errormsglngth.push('Please select project status.')
        }
        if (errormsglngth.length > 0) {
            setshowErrorMsg(true)
            return
        }

        if(errormsglngth.length == 0){

            setIsLoading(true)
            const API_URL = 'api/createOrUpdateProject';

            const req_body = {
                UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
                ID: type == 'add' ? null : individualprojectdetails.ID,
                Code: "",
                EntityID: EntityProject,
                ProjectName: ProjectName,
                InitiativeDescription: InitiativeDescription,
                Rationale: Rationale,
                ConstraintsChallengesComplexities: Constraints,
                OutcomeKPIs: OutcomesKPIs,
                PotentialRiskDerailers: PotentialRisk,
                ResourcesNeeded: ResourcesNeeded,
                CostAndInvestments: CostAndInvestments,
                DescisionSupportFromCMO: DescisionSupportfromCMO,
                ResponsibleDoer : ResponsibleDoer, 
                Accountable : Accountable, 
                Consult : Consult, 
                ContextAndConnect : ContextAndConnect, 
                Inform : Inform, 
                ReviewFrequencyID: Frequency,
                ProjectStatus : ProjectStatus,
                ReviewDate: Projectdate,
                IsSubmit: isSubmit,
                CreatedOn: new Date(),
                CreatedBy: userData['UserId'],
                UpdatedOn: new Date(),
                UpdatedBy: userData['UserId'],
                StatusCodeId: 1,
                Remark :  type == 'add' ? "New" : projectRemark
            };
            api.post(API_URL, req_body, {
                'Content-Type': 'application/json'
            })
                .then((response) => {
                    if (response && response.data ) {
                        seteditProject(false)
                        closeaddorupdateProjectPopup()
                        if(type == 'add') {
                            getAllMasterDetails()
                        }else{
                            getProjectDetailsByUserID()
                        }
                    }
                    else {
                        closeaddorupdateProjectPopup()
                        setIsLoading(false)
                    }
                })
                .catch((error) => {
                    closeaddorupdateProjectPopup()
                    setIsLoading(false)
                    console.error(error);
                });
        }
    }

    const closeaddorupdateProjectPopup = () => {
        
        if(ErrorMsg.length==0){

            setEntityProject("")
            setProjectName("")
            setInitiativeDescription("")
            setRationale("")
            setConstraints("")
            setOutcomesKPIs("")
            setPotentialRisk("")
            setResourcesNeeded("")
            setCostAndInvestments("")
            setDescisionSupportfromCMO("")
            setResponsibleDoer("")
            setAccountable("")
            setConsult("")
            setContextAndConnect("")
            setInform("")
            setFrequency("")
            setProjectStatus("")
            setProjectdate("")
            setprojectRemark("")
            setshowAddProject(false)
            seteditProject(false)
    
            setEditingMilestone(null);
            seteditMilestoneclicked(false)
            setNewMilestone(null);
            setaddMilestoneclicked(false)
        }
    }
    const editProjectClicked = () => {
        setEntityProject(individualprojectdetails.EntityID)
        setProjectName(individualprojectdetails.ProjectName)
        setInitiativeDescription(individualprojectdetails.InitiativeDescription)
        setRationale(individualprojectdetails.Rationale)
        setConstraints(individualprojectdetails.ConstraintsChallengesComplexities)
        setOutcomesKPIs(individualprojectdetails.OutcomeKPIs)
        setPotentialRisk(individualprojectdetails.PotentialRiskDerailers)
        setResourcesNeeded(individualprojectdetails.ResourcesNeeded)
        setCostAndInvestments(individualprojectdetails.CostAndInvestments)
        setDescisionSupportfromCMO(individualprojectdetails.DescisionSupportFromCMO)
        setResponsibleDoer(individualprojectdetails.ResponsibleDoer)
        setAccountable(individualprojectdetails.Accountable)
        setConsult(individualprojectdetails.Consult)
        setContextAndConnect(individualprojectdetails.ContextAndConnect)
        setInform(individualprojectdetails.Inform)
        setFrequency(individualprojectdetails.ReviewFrequencyID)
        setProjectStatus(individualprojectdetails.ProjectStatus)
        setProjectdate(individualprojectdetails.ReviewDate)
        setprojectRemark("")
        seteditProject(true)
        setshowAddProject(true)

        setEditingMilestone(null);
        seteditMilestoneclicked(false)
        setNewMilestone(null);
        setaddMilestoneclicked(false)
    }
    

    const DeletedraftedMilestone = (MilestoneID) => {
        setIsLoading(true)
        const API_URL = 'api/deleteDraftedMilestone';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            MilestoneID : MilestoneID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    getMileStoneByProjectID(individualprojectdetails.ID)
                    setIsLoading(false)
                    setmilestoneDeleteID(null)
                    setshowConfirmationModalForDeleteMilestone(false)
                }
                else {
                    getMileStoneByProjectID(individualprojectdetails.ID)
                    setIsLoading(false)
                    setmilestoneDeleteID(null)
                    setshowConfirmationModalForDeleteMilestone(false)
                }
            })
            .catch((error) => {
                getMileStoneByProjectID(individualprojectdetails.ID)
                setIsLoading(false)
                setmilestoneDeleteID(null)
                setshowConfirmationModalForDeleteMilestone(false)
                console.error(error);
            });
    }

    const deleteConfirmation = (MilestoneID) => {
        setmilestoneDeleteID(MilestoneID)
        setshowConfirmationModalForDeleteMilestone(true)
    }

    const cancelledMilestonedeletion = () => {
        setmilestoneDeleteID(null)
        setshowConfirmationModalForDeleteMilestone(false)
    }


    //Action Tracker functions
    const [editingActionTrackerRow, setEditingActionTrackerRow] = useState(null);
    const [EditingActionTrackerOuterRow, setEditingActionTrackerOuterRow] = useState(null);
    const [newActionTrackerRow, setNewActionTrackerRow] = useState(null);

    const handleAddActionTrackerRow = (dta,type = "") => {
        openActionTrackermodal(dta,type)
        setNewActionTrackerRow({
            Category: '',
            AssociatedAction: '',
            SubAction: '',
            RefMeeting: '',
            Priority: '',
            ResponsibleDoer: '',
            Accountable: '',
            Consult: '',
            Inform: '',
            StartDate: '',
            DueDate: '',
            Duration: '',
            EsimatedEfforts: '',
            ActionDescription: '',
            Recurring: '',
            RecurringOnDay: '',
            RecurringStartDate: '',
            RecurringEndDate: '',
            Frequency: '',
            Status1: '',
            Status2: '',
            Completion: '',
            ActualStartDate: '',
            ActualFinishDate: '',
            Remark: '',
            Dependency: '',
            // Dependency: ''
        });
    };

    const handleSaveNewActionTrackerRow = (isSubmitVal) => {
        
        var errormsglngth = []
        if (isSubmitVal && (newActionTrackerRow.Category == null || newActionTrackerRow.Category == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select category.']);
            errormsglngth.push('Please select category.')
        }
        if (isSubmitVal && (newActionTrackerRow.AssociatedAction == null || newActionTrackerRow.AssociatedAction == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter associated action.']);
            errormsglngth.push('Please enter associated action.')
        }
        // if (isSubmitVal && (newActionTrackerRow.SubAction == null || newActionTrackerRow.SubAction == "")) {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter sub action.']);
        //     errormsglngth.push('Please enter sub action.')
        // }
        if (isSubmitVal && (newActionTrackerRow.RefMeeting == null || newActionTrackerRow.RefMeeting == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter ref. meeting.']);
            errormsglngth.push('Please enter ref. meeting.')
        }
        if (isSubmitVal && (newActionTrackerRow.Priority == null || newActionTrackerRow.Priority == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select priority.']);
            errormsglngth.push('Please select priority.')
        }
        if (isSubmitVal && (newActionTrackerRow.ResponsibleDoer == null || newActionTrackerRow.ResponsibleDoer == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select responsible (doer).']);
            errormsglngth.push('Please select responsible (doer).')
        }
        
        if (isSubmitVal && (newActionTrackerRow.Accountable == null || newActionTrackerRow.Accountable == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter accountable.']);
            errormsglngth.push('Please enter accountable.')
        }
        if (isSubmitVal && (newActionTrackerRow.Consult == null || newActionTrackerRow.Consult == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter consult.']);
            errormsglngth.push('Please enter consult.')
        }
        if (isSubmitVal && (newActionTrackerRow.Inform == null || newActionTrackerRow.Inform == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter inform.']);
            errormsglngth.push('Please enter inform.')
        }
        if (isSubmitVal && (newActionTrackerRow.StartDate == null || newActionTrackerRow.StartDate == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter start date.']);
            errormsglngth.push('Please enter start date.')
        }
        if (isSubmitVal && (newActionTrackerRow.DueDate == null || newActionTrackerRow.DueDate == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter due date.']);
            errormsglngth.push('Please enter due date.')
        }
        if ( (newActionTrackerRow.StartDate != null && newActionTrackerRow.StartDate != "") && (newActionTrackerRow.DueDate != null && newActionTrackerRow.DueDate != "") ){
            const startDateObj = new Date(newActionTrackerRow.StartDate);
            const dueDateObj = new Date(newActionTrackerRow.DueDate);

            // Compare the dates
            if (dueDateObj >= startDateObj) {
                // DueDate is greater than or equal to StartDate
                
            } else {
                // DueDate is less than StartDate
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Start date cannot be greater than due date.']);
                errormsglngth.push('Start date cannot be greater than due date.')
            }
        }

        // if (isSubmitVal && (newActionTrackerRow.Duration == null || newActionTrackerRow.Duration == "")) {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter duration.']);
        //     errormsglngth.push('Please enter duration.')
        // }
        if (isSubmitVal && (newActionTrackerRow.EsimatedEfforts == null || newActionTrackerRow.EsimatedEfforts == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter estimated efforts.']);
            errormsglngth.push('Please enter estimated efforts.')
        }
        if (newActionTrackerRow.EsimatedEfforts != null && newActionTrackerRow.EsimatedEfforts != ""){
            if( newActionTrackerRow.EsimatedEfforts>=0 ){

            }
            else{
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter a valid estimated efforts.']);
                errormsglngth.push('Please enter a valid estimated efforts.')
            }
        }
        if (newActionTrackerRow.ActionDescription == null || newActionTrackerRow.ActionDescription == "") {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter action description.']);
            errormsglngth.push('Please enter action description.')
        }
        if (isSubmitVal && (newActionTrackerRow.Recurring == null || newActionTrackerRow.Recurring == "")) {
            if(newActionTrackerRow.Recurring == false){

            }
            else{
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select recurring.']);
                errormsglngth.push('Please select recurring.')
            }
        }
        if (isSubmitVal && ((newActionTrackerRow.Recurring != null || newActionTrackerRow.Recurring != "") &&  newActionTrackerRow.Recurring == 1))  {
            if (isSubmitVal && (newActionTrackerRow.Frequency == null || newActionTrackerRow.Frequency == "")) {
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select recurring frequency.']);
                errormsglngth.push('Please select recurring frequency.')
            }
            if (isSubmitVal && (newActionTrackerRow.RecurringStartDate == null || newActionTrackerRow.RecurringStartDate == "")) {
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select recurring start date.']);
                errormsglngth.push('Please select recurring start date.')
            }
            if (isSubmitVal && (newActionTrackerRow.RecurringEndDate == null || newActionTrackerRow.RecurringEndDate == "")) {
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select recurring end date.']);
                errormsglngth.push('Please select recurring end date.')
            }
            if (isSubmitVal && (newActionTrackerRow.Frequency != null || newActionTrackerRow.Frequency != "")) {
                if(newActionTrackerRow.Frequency == 2){
                    if(newActionTrackerRow.RecurringOnDay == null || newActionTrackerRow.RecurringOnDay == ""){

                        setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select recurring on day.']);
                        errormsglngth.push('Please select recurring on day.')
                    }
                }
            }
        }
        if (isSubmitVal && (newActionTrackerRow.Status1 == null || newActionTrackerRow.Status1 == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select status1.']);
            errormsglngth.push('Please select status1.')
        }
        if (isSubmitVal && (newActionTrackerRow.Status1 != null && newActionTrackerRow.Status1 != "")){
            if(newActionTrackerRow.Status1 == 3){
                if (isSubmitVal && (newActionTrackerRow.Completion == null || newActionTrackerRow.Completion == "")) {
                    setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select completion.']);
                    errormsglngth.push('Please select completion.')
                }
            }
        }
        // if (isSubmitVal && (newActionTrackerRow.Completion == null || newActionTrackerRow.Completion == "")) {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter completion.']);
        //     errormsglngth.push('Please enter completion.')
        // }
        if ((newActionTrackerRow.ActualStartDate != null && newActionTrackerRow.ActualStartDate != "") && (newActionTrackerRow.ActualFinishDate != null && newActionTrackerRow.ActualFinishDate != "")) {
            const startDateObj1 = new Date(newActionTrackerRow.ActualStartDate);
            const dueDateObj1= new Date(newActionTrackerRow.ActualFinishDate);

            // Compare the dates
            if (dueDateObj1 >= startDateObj1) {
                // DueDate is greater than or equal to StartDate
                
            } else {
                // DueDate is less than StartDate
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Actual start date cannot be greater than actual finish date.']);
                errormsglngth.push('Actual start date cannot be greater than actual finish date.')
            }
        }
        if (isSubmitVal && (newActionTrackerRow.Remark == null || newActionTrackerRow.Remark == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter remark.']);
            errormsglngth.push('Please enter remark.')
        }

        if (errormsglngth.length > 0) {
            setshowErrorMsg(true)
            return
        }

        if(errormsglngth.length == 0){
            addActionTracker(isSubmitVal)
        }
    };
    

    const addActionTracker  = (isSubmitVal) => {
        
        setIsLoading(true)
        const API_URL = 'api/createOrUpdateActionTracker';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            ID: null,
            Code: "",
            ModuleType : "ActionTracker",
            AssetProject : individualprojectdetails.ID,
            MilestoneID : GlobalMilestoneIDForActionTracker,
            ...newActionTrackerRow,
            Recurring : newActionTrackerRow.Recurring == 1 ? true : newActionTrackerRow.Recurring == 2 ? false :  false,
            RecurringStartDateValue : newActionTrackerRow.RecurringStartDate != null && newActionTrackerRow.RecurringStartDate != '' ? formatDtaeForrecurring(newActionTrackerRow.RecurringStartDate): null,
            RecurringEndDateValue : newActionTrackerRow.RecurringEndDate != null && newActionTrackerRow.RecurringEndDate != '' ? formatDtaeForrecurring(newActionTrackerRow.RecurringEndDate): null,
            Entity : individualprojectdetails.EntityID,
            IsSubmit: isSubmitVal,
            CreatedOn: new Date(),
            CreatedBy: userData['UserId'],
            UpdatedOn: new Date(),
            UpdatedBy: userData['UserId'],
            StatusCodeId: 1
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data ) {

                    if(response && response.data && response.data.Status && response.data.Status == 1){
                        setIsLoading(false)
                        setshowDependencyCompleteAlert(true)
                        setshowDependencyCompleteAlertMsg(response.data.Message)
                    }
                    else{
                        setNewActionTrackerRow(null);
                        openActionTrackermodal(GlobalMilestoneIDForActionTracker)
                        getMileStoneByProjectID(individualprojectdetails.ID)
                        setIsLoading(false)
                        addFiles(response.data.actionTrackerId,response.data.historyId,'action_tracker',isSubmitVal,0)
                    }
                }
                else {
                    setNewActionTrackerRow(null);
                    openActionTrackermodal(GlobalMilestoneIDForActionTracker)
                    getMileStoneByProjectID(individualprojectdetails.ID)
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setNewActionTrackerRow(null);
                closeaddorupdateProjectPopup()
                setIsLoading(false)
                console.error(error);
            });
    }

    const handleEditActionTrackerRow = (index,outerIndex) => {
        // FinalMilestoneDetails[outerIndex].ActionTrackerlist
        setEditingActionTrackerRow(index);
        setEditingActionTrackerOuterRow(outerIndex);
    };

    const handleSaveEditActionTrackerRow = (index,editID,isSubmitVal,AssetProject,Entity,indexMilestone) => {
        // var updatedData  = 
        const updatedData = [...FinalMilestoneDetails[indexMilestone].ActionTrackerlist];
        setActionTrackerData(updatedData);
        console.log('-------------------------------------------------->>>>>>>ACTDTA--->>>',updatedData,editingActionTrackerRow);
        var errormsglngth = []
        if (isSubmitVal && (updatedData[editingActionTrackerRow].Category == null || updatedData[editingActionTrackerRow].Category == "" || updatedData[editingActionTrackerRow].Category == 0)) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select category.']);
            errormsglngth.push('Please select category.')
        }
        if (isSubmitVal && (updatedData[editingActionTrackerRow].Priority == null || updatedData[editingActionTrackerRow].Priority == "" || updatedData[editingActionTrackerRow].Priority == 0)) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select priority.']);
            errormsglngth.push('Please select priority.')
        }
        if (isSubmitVal && (updatedData[editingActionTrackerRow].Status1 == null || updatedData[editingActionTrackerRow].Status1 == "" || updatedData[editingActionTrackerRow].Status1 == 0)) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select update status.']);
            errormsglngth.push('Please select update status.')
        }
        if (isSubmitVal && (updatedData[editingActionTrackerRow].ResponsibleDoer == null || updatedData[editingActionTrackerRow].ResponsibleDoer == "" || updatedData[editingActionTrackerRow].ResponsibleDoer == 0)) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select responsible doer.']);
            errormsglngth.push('Please select responsible doer.')
        }
        if (isSubmitVal && (updatedData[editingActionTrackerRow].StartDate == null || updatedData[editingActionTrackerRow].StartDate == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter start date.']);
            errormsglngth.push('Please select start date.')
        }
        if (isSubmitVal && (updatedData[editingActionTrackerRow].DueDate == null || updatedData[editingActionTrackerRow].DueDate == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter due date.']);
            errormsglngth.push('Please select due date.')
        }
        if ((updatedData[editingActionTrackerRow].StartDate != null && updatedData[editingActionTrackerRow].StartDate != "") && (updatedData[editingActionTrackerRow].DueDate != null && updatedData[editingActionTrackerRow].DueDate != "") ){
            const startDateObj = new Date(updatedData[editingActionTrackerRow].StartDate);
            const dueDateObj = new Date(updatedData[editingActionTrackerRow].DueDate);

            // Compare the dates
            if (dueDateObj >= startDateObj) {
                // DueDate is greater than or equal to StartDate
                
            } else {
                // DueDate is less than StartDate
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Start date cannot be greater than due date.']);
                errormsglngth.push('Start date cannot be greater than due date.')
            }
        }

        if (isSubmitVal && (updatedData[editingActionTrackerRow].RefMeeting == null || updatedData[editingActionTrackerRow].RefMeeting == "")) {
            setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter ref. meeting.']);
            errormsglngth.push('Please enter ref. meeting.')
        }
     

        // if (isSubmitVal && (updatedData[editingActionTrackerRow].AssociatedAction == null || updatedData[editingActionTrackerRow].AssociatedAction == "")) {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter associated action.']);
        //     errormsglngth.push('Please enter associated action.')
        // }
        // if (isSubmitVal && (updatedData[editingActionTrackerRow].SubAction == null || updatedData[editingActionTrackerRow].SubAction == "")) {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter sub action.']);
        //     errormsglngth.push('Please enter sub action.')
        // }
   
       
        
        // if (isSubmitVal && (updatedData[editingActionTrackerRow].Accountable == null || updatedData[editingActionTrackerRow].Accountable == "")) {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter accountable.']);
        //     errormsglngth.push('Please enter accountable.')
        // }
        // if (isSubmitVal && (updatedData[editingActionTrackerRow].Consult == null || updatedData[editingActionTrackerRow].Consult == "")) {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter consult.']);
        //     errormsglngth.push('Please enter consult.')
        // }
        // if (isSubmitVal && (updatedData[editingActionTrackerRow].Inform == null || updatedData[editingActionTrackerRow].Inform == "")) {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter inform.']);
        //     errormsglngth.push('Please enter inform.')
        // }
     
        // if (isSubmitVal && (updatedData[editingActionTrackerRow].Duration == null || updatedData[editingActionTrackerRow].Duration == "")) {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter duration.']);
        //     errormsglngth.push('Please enter duration.')
        // }
        // if (isSubmitVal && (updatedData[editingActionTrackerRow].EsimatedEfforts == null || updatedData[editingActionTrackerRow].EsimatedEfforts == "")) {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter estimated efforts.']);
        //     errormsglngth.push('Please enter estimated efforts.')
        // }
        // if (updatedData[editingActionTrackerRow].EsimatedEfforts != null && updatedData[editingActionTrackerRow].EsimatedEfforts != ""){
        //     if( updatedData[editingActionTrackerRow].EsimatedEfforts>=0 ){

        //     }
        //     else{
        //         setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter a valid estimated efforts.']);
        //         errormsglngth.push('Please enter a valid estimated efforts.')
        //     }
        // }
        // if (updatedData[editingActionTrackerRow].ActionDescription == null || updatedData[editingActionTrackerRow].ActionDescription == "") {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter action description.']);
        //     errormsglngth.push('Please enter action description.')
        // }
        // if (isSubmitVal && (updatedData[editingActionTrackerRow].Recurring == null || updatedData[editingActionTrackerRow].Recurring == "")) {
        //     if(updatedData[editingActionTrackerRow].Recurring == false){

        //     }
        //     else{
        //         setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select recurring.']);
        //         errormsglngth.push('Please select recurring.')
        //     }
        // }
        // if (isSubmitVal && ((updatedData[editingActionTrackerRow].Recurring != null || updatedData[editingActionTrackerRow].Recurring != "") &&  updatedData[editingActionTrackerRow].Recurring == 1))  {
        //     if (isSubmitVal && (updatedData[editingActionTrackerRow].Frequency == null || updatedData[editingActionTrackerRow].Frequency == "")) {
        //         setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select recurring frequency.']);
        //         errormsglngth.push('Please select recurring frequency.')
        //     }
        //     if (isSubmitVal && (updatedData[editingActionTrackerRow].RecurringStartDate == null || updatedData[editingActionTrackerRow].RecurringStartDate == "")) {
        //         setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select recurring start date.']);
        //         errormsglngth.push('Please select recurring start date.')
        //     }
        //     if (isSubmitVal && (updatedData[editingActionTrackerRow].RecurringEndDate == null || updatedData[editingActionTrackerRow].RecurringEndDate == "")) {
        //         setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select recurring end date.']);
        //         errormsglngth.push('Please select recurring end date.')
        //     }
        //     if (isSubmitVal && (updatedData[editingActionTrackerRow].Frequency != null || updatedData[editingActionTrackerRow].Frequency != "")) {
        //         if(updatedData[editingActionTrackerRow].Frequency == 2){
        //             if(updatedData[editingActionTrackerRow].RecurringOnDay == null || updatedData[editingActionTrackerRow].RecurringOnDay == ""){

        //                 setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select recurring on day.']);
        //                 errormsglngth.push('Please select recurring on day.')
        //             }
        //         }
        //     }
        // }
    
        // if (isSubmitVal && (updatedData[editingActionTrackerRow].Status1 != null && updatedData[editingActionTrackerRow].Status1 != "")){
        //     if(updatedData[editingActionTrackerRow].Status1 == 3){
        //         if (isSubmitVal && (updatedData[editingActionTrackerRow].Completion == null || updatedData[editingActionTrackerRow].Completion == "")) {
        //             setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please select completion.']);
        //             errormsglngth.push('Please select completion.')
        //         }
        //     }
        // }
        
        if ((updatedData[editingActionTrackerRow].ActualStartDate != null && updatedData[editingActionTrackerRow].ActualStartDate != "") && (updatedData[editingActionTrackerRow].ActualFinishDate != null && updatedData[editingActionTrackerRow].ActualFinishDate != "")) {
            const startDateObj1 = new Date(updatedData[editingActionTrackerRow].ActualStartDate);
            const dueDateObj1= new Date(updatedData[editingActionTrackerRow].ActualFinishDate);

            // Compare the dates
            if (dueDateObj1 >= startDateObj1) {
                // DueDate is greater than or equal to StartDate
                
            } else {
                // DueDate is less than StartDate
                setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Actual start date cannot be greater than actual finish date.']);
                errormsglngth.push('Actual start date cannot be greater than actual finish date.')
            }
        }
        
        // if (isSubmitVal && (updatedData[editingActionTrackerRow].Remark == null || updatedData[editingActionTrackerRow].Remark == "")) {
        //     setErrorMsg(prevErrorMsg => [...prevErrorMsg, 'Please enter remark.']);
        //     errormsglngth.push('Please enter remark.')
        // }

        if (errormsglngth.length > 0) {
            setshowErrorMsg(true)
            return
        }

        if(errormsglngth.length == 0){
            
            setIsLoading(true)
            const API_URL = 'api/createOrUpdateActionTracker';
            const req_body = {
                UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
                ID: editID,
                // Code: "",
                ModuleType : "ActionTracker",
                AssetProject : AssetProject,
                MilestoneID : GlobalMilestoneIDForActionTracker,
                ...updatedData[editingActionTrackerRow],
                Recurring : updatedData[editingActionTrackerRow].Recurring == 1 ? true : updatedData[editingActionTrackerRow].Recurring ? false : false,
                RecurringStartDateValue : updatedData[editingActionTrackerRow].RecurringStartDate != null && updatedData[editingActionTrackerRow].RecurringStartDate != '' ? formatDtaeForrecurring(updatedData[editingActionTrackerRow].RecurringStartDate): null,
                RecurringEndDateValue : updatedData[editingActionTrackerRow].RecurringEndDate != null && updatedData[editingActionTrackerRow].RecurringEndDate != '' ? formatDtaeForrecurring(updatedData[editingActionTrackerRow].RecurringEndDate): null,
                Entity :Entity,
                IsSubmit: isSubmitVal,
                CreatedOn: new Date(),
                CreatedBy: userData['UserId'],
                UpdatedOn: new Date(),
                UpdatedBy: userData['UserId'],
                StatusCodeId: 1
            };
            api.post(API_URL, req_body, {
                'Content-Type': 'application/json'
            })
                .then((response) => {
                    if (response && response.data ) {
                        if(response && response.data && response.data.Status && response.data.Status == 1){
                            setIsLoading(false)
                            setshowDependencyCompleteAlert(true)
                            setshowDependencyCompleteAlertMsg(response.data.Message)
                        }
                        else{
                            setEditingActionTrackerRow(null);
                            openActionTrackermodal(GlobalMilestoneIDForActionTracker)
                            getProjectDetailsByUserID()
                            addFiles(response.data.actionTrackerId,response.data.historyId,'action_tracker',isSubmitVal,0)
                            setIsLoading(false)
                        }
                    }
                    else {
                        setEditingActionTrackerRow(null);
                        openActionTrackermodal(GlobalMilestoneIDForActionTracker)
                        getProjectDetailsByUserID()
                        setIsLoading(false)
                    }
                })
                .catch((error) => {
                    setEditingActionTrackerRow(null);
                    closeaddorupdateProjectPopup()
                    setIsLoading(false)
                    console.error(error);
                });
        }
    };

    const handleActionTrackerChange = (e, index, isNew, outerIndex) => {
        
        const { name, value } = e.target;
        if (isNew) {
            setNewActionTrackerRow({ ...newActionTrackerRow, [name]: value });
        } else {
            
            // var updatedData  = 
            // const updatedData = [...FinalMilestoneDetails[outerIndex].ActionTrackerlist];
            // console.log('------------------------------------------ACTION TRACKER EDIT BEFORE---------------------------->',updatedData);
            // updatedData[index][name] = value;
            // console.log('------------------------------------------ACTION TRACKER EDIT AFTER---------------------------->',updatedData);
            // setActionTrackerData(updatedData);
            // Handling for editing existing Action Tracker rows
            const updatedData = [...FinalMilestoneDetails[outerIndex].ActionTrackerlist];
            const updatedRow = { ...updatedData[index], [name]: value };

            if (name === 'ActualStartDate' && new Date(value) > new Date(updatedRow.ActualFinishDate)) {
                updatedRow.ActualFinishDate = '';
            }
            if (name === 'ActualFinishDate' && new Date(value) < new Date(updatedRow.ActualStartDate)) {
                updatedRow.ActualStartDate = '';
            }

            updatedData[index] = updatedRow;

            // Updating the FinalMilestoneDetails array with the new ActionTrackerlist
            const updatedMilestoneDetails = [...FinalMilestoneDetails];
            updatedMilestoneDetails[outerIndex].ActionTrackerlist = updatedData;

            // Updating the state with the modified data
            setFinalMilestoneDetails(updatedMilestoneDetails);
        }
    };

    const handleCloseAction = (type) => {
        if (type) {
            setDraftFileList([])
            setNewActionTrackerRow(null)
        } else {
            setDraftFileList([])
            setEditingActionTrackerRow(null);
            setEditingActionTrackerOuterRow(null);
            getProjectDetailsByUserID()
        }
    };

    const DeletedraftedAction = (ActionTrackerID) => {
        setActionTrtackerIDForDraftDeletion(ActionTrackerID)
        setActionTrtackerIDForDraftDeletionModal(true)
    }

    const CloseDeletedraftedAction = (ActionTrackerID) => {
        setActionTrtackerIDForDraftDeletion(null)
        setActionTrtackerIDForDraftDeletionModal(false)

    }

    const DeletedraftedActionAPI = (ActionTrackerID) => {
        setIsLoading(true)
        const API_URL = 'api/deleteDraftedActionTracker';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            ActionTrackerId : ActionTrackerID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    openActionTrackermodal(GlobalMilestoneIDForActionTracker)
                    setActionTrtackerIDForDraftDeletion(null)
                    setActionTrtackerIDForDraftDeletionModal(false)

                    setIsLoading(false)
                    
                }
                else {
                    setActionTrtackerIDForDraftDeletion(null)
                    setActionTrtackerIDForDraftDeletionModal(false)

                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

  

    const setSelectValue = (list, value) => {
        if (Array.isArray(list)) {
            return list.find((data) => {
                return data.label === value;
            });
        }
        return null; // or handle the case when list is not an array
    };

    const OpenActionTrackerHistory = (ActionTrackerID) => {
        setIsLoading(true)
        const API_URL = 'api/getAllHistoryOfActionTracker';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            ActionTrackerId : ActionTrackerID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setHitoryDetailsList(response.data.History)
                    setshowActionTrackerHistory(true)
                    setActionTrackerID(ActionTrackerID)
                    setIsLoading(false)
                    
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const CloseActionTrackerHistory = () => {
        setHitoryDetailsList([])
        setshowActionTrackerHistory(false)
        setActionTrackerID(null)
    }

    const addCommentFromActionTrackerHistory = (ActionTrackerID,AssignedToUserId) => {
        setIsLoading(true)
        const API_URL = 'api/addCommentFromActionTrackerHistory';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            ActionTrackerId : ActionTrackerID,
            ActionByUserId : userData && 'UserId' in userData ? userData['UserId'] : "",
            AssignedToUserId : AssignedToUserId,
            Remarks : ATHistoryComment,
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setshowActionTrackerHistory(false)
                    CloseActionTrackerHistory()
                    setActionTrackerID(null)
                    setHitoryDetailsList([])
                    setATHistoryComment('')
                    setIsLoading(false)
                    OpenActionTrackerHistory(ActionTrackerID)
                }
                else {
                    setshowActionTrackerHistory(false)
                    CloseActionTrackerHistory()
                    setActionTrackerID(null)
                    setHitoryDetailsList([])
                    setATHistoryComment('')
                    setIsLoading(false)
                    OpenActionTrackerHistory(ActionTrackerID)

                }
            })
            .catch((error) => {
                setshowActionTrackerHistory(false)
                CloseActionTrackerHistory()
                setActionTrackerID(null)
                setHitoryDetailsList([])
                setATHistoryComment('')
                setIsLoading(false)
                console.error(error);
            });
    }


    const OpenProjectComments = (ProjectID) => {
        
        setIsLoading(true)
        const API_URL = 'api/getProjectCommentDetails';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            ProjectId : ProjectID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setChatDetails(response.data.Comments)
                    setshowProjectComments(true)
                    setProjectCommentsText('')
                    setIsLoading(false)
                    if (chatBodyRef.current) {
                        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
                    }
                    
                }
                else {
                    setshowProjectComments(true)
                    setProjectCommentsText('')
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const CloseProjectComments = () => {
        setshowProjectComments(false)
        setProjectCommentsText('')
        setChatDetails([])

    }

    const AddProjectComments = (ProjectID) => {
        setIsLoading(true)
        const API_URL = 'api/addProjectCommentDetails';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            ProjectId : ProjectID,
            ProjectComments : ProjectCommentsText,
            AssignedToUserId : individualprojectdetails.ResponsibleDoer,
            ActionByUserId :  userData && 'UserId' in userData ? userData['UserId'] : ""
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setshowProjectComments(false)
                    setProjectCommentsText('')
                    setChatDetails([])
                    setIsLoading(false)
                    OpenProjectComments(ProjectID)
                    
                }
                else {
                    setshowProjectComments(false)
                    setProjectCommentsText('')
                    setChatDetails([])
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setshowProjectComments(false)
                setProjectCommentsText('')
                setChatDetails([])
                setIsLoading(false)
                console.error(error);
            });
    }


    const OpenMilestoneComments = (MilestoneID,assignedUser) => {
        
        setMileStoneIDForChat(MilestoneID)
        setMileStoneIDForChatAssignedTo(assignedUser)
        setIsLoading(true)
        const API_URL = 'api/getMilestoneCommentDetails';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            MilestoneId : MilestoneID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setMilestoneChatDetails(response.data.Comments)
                    setshowMilestoneComments(true)
                    setMilestoneCommentsText('')
                    setIsLoading(false)
                    
                }
                else {
                    setshowMilestoneComments(true)
                    setMilestoneCommentsText('')
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const CloseMilestoneComments = () => {
        setMileStoneIDForChat(null)
        setMileStoneIDForChatAssignedTo(null)
        setshowMilestoneComments(false)
        setMilestoneCommentsText('')
        setMilestoneChatDetails([])

    }

    const AddMilestoneComments = (MilestoneID,AssignedToUserIdChat) => {
        setIsLoading(true)
        const API_URL = 'api/addMilestoneCommentDetails';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            MilestoneId : MilestoneID,
            MilestoneComments : MilestoneCommentsText,
            AssignedToUserId : AssignedToUserIdChat,//Need To change
            ActionByUserId :  userData && 'UserId' in userData ? userData['UserId'] : ""
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setMileStoneIDForChat(null)
                    setMileStoneIDForChatAssignedTo(null)
                    setshowMilestoneComments(false)
                    setMilestoneCommentsText('')
                    setMilestoneChatDetails([])
                    setIsLoading(false)
                    OpenMilestoneComments(MilestoneID)
                    
                }
                else {
                    setMileStoneIDForChat(null)
                    setMileStoneIDForChatAssignedTo(null)
                    setshowMilestoneComments(false)
                    setMilestoneCommentsText('')
                    setMilestoneChatDetails([])
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setMileStoneIDForChat(null)
                setMileStoneIDForChatAssignedTo(null)
                setshowMilestoneComments(false)
                setMilestoneCommentsText('')
                setMilestoneChatDetails([])
                setIsLoading(false)
                console.error(error);
            });
    }

    const getAllFileDetailsbyHistoryId = (historyId,isAT = false,isEdit = false) => {
        setIsLoading(true)
        const API_URL = 'api/getAllUploadedFiles/';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            historyId : isAT ? 0 : historyId,
            solutionOrTrackerId : isAT ? historyId: 0,
            type : 'action_tracker',
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    
                    if(isEdit){
                        setSavedFileList(response.data)
                        if(response.data.length + draftFileList.length=== 1)
                            {
                                if( draftFileList.length=== 1){
                                    openSavedFileViewerPopup(draftFileList[0],false)
                                }
                                else{
                                    openSavedFileViewerPopup(response.data[0],true)
                                }
                            }
                        else{
                            setIsFileViewerOpen(true)
                        }
                    }
                    else{

                        if(response.data.length === 1)
                        {
                            openSavedFileViewerPopup(response.data[0],true)
                        }
                        else{
                            setisFileViewerHistoryList(response.data)
                            setisFileViewerOpenHIstory(true)
    
                        }
                    }

                    setIsLoading(false)
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }
    const deleteUploadedFile = (fileId) => {
        setIsLoading(true)
        const API_URL = 'api/deleteUploadedFile/';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            fileId : fileId
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setSavedFileList([])
                    setIsFileViewerOpen(false)
                    setIsLoading(false)
                }
                else {
                    setSavedFileList([])
                    setIsFileViewerOpen(false)
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    //File Upload code
    const [errorMessages, setErrorMessages] = useState([]);
    const [draftFileList, setDraftFileList] = useState([]);
    const [totalFileCount, setTotalFileCount] = useState(0);
    const fileInputRef = useRef(null);

    const [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
    const [isFileViewerOpenModal, setisFileViewerOpenModal] = useState(false);
        
    
    const openFileSelectionPopUp = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            fileInputRef.current.click();
        }
    };

    const setFilesData = (event) => {
        const files = event.target.files;
        let tempDraftFileList = [...draftFileList];
        let tempTotalFileCount = totalFileCount;

        for (let i = 0; i < files.length; i++) {
            if (files[i].size > 16000000) {
                setErrorMessages(['File cannot upload, size is more than 15MB.']);
                alert(errorMessages[0]); // Open error message popup
            } else {
                const fileData = files[i];
                const reader = new FileReader();
                reader.onload = () => {
                    const typeResult = `${fileData.type.split("/")[0]}/*`;
                    tempDraftFileList.push({
                        name: fileData.name,
                        imageSrc: reader.result,
                        type: fileData.type,
                        fileDataDetail: fileData,
                        id: Date.now(), // unique ID, you can replace with your own logic
                        FileType: typeResult,
                        MOCType: event.target.id
                    });
                    tempTotalFileCount++;
                    setDraftFileList(tempDraftFileList);
                    setTotalFileCount(tempTotalFileCount);
                };
                reader.readAsDataURL(fileData);
            }
        }
    }

    const openFileViewerPopup = () => {
        setIsFileViewerOpen(true);
    }

    //Download file
    const downloadFile = (fileData) => {
        const filePath = fileData.imageSrc;
        const a = document.createElement('a');
        a.setAttribute('href', filePath);
        a.setAttribute('download', fileData.name);
        a.setAttribute('target', '_blank');
        a.click();
    };

    // const downloadFileHistory = (fileData) => {
    //     const filePath = fileData.Filepath;
    //     const a = document.createElement('a');
    //     a.setAttribute('href', filePath);
    //     a.setAttribute('download', fileData.FileName);
    //     a.setAttribute('target', '_blank');
    //     a.click();
    // };

    const [isFileError, setIsFileError] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [isPDF, setIsPDF] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [isDownload, setIsDownload] = useState(false);
    const [viewedFileName, setViewedFileName] = useState('');
    const [filePath, setFilePath] = useState('');
    const [CompleteFileData, setCompleteFileData] = useState(null);
    const [selectedFileOnserver, setselectedFileOnserver] = useState(false);


    const openSavedFileViewerPopup = (fileData,Onserver=false) => {
        setselectedFileOnserver(Onserver)

        setCompleteFileData(fileData)
        
        setIsFileError(false);
        setIsImage(false);
        setIsPDF(false);
        setIsVideo(false);
        setViewedFileName(Onserver ? fileData.FileName  : fileData.name);
        
        const fileName = Onserver ? fileData.FileName : fileData.name;
        let extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
        setisFileViewerOpenModal(true)
        
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);

        if (['jpeg', 'jpg', 'png', 'pdf', 'mp4'].includes(extension)) {
            switch (extension) {
                case 'pdf':
                    if (check) {
                        setIsDownload(true);
                    } else {
                        setIsPDF(true);
                        setFilePath(Onserver ? fileData.Filepath :  fileData.imageSrc);
                    }
                    break;
                case 'jpg':
                case 'png':
                case 'jpeg':
                    setIsImage(true);
                    setFilePath(Onserver ? fileData.Filepath :  fileData.imageSrc);
                    break;
                case 'mp4':
                    setIsVideo(true);
                    setFilePath(Onserver ? fileData.Filepath :  fileData.imageSrc);
                    break;
                default:
                    break;
            }

            if (isDownload) {
                setIsFileError(true);
            } else {
                setIsFileError(false);
            }

            // Assuming you are using Bootstrap for modal
            // document.getElementById('filePopUp').style.display = 'block';
            
        } else {
            setIsFileError(true);
            // document.getElementById('filePopUp').style.display = 'block';
        }
    }

    const deleteDraftUploadedFile = (index) => {
        const newDraftFileList = [...draftFileList];
        newDraftFileList.splice(index, 1);
        setDraftFileList(newDraftFileList);
        setTotalFileCount(newDraftFileList.length);
        // setTotalFileCount(newDraftFileList.length + savedFileList.length);
    };


    const addFiles = async (ATId = 0, historyId = 0, actionType, isSubmitted = true, solutionOrTrackerId = 0) => {
        setIsLoading(true)
        
        const fileUploadData = new FormData();
        draftFileList.forEach((file, i) => {
        fileUploadData.append(`file${i}`, file.fileDataDetail);
        });
        fileUploadData.append('ActionTrackerId', ATId);
        fileUploadData.append('fileCount', draftFileList.length);
        fileUploadData.append('UserId', userData['UserId']);
        fileUploadData.append('Type', actionType);
        fileUploadData.append('IsSubmitted', isSubmitted);
        fileUploadData.append('SolutionId', solutionOrTrackerId);
        fileUploadData.append('historyId', historyId);
        const API_URL = 'api/uploadFiles';
        api.post(API_URL, fileUploadData, {
            'Content-Type': 'multipart/form-data'
        })
    
        .then((response) => {
            
            if (response && response.data) {
                setDraftFileList([])
                setTotalFileCount(0)
                setIsLoading(false)
                
            }
            else {
                setDraftFileList([])
                setTotalFileCount(0)
                setIsLoading(false)
            }
        })
        .catch((error) => {
            setIsLoading(false)
            console.error(error);
        });;
        }




    // const downloadFileAPI = async (fileUrl) => {
    //     try {
    //         const response = await axios.get(fileUrl, {
    //             responseType: 'arraybuffer',
    //             headers: { 'Content-Type': undefined }
    //         });
    //         return response;
    //     } catch (error) {
    //         console.error('Error downloading the file:', error);
    //         throw error;
    //     }
    // };

    const downloadFileAPI = async (fileUrl, fileName, Onserver) => {
        
        try {

            if (Onserver == true)

            {
                const API_URL = 'api/proxy-download';
                const response = await api.get(API_URL, {
                    params: { fileUrl: fileUrl },
                    responseType: 'blob', // Important to get the response as a Blob
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    }
                });
                return response
            }
            else 
            { 
                const response = await axios.get(fileUrl, {
                    responseType: 'arraybuffer',
                    headers: { 'Content-Type': undefined }
                });
                return response;
            }
         
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const handleDownloadFile = async (path, name , Onserver=false) => {
        
        var path1 =""
        if (Onserver != true) {
            path1 = path.imageSrc;
            name = path.name;
        } else {
            path1 = `${path}?fileName=${name}`;
        }

        try {
            const response = await downloadFileAPI(path1,name,Onserver);

            const data = response.data;
            const contentType = response.headers['content-type'] || 'application/octet-stream';
            const blob = new Blob([data], { type: contentType });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = name;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Something went wrong while downloading the file...');
            // Handle the error appropriately in your application
        }
    };


    const setFileViewerClose = () => {
        setCompleteFileData(null)
        setViewedFileName('')
        setselectedFileOnserver(false)
        setisFileViewerOpenModal(false)
        
    } 

    const disableEntity = (editProject,val) => {

        if(editProject){
            if(val && val.IsSubmit){
                return true
            }
            else{
                return false
            }
        }
        else{
            return false
        }

    }

    const cancelProjectDeletion  = () => {
        setdeleteProjectID(null)
        setshowConfirmationModalForDeleteProject(false)
    }

    const DeletedraftedProject = (projectID) => {
        setdeleteProjectID(projectID)
        setshowConfirmationModalForDeleteProject(true)

    }

    const deleteProjectSavedInDraft = (projectID) => {
        setIsLoading(true)
        const API_URL = 'api/deleteDraftedProject';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            ProjectId : projectID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setdeleteProjectID(null)
                    setshowConfirmationModalForDeleteProject(false)
                    getProjectDetailsByUserID()
                    setIsLoading(false)
                    
                }
                else {
                    setdeleteProjectID(null)
                    setshowConfirmationModalForDeleteProject(false)
                    getProjectDetailsByUserID()
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setdeleteProjectID(null)
                setshowConfirmationModalForDeleteProject(false)
                setIsLoading(false)
                console.error(error);
            });
    }


    const DownloadProjectReport = () =>{ 
      const fileUrl = BASE_URL+'/ProjectSampleReport.pdf';
      const newWindow = window.open(fileUrl, '_blank');
      // Close the window after a short delay to allow download to start
    //   setTimeout(() => {
    //       newWindow.close();
    //   }, 300);
    }
    // const DownloadProjectReport = async () => {
    //     const fileUrl = BASE_URL+'/ProjectSampleReport.pdf';
    //     const fileName = fileUrl.split('/').pop(); // Extract the file name from the URL

    //     try {
    //         const response = await fetch(fileUrl);
    //         const blob = await response.blob();
    //         const url = window.URL.createObjectURL(blob);
    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = fileName; // Set the extracted file name
    //         document.body.appendChild(a);
    //         a.click();
    //         a.remove(); // Clean up by removing the anchor element
    //         window.URL.revokeObjectURL(url);
    //     } catch (error) {
    //         console.error('Error downloading the file', error);
    //     }
    // };
    return (
        <div className="LoginScreen container-fluid">
            <ActionTrackerHeader onLogout={logOutEvent} hide={false} onCompChange={setData} 
                selectedmilestoneFilter={selectedmilestoneFilter} selectedStatus={selectedStatus} 
                selectedEntity={selectedEntity} setselectedmilestoneFilter={setselectedmilestoneFilter}  
                setselectedEntity={setselectedEntity} setselectedProject={setselectedProject} 
                setselectedStatus={setselectedStatus} setEntityList={setEntityList} 
                EntityList={EntityList} 
                setProjectList={setProjectList} ProjectList={ProjectList} 
                setTotalMilestoneListForAT={setTotalMilestoneListForAT} TotalMilestoneListForAT={TotalMilestoneListForAT} 
                setTotalProjectsAssignedToUserForAT={setTotalProjectsAssignedToUserForAT} TotalProjectsAssignedToUserForAT={TotalProjectsAssignedToUserForAT} 
                setProjectStatusList={setProjectStatusList} 
                ProjectStatusList={ProjectStatusList} 
                OpenActionTrackerHistory={OpenActionTrackerHistory} 
                OpenProjectComments={OpenProjectComments} 
                OpenMilestoneComments={OpenMilestoneComments} 
                setTotalMileStoneList={setTotalMileStoneList} TotalMileStoneList={TotalMileStoneList}
                setTotalActionTrackerListAssignedToUser={setTotalActionTrackerListAssignedToUser} TotalActionTrackerListAssignedToUser={TotalActionTrackerListAssignedToUser}
                actiontrackerFilter={actiontrackerFilter} setactiontrackerFilter={setactiontrackerFilter}/>

            {showConfirmationModalForDeleteMilestone && (
                    <div className="popup">
                        <div className="popup-inner">
                            <div className='row mb-3'>

                                <div className='col-12 text-center'>
                                    <p style={{fontSize:'20px', color:'#21A8E1'}}>Information</p>
                                </div>
                            </div>
                            <form>
                                <label className='w-100 mb-3' style={{textAlign:'center'}}>
                                    Are you sure you want to delete the milestone.
                                </label>
                                <div className='d-flex w-80 justify-content-evenly mt-3'>
                                    <button onClick={() => cancelledMilestonedeletion()} type="button" style={{ fontWeight: 600, border: '1px solid #21A8E1', backgroundColor: 'white', color: '#21A8E1' }} className="px-5">No</button>
                                    <button onClick={() => DeletedraftedMilestone(milestoneDeleteID)} type="button" style={{ fontWeight: 600, border: '1px solid #21A8E1', backgroundColor: '#21A8E1', color: 'white' }} className="px-5">Yes</button>
                                </div>
                            </form>
                        </div>
                    </div>
            )}

            {showConfirmationModalForDeleteProject && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-center'>
                                <p style={{ fontSize: '20px', color:'#21A8E1' }}>Information</p>
                            </div>
                        </div>
                        <form>
                            <label className='w-100 mb-3' style={{ textAlign: 'center' }}>
                                Are you sure you want to delete the project.
                            </label>
                            <div className='d-flex w-80 justify-content-evenly mt-3'>
                                <button onClick={() => cancelProjectDeletion()} type="button" style={{ fontWeight: 600, border: '1px solid #21A8E1', backgroundColor: 'white', color: '#21A8E1' }} className="px-5">No</button>
                                <button onClick={() => deleteProjectSavedInDraft(deleteProjectID)} type="button" style={{ fontWeight: 600, border: '1px solid #21A8E1', backgroundColor: '#21A8E1', color: 'white' }} className="px-5">Yes</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {ActionTrtackerIDForDraftDeletionModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-center'>
                                <p style={{ fontSize: '20px', color:'#21A8E1' }}>Information</p>
                            </div>
                        </div>
                        <form>
                            <label className='w-100 mb-3' style={{ textAlign: 'center' }}>
                                Are you sure you want to delete the action tracker.
                            </label>
                            <div className='d-flex w-80 justify-content-evenly mt-3'>
                                <button onClick={() => CloseDeletedraftedAction()} type="button" style={{ fontWeight: 600, border: '1px solid #21A8E1', backgroundColor: 'white', color: '#21A8E1' }} className="px-5">No</button>
                                <button onClick={() => DeletedraftedActionAPI(ActionTrtackerIDForDraftDeletion)} type="button" style={{ fontWeight: 600, border: '1px solid #21A8E1', backgroundColor: '#21A8E1', color: 'white' }} className="px-5">Yes</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

             {/* AT History File Viewer */}
            {isFileViewerOpenHIstory && (
                <div className="popup" style={{zIndex:5000}}>
                        <div className="popup-inner-xl">
                                <div className='row m-2'>
                                    <div className='d-flex justify-content-between align-items-center col-12 '>
                                        <span style={{fontSize:'20px', color:'#21A8E1'}}>View Attachments</span>
                                        <img title="Close" onClick={() => setisFileViewerOpenHIstory(false)} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.5 rem'}} alt="Close" />
                                    </div>
                                </div>
                                <div>
                                    <hr style={{color:'#21A8E1'}}/>
                            </div>
                            <div className='ChatBodyDetails' style={{ maxHeight: '70vh', minHeight: '70vh', minHeight: 'auto', overflowY: 'auto' }}>
                                {isFileViewerHistoryList.map((item, index) => (
                                    <div className='justify-content-between mb-4' style={{ display: 'flex' }}>
                                        <div className='d-flex gap-2 w-100'>
                                            <div>
                                                {`${index + 1}) `}
                                            </div>
                                            <div className='d-flex justify-content-between gap-2 w-100'>
                                                <div>
                                                    {`${item.FileName}`}
                                                </div>

                                                <div className='d-flex align-items-center gap-2'>
                                                    <img title="View" onClick={() => openSavedFileViewerPopup(item,true)} src={EyeIcon} style={{ height: '17px', marginTop: '2px' }} className="d-flex center cursor-pointer" alt="eye" />
                                                    <img title='Download' onClick={() => handleDownloadFile(item.Filepath,item.FileName,true)} src={DownloadFile} style={{ height: '22px' }} className="cursor-pointer" alt="download" />
                                                    {/* <img onClick={() => deleteDraftUploadedFile(index)} src={DeleteFile} style={{ height: '22px' }} className="cursor-pointer" alt="delete" /> */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                </div>
            )}

            {/* File Viewer */}
            {isFileViewerOpen && (
                    <div className="popup" style={{zIndex:1000}}>
                        <div className="popup-inner-xl">
                                <div className='row m-2'>
                                    <div className='d-flex justify-content-between align-items-center col-12 '>
                                        <span style={{fontSize:'20px', color:'#21A8E1'}}>View Attachments</span>
                                        <img title='Close' onClick={() => setIsFileViewerOpen(false)} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.5 rem'}} alt="Close" />
                                    </div>
                                </div>
                                <div>
                                    <hr style={{color:'#21A8E1'}}/>
                            </div>
                            <div className='ChatBodyDetails' style={{ maxHeight: '70vh', minHeight: '70vh', minHeight: 'auto', overflowY: 'auto' }}>
                                {SavedFileList.map((item, index) => (
                                    <div className='justify-content-between mb-4' style={{ display: 'flex' }}>
                                        <div className='d-flex gap-2 w-100'>
                                            <div>
                                                {`${index + 1}) `}
                                            </div>
                                            <div className='d-flex justify-content-between gap-2 w-100'>
                                                <div>
                                                    {`${item.FileName}`}
                                                </div>

                                                <div className='d-flex align-items-center gap-2'>
                                                    <img title='View' onClick={() => openSavedFileViewerPopup(item,true)} src={EyeIcon} style={{ height: '17px', marginTop: '2px' }} className="d-flex center cursor-pointer" alt="eye" />
                                                    <img title="Download" onClick={() => handleDownloadFile(item.Filepath,item.FileName,true)} src={DownloadFile} style={{ height: '22px' }} className="cursor-pointer" alt="download" />
                                                    <img title="Delete" onClick={() => deleteUploadedFile(item.ID)} src={DeleteFile} style={{ height: '22px' }} className="cursor-pointer" alt="delete" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                                {draftFileList.map((item, index) => (
                                    <div className='justify-content-between mb-4' style={{ display: 'flex' }}>
                                        <div className='d-flex gap-2 w-100'>
                                            <div>
                                                {`${SavedFileList.length + (index + 1)}) `}
                                            </div>
                                            <div className='d-flex justify-content-between gap-2 w-100'>
                                                <div>
                                                    {`${item.name}`}
                                                </div>

                                                <div className='d-flex align-items-center gap-2'>
                                                    <img title='View' onClick={() => openSavedFileViewerPopup(item,false)} src={EyeIcon} style={{ height: '17px', marginTop: '2px' }} className="d-flex center cursor-pointer" alt="eye" />
                                                    <img title='Download' onClick={() => handleDownloadFile(item,null,false)} src={DownloadFile} style={{ height: '22px' }} className="cursor-pointer" alt="download" />
                                                    <img title='Delete' onClick={() => deleteDraftUploadedFile(index)} src={DeleteFile} style={{ height: '22px' }} className="cursor-pointer" alt="delete" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
            )}

            {/* Opened File changes */}
            {isFileViewerOpenModal && (
                <div className="popup" style={{zIndex:6000}}>
                    <div className="popup-inner-xl">
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between align-items-center col-12 '>
                                    <span style={{fontSize:'20px', color:'#21A8E1'}}>File Viewer</span>
                                    <div>
                                        <img
                                            src={DownloadFile}
                                            title="Download"
                                            onClick={() => selectedFileOnserver ? handleDownloadFile(filePath,viewedFileName,true) : handleDownloadFile(CompleteFileData,null,false) }
                                            className="cursor-pointer fileDownloadInFilePopup"
                                            alt="Download"
                                        />
                                        {/* <img
                                            src={DeleteFile}
                                            title="Delete file"
                                            className="cursor-pointer fileDownloadInFilePopup ms-2"
                                            alt="Download"
                                        /> */}
                                        <img title='Close' onClick={() => setFileViewerClose()} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.5 rem'}} alt="Close" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <hr style={{color:'#21A8E1'}}/>
                            </div>
                            <div className='ChatBodyDetails' style={{ maxHeight: '70vh', minHeight: '70vh', minHeight: 'auto', overflowY: 'auto' }}>
                                {/* {isFileError && <div className="alert alert-danger">File Error</div>}
                                {isImage && <img src={filePath} alt="file" />}
                                {isPDF && (
                                <object id="objPDFTyepFile" data={filePath + '#toolbar=0'} type="application/pdf" width="100%" height="500px">
                                    <p>Your browser does not support PDFs.</p>
                                </object>
                                )}
                                {isVideo && <video src={filePath} controls />} */}
                                <div className="container" hidden={isFileError}>
                                    {isImage && (
                                        <div className="row">
                                        <img src={filePath} className="img-fluid" alt="file" />
                                        </div>
                                    )}
                                    {isPDF && (
                                        <div className="row">
                                        <object data={filePath + '#toolbar=0'} type="application/pdf" id="objPDFTyepFile" style={{ height: '66vh' }}>
                                            <p className="object">
                                            Your web browser doesn't have a PDF plugin.
                                            Instead you can <a style={{ color: 'blue', textDecoration: 'underline' }} href={filePath} download>
                                                Click here to download the PDF file.
                                            </a>
                                            </p>
                                        </object>
                                        </div>
                                    )}
                                    {isVideo && (
                                        <div className="row">
                                        <video controls="controls">
                                            <source src={filePath} type="video/mp4" />
                                        </video>
                                        </div>
                                    )}
                                    </div>
                                    <div className="container" hidden={!isFileError}>
                                    <div>
                                        File cannot be viewed. Please download the file.
                                    </div>
                                    </div>
                                </div>
                    </div>
                </div>
            )}

            {/* Error popup */}
            {showErrorMsg && (
                <div className="popup" style={{zIndex:1000000}}>
                    <div className="popup-inner-Chat">
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between align-items-center col-12 '>
                                    <span className='delete-head-text' style={{fontSize:'20px', color:'#21A8E1'}}>Information</span>
                                    <img title="Cancel" onClick={() => closevalidateProjectDetails()} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.8 rem'}} alt="Add Milestone" />
                                </div>
                            </div>
                            <div>
                                {/* <hr style={{color:'#21A8E1'}}/> */}
                            </div>
                            <div className='ChatBodyDetails' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto',overflowY:'auto'}}>
                                {ErrorMsg.map((item, index) => (
                                    <div style={{ display : 'flex'}}>
                                        <div className=' d-flex mb-3' style={{width:'90%'}}>
                                            {`${index+1}) ${item}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* <div className='row m-2'>
                                <div className='d-flex justify-content-between col-12 mb-2'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={ProfIcon} className='cursor-pointer me-1' style={{height:'25px',width:'25px'}} alt="Add Comments" /> 
                                    </div>
                                </div>
                            </div> */}
                                <button 
                                onClick={closevalidateProjectDetails} 
                                style={{
                                    padding: "3px 32px",
                                    fontSize: "15px",
                                    border: "1px solid #21A8E1",
                                    backgroundColor: "white",
                                    margin: "0 10px 15px",
                                    borderRadius: "5px",
                                    float: "right",
                                    cursor: "pointer"
                                }}
                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#21A8E1"}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.backgroundColor = "white";
                                    e.currentTarget.style.color = "inherit";
                                }}
                            >
                                Close
                            </button>       

                    </div>
                </div>
            )}

            {/* Comment chat For Project Screen */}
            {showProjectComments && (
                <div className="popup">
                    <div className="popup-inner-Chat">
                        {/* <div className='mx-2'> */}
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between align-items-center col-12 '>
                                    <span style={{fontSize:'20px', color:'#21A8E1'}}>Comments</span>
                                    <img title="Cancel" onClick={() => CloseProjectComments()} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.8 rem'}} alt="Add Milestone" />
                                </div>
                            </div>
                            <div>
                                <hr style={{color:'#21A8E1'}}/>
                            </div>
                            <div ref={chatBodyRef} className='ChatBodyDetails' style={{maxHeight:'68vh',minHeight:'68vh',overflowY:'auto'}}>
                                {ChatDetails.map((item) => (
                                    <div style={{ display : 'flex',justifyContent : item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start'}}>
                                        <div className=' d-flex mb-4' style={{justifyContent: item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start',width:'90%'}}>
                                            {item.ActionByUserId != userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Add Milestone" /> : null}
                                            <div className='custom-bubblewidth'>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}} className= {item.ActionByUserId == userData['UserId'] ? 'chatbackgroundmyself me-3 w-100' : 'chatbackgroundother me-3 w-100'} >
                                                    {/* <span style={{fontSize:'14px'}}>{item.Comment}</span> */}
                                                    <div  style={{fontSize:'14px'}} dangerouslySetInnerHTML={{ __html:item.Comment.replace(/\n/g, '<br>')}}/>
                                                </div>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}}  className= 'd-flex  justify-content-between me-3 gap-2 w-100'>
                                                    <span style={{fontSize:'12px'}}>{item.ActionByUserIdName}</span>
                                                    <span style={{fontSize:'12px',display:'flex',flexShrink:'0'}}>{formatDateTimeForChat(item.CreatedOn)}</span>
                                                </div>
                                            </div>
                                            {item.ActionByUserId == userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px',marginLeft:'0.7rem'}} alt="Add Milestone" /> : null}
                                        </div>
                                    </div>
                                ))}

                                {ChatDetails.length == 0? <div className='d-flex justify-content-center'>No comments</div>:null}
                            </div>
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between col-12 mb-2'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={ProfIcon} className='cursor-pointer me-1' style={{height:'25px',width:'25px'}} alt="Add Comments" /> 
                                    </div>
                                    <input
                                        style={{width : '90%',padding:'0.4rem'}}
                                        type="text"
                                        name="projectcommenttext"
                                        placeholder='Write a comment'
                                        className='normalSelectStyle'
                                        value={ProjectCommentsText}
                                        onChange={(e) => setProjectCommentsText(e.target.value)}
                                    />
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img title="Send" onClick={() => AddProjectComments(individualprojectdetails.ID)} src={SendChatIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Send Icon" /> 
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            )}

            {/* Comment chat For Milestone Screen */}
            {showMilestoneComments && (
                <div className="popup">
                    <div className="popup-inner-Chat">
                        {/* <div className='mx-2'> */}
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between align-items-center col-12 '>
                                    <span style={{fontSize:'20px', color:'#21A8E1'}}>Comments</span>
                                    <img title="Cancel" onClick={() => CloseMilestoneComments()} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.8 rem'}} alt="Add Milestone" />
                                </div>
                            </div>
                            <div>
                                <hr style={{color:'#21A8E1'}}/>
                            </div>
                            <div className='ChatBodyDetails' style={{maxHeight:'68vh',minHeight:'68vh',overflowY:'auto'}}>
                                {MilestoneChatDetails.map((item) => (
                                    <div style={{ display : 'flex',justifyContent : item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start'}}>
                                        <div className=' d-flex mb-4' style={{justifyContent: item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start',width:'90%'}}>
                                            {item.ActionByUserId != userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Add Milestone" /> : null}
                                            <div className='custom-bubblewidth'>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}} className= {item.ActionByUserId == userData['UserId'] ? 'chatbackgroundmyself me-3 w-100' : 'chatbackgroundother me-3 w-100'}>
                                                    {/* <span style={{fontSize:'14px'}}>{item.Comment}</span> */}
                                                    <div  style={{fontSize:'14px'}} dangerouslySetInnerHTML={{ __html:item.Comment.replace(/\n/g, '<br>')}}/>
                                                </div>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}}  className= 'd-flex  justify-content-between me-3 gap-2  w-100'>
                                                    <span style={{fontSize:'12px'}}>{item.ActionByUserIdName}</span>
                                                    <span style={{fontSize:'12px',display:'flex',flexShrink:'0'}}>{formatDateTimeForChat(item.CreatedOn)}</span>
                                                </div>
                                            </div>
                                            {item.ActionByUserId == userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px',marginLeft:'0.7rem'}} alt="Add Milestone" /> : null}
                                        </div>
                                    </div>
                                ))}
                                {MilestoneChatDetails.length == 0? <div className='d-flex justify-content-center'>No comments</div>:null}
                            </div>
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between col-12 mb-2'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={ProfIcon} className='cursor-pointer me-1' style={{height:'25px',width:'25px'}} alt="Add Comments" /> 
                                    </div>
                                    <input
                                        style={{width : '90%',padding:'0.4rem'}}
                                        type="text"
                                        name="Milestonecommenttext"
                                        placeholder='Write a comment'
                                        className='normalSelectStyle'
                                        value={MilestoneCommentsText}
                                        onChange={(e) => setMilestoneCommentsText(e.target.value)}
                                    />
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img title="Send" onClick={() => AddMilestoneComments(MileStoneIDForChat,MileStoneIDForChatAssignedTo)} src={SendChatIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Send Icon" /> 
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            )}

            {/* Action Tracker History Screen */}

            {(showActionTrackerHistory && HitoryDetailsList.length>0) &&(<div class="popup " id="AThistoryPopup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <Modal
                    id="AThistoryPopupModal"
                    {...props}
                    size="xl"
                    className='modal-dialog-scrollable'
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={true} 
                    style={{overflowY:"hidden"}}      
                    onHide={CloseActionTrackerHistory}>
                    <Modal.Header className='customclsbtn' closeButton>
                    <Modal.Title><p className='mb-0' style={{fontSize:'20px',color:'white'}}>History</p></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            {/* <div className='mb-3 '>
                                <div className='d-flex justify-content-between col-12 roundedTopBorderradius px-2' style={{backgroundColor : '#21A8E1'}}>
                                    
                                    <img onClick={() => {CloseActionTrackerHistory()}} src={WhiteCancelicon} className='cursor-pointer' style={{height:'35px',width:'35px'}} alt="Close" />
                                </div>
                            </div> */}
                            <div className='ActiontrackerHistoryContainer px-3'>
                                <div className='row mb-2'>
                                    <div className='col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 '>
                                        <div className='d-flex'>
                                            <div className='ActionTrackerHistory-desc-title-blue'>{'History'}</div>&nbsp;
                                            <div className='ActionTrackerHistory-desc-title'>{`${HitoryDetailsList[0].Code} Created by ${HitoryDetailsList[HitoryDetailsList.length-1].ActionDoneBy} on ${formatDateInText(HitoryDetailsList[HitoryDetailsList.length-1].HistoryCreatedOn)}`}</div>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 '>
                                        <div className='d-flex justify-content-end'>
                                            <div className='ActionTrackerHistory-desc-title-general-bold' style={{color:compareDate(HitoryDetailsList[0].DueDate) == 'On Track' ? '#41A35F' : '#E91A1A'}}>{compareDate(HitoryDetailsList[0].DueDate)}</div>
                                        </div>
                                    </div>
                                </div>



                                <div class="blueContainer roundedTopBorderradius pb-2">

                                    <div class="row pt-2 ps-2  ">
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12">
                                            <div class="LabelheaderSecond">Action description</div>
                                        </div>
                                        <div class="colun1mob disnoneMob"></div>

                                        <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12 ptremove">
                                            {/* <div class="LabelheaderSecond">{HitoryDetailsList[0].ActionDescription}</div> */}
                                            <div class="LabelheaderSecond" dangerouslySetInnerHTML={{ __html:HitoryDetailsList[0].ActionDescription.replace(/\n/g, '<br>')}}/>
                                        </div>
                                    </div>

                                    <div class="row ps-2 pt-2">
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Status </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7 ">
                                            <div class="">{HitoryDetailsList[0].StatusName}</div>
                                        </div>
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Priority </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7 ">
                                            <div class="">{HitoryDetailsList[0].PriorityName}</div>
                                        </div>

                                        
                                    </div>



                                    <div class="row ps-2 pt-2">
                                     

                                     
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Entity </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7 ">
                                            <div class="">{HitoryDetailsList[0].EntityName}</div>
                                        </div>
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Asset/Project </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7">
                                            <div class="">{individualprojectdetails?individualprojectdetails.ProjectName : HitoryDetailsList[0].ProjectName}</div>
                                        </div>
                                    </div>


                                 

                                    <div class="row  ps-2 pt-2">
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                                <div class="">Start Date </div>
                                        </div>
                                        <div class="colun1 "></div>
                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7 ">
                                            <div class="">{formatDateInText(HitoryDetailsList[0].StartDate)}</div>
                                        </div>
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Due date  </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7  ">
                                            <div class="">{formatDateInText(HitoryDetailsList[0].DueDate)}</div>
                                        </div>
                                    </div>

                                    <div class="row  ps-2 pt-2">
                                     
                                    <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Responsible  </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7  ">
                                            <div class="">{HitoryDetailsList[0].userName}</div>
                                        </div>
                                    </div>
                                    <div class="row  ps-2 pt-2">
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Category </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7 ">
                                            <div class="">{HitoryDetailsList[0].CategoryName}</div>
                                        </div>
                                        <div class=" col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4 ">
                                            <div class="">Attachment </div>
                                        </div>
                                        <div class="colun1 "></div>

                                        <div class="  col-xxl-3 col-xl-3 col-lg-3 col-md-9 col-sm-9 col-xs-7 col-7">
                                            <div className="ViewAttachFile" style={{border: 'none', backgroundColor: '#fff'}}>
                                                <div class="custom-upload floatleft d-flex justify-content-evenly " style={{width: '100%'}} onClick={() => getAllFileDetailsbyHistoryId(HitoryDetailsList[(HitoryDetailsList.length)-1].HistoryID)}>
                                                    <img title="View" src={EyeIcon} style={{width: '22px'}} alt="Alternate Text" />
                                                    <div >View </div>
                                                    <div>|&nbsp;{HitoryDetailsList[(HitoryDetailsList.length)-1].AttachedFileCount}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                 


                                    
                                    


                                </div>

                                {/* ---------------------------------------History List View Starts--------------------------------------*/}

                                <div class="mt-3">
                                        {HitoryDetailsList.map((item, index) => (
                                        <div class="table-data-ld d-flex ">
                                            <div class="d-flex flex-column timeline-container">
                                                <div className={item.historyStatusName == 'New' ? 'timeline' : item.historyStatusName == 'Completed' ? 'timeline-grey' : item.historyStatusName == 'Cancel' ? 'timeline-red' : item.historyStatusName == 'Comment' ? 'timeline-blue' : 'timeline-orange' }>
                                                </div>
                                                {HitoryDetailsList.length != index+1  ? 
                                                    <div class="borderstr">
                                                    </div>
                                                :null}
                                            </div>
                                            <div id="historylist" className="w-100 grayAT py-2 ms-2 mb-2">
                                                <div class="row  ps-2">
                                                    <div class="d-flex flex-column" className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 d-flex flex-column' >
                                                        <div class="font-weight-700 hcolor">{item.ActionDoneBy}</div>
                                                        <div class="capsm"><span style={{fontSize:'14px'}} class="graycolor">{item.StatusOfHistory + ' ' +  formatDateInText(item.HistoryCreatedOn)}</span></div>
                                                    </div>

                                                    {HitoryDetailsList.length > 0 && index == 0 && !(item.historyStatusName == 'Completed' || item.historyStatusName == 'Cancel') ? 
                                                        (<div class="   col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12  pe-4    ptremove ">
                                                            <div class=" justify-content-end  d-flex PendingbyContainer">
                                                                <div class="Pendingby d-flex justify-content-center "><span>{item.DaysSinceCreatedOn}</span> </div>
                                                            </div>
                                                        </div>):
                                                    null}
                                                    <div class="row ps-2  pt-2">
                                                        <div class=" col-12 ">
                                                            {/* <div style={{width: '95%'}} class="remarkPreTag ps-1 font-weight-700">{item.HistoryRemark}</div> */}
                                                            <div  style={{width: '95%'}} class="remarkPreTag ps-1 font-weight-700" dangerouslySetInnerHTML={{ __html:item.HistoryRemark.replace(/\n/g, '<br>')}}/>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="row ps-2 pt-2">


                                                    <div class="col-12  mb-2 ">
                                                        <div class="ViewAttachFile">

                                                            <div class="custom-upload floatleft d-flex justify-content-evenly" style={{width: '100%'}} onClick={() => getAllFileDetailsbyHistoryId(item.HistoryID)}>
                                                                <img title="View" src={EyeIcon} style={{width: '22px'}} alt="Alternate Text" />
                                                                <div style={{cursor: 'pointer', fontSize: '14px'}} class="ptfm optw">View </div>
                                                                <div>|&nbsp;{item.AttachedFileCount}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>))}
                                    </div>

                                    {/* ---------------------------------------History List View Ends --------------------------------------*/}
                            </div>
                        </Modal.Body>
                    <Modal.Footer>
                        <div className='py-2  w-100'>
                            <p className='mb-0' style={{fontSize:'16px'}}>Comment</p>
                            <div className='d-flex'>
                                <div style={{width:'95%'}}>
                                    <textarea placeholder='Enter your comments' className='normalSelectStyle'  style={{padding:'0.4rem',width:'100%', maxHeight:'400px'}} name="ATHistoryComment"  value={ATHistoryComment}  onChange={(e) => setATHistoryComment(e.target.value)}/>
                                </div>
                                <div  style={{width:'5%', display:'flex', justifyContent:'end', alignItems:'center'}} >
                                    <div onClick={() => {addCommentFromActionTrackerHistory(HitoryDetailsList[0].ID,HitoryDetailsList[0].ActionAssignedToId)}}  className="cursor-pointer d-flex px-2 py-1">
                                        <img title="Save" src={SendChatIcon} className='inner-iconSize me-3 'style={{height:'25px',width:'25px'}} alt="Save" />
                                        {/* <span style={{fontWeight: 600,color: 'white'}}> {'Save'}</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>)}

            {/* Project Add Edit Modal */}
            {showAddProject &&(<div class="popup " id="ProjectPopup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <Modal
                    id="ProjectPopupModal"
                    {...props}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={true} onHide={() => {closeaddorupdateProjectPopup()}}>
                    <Modal.Header className='customclsbtn' closeButton>
                    <Modal.Title><p className='mb-0' style={{fontSize:'20px',color:'white'}}>{editProject ? 'Edit Project' : 'Add Project'}</p></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <div className='AddUpdateProjectContainer px-3'>
                            

                                {/* ---------------------------------------Project View Starts--------------------------------------*/}

                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Entity</span><span style={{color:'red'}}>*</span>
                                            <div className="SelectDropdownContainer">
                                                <select disabled={disableEntity(editProject,individualprojectdetails)} className='normalSelectStyle' style={{width:'100%', border:'1px solid #ccc',backgroundColor:disableEntity(editProject,individualprojectdetails) ?'#efeeee' : 'white'}} value={EntityProject} name="MileStoneStatus" onChange={(event) => setEntityProject(event.target.value)}>
                                                    <option value={0}>
                                                        {'---Select Entity---'}
                                                    </option>
                                                    {EntityList.map((item) => (
                                                        <option value={item.Id}>
                                                            {item.Value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Project Name</span><span style={{color:'red'}}>*</span>
                                            <input className='form-control'
                                                type="text"
                                                value={ProjectName}
                                                onChange={(event) => setProjectName(event.target.value)}
                                                placeholder="Enter project name"
                                            />
                                        </div> */}
                                    </div>

                                    <div className="row">
                                        
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Project Name</span><span style={{color:'red'}}>*</span>
                                            <textarea className='form-control'
                                                type="text"
                                                value={ProjectName}
                                                onChange={(event) => setProjectName(event.target.value)}
                                                placeholder="Enter project name"
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} 
                                                maxLength={50}
                                            />
                                            <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:ProjectName.length >180 ? 'red' : '#8f8f8f'}} >{ProjectName && (ProjectName!=null || ProjectName!="") ? ProjectName.length + '/50' : '0/50'}</div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Context And Connect</span><span style={{color:'red'}}>*</span>
                                            <textarea
                                                id="ContextAndConnect"
                                                value={ContextAndConnect}
                                                onChange={(event) => setContextAndConnect(event.target.value)}
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} // Optional: Inline styles for better appearance
                                                placeholder='Enter contect and connect'
                                                maxLength={180}
                                            />
                                            <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:ContextAndConnect && ContextAndConnect.length >180 ? 'red' : '#8f8f8f'}} >{ContextAndConnect && (ContextAndConnect!=null || ContextAndConnect!="") ? ContextAndConnect.length + '/180' : '0/180'}</div>
                                        </div>
                                    </div>
                                    
                                    <div className="row ">
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Initiative Description</span><span style={{color:'red'}}>*</span>
                                            <textarea
                                                id="initiativeDescription"
                                                value={InitiativeDescription}
                                                onChange={(event) => setInitiativeDescription(event.target.value)}
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} // Optional: Inline styles for better appearance
                                                placeholder='Enter initiative description'
                                                maxLength={180}
                                            />
                                            <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:InitiativeDescription.length >180 ? 'red' : '#8f8f8f'}} >{InitiativeDescription && (InitiativeDescription!=null || InitiativeDescription!="") ? InitiativeDescription.length + '/180' : '0/180'}</div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Rationale</span>
                                            <textarea
                                                id="Rationale"
                                                value={Rationale}
                                                onChange={(event) => setRationale(event.target.value)}
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} // Optional: Inline styles for better appearance
                                                placeholder='Enter rationale'
                                                maxLength={180}
                                            />
                                            <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:Rationale.length >180 ? 'red' : '#8f8f8f'}} >{Rationale && (Rationale!=null || Rationale!="") ? Rationale.length + '/180' : '0/180'}</div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Constraints / Challenges / Complexities</span>
                                            <textarea
                                                id="Constraints"
                                                value={Constraints}
                                                onChange={(event) => setConstraints(event.target.value)}
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} // Optional: Inline styles for better appearance
                                                placeholder='Enter constraints'
                                                maxLength={180}
                                                />
                                                <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:Constraints.length >180 ? 'red' : '#8f8f8f'}} >{Constraints && (Constraints!=null || Constraints!="") ? Constraints.length + '/180' : '0/180'}</div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Outcomes / KPIs</span>
                                            <textarea
                                                id="OutcomesKPIs"
                                                value={OutcomesKPIs}
                                                onChange={(event) => setOutcomesKPIs(event.target.value)}
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} // Optional: Inline styles for better appearance
                                                placeholder='Enter outcomes/KPIs'
                                                maxLength={180}
                                            />
                                            <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:OutcomesKPIs.length >180 ? 'red' : '#8f8f8f'}} >{OutcomesKPIs && (OutcomesKPIs!=null || OutcomesKPIs!="") ? OutcomesKPIs.length + '/180' : '0/180'}</div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Potential Risks / De-railers</span>
                                            <textarea
                                                id="PotentialRisk"
                                                value={PotentialRisk}
                                                onChange={(event) => setPotentialRisk(event.target.value)}
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} // Optional: Inline styles for better appearance
                                                placeholder='Enter potential risks/de-railers'
                                                maxLength={180}
                                            />
                                            <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:PotentialRisk.length >180 ? 'red' : '#8f8f8f'}} >{PotentialRisk && (PotentialRisk!=null || PotentialRisk!="") ? PotentialRisk.length + '/180' : '0/180'}</div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Resources Needed</span>
                                            <textarea
                                                id="ResourcesNeeded"
                                                value={ResourcesNeeded}
                                                onChange={(event) => setResourcesNeeded(event.target.value)}
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} // Optional: Inline styles for better appearance
                                                placeholder='Enter resources needed'
                                                maxLength={180}
                                            />
                                            <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:ResourcesNeeded.length >180 ? 'red' : '#8f8f8f'}} >{ResourcesNeeded && (ResourcesNeeded!=null || ResourcesNeeded!="") ? ResourcesNeeded.length + '/180' : '0/180'}</div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Costs and Investments</span>
                                            <textarea
                                                id="CostAndInvestments"
                                                value={CostAndInvestments}
                                                onChange={(event) => setCostAndInvestments(event.target.value)}
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} // Optional: Inline styles for better appearance
                                                placeholder='Enter costs and investments'
                                                maxLength={180}
                                            />
                                            <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:CostAndInvestments.length >180 ? 'red' : '#8f8f8f'}} >{CostAndInvestments && (CostAndInvestments!=null || CostAndInvestments!="") ? CostAndInvestments.length + '/180' : '0/180'}</div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Descision / Support from CMO</span>
                                            <textarea
                                                id="DescisionSupportfromCMO"
                                                value={DescisionSupportfromCMO}
                                                onChange={(event) => setDescisionSupportfromCMO(event.target.value)}
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} // Optional: Inline styles for better appearance
                                                placeholder='Enter descision/support from CMO'
                                                maxLength={180}
                                            />
                                            <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:DescisionSupportfromCMO.length >180 ? 'red' : '#8f8f8f'}} >{DescisionSupportfromCMO && (DescisionSupportfromCMO!=null || DescisionSupportfromCMO!="") ? DescisionSupportfromCMO.length + '/180' : '0/180'}</div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Responsible (Doer)</span><span style={{color:'red'}}>*</span>
                                            <div className="SelectDropdownContainer">
                                                <select id="ResponsibleDoer" className='normalSelectStyle'  style={{width:'100%'}} value={ResponsibleDoer} onChange={(event) => setResponsibleDoer(event.target.value)}>
                                                    <option value={0}>
                                                        {'---Select responsible (doer)---'}
                                                    </option>
                                                    {UserMasterList
                                                    .filter((item) => item.EntityId == EntityProject && item.EntityId != 0  )
                                                    .map((item) => (
                                                        <option value={item.Id}>
                                                            {item.Value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {/* <textarea
                                                id="ResponsibleDoer"
                                                value={ResponsibleDoer}
                                                onChange={(event) => setResponsibleDoer(event.target.value)}
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} // Optional: Inline styles for better appearance
                                                placeholder='Enter responsibleDoer'
                                                maxLength={50}
                                                /> */}
                                            {/* <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:ResponsibleDoer && ResponsibleDoer.length >50 ? 'red' : '#8f8f8f'}} >{ResponsibleDoer && (ResponsibleDoer!=null || ResponsibleDoer!="") ? ResponsibleDoer.length + '/50' : '0/50'}</div> */}
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Accountable</span>
                                            <textarea
                                                id="Accountable"
                                                value={Accountable}
                                                onChange={(event) => setAccountable(event.target.value)}
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} // Optional: Inline styles for better appearance
                                                placeholder='Enter accountable'
                                                maxLength={50}
                                            />
                                            <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:Accountable.length >50 ? 'red' : '#8f8f8f'}} >{Accountable && (Accountable!=null || Accountable!="") ? Accountable.length + '/50' : '0/50'}</div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Consult</span>
                                            <textarea
                                                id="Consult"
                                                value={Consult}
                                                onChange={(event) => setConsult(event.target.value)}
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} // Optional: Inline styles for better appearance
                                                placeholder='Enter consult'
                                                maxLength={50}
                                            />
                                            <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:Consult.length >50 ? 'red' : '#8f8f8f'}} >{Consult && (Consult!=null || Consult!="") ? Consult.length + '/50' : '0/50'}</div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Inform</span>
                                            <textarea
                                                id="Inform"
                                                value={Inform}
                                                onChange={(event) => setInform(event.target.value)}
                                                rows="2" // Number of visible text lines
                                                cols="50" // Width of the text area
                                                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }} // Optional: Inline styles for better appearance
                                                placeholder='Enter inform'
                                                maxLength={50}
                                            />
                                            <div className='d-flex justify-content-end w-100' style={{fontSize:'12px',color:Inform.length >50 ? 'red' : '#8f8f8f'}} >{Inform && (Inform!=null || Inform!="") ? Inform.length + '/50' : '0/50'}</div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Review Frequency</span>
                                            <div className="SelectDropdownContainer">
                                                <select className='normalSelectStyle' style={{width:'100%'}} value={Frequency} onChange={(event) => setFrequency(event.target.value)}>
                                                    <option value={0}>
                                                        {'---Select Frequency---'}
                                                    </option>
                                                    {FrequencyList.map((item) => (
                                                        <option value={item.Id}>
                                                            {item.Value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Review Date</span>
                                            <input className='form-control'
                                                type="date"
                                                value={formatDateAT(Projectdate)}
                                                onChange={(event) => setProjectdate(event.target.value)}
                                                placeholder="Enter date"
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        
                                        <div className="col-md-6 col-sm-12 col-12 mt-3">
                                            <span>Project Status</span><span style={{color:'red'}}>*</span>
                                            <div className="SelectDropdownContainer">
                                                <select className='normalSelectStyle' style={{width:'100%'}} value={ProjectStatus} onChange={(event) => setProjectStatus(event.target.value)}>
                                                    <option value={0}>
                                                        {'---Select Status---'}
                                                    </option>
                                                    {ProjectStatusList.map((item) => (
                                                        <option value={item.Id}>
                                                            {item.Value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                    {/* ---------------------------------------Project View Ends --------------------------------------*/}
                        </Modal.Body>
                    <Modal.Footer>
                        <div className='py-2  w-100'>
                            <div className='d-flex justify-content-flex-end'>
                                <div  style={{width:'100%', display:'flex', justifyContent:'end', alignItems:'center'}} >
                                    {!editProject || !individualprojectdetails.IsSubmit ?  <div onClick={() => { editProject ? addorUpdateProject('edit',false) : addorUpdateProject('add',false) }}style={{ border: '1px solid #21A8E1', backgroundColor: 'white',width:'150px' }} className="cursor-pointer d-flex px-2 py-1 me-2 justify-content-center">
                                        <img title="Draft" src={ActionIcon} className='inner-iconSize me-3 ' alt="Save" />
                                        <span style={{fontWeight: 600,color: '#21A8E1'}}> {'Save as draft'}</span>
                                    </div> : null}
                                    <div onClick={() => { editProject ? addorUpdateProject('edit',true) : addorUpdateProject('add',true) }}style={{ border: '1px solid #21A8E1', backgroundColor: '#21A8E1',width:'150px' }} className="cursor-pointer d-flex px-2 py-1 justify-content-center">
                                        <img title="Save" src={WhiteSaveIcon} className='inner-iconSize me-3 ' alt="Save" />
                                        <span style={{fontWeight: 600,color: 'white'}}> {'Save'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>)}

             {/* Alert  popup for no records*/}
             {showAlertForMilestone && (
                <div className="popup" style={{zIndex:1000000}}>
                    <div className="popup-inner-Chat-AlertAT-Milestone">
                            <div className='row mx-2 mt-2'>
                                <div className='d-flex justify-content-between align-items-center col-12 '>
                                    <span style={{fontSize:'20px', color:'#21A8E1'}}>Information</span>
                                    <img title="Close" onClick={() => setshowAlertForMilestone(false)} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.8 rem'}} alt="Add Milestone" />
                                </div>
                            </div>
                            <div>
                                {/* <hr style={{color:'#21A8E1'}}/> */}
                            </div>
                            <div className='' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto'}}>
                                
                                    <div style={{ display : 'flex', justifyContent:'center', alignItems:'center'}}>
                                        <div className=' d-flex justify-content-start align-items-center p-4' style={{width:'100%'}}>
                                            {`No information to show in the selection`}
                                        </div>
                                    </div>
                            </div>
                            <div>
                                {/* <hr style={{color:'#21A8E1'}}/> */}
                            </div>
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between col-12 mb-2'>
                                    <div className='w-100 d-flex justify-content-end align-items-center'>
                                        <div onClick={() => setshowAlertForMilestone(false)} style={{ border: '1px solid #21A8E1', backgroundColor: '#21A8E1',width:'85px', borderRadius:'4px' }} className="cursor-pointer d-flex px-2 py-1 justify-content-center">
                                        <span style={{fontWeight: 600,color: 'white'}}> {'Close'}</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            )}

            {/* Alert  popup for dependency Error in Action Tracker*/}
            {showDependencyCompleteAlert && (
                <div className="popup" style={{zIndex:1000000}}>
                    <div className="popup-inner-Chat-AlertAT-Milestone">
                            <div className='row mx-2 mt-2'>
                                <div className='d-flex justify-content-between align-items-center col-12 '>
                                    <span style={{fontSize:'20px', color:'#21A8E1'}}>Information</span>
                                    <img title="Close" onClick={() => CloseActionTrackerDependencyPopup()} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.8 rem'}} alt="Add Milestone" />
                                </div>
                            </div>
                            <div>
                                {/* <hr style={{color:'#21A8E1'}}/> */}
                            </div>
                            <div className='' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto'}}>
                                
                                    <div style={{ display : 'flex', justifyContent:'center', alignItems:'center'}}>
                                        <div className=' d-flex justify-content-start align-items-center p-4' style={{width:'100%'}}>
                                            {showDependencyCompleteAlertMsg}
                                        </div>
                                    </div>
                            </div>
                            <div>
                                {/* <hr style={{color:'#21A8E1'}}/> */}
                            </div>
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between col-12 mb-2'>
                                    <div className='w-100 d-flex justify-content-end align-items-center'>
                                        <div onClick={() => CloseActionTrackerDependencyPopup()} style={{ border: '1px solid #21A8E1', backgroundColor: '#21A8E1',width:'85px', borderRadius:'4px' }} className="cursor-pointer d-flex px-2 py-1 justify-content-center">
                                        <span style={{fontWeight: 600,color: 'white'}}> {'Close'}</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            )}

            {isLoading &&( <Loader/> ) }
            <div className='MainBodyDiv'>
                <div className="container-fluid">
                    {FinalMilestoneDetails.length> 0?  
                    <section className={'mb-2 border-blue-data'}>
                        {/* Milestone */}
                        <div className='p-3 milestoneContainer'>
                                {FinalMilestoneDetails.map((itemMilestone, indexMilestone) => (
                                    <div className='borderprojectmilestone' style={{ marginBottom:'0.5rem'}} key={indexMilestone}>
                                        <div className='d-flex w-100' style={{backgroundColor:'#21A8E1',padding:'0.5rem',color:'white'}}>
                                            <span style={{width : '30%'}}>{`${itemMilestone.ProjectCode}    ${itemMilestone.ProjectName}`}</span>
                                            <span style={{width : '70%'}}>{`${itemMilestone.MilestonCode}    ${itemMilestone.MilestoneName}`}</span>
                                        </div>
                                            
                                        <div id="table-scroll" className="table-scroll">
                                            <div className="table-wrap">
                                                <table style={{ border: 'none', borderCollapse: 'seperate' }}  className="main-table">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ backgroundColor: '#EDEDED'  }} className='tableheaderStyleAT fixed-side sticky-col' scope="col">Category <span style={{color:'red'}}>*</span></th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none',minWidth:'100px'  }} className='tableheaderStyleAT w-25' scope="col">Code</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none',minWidth:'300px'  }} className='tableheaderStyleAT w-25' scope="col">Associated Action</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none',minWidth:'300px'  }} className='tableheaderStyleAT w-25' scope="col">Action Description</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Priority <span style={{color:'red'}}>*</span></th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Update Status <span style={{color:'red'}}>*</span></th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Overall Status</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Responsible (Doer) <span style={{color:'red'}}>*</span></th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none' }} className='tableheaderStyleAT w-25' scope="col">Accountable</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Consulted</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Informed</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Start Date <span style={{color:'red'}}>*</span></th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Due Date <span style={{color:'red'}}>*</span></th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Duration</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none',maxWidth:'200px',minWidth:'120px', textWrap:'wrap'  }} className='tableheaderStyleAT w-25' scope="col">Estimate Efforts (Man Hour)</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none',minWidth:'300px'  }} className='tableheaderStyleAT w-25' scope="col">Sub Action</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none',minWidth:'100px'  }} className='tableheaderStyleAT w-25' scope="col">Ref. Meeting <span style={{color:'red'}}>*</span></th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Attached Files</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Recurring</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Recurring Frequency</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Recurring Day</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Recurring Start Date</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Recurring Finish Date</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">% Completion</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Actual Start Date</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Actual Finish Date</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none',minWidth:'300px'  }} className='tableheaderStyleAT w-25' scope="col">Remark</th>
                                                            <th style={{ backgroundColor: '#EDEDED', border: 'none'  }} className='tableheaderStyleAT w-25' scope="col">Dependency</th>
                                                            <th style={{ backgroundColor: '#EDEDED' }} className='tableheaderStyleAT w-25 sticky-col-last' scope="col">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        

                                                        {/* Exisiting Action Tracker (Update)*/}
                                                        {itemMilestone.ActionTrackerlist.map((item,index) => (
                                                            <tr key={index}>
                                                                
                                                                <td style={{ fontSize:'14px', backgroundColor:'white' ,borderBottom:'1px solid #ccc'}} className='w-25 fixed-side sticky-col'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <div className="SelectDropdownContainer mb-3 mt-3" >
                                                                            <select  className='normalSelectStyle' style={{ padding:'0.4rem'}}  name="Category" value={item.Category} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} >
                                                                                <option value={0}>
                                                                                    {'---Select---'}
                                                                                </option>
                                                                                {CategoryList.map((item) => (
                                                                                    <option style={{color:'black'}} value={item.Id}>
                                                                                        {item.Value}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.CategoryName}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',minWidth:'100px',maxwidth:'100px',textWrap:'wrap',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                    <div className='mb-3 mt-3 itemAlignCenterCommon' dangerouslySetInnerHTML={{ __html:item.Code.replace(/\n/g, '<br>')}}/>)
                                                                :
                                                                (
                                                                    <div className='mb-3 mt-3' dangerouslySetInnerHTML={{ __html:item.Code.replace(/\n/g, '<br>')}}/>)
                                                                }
                                                                    
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',minWidth:'300px',maxwidth:'300px',textWrap:'wrap',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <textarea className='normalSelectStyle mb-3 mt-3'  style={{padding:'0.4rem',height:'2.5rem',width:'100%'}} name="AssociatedAction"  value={item.AssociatedAction}  onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)}/>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3' dangerouslySetInnerHTML={{ __html:item.AssociatedAction.replace(/\n/g, '<br>')}}/>)
                                                                    }
                                                                </td>
                                                               
                                                                <td style={{border: 'none', fontSize:'14px',minWidth:'300px',maxwidth:'300px',textWrap:'wrap',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <textarea className='normalSelectStyle mb-3 mt-3'  style={{padding:'0.4rem',height:'2.5rem',width:'100%'}} name="ActionDescription"  value={item.ActionDescription}  onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)}/>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3' dangerouslySetInnerHTML={{ __html:item.ActionDescription.replace(/\n/g, '<br>')}}/>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <div className="SelectDropdownContainer mb-3 mt-3">
                                                                        <select className='normalSelectStyle' style={{padding:'0.4rem'}}  name="Priority" value={item.Priority} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} >
                                                                            <option value={0}>
                                                                                {'---Select---'}
                                                                            </option>
                                                                            {PriorityList.map((item) => (
                                                                                <option style={{color:'black'}} value={item.Id}>
                                                                                    {item.Value}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.PriorityName}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <div className="SelectDropdownContainer mb-3 mt-3">
                                                                        <select className='normalSelectStyle' style={{padding:'0.4rem'}}  name="Status1" value={item.Status1} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} >
                                                                            <option value={0}>
                                                                                {'---Select---'}
                                                                            </option>
                                                                            {StatusList.map((item) => (
                                                                                <option style={{color:'black'}} value={item.Id}>
                                                                                    {item.Value}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.StatusName}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25 mb-3 mt-3'>
                                                                {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                    <div style={{color: item.StatusName === 'Completed' 
                                                                        ? 'black' : compareDate(item.DueDate) == 'Delayed' ? 'red' : compareDate(item.DueDate) == 'On Track'  ? '#41a35f' : 'black' , justifyContent:"center", display:'flex'}} className='mb-3 mt-3 itemAlignCenterCommon'>{item.DueDate && item.DueDate !=null && item.StatusName != 'Completed' ?  compareDate(item.DueDate) : "-"}</div>)
                                                                    :
                                                                    (<div style={{color:  item.StatusName === 'Completed'
                                                                        ? 'black' :compareDate(item.DueDate) == 'Delayed' ? 'red' : compareDate(item.DueDate) == 'On Track'  ? '#41a35f' : 'black' , justifyContent:"center", display:'flex'}} className='mb-3 mt-3'>{item.DueDate && item.DueDate !=null && item.StatusName != 'Completed' ?  compareDate(item.DueDate) : "-"}</div>)
                                                                }
                                                                </td>

                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        userData['PositionID'] == 1 ? 
                                                                            <div className="SelectDropdownContainer mb-3 mt-3">
                                                                                <select className='normalSelectStyle' style={{padding:'0.4rem'}}  name="ResponsibleDoer" value={item.ResponsibleDoer} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} >
                                                                                    <option value={0}>
                                                                                        {'---Select---'}
                                                                                    </option>
                                                                                    {UserMasterList
                                                                                    .filter((items) => items.EntityId == item.Entity)
                                                                                    .map((item) => (
                                                                                        <option style={{color:'black'}} value={item.Id}>
                                                                                            {item.Value}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div> 
                                                                            :
                                                                            <div className="SelectDropdownContainer mb-3 mt-3">
                                                                                <select className='normalSelectStyle' style={{padding:'0.4rem'}}  name="ResponsibleDoer" value={item.ResponsibleDoer} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} >
                                                                                    <option value={0}>
                                                                                        {'---Select---'}
                                                                                    </option>
                                                                                    {EntityWiseUserList
                                                                                    .map((item) => (
                                                                                        <option style={{color:'black'}} value={item.Id}>
                                                                                            {item.Value}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div> 
                                                                        
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.userName}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc', maxWidth:'200px', textWrap:'wrap'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <textarea className='normalSelectStyle mb-3 mt-3' style={{minWidth:'180px',padding:'0.4rem',height:'2.5rem',width:'100%'}} name="Accountable"  value={item.Accountable}  onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)}/>
                                                                    ) 
                                                                    : 
                                                                    (<div style={{minWidth:'100px'}} className='mb-3 mt-3'>{item.Accountable}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px', maxWidth:'200px', textWrap:'wrap',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <textarea className='normalSelectStyle mb-3 mt-3'  style={{minWidth:'180px',padding:'0.4rem',height:'2.5rem',width:'100%'}} name="Consult"  value={item.Consult}  onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)}/>
                                                                    ) 
                                                                    : 
                                                                    (<div   style={{minWidth:'100px'}} className='mb-3 mt-3'>{item.Consult}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px', maxWidth:'200px', textWrap:'wrap',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <textarea className='normalSelectStyle mb-3 mt-3'  style={{minWidth:'180px',padding:'0.4rem',height:'2.5rem',width:'100%'}} name="Inform"  value={item.Inform}  onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)}/>
                                                                    ) 
                                                                    : 
                                                                    (<div  style={{minWidth:'100px'}} className='mb-3 mt-3'>{item.Inform}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <input className='normalSelectStyle mb-3 mt-3'  style={{padding:'0.4rem',height:'2.5rem'}} type="date" name="StartDate" value={formatDateAT(item.StartDate)} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} />
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.StartDate ? handleFormatDate(item.StartDate) : '-'}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <input className='normalSelectStyle mb-3 mt-3' style={{padding:'0.4rem',height:'2.5rem'}} type="date" name="DueDate" value={formatDateAT(item.DueDate)} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} />
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.DueDate ? handleFormatDate(item.DueDate) : '-'}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <div className=' mb-3 mt-3'  style={{padding:'0.4rem',height:'2.5rem'}}>{calculateDateDifference(item.StartDate,item.DueDate)}</div>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{calculateDateDifference(item.StartDate,item.DueDate)}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <input className='normalSelectStyle mb-3 mt-3'  style={{padding:'0.4rem',height:'2.5rem'}} type="number" name="EsimatedEfforts" value={item.EsimatedEfforts} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)}   min="0"
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}/>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.EsimatedEfforts}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',minWidth:'300px',maxwidth:'300px',textWrap:'wrap',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <textarea className='normalSelectStyle mb-3 mt-3'  style={{padding:'0.4rem',height:'2.5rem',width:'100%'}} name="SubAction"  value={item.SubAction}  onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)}/>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3' dangerouslySetInnerHTML={{ __html:item.SubAction.replace(/\n/g, '<br>')}}/>)
                                                                    }
                                                                </td>





                                                                <td style={{border: 'none', fontSize:'14px',minWidth:'100px',maxwidth:'100px',textWrap:'wrap',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <textarea className='normalSelectStyle mb-3 mt-3'  style={{padding:'0.4rem',height:'2.5rem',width:'100%'}} name="RefMeeting"  value={item.RefMeeting}  onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)}/>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3' dangerouslySetInnerHTML={{ __html:item.RefMeeting.replace(/\n/g, '<br>')}}/>)
                                                                    }
                                                                </td>
                                                              
                                                             
                                                              {/* Fileupload */}
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <div className='d-flex mt-2 mb-2'>
                                                                            <div className="d-flex justify-content-center" style={{}} >
                                                                                <div className="floatleft mt-3 mb-3" style={{ width: '100%' }}>
                                                                                    <img src={PaperClip} style={{ height: '13px',cursor:'pointer' }} title='Upload' className="" onClick={() => openFileSelectionPopUp()} alt="Alternate Text" />&nbsp;&nbsp;
                                                                                    <span onClick={() => openFileSelectionPopUp()} style={{ cursor: 'pointer' }}>Upload</span>
                                                                                    <input style={{display:'none'}} type="file" id="trackerFiles" name='file' onChange={(e) => setFilesData(e)} ref={fileInputRef} multiple />
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex justify-content-center ms-2" style={{cursor:'pointer'}} >
                                                                                <div className="floatleft mt-3 mb-3" style={{ width: '100%' }}>
                                                                                    <img title='View' onClick={() => getAllFileDetailsbyHistoryId(item.ID,true,true) } src={EyeIcon} style={{ height: '17px',cursor: 'pointer' }} className="sysImage" alt="Alternate Text" />&nbsp;&nbsp;
                                                                                    <span onClick={() => getAllFileDetailsbyHistoryId(item.ID,true,true) } style={{ cursor: 'pointer' }}>View</span><span style={{ cursor: 'pointer' }}></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) 
                                                                    :
                                                                    (<div className="d-flex justify-content-center " >
                                                                        <div className="mt-3 mb-3">
                                                                            <img title='View' onClick={() =>  getAllFileDetailsbyHistoryId(item.ID,true)} src={EyeIcon} style={{ height: '17px' , cursor:'pointer'}} className="sysImage" alt="Alternate Text" />&nbsp;&nbsp;
                                                                            <span onClick={() =>  getAllFileDetailsbyHistoryId(item.ID,true)} style={{ cursor: 'pointer' }}>View</span><span style={{ cursor: 'pointer' }}></span>
                                                                        </div>
                                                                    </div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <div className="SelectDropdownContainer mb-3 mt-3">
                                                                            <select className='normalSelectStyle' style={{padding:'0.4rem'}}  name="Recurring" value={item.Recurring == true ? 1 : 2} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} >
                                                                                <option value={0}>
                                                                                    {'---Select---'}
                                                                                </option>
                                                                                {RecurringList.map((item) => (
                                                                                    <option style={{color:'black'}} value={item.Id}>
                                                                                        {item.Value}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.Recurring == true ? 'Yes' : 'No'}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <div className="SelectDropdownContainer mb-3 mt-3">
                                                                            <select className='normalSelectStyle' style={{padding:'0.4rem'}}  name="RecurringOnDay" value={item.RecurringOnDay} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} >
                                                                                <option value={100}>
                                                                                    {'---Select---'}
                                                                                </option>
                                                                                {RecurringOnDayList.map((item) => (
                                                                                    <option style={{color:'black'}} value={item.Id}>
                                                                                        {item.Value}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.RecurringOnDay}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <div className="SelectDropdownContainer mb-3 mt-3">
                                                                        <select className='normalSelectStyle' style={{padding:'0.4rem'}}  name="Frequency" value={item.Frequency} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} >
                                                                            <option value={0}>
                                                                                {'---Select---'}
                                                                            </option>
                                                                            {FrequencyList.map((item) => (
                                                                                <option style={{color:'black'}} value={item.Id}>
                                                                                    {item.Value}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.FrequencyType}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <input className='normalSelectStyle  mb-3 mt-3'  style={{padding:'0.4rem',height:'2.5rem'}} type="date" name="RecurringStartDate" value={formatDateAT(item.RecurringStartDate)} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} />
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.RecurringStartDate ? handleFormatDate(item.RecurringStartDate) : '-'}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <input className='normalSelectStyle mb-3 mt-3'  style={{padding:'0.4rem',height:'2.5rem'}} type="date" name="RecurringEndDate" value={formatDateAT(item.RecurringEndDate)} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} />
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.RecurringEndDate ? handleFormatDate(item.RecurringEndDate) : '-'}</div>)
                                                                    }
                                                                </td>
                                                             {/* //calculate */}
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        item.Status1 == 3 ?
                                                                            (<div className="SelectDropdownContainer mb-3 mt-3">
                                                                                <select className='normalSelectStyle' style={{padding:'0.4rem'}}  name="Completion" value={item.Completion} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)}>
                                                                                    <option value={0}>
                                                                                        {'---Select---'}
                                                                                    </option>
                                                                                    {CompletionList
                                                                                    .map((item) => (
                                                                                        <option style={{color:'black'}} value={item.Id}>
                                                                                            {item.Value}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>):
                                                                            (<div  className=' mb-3 mt-3'  style={{padding:'0.6rem'}}>
                                                                                {
                                                                                    item.Status1 == 1 || item.Status1 == 4 ? "0%": item.Status1 == 2 || item.Status1 == 5 ? "100%" : '-'
                                                                                }
                                                                            </div>)
                                                                            
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.Status1 == 3 ? item.CompletionName : item.Status1 == 1 || item.Status1 == 4 ? "0%": item.Status1 == 2 || item.Status1 == 5 ? "100%" : '-'}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <input className='normalSelectStyle  mb-3 mt-3'  style={{padding:'0.4rem',height:'2.5rem'}} type="date" name="ActualStartDate" value={formatDateAT(item.ActualStartDate)} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} max={item.ActualFinishDate ? formatDateAT(item.ActualFinishDate) : undefined} />
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.ActualStartDate ? handleFormatDate(item.ActualStartDate) : '-'}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <input className='normalSelectStyle mb-3 mt-3'  style={{padding:'0.4rem',height:'2.5rem'}} type="date" name="ActualFinishDate" value={formatDateAT(item.ActualFinishDate)} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} min={item.ActualStartDate ? formatDateAT(item.ActualStartDate) : undefined}/>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3'>{item.ActualFinishDate ? handleFormatDate(item.ActualFinishDate) : '-'}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{border: 'none', fontSize:'14px',minWidth:'300px',maxwidth:'300px',textWrap:'wrap',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <textarea className='normalSelectStyle mb-3 mt-3'  style={{padding:'0.4rem',height:'2.5rem',width:'100%'}} name="Remark"  value={item.Remark}  onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)}/>
                                                                    ) 
                                                                    : 
                                                                    (<div className='mb-3 mt-3' dangerouslySetInnerHTML={{ __html:item.Remark.replace(/\n/g, '<br>')}}/>)
                                                                    }
                                                                </td>

                                                                {/* //dependency */}
                                                                <td style={{border: 'none', fontSize:'14px',borderBottom:'1px solid #ccc'}} className='w-25'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <div className="SelectDropdownContainer mb-3 mt-3">
                                                                            <select className='normalSelectStyle' style={{padding:'0.4rem'}}  name="Dependency" value={item.Dependency} onChange={(e) => handleActionTrackerChange(e, index, false, indexMilestone)} >
                                                                                <option value={0}>
                                                                                    {'---Select---'}
                                                                                </option>
                                                                                {ActionTrackerList
                                                                                    // .filter((items) => items.EntityId ==  item.Entity)
                                                                                    .filter((items) => items.ProjectID == item.AssetProject && items.Id != item.ID)
                                                                                    .map((item) => (
                                                                                        <option style={{color:'black'}} value={item.Id}>
                                                                                            {item.Value}
                                                                                        </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        ) 
                                                                        : 
                                                                        (<div className='mb-3 mt-3'>{item.DependencyName}</div>)
                                                                    }
                                                                </td>
                                                                <td style={{fontSize:'14px', backgroundColor:'white',borderBottom:'1px solid #ccc'}} className='w-25 sticky-col-last mb-5'>
                                                                    {editingActionTrackerRow === index && EditingActionTrackerOuterRow === indexMilestone ? (
                                                                        <div className='mb-3 mt-3'>
                                                                            <img onClick={() => handleSaveEditActionTrackerRow(index, item.ID ,true, item.AssetProject, item.Entity,indexMilestone)} title='Save' src={SaveMilestone} className='inner-iconSize me-3 cursor-pointer' alt="Save Icon" /> 
                                                                            {!item.IsSubmit ? <img onClick={() => handleSaveEditActionTrackerRow(index, item.ID  ,false,item.AssetProject,item.Entity,indexMilestone)} title='Draft' src={SaveAsDraftMilestone} className='inner-iconSize me-3 cursor-pointer' alt="Save Draft Icon" />  : null}
                                                                            <img  onClick={() => handleCloseAction(false)} title='Close' src={CloseAction} className='inner-iconSize me-3 cursor-pointer' alt="Close Action Icon" /> 
                                                                        </div>
                                                                    ) : (
                                                                    <div className='w-100 d-flex align-items-center mb-3 mt-3'>
                                                                        {(!item.IsSubmit || (item.IsSubmit && item.ResponsibleDoer === userData['UserId']) || userData['IsAdmin'] == true) ? <img onClick={() => handleEditActionTrackerRow(index,indexMilestone)} src={EditIconsBlue} title='Edit' className='inner-iconSize cursor-pointer' alt="Edit Action Tracker" /> : null}
                                                                        {item.IsSubmit ? <img onClick={() => OpenActionTrackerHistory(item.ID)} title='History' src={RemarkBlue} className='inner-iconSize ms-3 cursor-pointer' alt="History Icon" /> : <img onClick={() => DeletedraftedAction(item.ID)} title='Delete' src={DeleteIconMilestone} className='inner-iconSize ms-3 cursor-pointer' alt="Delete Action Tracker Icon" /> }
                                                                    </div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </section>
                    :
                    <div className='d-flex justify-content-center align-items-center SectionNoRecord'>
                        {/* <div style={{fontSize:'16px'}}>No records</div> */}
                    </div>
                }
                </div>
                {/* <ActionTracker/> */}
            </div>
            <Footer/>
        </div>
    );
};

export default MyActionTracker;
