import React, { useEffect, useState } from "react";
import "./CompetitionTable.css";
import imageURL from '../../../assets/images/infoIcon.svg';
import edit from '../../../assets/images/edit-icon.svg';
import remove from '../../../assets/images/remove-icon.svg';
import add from '../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../components/help_side_panel/HelpSidePanel";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import SmallSizedLoader from "../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '../../../api_interceptor/api'
import Select from 'react-select';
import Alert_Popup from "../../../components/alert_popup/Alert_Popup";
import close from '../../../assets/images/close.png';
import { updateState } from "../../../common_function/common_function";
import _ from 'lodash';


export default function CompetitionTable(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [monthlyUpdateList, setmonthlyUpdateList] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const [competitionAreaList, setCompetitionAreaList] = useState([]);
    const [ourCompetitiveAdvantager, setOurCompetitiveAdvantager] = useState([]);
    const [CompetitorCompetitiveAdvantager, setCompetitorCompetitiveAdvantager] = useState([]);
    const [categorySpentBycategory_spent_by_client_nameList, setcategorySpentBycategory_spent_by_client_nameList] = useState([]);
    const [isClientInReachList, setIsClientInReachList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const company_id = useSelector((state) => state.user.companyID);
    const [prevList, setPrevList] = useState([]);


    useEffect(() => {
        if (userData && userData['UserId']) {
            getMonthlyUpdate(userData['UserId'])
        }
    }, [userData]);

    const getMonthlyUpdate = (UID) => {
        updateState(props.setPreviewEnabled,userData['UserId'],access_details['company_division_id'],props.setPreviewList,access_details['company_division_id'])
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-competition';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data.help_details && (response.data.help_details).length > 0) {
                        setmonthlyUpdateList(response.data.competition_list)
                        sethelpDetails(response.data.help_details)
                        getAreaOfUpdateDetails(userData['UserId'], response.data.competition_list)

                    }
                    else {
                        sethelpDetails(response.data.help_details)
                        getAreaOfUpdateDetails(userData['UserId'], response.data.competition_list)
                        setIsLoading(false)
                    }
                }
                else {
                    getAreaOfUpdateDetails(userData['UserId'], response.data.competition_list)
                    sethelpDetails(response.data.help_details)

                    setIsLoading(false)
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const getAreaOfUpdateDetails = (UID, valdata) => {
        const API_URL = 'monthlymodule/api/get-competition-dropdown-details';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setCompetitionAreaList(response.data.competition_area_list);
                        setcategorySpentBycategory_spent_by_client_nameList(response.data.category_spent_by_client_list);
                        setOurCompetitiveAdvantager(response.data.our_competitive_advantage);
                        setCompetitorCompetitiveAdvantager(response.data.our_competitive_advantage);
                        setIsClientInReachList(
                            [
                                { label: "Yes", value: 1 },
                                { label: "No", value: 0 },
                            ]
                        );
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const handleEdit = (index) => {
        // const updatedMonthlyUpdateList = [...monthlyUpdateList];

        if (prevList.length === 0) {
            setPrevList(monthlyUpdateList.map(item => ({ ...item }))); // Save the original state
        }

        const updatedMonthlyUpdateList = _.cloneDeep(monthlyUpdateList);
        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);

        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }

        if (editableRows.length === 0 && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });

            console.log({ currentOperation });
            setCurrentOperation('edit');
            setmonthlyUpdateList(updatedMonthlyUpdateList);
        }
    };

    const handleDelete = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            setDeleteReason(null)
            setDeletingIndex(index);
            setShowConfirmationModal(true);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
            handleDeleteAPI(deletingIndex, deleteReason);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };

    const handleDeleteAPI = (index, reason) => {
        const editedItem = monthlyUpdateList[index];
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/delete-competition';
        const req_body = {
            "id": editedItem.id,
            "deleted_reason": reason,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    getMonthlyUpdate(userData['UserId'])
                    setmonthlyUpdateList(prevList => prevList.filter((item, i) => i !== index));
                }
                else {
                    getMonthlyUpdate(userData['UserId'])
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    const editMontlyUpdate = (editedItem, index) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/add-competition';
        const req_body = editedItem;
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMonthlyUpdate(userData['UserId'])
                }
                else {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMonthlyUpdate(userData['UserId'])
                }

            })
            .catch((error) => {
                setAddClick(false)
                setIsLoading(false)
                console.error(error);
            });
    }

    const getVal = (val) => {
        if (val == true) {
            return 1
        }
        if (val == false) {
            return 0
        }
        if (val == 0) {
            return 0
        }
        if (val == 1) {
            return 1
        }
    }

    const getdropdownValYesNo = (editedItem) => {
        if (editedItem.is_client_in_reach_out_list && editedItem.is_client_in_reach_out_list.value == undefined) {
            return setSelectValue(isClientInReachList, setDropDownTrueFalse(editedItem.is_client_in_reach_out_list)).value
        }
        else if (editedItem.is_client_in_reach_out_list == false) {
            return 0
        }
        else {
            return getVal(editedItem.is_client_in_reach_out_list.value)
        }
    }

    // Validation start logic

    const validateMonthlyUpdate = (editedItem) => {
   

        const emptyFields = [];

        // Validation 1: Dropdown should be mandatory
        // if (/^\s*$/.test(editedItem.competitors_name)) {
        //     emptyFields.push("Please enter value for competitor's name.\n");
        // }

        // if (/^\s*$/.test(editedItem.competitors_area_name)) {
        //     emptyFields.push("Please select competitor's area.\n");
        // }

        // if (/^\s*$/.test(editedItem.key_clients)) {
        //     emptyFields.push("Please enter value for key clients.\n");
        // }

        // if (!editedItem.category_spent_by_client_name) {
        //     emptyFields.push("Please select category spend by clients.\n");
        // }

        // if (currentOperation === 'edit') {
        //     // Validation for edit operation
        //     if (!editedItem.hasOwnProperty('is_client_in_reach_out_list')) {
        //         emptyFields.push("Please select is client in our reach out list");
        //     }
        // } else if (currentOperation === 'add') {
        //     // Validation for add operation
        //     if (!editedItem.is_client_in_reach_out_list) {
        //         emptyFields.push("Please select is client in our reach out list");
        //     }
        // }


        // if (/^\s*$/.test(editedItem.actions_takens_to_get_client)) {
        //     emptyFields.push("Please enter value for action taken to get the client.\n");
        // }

        // if (/^\s*$/.test(editedItem.competitors_competitive_advantage)) {
        //     emptyFields.push("Please enter value for competitor's competitive advantage.\n");
        // }

        // if (/^\s*$/.test(editedItem.our_competitive_advantage)) {
        //     emptyFields.push("Please enter value for competitor's name.\n");
        // }

        // if (/^\s*$/.test(editedItem.key_updates_regarding_competition)) {
        //     emptyFields.push("Please enter value for key updates's regarding competition.\n");
        // }

        
        if (
            (editedItem.competitors_name == null || /^\s*$/.test(editedItem.competitors_name)) &&
            (editedItem.competitors_area_name == null || /^\s*$/.test(editedItem.competitors_area_name)) &&
            (editedItem.key_clients == null || /^\s*$/.test(editedItem.key_clients)) &&
            (editedItem.actions_takens_to_get_client == null || /^\s*$/.test(editedItem.actions_takens_to_get_client)) &&
            (editedItem.competitors_competitive_advantage == null || /^\s*$/.test(editedItem.competitors_competitive_advantage)) &&
            (editedItem.our_competitive_advantage == null || /^\s*$/.test(editedItem.our_competitive_advantage)) &&
            (editedItem.key_updates_regarding_competition == null || /^\s*$/.test(editedItem.key_updates_regarding_competition)) &&
            (editedItem.category_spent_by_client_name == null || /^\s*$/.test(editedItem.category_spent_by_client_name)) &&
            (editedItem.is_client_in_reach_out_list == null || /^\s*$/.test(editedItem.is_client_in_reach_out_list))
        ) {
            emptyFields.push("Please enter value for at least one field.\n");
        }

        return emptyFields;
    };

    

    // Validation end logic

    const handleSave = (index, e) => {
        const editedItem = monthlyUpdateList[index];
        // Validation 1: Dropdown should be mandatory


        const req_body = {
            "competitors_area_id": editedItem.competitors_area_name ?? null,
            "competitors_name": editedItem.competitors_name ?? null,
            "key_clients": editedItem.key_clients ?? null,
            "category_spent_by_client_id": 
                editedItem.category_spent_by_client_name && editedItem.category_spent_by_client_name.value === undefined 
                ? setSelectValue(categorySpentBycategory_spent_by_client_nameList, editedItem.category_spent_by_client_name).value 
                : (editedItem.category_spent_by_client_name?.value ?? null),
            "is_client_in_reach_out_list": getdropdownValYesNo(editedItem) ?? null,
            "actions_takens_to_get_client": editedItem.actions_takens_to_get_client ?? null,
            "competitors_competitive_advantage": 
                editedItem.competitors_competitive_advantage && editedItem.competitors_competitive_advantage.value === undefined 
                ? setSelectValue(CompetitorCompetitiveAdvantager, editedItem.competitors_competitive_advantage).value 
                : (editedItem.competitors_competitive_advantage?.value ?? null),
            "our_competitive_advantage": 
                editedItem.our_competitive_advantage && editedItem.our_competitive_advantage.value === undefined 
                ? setSelectValue(ourCompetitiveAdvantager, editedItem.our_competitive_advantage).value 
                : (editedItem.our_competitive_advantage?.value ?? null),
            "key_updates_regarding_competition": editedItem.key_updates_regarding_competition ?? null,
            "is_update": isAddClicked ? false : true,
            "update_id": isAddClicked ? null : editedItem.id,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        }
        // Check if it's an add operation
        if (isAddClicked) {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                editMontlyUpdate(req_body, index)
                setCurrentOperation(null)
            }
        } else {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                editMontlyUpdate(req_body, index)
                setCurrentOperation(null)
            }
        }


    };


    const handleClose = (index) => {
        // Remove the index from editableRows when clicking the close icon
        setEditableRows([]);
        setAddClick(false)
        // If the item is newly added and being closed, remove it from the list
        // if (!monthlyUpdateList[index].is_update) {
        //     setmonthlyUpdateList((prevList) => {
        //         const updatedList = [...prevList];
        //         updatedList.splice(index, 1);
        //         return updatedList;
        //     });
        // }

        // // Reset the is_edit flag
        // const updatedMonthlyUpdateList = [...monthlyUpdateList];
        // updatedMonthlyUpdateList[index].is_edit = false;
        // setmonthlyUpdateList(updatedMonthlyUpdateList);

        
        if (prevList.length > 0) {
            setmonthlyUpdateList(prevList);
        }

        if (!monthlyUpdateList[index].is_update && prevList.length > 0) {
            setmonthlyUpdateList(prevList);

            const updatedMonthlyUpdateList = [...prevList];
            updatedMonthlyUpdateList[index].is_edit = false;
            setmonthlyUpdateList(prevList);
        }
         
       

        if (currentOperation === 'add') {
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }
        setCurrentOperation(null)
    };

    const handleAdd = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...monthlyUpdateList];
            const newItem = {
                competitors_area_name: "",  // Set default value based on the clicked row
                competitors_name: "",
                key_clients: "",
                category_spent_by_client_name: "",
                is_client_in_reach_out_list: "",
                actions_takens_to_get_client: "",
                competitors_competitive_advantage: "",
                our_competitive_advantage: "",
                key_updates_regarding_competition: "",
                company_id: access_details['company_division_id']
            };
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, index + 1]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const handleAddNew = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...monthlyUpdateList];
            const newItem = {
                competitors_area_name: "",  // Set default value based on the clicked row
                competitors_name: "",
                key_clients: "",
                category_spent_by_client_name: "",
                is_client_in_reach_out_list: "",
                actions_takens_to_get_client: "",
                competitors_competitive_advantage: "",
                our_competitive_advantage: "",
                key_updates_regarding_competition: "",
                company_id: access_details['company_division_id']
            };
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, 0]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const handleInputChange = (index, field, value) => {
        console.log("Index ------------>>", index, field, value);
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        updatedMonthlyUpdateList[index][field] = value;
        setmonthlyUpdateList(updatedMonthlyUpdateList);
    };

    const setSelectValue = (list, value) => {
        console.log("List ---- >> Value ----->>>>", list, value);
        return list.find((data) => {
            return data.label === value;
        });

    }
    const setDropDownTrueFalse = (val) => {
        if (val == true) {
            return "Yes"
        }
        else if (val == false) {
            return "No"
        }
        else {
            return ""
        }
    }

    // validation popup close

    const closePopup = () => {
        setPopupOpen(false);
    };



    // dropdown scroll close start

    // const customStyles = {
    //     control: (provided) => ({
    //         ...provided,
    //         backgroundColor: 'white',
    //         color: 'red', // Change the background color of the control
    //         marginTop: 0, // Adjust the top margin
    //     }),
    //     menu: (provided) => ({
    //         ...provided,
    //         zIndex: 0,
    //         position: 'absolute',
    //         fontSize: '11px',
    //         marginTop: 0,
    //         marginBottom: 0, // Adjust the bottom margin
    //     }),
    //     option: (provided, state) => ({
    //         ...provided,
    //         backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
    //         color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
    //         height: '45px',

    //     }),
    // };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the text color of the control
            borderColor: state.isFocused ? 'black' : provided.borderColor, // Change border color when focused
            boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow, // Add box shadow when focused
            marginTop: 0, // Adjust the top margin
            '&:hover': { // Prevent blue highlight on hover
                borderColor: state.isFocused ? 'black' : provided.borderColor,
                boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow,
            }
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
                    color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
                    height: '45px',
            // '&:hover': {
            //     color: 'white', // Change text color on hover
            // },
        }),
    };

    // Add state variables for each dropdown
    const [isCompetitorsAreaDropdownOpen, setIsCompetitorsAreaDropdownOpen] = useState(false);
    const [isCategorySpentDropdownOpen, setIsCategorySpentDropdownOpen] = useState(false);
    const [isReachOutDropdownOpen, setIsReachOutDropdownOpen] = useState(false);
    const [CompetitorCompetitiveDropdownOpen, setCompetitorCompetitiveDropdownOpen] = useState(false);
    const [OurCompetitorDropdownOpen, setOurCompetitorDropdownOpen] = useState(false);
    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };

    // dropdown scroll close end

    return (
        <div className="parentContainer">
            {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-center'>
                                <p className="head-text">Delete Item</p>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Mention Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}
                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                />
                            </label>
                            <button onClick={() => setShowConfirmationModal(!showConfirmationModal)} className="cancel-btn">Cancel</button>
                            <button type="submit" className="confirm-btn">Confirm</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Validation popup start */}
            {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text">Information</p>
                            </div>
                        </div>
                        <form>
                            <ol>
                                {errorList.map((item) =>
                                    <li className='error-text'>{item}</li>
                                )}
                            </ol>
                            <button className="close-btn" onClick={closePopup}>Close</button>
                        </form>
                    </div>
                </div>
            )}
            {/* Validation popup end */}

            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}
            <div className="monthlyHeaderWrapper">
                <div>
                    <span style={{ fontSize: "13px", display: "block" }}>Annexures (For Information/ Additional Reading)</span>
                    <span className="monthly-Title-Table-header" style={{ display: "block" }}>Competition Updates</span>
                </div>

                {!isLoading && <HelpSidePanel content={"Information on what is happening in the ecosystem as a whole"} />}
            </div>
            {isLoading ? (<SmallSizedLoader />) :
                (<div className="table-div padStyle">
                    <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff"  , zIndex:9999999999}} />
                    <div className="table-container">
                        <table className="table-container-subdiv competition-table">
                            <thead className="competition-head">
                                <tr>
                                <th style={{ width: "5%" }} className="column-header-style fixed-column sr-column">Competitor’s Name</th>
                                <th style={{ width: "7%" }} className="column-header-style">Competitor’s Area</th>
                                <th style={{ width: "5%" }} className="column-header-style">Key Clients</th>
                                <th style={{ width: "5%" }} className="column-header-style">Category Spend By Clients</th>
                                <th style={{ width: "5%" }} className="column-header-style">Is Client In Our Reach-Out List?</th>
                                <th style={{ width: "5%" }} className="column-header-style">Action Taken To Get The Client</th>
                                <th style={{ width: "9%" }} className="column-header-style">
                                    <div className="d-flex justify-content-between">
                                        <div>Competitor's Competitive Advantage</div>
                                        <div>
                                            <a
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content="category_spent_by_client_name of the task or update"
                                                data-tooltip-place="bottom">
                                                <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }} />
                                            </a>
                                        </div>
                                    </div>
                                </th>
                                <th style={{ width: "9%" }} className="column-header-style">
                                    <div className="d-flex justify-content-between">
                                        <div>Our Competitive Advantage</div>
                                        <div>
                                            <a
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content="What does it impact?"
                                                data-tooltip-place="bottom">
                                                <img className="me-1" src={imageURL} alt="info" style={{ width: "15px" }} />
                                            </a>
                                        </div>
                                    </div>
                                </th>
                                <th style={{ width: "10%" }} className="column-header-style">Key Updates Regarding Competition</th>
                                <th style={{ width: "0%", display: props.ismonth ? "none" : "table-cell" }} className="column-header-style bg-wh">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="competition-body">
                            {monthlyUpdateList.length > 0 ? (
                                monthlyUpdateList.map((item, index) =>
                                    <tr key={index} className={editableRows.includes(index) ? "editable-row" : ""}>
                                        {/* Competitors Name  */}
                                        <td className="column-data-style fixed-column sr-column ">
                                            {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.competitors_name} onChange={(e) => handleInputChange(index, 'competitors_name', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.previous_competitors_name}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.competitors_name} onChange={(e) => handleInputChange(index, 'competitors_name', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* Competitiors Area */}
                                        <td className="column-data-style">
                                            {/* {editableRows.includes(index) ? (
                                                <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Select
                                                        id={index}
                                                        options={competitionAreaList}
                                                        value={setSelectValue(competitionAreaList, monthlyUpdateList[index].competitors_area_name)}
                                                        styles={customStyles}
                                                        menuPosition={'fixed'}
                                                        maxMenuHeight={100}
                                                        onChange={
                                                            (e) => handleInputChange(index, 'competitors_area_name', e)
                                                        }
                                                        closeMenuOnScroll={() => !isSelectHovered}
                                                        onMenuOpen={() => setIsCompetitorsAreaDropdownOpen(true)}
                                                        onMenuClose={() => setIsCompetitorsAreaDropdownOpen(false)}
                                                        menuIsOpen={isCompetitorsAreaDropdownOpen}
                                                        menuPortalTarget={document.body}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{item.previous_competitors_area_name}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={competitionAreaList}
                                                                value={setSelectValue(competitionAreaList, monthlyUpdateList[index].competitors_area_name)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'competitors_area_name', e)
                                                                }
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setIsCompetitorsAreaDropdownOpen(true)}
                                                                onMenuClose={() => setIsCompetitorsAreaDropdownOpen(false)}
                                                                menuIsOpen={isCompetitorsAreaDropdownOpen}
                                                                menuPortalTarget={document.body}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )} */}
                                            {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.competitors_area_name} onChange={(e) => handleInputChange(index, 'competitors_area_name', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.previous_competitors_area_name}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.competitors_area_name} onChange={(e) => handleInputChange(index, 'competitors_area_name', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* Key Clients */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.key_clients} onChange={(e) => handleInputChange(index, 'key_clients', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.previous_key_clients}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.key_clients} onChange={(e) => handleInputChange(index, 'key_clients', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* Category Spend By Clients  */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Select
                                                        id={index}
                                                        options={categorySpentBycategory_spent_by_client_nameList}
                                                        value={setSelectValue(categorySpentBycategory_spent_by_client_nameList, monthlyUpdateList[index].category_spent_by_client_name)}
                                                        styles={customStyles}
                                                        menuPosition={'fixed'}
                                                        maxMenuHeight={100}
                                                        onChange={
                                                            (e) => handleInputChange(index, 'category_spent_by_client_name', e)
                                                        }
                                                        closeMenuOnScroll={() => !isSelectHovered}
                                                        onMenuOpen={() => setIsCategorySpentDropdownOpen(true)}
                                                        onMenuClose={() => setIsCategorySpentDropdownOpen(false)}
                                                        menuIsOpen={isCategorySpentDropdownOpen}
                                                        menuPortalTarget={null}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{item.previous_category_spent_by_client_name}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={categorySpentBycategory_spent_by_client_nameList}
                                                                value={setSelectValue(categorySpentBycategory_spent_by_client_nameList, monthlyUpdateList[index].category_spent_by_client_name)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'category_spent_by_client_name', e)
                                                                }
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setIsCategorySpentDropdownOpen(true)}
                                                                onMenuClose={() => setIsCategorySpentDropdownOpen(false)}
                                                                menuIsOpen={isCategorySpentDropdownOpen}
                                                                menuPortalTarget={null}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* Is Client in our Reach out List  */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Select
                                                        id={index}
                                                        options={isClientInReachList}
                                                        value={isAddClicked ? setSelectValue(isClientInReachList, isClientInReachList) : setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].is_client_in_reach_out_list))}
                                                        styles={customStyles}
                                                        menuPosition={'fixed'}
                                                        maxMenuHeight={100}
                                                        onChange={
                                                            (e) => handleInputChange(index, 'is_client_in_reach_out_list', e)
                                                        }
                                                        closeMenuOnScroll={() => !isSelectHovered}
                                                        onMenuOpen={() => setIsReachOutDropdownOpen(true)}
                                                        onMenuClose={() => setIsReachOutDropdownOpen(false)}
                                                        menuIsOpen={isReachOutDropdownOpen}
                                                        menuPortalTarget={null}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{setDropDownTrueFalse(item.previous_is_client_in_reach_out_list)}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={isClientInReachList}
                                                                value={setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].is_client_in_reach_out_list))}
                                                                // value={isAddClicked ? setSelectValue(isClientInReachList, isClientInReachList) : setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].is_client_in_reach_out_list))}
                                                                // value={isAddClicked ? null : setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].is_client_in_reach_out_list))}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'is_client_in_reach_out_list', e)
                                                                }
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setIsReachOutDropdownOpen(true)}
                                                                onMenuClose={() => setIsReachOutDropdownOpen(false)}
                                                                menuIsOpen={isReachOutDropdownOpen}
                                                                menuPortalTarget={null}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* Action taken to ghet the client  */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.actions_takens_to_get_client} onChange={(e) => handleInputChange(index, 'actions_takens_to_get_client', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.previous_actions_takens_to_get_client}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.actions_takens_to_get_client} onChange={(e) => handleInputChange(index, 'actions_takens_to_get_client', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* Competitors Competitive advantages  */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                          
                                            <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Select
                                                        id={index}
                                                        options={CompetitorCompetitiveAdvantager}
                                                        value={setSelectValue(CompetitorCompetitiveAdvantager, monthlyUpdateList[index].competitors_competitive_advantage)}
                                                        styles={customStyles}
                                                        menuPosition={'fixed'}
                                                        maxMenuHeight={100}
                                                        onChange={
                                                            (e) => handleInputChange(index, 'competitors_competitive_advantage', e)
                                                        }
                                                        closeMenuOnScroll={() => !isSelectHovered}
                                                        onMenuOpen={() => setCompetitorCompetitiveDropdownOpen(true)}
                                                        onMenuClose={() => setCompetitorCompetitiveDropdownOpen(false)}
                                                        menuIsOpen={CompetitorCompetitiveDropdownOpen}
                                                        menuPortalTarget={document.body}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{item.previous_competitors_competitive_advantage}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={CompetitorCompetitiveAdvantager}
                                                                value={setSelectValue(CompetitorCompetitiveAdvantager, monthlyUpdateList[index].competitors_competitive_advantage)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'competitors_competitive_advantage', e)
                                                                }
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setCompetitorCompetitiveDropdownOpen(true)}
                                                                onMenuClose={() => setCompetitorCompetitiveDropdownOpen(false)}
                                                                menuIsOpen={CompetitorCompetitiveDropdownOpen}
                                                                menuPortalTarget={document.body}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                            
                                        </td>
                                        {/* Our Competitive Advantages  */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                            <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Select
                                                        id={index}
                                                        options={ourCompetitiveAdvantager}
                                                        value={setSelectValue(ourCompetitiveAdvantager, monthlyUpdateList[index].our_competitive_advantage)}
                                                        styles={customStyles}
                                                        menuPosition={'fixed'}
                                                        maxMenuHeight={100}
                                                        onChange={
                                                            (e) => handleInputChange(index, 'our_competitive_advantage', e)
                                                        }
                                                        closeMenuOnScroll={() => !isSelectHovered}
                                                        onMenuOpen={() => setOurCompetitorDropdownOpen(true)}
                                                        onMenuClose={() => setOurCompetitorDropdownOpen(false)}
                                                        menuIsOpen={OurCompetitorDropdownOpen}
                                                        menuPortalTarget={document.body}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{item.previous_our_competitive_advantage}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={ourCompetitiveAdvantager}
                                                                value={setSelectValue(ourCompetitiveAdvantager, monthlyUpdateList[index].our_competitive_advantage)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'our_competitive_advantage', e)
                                                                }
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setOurCompetitorDropdownOpen(true)}
                                                                onMenuClose={() => setOurCompetitorDropdownOpen(false)}
                                                                menuIsOpen={OurCompetitorDropdownOpen}
                                                                menuPortalTarget={document.body}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* Key Updates regarding competition  */}
                                        <td className="column-data-style update-month">
                                            {editableRows.includes(index) ? (
                                                <div>
                                                    {/* {item && item.previous_key_updates_regarding_competition && item.previous_key_updates_regarding_competition !=null && item.previous_key_updates_regarding_competition !=undefined && item.previous_key_updates_regarding_competition!="" ? <label className={index == 0 ? "update-message-bottom": "update-message-top"}>{item.previous_key_updates_regarding_competition}</label>: null}  */}
                                                    <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.key_updates_regarding_competition} onChange={(e) => handleInputChange(index, 'key_updates_regarding_competition', e.target.value)} />
                                                </div>
                                            ) : (
                                                <div>
                                                   {/* <p>{item.is_edit ? item.previous_key_updates_regarding_competition : item.key_updates_regarding_competition}</p> */}
                                                   {item.previous_key_updates_regarding_competition && (
                                                        <pre className="preStyleFormat" >
                                                            {item.previous_key_updates_regarding_competition}
                                                        </pre>
                                                          )}
                                                    {item.is_edit ?
                                                        <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.key_updates_regarding_competition} onChange={(e) => handleInputChange(index, 'key_updates_regarding_competition', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        <td className={index % 2 === 0 ? "column-data-style bg-ylw " : "column-data-style bg-wh "} style={{display: props.ismonth ? "none" : "column-data-style" }}>
                                            {editableRows.includes(index) ? (
                                                <div className="d-flex justify-content-around">
                                                    <div className="d-flex justify-content-center">
                                                        <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                        <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ width: "100px" }}>
                                                    {!item.is_edit ?
                                                        <div className="d-flex justify-content-around">
                                                            <div>
                                                                <img src={edit} className="edit" alt="Edit" style={{ width: "13px" }} onClick={() => handleEdit(index)} />
                                                            </div>
                                                            <div>
                                                                <img src={remove} className="delete" alt="Delete" style={{ width: "13px" }} onClick={() => handleDelete(index)} />
                                                            </div>
                                                            <div>
                                                                <img src={add} className="add" alt="Add" style={{ width: "13px" }} onClick={() => handleAdd(index, "add")} />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="d-flex justify-content-around">
                                                            <div className="d-flex justify-content-center">
                                                                <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                                <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                     ))
                                     : (
                                         <tr>
                                             <td colSpan="12" className="text-center">
                                                 <div className="add">
                                                     <div className="add-btn" onClick={handleAddNew}>
                                                         <span>Add New</span>
                                                     </div>
                                                 </div>
                                             </td>
                                         </tr>
                                     )}
                            </tbody>
                        </table>
                    </div>
                </div>)}
        </div>
    );
};