import React, { useEffect, useState } from "react";
import "./UpcomingClients.css";
import imageURL from '../../../../assets/images/infoIcon.svg';
import edit from '../../../../assets/images/edit-icon.svg';
import remove from '../../../../assets/images/remove-icon.svg';
import add from '../../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../../components/help_side_panel/HelpSidePanel";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import SmallSizedLoader from "../../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '../../../../api_interceptor/api'
import Select from 'react-select';
import Alert_Popup from "../../../../components/alert_popup/Alert_Popup";
import close from '../../../../assets/images/close.png';
import { updateState, roundToTwoDecimals } from "../../../../common_function/common_function";
import _ from 'lodash';


export default function UpcomingClients(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [monthlyUpdateList, setmonthlyUpdateList] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const [competitionAreaList, setCompetitionAreaList] = useState([]);
    const [categorySpentBycategory_spent_by_client_nameList, setcategorySpentBycategory_spent_by_client_nameList] = useState([]);
    const [isClientInReachList, setIsClientInReachList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const company_id = useSelector((state) => state.user.companyID);
    const [isstatus, setStatusList] = useState([]);
    const [isExistingClient, setisExistingClient] = useState([]);
    const [isAOP, setisAOP] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [prevList, setPrevList] = useState([]);


    
    const currentDate = new Date();
    let month = currentDate.getMonth(); 
    let year = currentDate.getFullYear();
    
    if (month == 0) {
        month = 11; 
        year--;     
    } else {
        month--;   
    }
    
    const monthName = new Date(year, month).toLocaleString('default', { month: 'long' });

    useEffect(() => {
        if (userData && userData['UserId']) {
            getMonthlyUpdate(userData['UserId'])
        }
    }, [userData]);

    const getMonthlyUpdate = (UID) => {
        updateState(props.setPreviewEnabled,userData['UserId'],access_details['company_division_id'],props.setPreviewList,company_id && 'value' in company_id ? company_id['value'] : "")
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-upcoming-clients';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: company_id && 'value' in company_id ? company_id['value'] : ""
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data.help_details && (response.data.help_details).length > 0) {
                        setmonthlyUpdateList(response.data.new_client_list)
                        sethelpDetails(response.data.help_details)
                        getAreaOfUpdateDetails(userData['UserId'], response.data.monthly_data_list)
                        setCurrency(access_details['currency'])
                        setIsLoading(false)
                    }
                    else {
                        sethelpDetails(response.data.help_details)
                        getAreaOfUpdateDetails(userData['UserId'], response.data.monthly_data_list)
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const getAreaOfUpdateDetails = (UID, valdata) => {
        const API_URL = 'monthlymodule/api/get-aspirational-clients-dropdown-details';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id'],
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setStatusList(response.data.upcoming_stage_list);
                        setisExistingClient(response.data.client_status_list);
                        setisAOP(response.data.aop_descision_list);
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }


    const handleEdit = (index) => {
        // const updatedMonthlyUpdateList = [...monthlyUpdateList];

        if (prevList.length === 0) {
            setPrevList(monthlyUpdateList.map(item => ({ ...item }))); // Save the original state
        }

        const updatedMonthlyUpdateList = _.cloneDeep(monthlyUpdateList);
        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);

        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }

        if (editableRows.length === 0 && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });

            console.log({ currentOperation });
            setCurrentOperation('edit');
            setmonthlyUpdateList(updatedMonthlyUpdateList);
        }
    };

    const handleDelete = (index) => {
        if (editableRows.length === 0) {
            setDeleteReason(null)
            setDeletingIndex(index);
            setShowConfirmationModal(true);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
            handleDeleteAPI(deletingIndex, deleteReason);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };

    const handleDeleteAPI = (index, reason) => {
        const editedItem = monthlyUpdateList[index];
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/delete-upcoming-clients';
        const req_body = {
            "id": editedItem.id,
            "deleted_reason": reason,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    getMonthlyUpdate(userData['UserId'])
                }
                else {
                    getMonthlyUpdate(userData['UserId'])
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    const editMontlyUpdate = (editedItem, index) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/add-upcoming-clients';
        const req_body = editedItem;
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMonthlyUpdate(userData['UserId'])
                }
                else {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMonthlyUpdate(userData['UserId'])
                }

            })
            .catch((error) => {
                setAddClick(false)
                setIsLoading(false)
                console.error(error);
            });
    }

    const getVal = (val) => {
        if (val == true) {
            return 1
        }
        if (val == false) {
            return 0
        }
        if (val == 0) {
            return 0
        }
        if (val == 1) {
            return 1
        }
    }

   

    // Validation start logic

    const validateMonthlyUpdate = (editedItem) => {
        const emptyFields = [];

        // Validation 1: Dropdown should be mandatory
       

        if (/^\s*$/.test(editedItem.client)) {
            emptyFields.push("Please enter value  for client.\n");
        }    
        
        if (/^\s*$/.test(editedItem.opportunity)) {
            emptyFields.push("Please enter value  for opportunity.\n");
        }  

        if (/^\s*$/.test(editedItem.annual_contract)) {
            emptyFields.push("Please enter value for annual contract.\n");
        } else {
            const annualContractStr = String(editedItem.annual_contract);
            const hasDecimal = annualContractStr.includes('.');
            
            if (hasDecimal) {
                const [wholePart, decimalPart = ''] = annualContractStr.split('.');
                if (wholePart.length > 6) {
                    emptyFields.push("Value cannot exceed 6 digits.\n");
                }
            } else {
                if (annualContractStr.length > 6) {
                    emptyFields.push("Value cannot exceed 6 digits.\n");
                }
            }
        }

        if (/^\s*$/.test(editedItem.update_for_the_month)) {
            emptyFields.push(`Please enter value for update for ${monthName.toLowerCase()}'${year}\n`);
        }

        // if (/^\s*$/.test(editedItem.update_for_Feb_24)) {
        //     emptyFields.push("Please enter value  for update for feb'24.\n");
        // }
        
        if (!editedItem.existing_client) {
            emptyFields.push("Please select existing client.\n");
        }

        if (!editedItem.aop) {
            emptyFields.push("Please select AOP.\n");
        }

        if (!editedItem.stage) {
            emptyFields.push("Please select stage.\n");
        }      

        return emptyFields;
    };


    // Validation end logic

    const handleSave = (index, e) => {
        const editedItem = monthlyUpdateList[index];
        // Validation 1: Dropdown should be mandatory

        const req_body = {
            "client" : editedItem.client,
            "existing_client" : editedItem.existing_client && editedItem.existing_client.value == undefined ? setSelectValue(isExistingClient, editedItem.existing_client).value : editedItem.existing_client.value,
            "aop" : editedItem.aop && editedItem.aop.value == undefined ? setSelectValue(isAOP, editedItem.aop).value : editedItem.aop.value,
            "opportunity" : editedItem.opportunity,
            "annual_contract" : roundToTwoDecimals(editedItem.annual_contract),
            "stage" : editedItem.stage && editedItem.stage.value == undefined ? setSelectValue(isstatus, editedItem.stage).value : editedItem.stage.value,
            "update_for_the_month" : editedItem.update_for_the_month,
            // "update_for_Feb_24": editedItem.update_for_Feb_24,
            "is_update": isAddClicked ? false : true,
            "update_id": isAddClicked ? null : editedItem.id,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: company_id && 'value' in company_id ? company_id['value'] : ""
        }

         // Check if it's an add operation
         if (isAddClicked) {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                editMontlyUpdate(req_body, index)
        setCurrentOperation(null)
            }
        } else {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                editMontlyUpdate(req_body, index)
        setCurrentOperation(null)
            }
        }

       
    };


    const handleClose = (index) => {
        // Remove the index from editableRows when clicking the close icon
        setEditableRows([]);
        setAddClick(false)
        // If the item is newly added and being closed, remove it from the list
        // if (!monthlyUpdateList[index].is_update) {
        //     setmonthlyUpdateList((prevList) => {
        //         const updatedList = [...prevList];
        //         updatedList.splice(index, 1);
        //         return updatedList;
        //     });
        // }

        // // Reset the is_edit flag
        // const updatedMonthlyUpdateList = [...monthlyUpdateList];
        // updatedMonthlyUpdateList[index].is_edit = false;
        // setmonthlyUpdateList(updatedMonthlyUpdateList);

        if (prevList.length > 0) {
            setmonthlyUpdateList(prevList);
        }

        if (!monthlyUpdateList[index].is_update  && prevList.length > 0) {
            setmonthlyUpdateList(prevList);

            const updatedMonthlyUpdateList = [...prevList];
            updatedMonthlyUpdateList[index].is_edit = false;
            setmonthlyUpdateList(prevList);
        }
         
      

        if (currentOperation === 'add') {
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }
        setCurrentOperation(null)
    };


    const handleAdd = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        const newItem = {
            client : "",
            existing_client : "",
            aop : "",
            opportunity : "",
            annual_contract : "",
            stage : "",
            update_for_the_month : "",
            update_for_Feb_24: "",
            company_id: company_id && 'value' in company_id ? company_id['value'] : ""
        };
        setmonthlyUpdateList((prevList) => {
            const updatedList = [...prevList];
            updatedList.splice(index + 1, 0, newItem);
            return updatedList;
        });
        setEditableRows([...editableRows, index + 1]);
        setCurrentOperation('add');
        setAddClick(true); // Set isAddClicked to true for add operation
    }
    };

    const handleAddNew = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        const newItem = {
            client : "",
            existing_client : "",
            aop : "",
            opportunity : "",
            annual_contract : "",
            stage : "",
            update_for_the_month : "",
            update_for_Feb_24: "",
            company_id: company_id && 'value' in company_id ? company_id['value'] : ""
        };
        setmonthlyUpdateList((prevList) => {
            const updatedList = [...prevList];
            updatedList.splice(index + 1, 0, newItem);
            return updatedList;
        });
        setEditableRows([...editableRows, 0]);
        setCurrentOperation('add');
        setAddClick(true); // Set isAddClicked to true for add operation
    }
    };

    const handleInputChange = (index, field, value) => {
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        // updatedMonthlyUpdateList[index][field] = value;
        const updatedValue = typeof value === 'object' ? value.label : value;
        updatedMonthlyUpdateList[index][field] = updatedValue;
        setmonthlyUpdateList(updatedMonthlyUpdateList);
    };

    const setSelectValue = (list, value) => {
        console.log("List ---- >> Value ----->>>>", list, value);
        return list.find((data) => {
            return data.label === value;
        });

    }
    const setDropDownTrueFalse = (val) => {
        if (val == true) {
            return "Yes"
        }
        else if (val == false) {
            return "No"
        }
        else {
            return ""
        }
    }

    function formatCurrency(amount) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return formatter.format(amount);
    }

    // validation popup close

    const closePopup = () => {
        setPopupOpen(false);
    };

   // dropdown scroll close start

    // const customStyles = {
    //     control: (provided) => ({
    //         ...provided,
    //         backgroundColor: 'white',
    //         color: 'red', // Change the background color of the control
    //         marginTop: 0, // Adjust the top margin
    //     }),
    //     menu: (provided) => ({
    //         ...provided,
    //         zIndex: 0,
    //         position: 'absolute',
    //         fontSize: '11px',
    //         marginTop: 0,
    //         marginBottom: 0, // Adjust the bottom margin
    //     }),
    //     option: (provided, state) => ({
    //         ...provided,
    //         backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
    //         color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
    //         height: '45px',

    //     }),
    // };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the text color of the control
            borderColor: state.isFocused ? 'black' : provided.borderColor, // Change border color when focused
            boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow, // Add box shadow when focused
            marginTop: 0, // Adjust the top margin
            '&:hover': { // Prevent blue highlight on hover
                borderColor: state.isFocused ? 'black' : provided.borderColor,
                boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow,
            }
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
                    color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
                    height: '45px',
            // '&:hover': {
            //     color: 'white', // Change text color on hover
            // },
        }),
    };

    // Add state variables for each dropdown
    const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
    const [isExistingClientDropdownOpen, setisExistingClientDropdownOpen] = useState(false);
    const [isAOPDropdownOpen, setisAOPDropdownOpen] = useState(false);

    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };

    // dropdown scroll close end

    return (
        <div className="parentContainer" style={{marginBottom:"20px"}}>
            {/* {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>
                            <div className='col-6 text-start'>
                            </div>
                            <div className='col-6 text-end'>
                                <a onClick={() => {
                                    setShowConfirmationModal(!showConfirmationModal)
                                }}>< i class="fa fa-times" aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}

                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                />
                            </label>
                            <button type="submit">Delete</button>
                        </form>
                    </div>
                </div>
            )} */}

            {/* Validation popup start */}

            {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-center'>
                                <p className="head-text">Delete Item</p>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Mention Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}
                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                />
                            </label>
                            <button onClick={() => setShowConfirmationModal(!showConfirmationModal)} className="cancel-btn">Cancel</button>
                            <button type="submit" className="confirm-btn">Confirm</button>
                        </form>
                    </div>
                </div>
            )}
            
         {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text" style={{fontSize:"20px"}}>Information</p>
                            </div>
                        </div>
                        <div className='ChatBodyDetails' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto',overflowY:'auto', padding:"0rem"}}>
                                {errorList.map((item, index) => (
                                    <div style={{ display : 'flex'}}>
                                        <div className=' d-flex mb-3' style={{width:'90%'}}>
                                            {`${index+1}) ${item}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        <button className="close-btn" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
            {/* Validation popup end */}

            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}
            <div className="monthlyHeaderWrapper">
                <span className="monthly-Title-Table-header">{"Upcoming Clients / Contracts"}</span>
                {!isLoading && <HelpSidePanel content={"Update on opportunities where decision has been made and/or are being pursued"} />}
            </div>
            {isLoading ? (<SmallSizedLoader />) :
                (<div className="table-div padStyle ">
                    <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff" , zIndex:9999999999 }} />
                    <div className="table-container">
                        <table className="table-container-subdiv open-actionTable">
                            <thead className="aopClients-head">
                            <tr>
                                <th style={{ width: "5%" }} className="column-header-style fixed-column sr-column">Client
                                      <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>
                                </th>
                                <th style={{ width: "5%" }} className="column-header-style">Existing Client? 
                                    <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>
                                </th>
                                <th style={{ width: "5%" }} className="column-header-style">AOP?
                                     <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>
                                </th>
                                <th style={{ width: "5%" }} className="column-header-style">Opportunity
                                     <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>
                                </th>
                                <th style={{ width: "8%" }} className="column-header-style">Annual Contract Value ({currency})
                                    <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>
                                </th>
                                <th style={{ width: "5%" }} className="column-header-style">Stage
                                     <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>
                                </th>
                                <th style={{ width: "10%" }} className="column-header-style">Update For {monthName}'{year}
                                    <span style={{ marginLeft: '4px', color: 'red' }} aria-label="required">*</span>
                                </th>
                                {/* <th style={{ width: "10%" }} className="column-header-style">Update For Feb'24</th> */}
                                <th style={{ width: "0%", display: props.ismonth ? "none" : "table-cell" }} className="column-header-style bg-wh fixed-column actions-column">Actions</th>
                            </tr>

                            </thead>
                            <tbody className="aopClients-body">
                            {monthlyUpdateList.length > 0 ? (
                                monthlyUpdateList.map((item, index) =>
                                    <tr key={index} className={editableRows.includes(index) ? "editable-row" : ""}>
                                        {/* Client Name*/}
                                        <td className="column-data-style fixed-column sr-column">
                                            {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.client} onChange={(e) => handleInputChange(index, 'client', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.previous_client}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.client} onChange={(e) => handleInputChange(index, 'client', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* IsExisting*/}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Select
                                                        id={index}
                                                        options={isExistingClient}
                                                        // value={setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].current))}
                                                        // value={isAddClicked ? setSelectValue(isClientInReachList, isClientInReachList) : setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].status))}
                                                        value={setSelectValue(isExistingClient, monthlyUpdateList[index].existing_client)}
                                                        styles={customStyles}
                                                        menuPosition={'fixed'}
                                                        maxMenuHeight={100}
                                                        onChange={
                                                            (e) => handleInputChange(index, 'existing_client', e)
                                                        }
                                                        closeMenuOnScroll={() => !isSelectHovered}
                                                        onMenuOpen={() => setisExistingClientDropdownOpen(true)}
                                                        onMenuClose={() => setisExistingClientDropdownOpen(false)}
                                                        menuIsOpen={isExistingClientDropdownOpen}
                                                        menuPortalTarget={null}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{item.previous_existing_client}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Select
                                                            id={index}
                                                            options={isExistingClient}
                                                            // value={setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].existing_client))}
                                                            value={setSelectValue(isExistingClient, monthlyUpdateList[index].existing_client)}
                                                            // value={isAddClicked ? setSelectValue(isClientInReachList, isClientInReachList) : setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].current))}
                                                            styles={customStyles}
                                                            menuPosition={'fixed'}
                                                            maxMenuHeight={100}
                                                            onChange={
                                                                (e) => handleInputChange(index, 'existing_client', e)
                                                            }
                                                            closeMenuOnScroll={() => !isSelectHovered}
                                                            onMenuOpen={() => setisExistingClientDropdownOpen(true)}
                                                            onMenuClose={() => setisExistingClientDropdownOpen(false)}
                                                            menuIsOpen={isExistingClientDropdownOpen}
                                                            menuPortalTarget={null}
                                                        />
                                                    </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/*AOP*/}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Select
                                                        id={index}
                                                        options={isAOP}
                                                        // value={setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].current))}
                                                        // value={isAddClicked ? setSelectValue(isClientInReachList, isClientInReachList) : setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].status))}
                                                        value={setSelectValue(isAOP, monthlyUpdateList[index].aop)}
                                                        styles={customStyles}
                                                        menuPosition={'fixed'}
                                                        maxMenuHeight={100}
                                                        onChange={
                                                            (e) => handleInputChange(index, 'aop', e)
                                                        }
                                                        closeMenuOnScroll={() => !isSelectHovered}
                                                        onMenuOpen={() => setisAOPDropdownOpen(true)}
                                                        onMenuClose={() => setisAOPDropdownOpen(false)}
                                                        menuIsOpen={isAOPDropdownOpen}
                                                        menuPortalTarget={null}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{item.previous_aop}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Select
                                                            id={index}
                                                            options={isAOP}
                                                            // value={setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].aop))}
                                                            value={setSelectValue(isAOP, monthlyUpdateList[index].aop)}
                                                            // value={isAddClicked ? setSelectValue(isClientInReachList, isClientInReachList) : setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].current))}
                                                            styles={customStyles}
                                                            menuPosition={'fixed'}
                                                            maxMenuHeight={100}
                                                            onChange={
                                                                (e) => handleInputChange(index, 'aop', e)
                                                            }
                                                            closeMenuOnScroll={() => !isSelectHovered}
                                                            onMenuOpen={() => setisAOPDropdownOpen(true)}
                                                            onMenuClose={() => setisAOPDropdownOpen(false)}
                                                            menuIsOpen={isAOPDropdownOpen}
                                                            menuPortalTarget={null}
                                                        />
                                                    </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/*Opportunity*/}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.opportunity} onChange={(e) => handleInputChange(index, 'opportunity', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.previous_opportunity}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.opportunity} onChange={(e) => handleInputChange(index, 'opportunity', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/*Annual Contract*/}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput budgetInput" style={{ width: "100%" }} type="number" value={item.annual_contract} onChange={(e) => handleInputChange(index, 'annual_contract', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.previous_annual_contract}</p>
                                                    {item.is_edit ?
                                                        <input className="tableInput budgetInput" style={{ width: "100%" }} type="number" value={item.annual_contract} onChange={(e) => handleInputChange(index, 'annual_contract', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Select
                                                        id={index}
                                                        options={isstatus}
                                                        // value={setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].current))}
                                                        // value={isAddClicked ? setSelectValue(isClientInReachList, isClientInReachList) : setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].status))}
                                                        value={setSelectValue(isstatus, monthlyUpdateList[index].stage)}
                                                        styles={customStyles}
                                                        menuPosition={'fixed'}
                                                        maxMenuHeight={100}
                                                        onChange={
                                                            (e) => handleInputChange(index, 'stage', e)
                                                        }
                                                        closeMenuOnScroll={() => !isSelectHovered}
                                                        onMenuOpen={() => setIsStatusDropdownOpen(true)}
                                                        onMenuClose={() => setIsStatusDropdownOpen(false)}
                                                        menuIsOpen={isStatusDropdownOpen}
                                                        menuPortalTarget={null}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{item.previous_stage}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                        <Select
                                                            id={index}
                                                            options={isstatus}
                                                            // value={setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].status))}
                                                            value={setSelectValue(isstatus, monthlyUpdateList[index].stage)}
                                                            // value={isAddClicked ? setSelectValue(isClientInReachList, isClientInReachList) : setSelectValue(isClientInReachList, setDropDownTrueFalse(monthlyUpdateList[index].current))}
                                                            styles={customStyles}
                                                            menuPosition={'fixed'}
                                                            maxMenuHeight={100}
                                                            onChange={
                                                                (e) => handleInputChange(index, 'stage', e)
                                                            }
                                                            closeMenuOnScroll={() => !isSelectHovered}
                                                            onMenuOpen={() => setIsStatusDropdownOpen(true)}
                                                            onMenuClose={() => setIsStatusDropdownOpen(false)}
                                                            menuIsOpen={isStatusDropdownOpen}
                                                            menuPortalTarget={null}
                                                        />
                                                    </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* Update on the client*/}


                                        {/* <td className="column-data-style update-month">
                                            {editableRows.includes(index) ? (
                                                <div> */}
                                                    {/* {item && item.previous_update_for_the_month && item.previous_update_for_the_month !=null && item.previous_update_for_the_month !=undefined && item.previous_update_for_the_month!="" ? <label className={index == 0 ? "update-message-bottom": "update-message-top"}>{item.previous_update_for_the_month}</label>: null}  */}
                                                    {/* <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.update_for_the_month} onChange={(e) => handleInputChange(index, 'update_for_the_month', e.target.value)} />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{item.is_edit ? item.previous_update_for_the_month : item.update_for_the_month}</p>
                                                    {item.is_edit ?
                                                        <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.update_for_the_month} onChange={(e) => handleInputChange(index, 'update_for_the_month', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td> */}

                                            <td className="column-data-style update-month">
                                            {editableRows.includes(index) ? (
                                                <div>
                                                    <textarea
                                                        className="textTable"
                                                        spellCheck={true}
                                                        placeholder="Start typing..."
                                                        style={{ width: "100%" }}
                                                        rows="2"
                                                        cols="50"
                                                        value={item.update_for_the_month}
                                                        onChange={(e) => handleInputChange(index, 'update_for_the_month', e.target.value)}
                                                    />
                                                                                </div>
                                                    ) : (
                                                        <div>
                                                            {item.previous_update_for_the_month && (
                                                                <pre className="preStyleFormat" >
                                                                    {item.previous_update_for_the_month}
                                                                </pre>
                                                            )}
                                                            {item.is_edit ?
                                                                <textarea
                                                                className="textTable"
                                                                spellCheck={true}
                                                                placeholder="Start typing..."
                                                                style={{ width: "100%" }}
                                                                rows="2"
                                                                cols="50"
                                                                value={item.update_for_the_month}
                                                                onChange={(e) => handleInputChange(index, 'update_for_the_month', e.target.value)}
                                                            />
                                                                : ""
                                                            }
                                                        </div>
                                                    )}
                                                </td>

                                           {/* Update for Month Feb'24 */}

                                           {/* <td className="column-data-style update-month">
                                            {editableRows.includes(index) ? (
                                                <div>
                                                    {/* {item && item.previous_update_for_the_month && item.previous_update_for_the_month !=null && item.previous_update_for_the_month !=undefined && item.previous_update_for_the_month!="" ? <label className={index == 0 ? "update-message-bottom": "update-message-top"}>{item.previous_update_for_the_month}</label>: null}  */}
                                                    {/* <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.update_for_Feb_24} onChange={(e) => handleInputChange(index, 'update_for_Feb_24', e.target.value)} />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{item.is_edit ? item.previous_update_for_Feb_24 : item.update_for_Feb_24}</p>
                                                    {item.is_edit ?
                                                        <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.update_for_Feb_24} onChange={(e) => handleInputChange(index, 'update_for_Feb_24', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td> */}
                                        <td className={index % 2 === 0 ? "column-data-style bg-ylw" : "column-data-style bg-wh"} style={{display: props.ismonth ? "none" : "column-data-style" }}>
                                            {editableRows.includes(index) ? (
                                                <div className="d-flex justify-content-around">
                                                    <div className="d-flex justify-content-center">
                                                        <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                        <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ width: "100px" }}>
                                                    {!item.is_edit ?
                                                    <div className="d-flex justify-content-around">
                                                        <div>
                                                            <img src={edit} className="edit" alt="Edit" style={{ width: "13px" }} onClick={() => handleEdit(index)} />
                                                        </div>
                                                        <div>
                                                            <img src={remove} className="delete" alt="Delete" style={{ width: "13px" }} onClick={() => handleDelete(index)} />
                                                        </div>
                                                        <div>
                                                            <img src={add} className="add" alt="Add" style={{ width: "13px" }} onClick={() => handleAdd(index, "add")} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="d-flex justify-content-around">
                                                        <div className="d-flex justify-content-center">
                                                            <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                            <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                        </div>
                                                    </div>
}
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                    ))
                                    : (
                                        <tr>
                                            <td colSpan="9" className="text-center">
                                                <div className="add">
                                                    <div className="add-btn" onClick={handleAddNew}>
                                                        <span>Add New</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>)}
        </div>
    );
}