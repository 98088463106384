import React, { useEffect, useRef } from "react";
import Logo from '../../assets/images/Planet Logo@2x.png'
import FilterIcon from '../../assets/images/filter icon.svg'
import Notifications from '../../assets/images/Notifications.svg'
import ProfileIcon from '../../assets/images/Profile icon.svg'
import User from '../../assets/images/user.png';
import Dropdown from '../../assets/images/Icon ionic-ios-arrow-down@2x.png';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment-timezone';
import { deleteAllCookies } from "../../api_interceptor/api";
import Loader from "../loader/Loader";
import api from '../../api_interceptor/api'
import { setCompanyIDDetails, setDivisionIDDetails, setDivisionList, setEmailIdRedux, setPasswordRedux, setUserDetails } from "../../redux/action/Actions";
import CancelBluIconComment from '../../assets/images/Cancel icon Comments.svg'
import { formatDateTimeNotification } from "../../common_function/common_function";
import { useNavigate, useParams} from 'react-router-dom';
import Select, {components} from 'react-select';
import searchIcnDropdown from '../../assets/images/searchIcnDropdown.svg'
import arrowdownblue from '../../assets/images/arrow-down-blue.svg'
import CommementLightBlue from '../../assets/images/Comment icon light blue icon.svg'



const MilestoneHeader = (props) => {
    const { paramMilestoneId } = useParams();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [landingCompanyDetails, setlandingCompanyDetails] = useState([]);
    const [landingDivisionDetails, setlandingDivisionDetails] = useState([]);
    const dispatch = useDispatch();

    const userData = useSelector((state) => state.user.userData);
    const company_id = useSelector((state) => state.user.companyID);
    const division_id = useSelector((state) => state.user.divisionID);

    const Isremember = useSelector((state) => state.user.IsRemeberMeChecked);
    const isEmailThere = useSelector((state) => state.user.EmailID);
    const isPasswordThere = useSelector((state) => state.user.Password);
    const [isCompanyDropdownOpen, setCompanyDropdownOpen] = useState(false);
    const [showNotification, setshowNotification] = useState(false);
    const dropdownRef = useRef(null);
    const projectListRef = useRef(null);
    const companyDropdownRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState('Select an option');
    // const [EntityList, setEntityList] = useState([]);
    // const [ProjectList, setProjectList] = useState([]);
    // const [ProjectStatusList, setProjectStatusList] = useState([]);
    const [ShowAdvancedFilterModal, setShowAdvancedFilterModal] = useState(false);
    const [NotificationDetails, setNotificationDetails] = useState([]);
    const [NotificationDetailsCount, setNotificationDetailsCount] = useState(null);
    const [tempSelectedProject, setTempSelectedProject] = useState(0);
    const [selectedMilestoneOption, setSelectedMilestoneOption] = useState([]);

    //Calling Master API's
    useEffect(() => {
        if (userData && userData['UserId']) {
            // getAllMasterDetails()
            getAllNoticationList()
        }
    }, [userData]);

    const getAllNoticationList = () => {
        setIsLoading(true)
        const API_URL = 'api/getAllNoticationByUserId';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data.NotificationList) {
                    setNotificationDetails(response.data.NotificationList)
                    setNotificationDetailsCount(response.data.NotificationList.length)
                    setIsLoading(false)
                    
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    useEffect(() => {
        if (landingCompanyDetails.length > 0) {
            setIsLoading(false)
            company_id == 0 ? setSelectedOption(landingCompanyDetails[0].label) : setSelectedOption(company_id.label);
            getUserDivision(landingCompanyDetails);

        }
    }, [landingCompanyDetails]);

    useEffect(() => {
        if (landingDivisionDetails.length > 0) {
            dispatch(setDivisionList(landingDivisionDetails))
            props.onCompChange(landingDivisionDetails)
        }
        else {
            dispatch(setDivisionList([]))
            props.onCompChange([])

        }
    }, [landingDivisionDetails]);


    useEffect(() => {
        if (landingCompanyDetails.length > 0) {
            getUserDivision(landingCompanyDetails);
        }
    }, [selectedOption]);

    const getData = () => {
        props.onCompChange([])
    }

    const getUserCompany = () => {
        setIsLoading(true)
        const API_URL = '/get-user-companies';
        const req_body = {
            user_id: userData['user_id'],
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data && response.data.company_list && (response.data.company_list).length > 0) {
                    if (company_id == 0) {
                        dispatch(setCompanyIDDetails(response.data.company_list[0]));
                    }
                    setlandingCompanyDetails(response.data.company_list)
                    setIsLoading(false)
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }
    const getUserDivision = (compId) => {
        setIsLoading(true)
        const API_URL = '/get-user-divisions';
        const req_body = {
            user_id: userData['user_id'],
            company_id: company_id['value']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data && response.data.division_list && (response.data.division_list).length > 0) {
                    if (division_id == 0) {
                        dispatch(setDivisionIDDetails(response.data.division_list[0]));
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                    setlandingDivisionDetails(response.data.division_list)
                    setIsLoading(false)
                }
                else {
                    dispatch(setDivisionIDDetails(0));
                    dispatch(setDivisionList([]))
                    setIsLoading(false)
                    getData()
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }
    const logout = (event) => {
        
        setIsLoading(false)
        event.preventDefault();
        if(Isremember == false){
            dispatch(setEmailIdRedux(null))
            dispatch(setPasswordRedux(null))
        }
        dispatch(setUserDetails(null))
        // props.onLogout();

        deleteAllCookies()
        // setIsLoading(true)
        // const API_URL = '/logout';
        // const current_date = new Date();
        // const req_body = {
        //     user_id: userData.user_id,
        //     date_time: current_date
        // };
        // api.post(API_URL, req_body, {
        //     'Content-Type': 'application/json'
        // })
        //     .then((response) => {
        //         console.log('respomse --------->>', response);
        //         if (response && response.data && response.data.status) {
        //             setIsLoading(false)
        //             event.preventDefault();
        //             // props.onLogout();

        //             deleteAllCookies()
        //         }
        //         else {
        //             setIsLoading(false)
        //             event.preventDefault();
        //             // props.onLogout();
        //             deleteAllCookies()
        //         }

        //     })
        //     .catch((error) => {
        //         setIsLoading(false)
        //         event.preventDefault();
        //         // props.onLogout();
        //         deleteAllCookies()
        //         console.error(error);
        //     });
    }
    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    

    const companyDrop = () => {
        setCompanyDropdownOpen(!isCompanyDropdownOpen);
    };

    const closeDropdownOnClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            companyDropdownRef.current &&
            !companyDropdownRef.current.contains(event.target)
        ) {
            setDropdownOpen(false);
            setCompanyDropdownOpen(false);
        }

    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        
        // Add event listener when the dropdown is open
        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen]);

    // useEffect(() => {
    //     document.addEventListener('click', function(event){
    //         if(event.target.closest(".log-out")){
    //             return;
    //         }
    //         closeDropdownOnClickOutside()
    //     });

    //     return () => {
    //         document.removeEventListener('click', closeDropdownOnClickOutside);
    //     };
    // }, [isDropdownOpen]);

    // useEffect(() => {
    //     if (isDropdownOpen) {
    //         document.addEventListener('mousedown', handleOutsideClick);
    //     } else {
    //         document.removeEventListener('mousedown', handleOutsideClick);
    //     }

    //     return () => {
    //         document.removeEventListener('mousedown', handleOutsideClick);
    //     };
    // }, [isDropdownOpen]);

    const handleOutsideClick = (event) => {
        // setDropdownOpen(false);
    };


    const toggleNotification = () => {
        setshowNotification(!showNotification)
    }

    const toggleAdvancedFilter = () => {
        setShowAdvancedFilterModal(!ShowAdvancedFilterModal)
    }



    // const getAllMasterDetails = () => {
    //     setIsLoading(true)
    //     const API_URL = 'api/get-all-master-details';
    //     const req_body = {
    //         UserId: userData['UserId'],
    //     };
    //     api.post(API_URL, req_body, {
    //         'Content-Type': 'application/json'
    //     })
    //     .then((response) => {
            
    //         if (response && response.data) {
    //             dispatch(setCompanyIDDetails(response.data.EntityList));
    //             setEntityList(response.data.EntityList)
    //             setProjectList(response.data.ProjectList)
    //             setProjectStatusList(response.data.ProjectStatusList)
    //             setIsLoading(false)
    //         }
    //         else {
    //             setIsLoading(false)
    //         }
    //     })
    //     .catch((error) => {
    //         setIsLoading(false)
    //         console.error(error);
    //     });
    // }
    

    const EntitySelected = (val) =>{
        
        props.setselectedEntity(val == 1000 ? [] :  val == 0 ? [0] : [val])
    }
    const ProjectSelected = (val) =>{
        setTempSelectedProject(val);
        setTimeout(() => {
            console.log("selected value  inside =>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",val,"State ---- >",tempSelectedProject)

        }, 500); 
        console.log("selected value =>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",val)
        props.setselectedProject(val == 1000 ? [] :  val == 0 ? [0] : [val])
        // if(val == 0){
        //     MileStoneSelected(0)
        // }
    }

    

    const ProjectStatusSelected = (val) =>{
        
        props.setselectedStatus(val == 1000 ? [] :  val == 0 ? [0] : [val])
    }

    const MileStoneSelected = (val) =>{
        
        props.setselectedmilestoneFilter(val == 1000 ? [] :  val == 0 ? [0] : [val])
    }
    

    const selectedEntity = props.selectedEntity;
    const projectList = props.TotalProjectsAssignedToUser || [];
    const ProjectWiseStatusList = props.ProjectStatusList || [];
    const selectedStatus = props.selectedStatus;
    const selectedProject = props.selectedProject;
    const TotalMileStoneList = props.TotalMileStoneList || [];

    console.log("Selected Status:", selectedStatus);
    console.log("Selected Project --------------------->>:", selectedProject);

    console.log("Selected Entity:", selectedEntity);
    console.log("Project List:", projectList);

    // const filteredProjects = selectedEntity.length === 0
    //     ? projectList
    //     : projectList.filter(item => item.EntityId == selectedEntity[0]);

    const filteredProjects = projectList
    //     const filteredProjects = projectList.filter(item => {
    //     const entityMatch = selectedEntity.length === 0 || item.EntityId == selectedEntity[0];
    //     const statusMatch = selectedStatus.length === 0 || item.StatusID == selectedStatus[0];
    //     return entityMatch && statusMatch;
    // });

    // Get project IDs from the filtered projects
    const projectIds = filteredProjects.map(project => project.Id);

    console.log("Project IDs for filtering milestones:", projectIds);

    // Filter milestones based on the project IDs from the filtered projects
    const filterMilestone = TotalMileStoneList.filter(milestone => {
        return projectIds.includes(milestone.ProjectID);
    });

    useEffect(() => {
        if(paramMilestoneId && TotalMileStoneList.length > 0){
            var selectedMilestones = TotalMileStoneList
            .filter(
                (milestone)=>{return milestone.Id == paramMilestoneId}
            ).map(filteredItem => (
                { value: filteredItem.Id, label: truncateText(filteredItem.Code + ' | ' + filteredItem.MilestoneName, 50)}
            ))
            setSelectedMilestoneOption(selectedMilestones);
            var selectedValues = selectedMilestones.map((item)=>  item.value);
            MileStoneSelected(selectedValues.length > 0 ? selectedValues[0] : 0);
        }
    }, [paramMilestoneId,TotalMileStoneList]);

    console.log("Filtered Milestones:", filterMilestone);



    const openNotificationPopup = (ModuleID, type,AssignedToUserId,NotificationID,MilestoneProjectID) => {

        
        setIsLoading(true)
        const API_URL = 'api/setNotificationSeenByID';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            NotificationID : NotificationID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    
                    toggleNotification()

                    if(type == "Action Tracker"){
                        props.OpenActionTrackerHistory(ModuleID)
                    }
                    if(type== "Project"){
                        // props.OpenProjectComments(ModuleID)
                        navigateTo("myprojects/"+ModuleID)
                    }
                    if(type == "Milestone"){
                        // props.OpenMilestoneComments(ModuleID,AssignedToUserId)

                        if(userData['PositionID'] == 1)
                            {
                            navigateTo("myprojects/"+MilestoneProjectID)
                            } 
                        
                        else{
                            navigateTo("mymilestones/"+ModuleID)
                            }                     
                 
                    }
                    getAllNoticationList()
                    setIsLoading(false)
                    
                }
                else {
                    toggleNotification()
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                toggleNotification()
                setIsLoading(false)
                console.error(error);
            });



        
    }


    const [selectedValue, setSelectedValue] = useState(0);

  // Function to handle selection change
    const handleSelectChange = (value) => {
        setSelectedValue(value);
        EntitySelected(value);
    };

    // Effect to set default selection if EntityList has only one item
    useEffect(() => {
        if (props.EntityList.length === 1) {
        const entityId = props.EntityList[0].Id;
        setSelectedValue(entityId);
        EntitySelected(entityId);
        }
    }, [props.EntityList]);


    useEffect(() => {
        if(filteredProjects != null && filteredProjects != undefined){
            console.log("chnages adkjahsdjkaksbdkajsgdjasgajshgakj"+ tempSelectedProject)
            // setTempSelectedProject(0);
            // props.setselectedProject([]);
        //     this.projectListRef.current.value = 0;
        }
    }, [filteredProjects]);
    

    useEffect(() => {
        console.log("chnages adkjahsdjkaksbdkajsgdjasgajshgakj test"+ tempSelectedProject)
    },[props.selectedProject])

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };
    const navigate = useNavigate();

    const navigateTo = (module) =>{
        navigate('/'+ module)
    }

        // Custom styles for react-select
        const customStyles = {
            control: (provided) => ({
                ...provided,
                borderColor: '#21a8e1',
                '&:hover': {
                    borderColor: '#21a8e1'
                }
            }),
            // option: (provided, state) => ({
            //     ...provided,
            //     color: state.isSelected ? '#21a8e1' : provided.color,
            //     backgroundColor: state.isSelected ? '#eaf6fb' : provided.backgroundColor,
            //     '&:hover': {
            //         backgroundColor: '#eaf6fb'
            //     }
            // }),
            singleValue: (provided, state) => ({
                ...provided,
                color: '#21a8e1'
            }),
            container: base => ({ ...base, width: '15rem' }),
            placeholder: (provided, state) => ({
                ...provided,
                color: '#21a8e1'
              }),
              indicatorSeparator: () => ({ display: 'none' }) 
            }
            const DropdownIndicator = (props) => {
                return (
                    // <components.DropdownIndicator {...props}>
                    //     <img src={searchIcnDropdown} alt="Search" style={{ width: '20px', height: '20px' }} />
                    // </components.DropdownIndicator>
                    <components.DropdownIndicator {...props}>
                        {props.selectProps.menuIsOpen ? (
                            <img src={searchIcnDropdown} alt="Search" style={{ width: '20px', height: '20px' }} />
        
                        ) : (
                            <img src={arrowdownblue} alt="Search" style={{ width: '13px', height: '20px' }} />
                        )}
                    </components.DropdownIndicator>
                );
            };

    return (
        <div className="row">
            {isLoading && (<Loader />)}
            {showNotification && (
                <div className={`modal right ${showNotification ? 'show' : 'hide'}`} id="slideModal" tabIndex="-1" role="dialog" aria-labelledby="slideModalLabel" aria-hidden="true">
                    <div className="modal-dialog notification-modal" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <span className="modal-title" id="slideModalLabel" style={{color:'#005273', fontSize:'20px', fontWeight:'600'}}>Comments</span>
                                <img src={CancelBluIconComment} onClick={toggleNotification} className='commonImageStyle cursor-pointer' alt="Notification" />
                            </div>
                            <div className="modal-body">
                                {NotificationDetails.map((item, index) => (
                                    <div onClick={() => {openNotificationPopup(item.ModuleID, item.ModuleType,item.AssignedToUserId,item.ID,item.MilestoneProjectID)}} className="Milestone-container-notification mt-2 cursor-pointer">
                                        <div className="mb-1">
                                            <span style={{color:'#005273', fontWeight:600, fontSize:'16px'}}>{`${item.ActionByUserIdName} ${item.Title}`}</span>
                                        </div>
                                        <div className="mb-1">
                                            <span style={{color:'#005273',  fontSize:'14px'}}>{item.Description}</span>
                                        </div>
                                        <div className="mb-1">
                                            <span style={{color:'#005273', fontWeight:600, fontSize:'14px'}}>{`Date - ${formatDateTimeNotification(item.CreatedOn)}`}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>)}
            {ShowAdvancedFilterModal && (
                <div className="popup">
                    <div className="popup-inner-lg">
                        <div className='d-flex justify-content-between mb-3'>
                            <div className=' text-start'>
                                <h5>Advanced Filter</h5>
                            </div>
                            <div className=' text-end'>
                                <a onClick={() => {
                                    setShowAdvancedFilterModal(false)
                                }}>< i class="fa fa-times" aria-hidden="true"></i></a>
                            </div>
                        </div>

                        <div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-12 mt-3">
                                    <span>Entity</span>
                                    <input className='form-control'
                                        type="email"
                                        placeholder="Enter your email address"
                                    />
                                </div>
                                <div className="col-md-6 col-sm-12 col-12 mt-3">
                                    <span>Asset/Project</span>
                                    <input className='form-control'
                                        type="email"
                                        placeholder="Enter your email address"
                                    />
                                </div>
                            </div>
                            
                            <div className="row ">
                                <div className="col-md-6 col-sm-12 col-12 mt-3">
                                    <span>Priority</span>
                                    <input className='form-control'
                                        type="email"
                                        placeholder="Enter your email address"
                                    />
                                </div>
                                <div className="col-md-6 col-sm-12 col-12 mt-3">
                                    <span>Responsible</span>
                                    <input className='form-control'
                                        type="email"
                                        placeholder="Enter your email address"
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-6 col-sm-12 col-12 mt-3">
                                    <span>Due date</span>
                                    <input className='form-control'
                                        type="email"
                                        placeholder="Enter your email address"
                                    />
                                </div>
                                <div className="col-md-6 col-sm-12 col-12 mt-3">
                                    <span>Delayed</span>
                                    <input className='form-control'
                                        type="email"
                                        placeholder="Enter your email address"
                                    />
                                </div>
                            </div>

                        </div>

                        {/* <div className="mt-3 mb-3">
                            <div className='d-flex justify-content-flex-end'>
                                <div>Clear filters</div>
                                <div>Apply filters</div>
                            </div>
                        </div> */}

                        <div className="modal-footer mt-3">
                            <button type="button"  className=" btn-secondary review-canclebtn" >Clear filters</button>
                            <button type="button" className=" btn-primary add-btn base">Apply filters</button>
                        </div>
                    </div>
                </div>
            )}

        
            {userData &&
                <section className="p-3 borderShadowStyle">
                    <div>
                        <div class="navbar py-0">
                            <div class="container-fluid">
                                <div className="d-flex justify-content-between align-items-center headermainContainer w-100">
                                    <div className="d-flex gap-3">
                                        <a onClick={() => {navigateTo("")}}><img src={Logo} className='header-logo cursor-pointer' alt="Logo" /></a>
                                        <div className="d-flex gap-3">
                                            {/* <div className="SelectDropdownContainer">
                                                <select onChange={(e) => handleSelectChange(e.target.value)} value={selectedValue} style={{width : '15rem'}} className="custom-advance-btn">
                                                        {props.EntityList.length == 1 ? null : <option value={0}>
                                                            {'All Entities'}
                                                        </option>}
                                                    {props.EntityList.map((item) => (
                                                        <option value={item.Id}>
                                                            {item.Value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div> */}
                                            <Select
                                                    onChange={(selectedOption) => handleSelectChange(selectedOption.value)}
                                                    // value={props.EntityList.length === 1 ? null : { value: selectedValue, label: selectedValue == 0 ? 'All Entities' : props.EntityList.find(item => item.Id === selectedValue)?.Value }}
                                                    options={props.EntityList.length === 1 ? [] : [{ value: 1000, label: 'Select entity' },{ value: 0, label: 'All entities' }, ...props.EntityList.map(item => ({ value: item.Id, label: item.Value }))]}
                                                    // className="custom-advance-btn"
                                                    // styles={{ container: base => ({ ...base, width: '15rem' }) }}
                                                    styles={customStyles}
                                                    placeholder={'Select entity'}

                                                    components={{ DropdownIndicator }}

                                                />

                                            {/* <div className="SelectDropdownContainer">
                                                <select onChange={(e) => {ProjectStatusSelected(e.target.value)}} value={selectedStatus ==  [] ?  0 : selectedStatus}  style={{width : '15rem'}} className="custom-advance-btn">
                                                        <option value={0}>
                                                            {'All Status'}
                                                        </option>
                                                    {props.ProjectStatusList.map((item) => (
                                                        <option value={item.Id}>
                                                            {item.Value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div> */}
                                             <div className="SelectDropdownContainer">
                                                <Select
                                                    onChange={(selectedOption) => { ProjectStatusSelected(selectedOption.value) }}
                                                    // value={{ value: selectedStatus == [] ? 0 : selectedStatus, label: selectedStatus === 0 ? 'All Status' : props.ProjectStatusList.find(item => item.Id === selectedStatus)?.Value }}
                                                    options={[{ value:1000, label: 'Select status' },{ value: 0, label: 'All status' }, ...props.ProjectStatusList.map(item => ({ value: item.Id, label: item.Value }))]}
                                                    // className="custom-advance-btn"
                                                    placeholder={'Select status'}
                                                    styles={customStyles}
                                                    components={{ DropdownIndicator }}

                                                />
                                            </div>

                                            {/* <div className="SelectDropdownContainer">
                                                <select onChange={(e) => {ProjectSelected(e.target.value)}} style={{width : '15rem'}}  className="custom-advance-btn projectselectStyle">
                                                    <option value={0}>
                                                        {'All projects'}
                                                        
                                                    </option>
                                                    {filteredProjects.map(filteredItem => (
                                                        <option title={filteredItem.Value} key={filteredItem.Id} value={filteredItem.Id} >
                                                            {truncateText(filteredItem.Code+' | '+filteredItem.Value, 50)}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div> */}
                                            <div className="SelectDropdownContainer">
                                                <Select
                                                    onChange={(selectedOption) => { ProjectSelected(selectedOption.value) }}
                                                    // value={{ value: 0, label: 'Select project' }}
                                                    options={[ { value: 1000, label: 'Select project' },{ value: 0, label: 'All projects' }, ...filteredProjects
                                                        .map(filteredItem => ({ value: filteredItem.Id, label: truncateText(filteredItem.Code + ' | ' + filteredItem.Value, 50) }))]}
                                                    // className="custom-advance-btn projectselectStyle"
                                                    placeholder={'Select project'}
                                                    styles={customStyles}
                                                    components={{ DropdownIndicator }}

                                                />
                                            </div>
                                            {/* <div className="SelectDropdownContainer">
                                                <select onChange={(e) => {MileStoneSelected(e.target.value)}} style={{width : '15rem'}}  className="custom-advance-btn projectselectStyle">
                                                    <option value={0}>
                                                        {'All milestones'}
                                                        
                                                    </option>
                                                    {filterMilestone
                                                    .filter(e => {
                                                        if (tempSelectedProject == null || tempSelectedProject == undefined || tempSelectedProject == 0|| tempSelectedProject.length === 0) {
                                                            return true; // Do not filter, show as it is
                                                        }
                                                        return e.ProjectID == tempSelectedProject;
                                                    })
                                                    .map(filteredItem => (
                                                        <option title={filteredItem.Code+' | '+filteredItem.MilestoneName} key={filteredItem.Id} value={filteredItem.Id} >
                                                            {truncateText(filteredItem.Code+' | '+filteredItem.MilestoneName, 50)}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div> */}

                                            <div className="SelectDropdownContainer">
                                                <Select
                                                    value={selectedMilestoneOption}
                                                    onChange={(selectedOption) => { MileStoneSelected(selectedOption.value);setSelectedMilestoneOption(selectedOption); }}
                                                    // value={{ value: 0, label: 'Select project' }}
                                                    // onChange={(selectedOption) => { MileStoneSelected(selectedOption.value) }}
                                                    options={[
                                                    {value : 1000,label : 'Select milestone'},
                                                    { value: 0, label: 'All milestones' },
                                                    ...filterMilestone
                                                        // .filter(e => {
                                                        // if (!tempSelectedProject || tempSelectedProject === 0) {
                                                        //     return true; // Do not filter, show as it is
                                                        // }
                                                        // return e.ProjectID === tempSelectedProject;
                                                        // })
                                                        .map(filteredItem => ({
                                                        value: filteredItem.Id,
                                                        label: truncateText(filteredItem.Code + ' | ' + filteredItem.MilestoneName, 50)
                                                        }))
                                                    ]}
                                                    placeholder={'Select milestone'}
                                                    styles={customStyles}
                                                    components={{ DropdownIndicator }}

                                                />
                                            </div>

                                            
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex gap-3 align-items-center  position-relative">
                                            <div className="position-relative">
                                                <img title="Comments" src={CommementLightBlue} style={{height:"26px"}} onClick={toggleNotification} className='cursor-pointer' alt="Notification" />
                                                {NotificationDetailsCount > 0 && (
                                                    <span className={NotificationDetailsCount > 9 ? "badge-count" : "badge-count-small"}>{NotificationDetailsCount}</span>
                                                )}
                                            </div>
                                            {/* <img src={Notifications} onClick={toggleNotification} className='commonImageStyle cursor-pointer' alt="Notification" />
                                             */}
                                            {/* <div className="position-relative">
                                                <img title="Notifications" src={Notifications} onClick={toggleNotification} className='commonImageStyle cursor-pointer' alt="Notification" />
                                                {NotificationDetailsCount > 0 && (
                                                    <span className={NotificationDetailsCount > 9 ? "badge-count" : "badge-count-small"}>{NotificationDetailsCount}</span>
                                                )}
                                            </div> */}
                                            <img src={ProfileIcon} onClick={toggleDropdown} className='commonImageStyle cursor-pointer' alt="UserProfile" />
                                            {isDropdownOpen &&
                                                <div style={{width:'100px'}} className="log-out  d-flex flex-column">
                                                    <div >
                                                        <span onClick={logout} className="logout-pop-text-style"><i class="fa fa-sign-out me-2"></i>Log Out</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
        </div>
    );
};

export default MilestoneHeader;