const initialState = {
    userData: null,
    access_details: null,
    companyID: 0,
    divisionID: 0,
    divisionList: [],
    EmailID: null,
    Password: null,
    IsRemeberMeChecked: false,
};
const Reducer = (state = initialState, action)=>{
    switch (action.type) {
        case 'SET_USER_DETAILS':
            return {
                ...state,
                userData: action.payload,
            }   
        case 'CLEAR_USER_DETAILS':
            return {
                ...state,
                userData: null,
            }
        case 'SET_USER_MODULE_ACCESS_DETAILS':
            return {
                ...state,
                access_details: action.payload,
            }
        case 'CLEAR_USER_MODULE_ACCESS_DETAILS':
            return {
                ...state,
                access_details: null,
            }
        case 'SET_COMPANY_ID':
            return {
                ...state,
                companyID: action.payload,
            }
        case 'SET_Entity_ID':
            return {
                ...state,
                selectedEntity: action.payload,
            }
        case 'SET_DIVISION_ID':
            return {
                ...state,
                divisionID: action.payload,
            }
        case 'SET_DIVISION_LIST':
            return {
                ...state,
                divisionList: action.payload,
            }
        case 'SET_EMAIL_ID':
            return {
                ...state,
                EmailID: action.payload,
            }   
        case 'CLEAR_EMAIL_ID':
            return {
                ...state,
                EmailID: null,
            }
        case 'SET_PASSWORD':
            return {
                ...state,
                Password: action.payload,
            }
        case 'CLEAR_PASSWORD':
            return {
                ...state,
                Password: null,
            }
        case 'SET_REMEMBER_ME':
            return {
                ...state,
                IsRemeberMeChecked: action.payload,
            }
        case 'CLEAR_REMEMBER_ME':
            return {
                ...state,
                IsRemeberMeChecked: action.payload,
            }
        default:
            return state;
    }
};

export default Reducer;