import React, { useEffect, useState } from "react";
import "./QHSE_KPI.css";
import imageURL from '../../../assets/images/infoIcon.svg';
import edit from '../../../assets/images/edit-icon.svg';
import remove from '../../../assets/images/remove-icon.svg';
import add from '../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../components/help_side_panel/HelpSidePanel";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import SmallSizedLoader from "../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '../../../api_interceptor/api'
import Select from 'react-select';
import Alert_Popup from "../../../components/alert_popup/Alert_Popup";
import close from '../../../assets/images/close.png';
import { updateState } from "../../../common_function/common_function";

export default function QHSE_KPI(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [monthlyUpdateList, setmonthlyUpdateList] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const [competitionAreaList, setCompetitionAreaList] = useState([]);
    const [ourCompetitiveAdvantager, setOurCompetitiveAdvantager] = useState([]);
    const [CompetitorCompetitiveAdvantager, setCompetitorCompetitiveAdvantager] = useState([]);
    const [categorySpentBycategory_spent_by_client_nameList, setcategorySpentBycategory_spent_by_client_nameList] = useState([]);
    const [isClientInReachList, setIsClientInReachList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);

    const company_id = useSelector((state) => state.user.companyID);


    const [isEditable, setIsEditable] = useState(false); 
    const [isEditVisible, setIsEditVisible] = useState(true);
    const [safetyIncidents, setSafetyIncidents] = useState("");
    const [audits, setAudits] = useState("");
    // const [kpiData, setkpiData] = useState([]);


    useEffect(() => {
        if (userData && userData['UserId']) {
            getQHSEKPI(userData['UserId'])
        }
    }, [userData]);

    const getQHSEKPI = (UID) => {
        updateState(props.setPreviewEnabled,userData['UserId'],access_details['company_division_id'],props.setPreviewList,company_id && 'value' in company_id ? company_id['value'] : "")
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-qhse-kpi';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id'],
            
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setSafetyIncidents(response.data.QHSEKPISafety)
                        setAudits(response.data.QHSEKPIAudit)
                        sethelpDetails(response.data.help_details)
                        setIsLoading(false)

                    }
                    else {
                        sethelpDetails(response.data.help_details)
                        setIsLoading(false)
                    }
                }
                else {
                    sethelpDetails(response.data.help_details)

                    setIsLoading(false)
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }



    const handleEdit = (index) => {
        const updatedMonthlyUpdateList = [...monthlyUpdateList];
        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);

        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }

        if (editableRows.length === 0 && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });

            console.log({ currentOperation });
            setCurrentOperation('edit');
            setmonthlyUpdateList(updatedMonthlyUpdateList);
        }
    };

    const handleDelete = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            setDeleteReason(null)
            setDeletingIndex(index);
            setShowConfirmationModal(true);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
            handleDeleteAPI(deletingIndex, deleteReason);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };

    const handleDeleteAPI = (index, reason) => {
        const editedItem = monthlyUpdateList[index];
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/delete-competition';
        const req_body = {
            "id": editedItem.id,
            "deleted_reason": reason,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: company_id && 'value' in company_id ? company_id['value'] : ""
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    getQHSEKPI(userData['UserId'])
                    setmonthlyUpdateList(prevList => prevList.filter((item, i) => i !== index));
                }
                else {
                    getQHSEKPI(userData['UserId'])
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    const addQHSEKPI = (reqBody, index) => {
        ;
        setIsLoading(true);
        const API_URL = 'monthlymodule/api/add-qhse-kpi';
    
        // Combine the request body with company_division_id
        const requestBody = {
            ...reqBody, // Spread the existing request body
            company_division_id: access_details['company_division_id'] // Add company_division_id
        };
    
        api.post(API_URL, requestBody, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            if (response && response.data) {
                getQHSEKPI(userData['UserId']);
            } else {
                getQHSEKPI(userData['UserId']); // Consider if else block is redundant
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.error(error);
        });
    };
    

    const getVal = (val) => {
        if (val == true) {
            return 1
        }
        if (val == false) {
            return 0
        }
        if (val == 0) {
            return 0
        }
        if (val == 1) {
            return 1
        }
    }

    // const getdropdownValYesNo = (editedItem) => {
    //     if (editedItem.is_client_in_reach_out_list && editedItem.is_client_in_reach_out_list.value == undefined) {
    //         return setSelectValue(isClientInReachList, setDropDownTrueFalse(editedItem.is_client_in_reach_out_list)).value
    //     }
    //     else if (editedItem.is_client_in_reach_out_list == false) {
    //         return 0
    //     }
    //     else {
    //         return getVal(editedItem.is_client_in_reach_out_list.value)
    //     }
    // }

    // Validation start logic

    // const validateMonthlyUpdate = (editedItem) => {
   
    //     const emptyFields = [];

    //     // Validation 1: Dropdown should be mandatory
    //     if (/^\s*$/.test(editedItem.competitors_name)) {
    //         emptyFields.push("Please enter value for competitor's name.\n");
    //     }

    //     if (/^\s*$/.test(editedItem.competitors_area_name)) {
    //         emptyFields.push("Please select competitor's area.\n");
    //     }

    //     if (/^\s*$/.test(editedItem.key_clients)) {
    //         emptyFields.push("Please enter value for key clients.\n");
    //     }

    //     if (!editedItem.category_spent_by_client_name) {
    //         emptyFields.push("Please select category spend by clients.\n");
    //     }

    //     if (currentOperation === 'edit') {
    //         // Validation for edit operation
    //         if (!editedItem.hasOwnProperty('is_client_in_reach_out_list')) {
    //             emptyFields.push("Please select is client in our reach out list");
    //         }
    //     } else if (currentOperation === 'add') {
    //         // Validation for add operation
    //         if (!editedItem.is_client_in_reach_out_list) {
    //             emptyFields.push("Please select is client in our reach out list");
    //         }
    //     }


    //     if (/^\s*$/.test(editedItem.actions_takens_to_get_client)) {
    //         emptyFields.push("Please enter value for action taken to get the client.\n");
    //     }

    //     if (/^\s*$/.test(editedItem.competitors_competitive_advantage)) {
    //         emptyFields.push("Please enter value for competitor's competitive advantage.\n");
    //     }

    //     if (/^\s*$/.test(editedItem.our_competitive_advantage)) {
    //         emptyFields.push("Please enter value for competitor's name.\n");
    //     }

    //     if (/^\s*$/.test(editedItem.key_updates_regarding_competition)) {
    //         emptyFields.push("Please enter value for key updates's regarding competition.\n");
    //     }

    //     return emptyFields;
    // };


    // Validation end logic

       const handleSave = (index, e) => {
        

        setIsEditVisible(true); // Show the edit button after saving
        setIsEditable(false); // Exit editing mode

        const req_body = {
            SafetyIncidents: safetyIncidents,
            Audit: audits
            
       
        }
        // Check if it's an add operation
        if (isAddClicked) {
                addQHSEKPI(req_body, index)
            
        } else {
          
                addQHSEKPI(req_body, index)
            
        }

    };


    const handleClose = () => {
    
    
        setEditableRows([]);

      
    
     
  
        setCurrentOperation(null);

        setIsEditVisible(true);
        setIsEditable(false);

        getQHSEKPI()
    };
    

    const handleAdd = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...monthlyUpdateList];
            const newItem = {
                competitors_area_name: "",  // Set default value based on the clicked row
                competitors_name: monthlyUpdateList[index].competitors_name,
                key_clients: "",
                category_spent_by_client_name: "",
                is_client_in_reach_out_list: "",
                actions_takens_to_get_client: "",
                competitors_competitive_advantage: "",
                our_competitive_advantage: "",
                key_updates_regarding_competition: "",
                company_id: company_id && 'value' in company_id ? company_id['value'] : ""
            };
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, index + 1]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const handleAddNew = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...monthlyUpdateList];
            const newItem = {
                competitors_area_name: "",  // Set default value based on the clicked row
                competitors_name: "",
                key_clients: "",
                category_spent_by_client_name: "",
                is_client_in_reach_out_list: "",
                actions_takens_to_get_client: "",
                competitors_competitive_advantage: "",
                our_competitive_advantage: "",
                key_updates_regarding_competition: "",
                company_id: company_id && 'value' in company_id ? company_id['value'] : ""
            };
            setmonthlyUpdateList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, 0]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };


    const setSelectValue = (list, value) => {
        console.log("List ---- >> Value ----->>>>", list, value);
        return list.find((data) => {
            return data.label === value;
        });

    }
    const setDropDownTrueFalse = (val) => {
        if (val == true) {
            return "Yes"
        }
        else if (val == false) {
            return "No"
        }
        else {
            return ""
        }
    }

    // validation popup close

    const closePopup = () => {
        setPopupOpen(false);
    };

    const handleEditClick = () => {
        setIsEditable(true);
    };

    const handleInputChange = (e) => {
        setSafetyIncidents(e.target.value);
    };

    const handleInputChangeAudit = (e) => {
        setAudits(e.target.value);
    };


    const handleBlur = () => {
        setIsEditable(false);
    };

    // dropdown scroll close start

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the background color of the control
            marginTop: 0, // Adjust the top margin
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
            color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
            height: '45px',

        }),
    };

    // Add state variables for each dropdown
    const [isCompetitorsAreaDropdownOpen, setIsCompetitorsAreaDropdownOpen] = useState(false);
    const [isCategorySpentDropdownOpen, setIsCategorySpentDropdownOpen] = useState(false);
    const [isReachOutDropdownOpen, setIsReachOutDropdownOpen] = useState(false);
    const [CompetitorCompetitiveDropdownOpen, setCompetitorCompetitiveDropdownOpen] = useState(false);
    const [OurCompetitorDropdownOpen, setOurCompetitorDropdownOpen] = useState(false);
    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };

    // dropdown scroll close end


 

    const handleToggleEdit = () => {
        setIsEditable((prev) => !prev);
        setIsEditVisible(false); // Hide the edit button when clicked
        setIsEditable(true); // Assuming you toggle editing mode here
      };

      
      const kpiData = [
        { id: 1, name: '# Safety Incidents' },
        { id: 2, name: '# Audits'},
    ];
    
    return (
        <div className="parentContainer">
            {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-center'>
                                <p className="head-text">Delete Item</p>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Mention Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}
                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                />
                            </label>
                            <button onClick={() => setShowConfirmationModal(!showConfirmationModal)} className="cancel-btn">Cancel</button>
                            <button type="submit" className="confirm-btn">Confirm</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Validation popup start */}
         {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text" style={{fontSize:"20px"}}>Information</p>
                            </div>
                        </div>
                        <div className='ChatBodyDetails' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto',overflowY:'auto', padding:"0rem"}}>
                                {errorList.map((item, index) => (
                                    <div style={{ display : 'flex'}}>
                                        <div className=' d-flex mb-3' style={{width:'90%'}}>
                                            {`${index+1}) ${item}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        <button className="close-btn" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
            {/* Validation popup end */}

            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}
            <div className="monthlyHeaderWrapper">

            <div>
                <span style={{ fontSize: "13px", display: "block" }}>Annexures (For Information/ Additional Reading)</span>
                <span className="monthly-Title-Table-header" style={{ display: "block" }}>QHSE Updates</span>
            </div>
               
                {!isLoading && <HelpSidePanel content={"Information on what is happening in the ecosystem as a whole"} />}
            </div>
            {isLoading ? (<SmallSizedLoader />) :
                (<div className="table-div padStyle" displa style={{ display: "flex", maxWidth: "40%" }}>
                   
                    <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff" , zIndex:9999999999}} />
                    <div className="table-container" style={{ overflowX: 'auto', maxWidth:"100%", display:"flex", columnGap:"1rem", overflowY:"hidden"}}>
                   <div style={{width:"80%"}}>
                   <table className="table-container-subdiv open-actionTable action-summary" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead className="action-head">
                            <tr className="column-header-style" style={{ backgroundColor: '#f2f2f2' }}>
                                <th style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'left' }}>Sr. No.</th>
                                <th className="column-header-style" style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'left' }}>KPI <span style={{ color: 'red' }} aria-label="required">*</span></th>
                                <th style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'center' }}>Count <span style={{ color: 'red' }} aria-label="required">*</span></th>
                            </tr>
                        </thead>
                <tbody className="action-body">
                    {/* KPI Row 1: Safety Incidents */}
                    <tr className="column-data-style">
                        <td style={{ border: '1px solid #ddd', padding: '12px', width: '5%' }}>1</td>
                        <td style={{ border: '1px solid #ddd', padding: '12px', width: '25%' }}>Safety Incidents</td>
                        <td style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'center', width: '20%' }}>
                            {isEditable ? (
                                <input
                                    type="number"
                                    value={safetyIncidents}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value >= 0) {
                                            handleInputChange(e); // Only call the handler if the value is 0 or more
                                        }
                                    }}
                                        style={{
                                            width: '40%', // Adjusted width for better visibility
                                            border: '1px solid #ccc',
                                            outline: 'none',
                                            textAlign: 'center',
                                            borderRadius: '4px',
                                            padding: '5px', // Padding for the input
                                            background: '#f9f9f9' // Light background for the input
                                        }} // Style input for better UX
                                />
                            ) : (
                                safetyIncidents
                            )}
                        </td>
                    </tr>

            {/* KPI Row 2: Audits */}
            <tr className="column-data-style">
                <td style={{ border: '1px solid #ddd', padding: '12px' }}>2</td>
                <td style={{ border: '1px solid #ddd', padding: '12px', width: '15%' }}>Audits</td>
                <td style={{ border: '1px solid #ddd', padding: '12px', textAlign: 'center', width: '20%' }}>
                    {isEditable ? (
                        <input
                            type="number"
                            value={audits}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value >= 0) {
                                    handleInputChangeAudit(e); // Only call the handler if the value is 0 or more
                                }
                            }}
                            style={{
                                width: '40%', // Adjusted width for better visibility
                                border: '1px solid #ccc',
                                outline: 'none',
                                textAlign: 'center',
                                borderRadius: '4px',
                                padding: '5px', // Padding for the input
                                background: '#f9f9f9' // Light background for the input
                            }} // Style input for better UX
                        />
                    ) : (
                        audits
                    )}
                </td>
            </tr>
        </tbody>
                    </table>
                   </div>



                    
                 
                   <div style={{  width:"20%"}}> <div>
                     {isEditVisible && (
                            <div>
                                <button onClick={handleToggleEdit} style={{border:'none', backgroundColor:"white"}}>
                                    <img 
                                        src={edit}
                                        className="edit" 
                                        alt="Edit" 
                                        style={{ width: '13px', marginRight: '5px'}} 
                                    />
                                </button>
                            </div>
                        )}
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", height: "100%" }}>
                    {isEditable && (
                        <div className="d-flex justify-content-end" style={{  textAlign: "right" }}>
                            <button onClick={handleSave} className="d-flex align-items-center" style={{ border: "none" }}>
                                <span className="save-btn">save</span>
                            </button>
                            <img 
                                src={close}
                                className="close" 
                                alt="close" 
                                style={{ marginRight: "5px" }} 
                                onClick={() => handleClose()}
                            />
                        </div>
                    )}
                    </div></div>
                    </div>
                  

                </div>)}
        </div>
    );
};