import React, { useState, useEffect , useRef} from 'react';
import Header from '../../components/header/Header';
import api, { BASE_URL } from '../../api_interceptor/api'
import Loader from '../../components/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyIDDetails, setDivisionIDDetails, setUserModuleAccessDetails } from '../../redux/action/Actions';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
// import ActionTracker from './Dashboard';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import DashboardHeader from '../../components/header/DashboardHeader';
import ProfileIcon from '../../assets/images/MyProjects.svg'
import Mymilestones from '../../assets/images/MyMilestone.svg'
import MyActions from '../../assets/images/My actions.svg'
import WeeklyIcon from '../../assets/images/07 Calendar.svg'
import MonthlyIcon from '../../assets/images/30 Calendar.svg'
import QauterlyIcon from '../../assets/images/90 Calendar.svg'
import MileStoneNewICN from '../../assets/images/MileStoneNewICN.svg'
import ActionICN from '../../assets/images/ActionNewICN.svg'



const Dashboard = (props) => {
    
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const userData = useSelector((state) => state.user.userData);

    useEffect(() => {
        document.title = "PlanetXPro";
    }, []);
    

    const logOutEvent = e =>{
        props.onLogout();
    }

    const navigateTo = (module) =>{
        navigate('/'+ module)
    }
    
    const redirectToMonthlyModule = () => {
        
        const params = new URLSearchParams({ userId: userData['UserId']});
        window.location.href = `http://localhost:3001/monthly?${params.toString()}`;
        // window.location.href = `https://planetxpro.planetcp.com/Projects/monthly?${params.toString()}`;
      };
   
    return (
        <div className="LoginScreen container-fluid">
            <DashboardHeader onLogout={logOutEvent} hide={false} />

            

            {isLoading &&( <Loader/> ) }
            <div className='MainBodyDiv'>
                <div className="container-fluid h-100">
                    <div className='d-flex flex-column align-items-center justify-content-center gap-5' style={{width:'100%',height:'100%'}}>
                        {/* ----------------------ROW 1----------------------------- */}
                        <div >
                            <div className='mb-3'>
                                <span style={{color:"#A0A0A0", fontSize:"16px", fontWeight:'bold'}}>PROJECT</span>
                            </div>
                            <div className='d-flex gap-5'>
                                <div className='d-flex flex-column align-items-center gap-3'>
                                    <div onClick={() => {navigateTo("myprojects")}}className='cursor-pointer' style={{backgroundColor:'#21A8E1', borderRadius:'7px'}}>
                                        <div className='p-4'>
                                            <img src={ProfileIcon} style={{height:"65px"}} className=' cursor-pointer' alt="UserProfile" />
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{fontSize:'14px',marginTop:'2rem'}}>MY PROJECTS</span>
                                    </div>
                                </div>
                                <div className='d-flex flex-column align-items-center gap-3'>
                                    <div  onClick={() => {navigateTo("mymilestones")}} className='cursor-pointer' style={{backgroundColor:'#21A8E1', borderRadius:'7px'}}>
                                        <div className='p-4'>
                                            <img src={MileStoneNewICN} style={{height:"65px"}} className=' cursor-pointer' alt="UserProfile" />
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{fontSize:'14px',marginTop:'2rem'}}>MY MILESTONES</span>
                                    </div>
                                </div>
                                <div className='d-flex flex-column align-items-center gap-3'>
                                    <div onClick={() => {navigateTo("myactions")}} className='cursor-pointer' style={{backgroundColor:'#21A8E1', borderRadius:'7px'}}>
                                        <div className='p-4'>
                                            <img src={ActionICN} style={{height:"65px"}} className=' cursor-pointer' alt="UserProfile" />
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{fontSize:'14px',marginTop:'2rem'}}>MY ACTIONS</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                         {/* ----------------------ROW 2----------------------------- */}
                        <div >
                            <div className='mb-3'>
                                <span style={{color:"#A0A0A0", fontSize:"16px", fontWeight:'bold'}}>MRM UPDATE</span>
                            </div>
                            <div className='d-flex gap-5'>
                                <div className='d-flex flex-column align-items-center gap-3'>
                                    <div onClick={() => {navigateTo("monthly")}}  className='cursor-pointer' style={{backgroundColor:'#21A8E1', borderRadius:'7px'}}>
                                        <div className='p-4'>
                                            <img src={MonthlyIcon} style={{height:"65px", width:'70px'}} className=' cursor-pointer' alt="UserProfile" />
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{fontSize:'14px',marginTop:'2rem'}}>MONTHLY</span>
                                    </div>
                                </div>
                                <div className='d-flex flex-column align-items-center gap-3 invisible'>
                                    <div onClick = {() => {}}  style={{backgroundColor:'#21A8E1', borderRadius:'7px', opacity:'0.4', cursor: 'not-allowed'}}>
                                        <div className='p-4'>
                                            <img src={WeeklyIcon} style={{height:"65px", width:'70px'}} alt="UserProfile" />
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{fontSize:'14px',marginTop:'2rem'}}>WEEKLY</span>
                                    </div>
                                </div>
                                {/* <div  onClick={() => redirectToMonthlyModule()} className='d-flex flex-column align-items-center gap-3'> */}
                                
                                <div className='d-flex flex-column align-items-center gap-3 invisible'>
                                    <div onClick = {() => {}}  style={{backgroundColor:'#21A8E1', borderRadius:'7px', opacity:'0.4', cursor:'no-drop'}}>
                                        <div className='p-4'>
                                            <img src={QauterlyIcon} style={{height:"65px", width:'70px'}}  alt="UserProfile" />
                                        </div>
                                    </div>
                                    <div>
                                        <span style={{fontSize:'14px',marginTop:'2rem'}}>QUARTERLY</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ActionTracker/> */}
            </div>
            <Footer/>
        </div>
    );
};

export default Dashboard;
