
import React from 'react';
import { useLocation } from 'react-router-dom';
import { BASE_URL } from '../../api_interceptor/api';

const PrivacyPolicy = () => {

    return (
        <div>
            <iframe 
                src={BASE_URL + 'PrivacyPolicy/Privacy_Policy.html'} 
                title="Privacy Policy" 
                style={{ width: '100%', height: '100vh', border: 'none' }}
            />
        </div>
    );
};

export default PrivacyPolicy;
