import React, { useEffect, useState, useRef } from "react";
import "./StrategicFocus.css";
import imageURL from '../../../assets/images/infoIcon.svg';
import edit from '../../../assets/images/edit-icon.svg';
import remove from '../../../assets/images/remove-icon.svg';
import add from '../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../components/help_side_panel/HelpSidePanel";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import SmallSizedLoader from "../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '../../../api_interceptor/api'
import Select from 'react-select';
import Alert_Popup from "../../../components/alert_popup/Alert_Popup";
import AnalyticsImage from '../../../assets/images/Warning icon.svg';
import close from '../../../assets/images/close.png';

import { updateState, formatDateTimeForChat, formatDate } from "../../../common_function/common_function";
import CancelBluIconComment from '../../../assets/images/Cancel icon Comments.svg'
import ProfIcon from '../../../assets/images/Profile icon.svg'
import SendChatIcon from '../../../assets/images/Send chat icon.svg'
import CommementBlue from '../../../assets/images/Comment icon blue.svg'
import _ from 'lodash';




export default function StrategicFocus(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [strategicFocusList, setstrategicFocusList] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const [getStatusList, setStatusList] = useState([]);
    const [ImpactOnList, setImpactOnList] = useState([]);
    const [CriticalityList, setCriticalityList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [getCountData, setgetCountData] = useState(null);
    const [getCountDataShow, setgetCountDatadshow] = useState(false);
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const company_id = useSelector((state) => state.user.companyID);
    
    const [showStrategicComments, setshowStrategicComments] = useState(false);
    const [StrategicChatDetails, setStrategicChatDetails] = useState([]);
    const [StrategicCommentsText, setStrategicCommentsText] = useState('');
    const [StrategicIDForChatAssignedTo, setStrategicIDForChatAssignedTo] = useState(null);
    const [StrategicIDForChat, setStrategicIDForChat] = useState(null);
    const [StrategicChatDetailsHeader, setStrategicChatDetailsHeader] = useState("");
    const [updatedMonthlyUpdateList, setUpdatedMonthlyUpdateList] = useState([]);
    const [prevList, setPrevList] = useState([]);



    const currentDate = new Date();
    let month = currentDate.getMonth(); 
    let year = currentDate.getFullYear();
    
    if (month == 0) {
        month = 11; 
        year--;     
    } else {
        month--;   
    }
    
    const monthName = new Date(year, month).toLocaleString('default', { month: 'long' });


    useEffect(() => {
        if (userData && userData['UserId']) {
            getStrategicFocus(userData['UserId'])
        }
    }, [userData]);

    useEffect(() => {
        setgetCountDatadshow(!getCountDataShow)
    }, [getCountData]);

    const getStrategicFocus = (UID) => {
        updateState(
            props.setPreviewEnabled,
            userData['UserId'],
            access_details['company_division_id'],
            props.setPreviewList,
            access_details['company_division_id']
        );
        
        setIsLoading(true);
        
        const API_URL = 'monthlymodule/api/get-strategic-focus';
    
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
    
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
        .then((response) => {
            if (response && response.data) {
                if (response.data) {
                    console.log("respdata0000--->", response.data);
    
                    setgetCountData(response.data);
                    setstrategicFocusList(response.data.Strategic_Focus_List);
                    sethelpDetails(response.data.help_details);
    
                    getAreaOfUpdateDetails(userData['UserId'], response.data.Strategic_Focus_List);
                } else {
                    sethelpDetails(response.data.help_details);
                    getAreaOfUpdateDetails(userData['UserId'], response.data.Strategic_Focus_List);
                }
            } else {
                sethelpDetails(response.data.help_details);
                getAreaOfUpdateDetails(userData['UserId'], response.data.Strategic_Focus_List);
                setIsLoading(false);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.error(error);
        });
    }
    
    const getAreaOfUpdateDetails = (UID, valdata) => {
        const API_URL = 'monthlymodule/api/get-open-action-items-dropdown-details';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setStatusList(response.data.status_list);
                        setImpactOnList(response.data.impact_on_list);
                        setCriticalityList(response.data.criticality_list);
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    

    const handleDelete = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            setDeleteReason(null)
            setDeletingIndex(index);
            setShowConfirmationModal(true);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
            handleDeleteAPI(deletingIndex, deleteReason);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };

    const handleDeleteAPI = (index, reason) => {
        const editedItem = strategicFocusList[index];
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/delete-strategic-focus';
        const req_body = {
            "id": editedItem.ID,
            "deleted_reason": reason,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    getStrategicFocus(userData['UserId'])
                    setstrategicFocusList(prevList => prevList.filter((item, i) => i !== index));
                }
                else {
                    getStrategicFocus(userData['UserId'])
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    const addStrategicFocus = (editedItem, index) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/add-strategic-focus';
        const req_body = editedItem;
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getStrategicFocus(userData['UserId'])
                }
                else {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getStrategicFocus(userData['UserId'])
                }

            })
            .catch((error) => {
                setAddClick(false)
                setIsLoading(false)
                console.error(error);
            });
    }

      // Validation start logic

      const validateMonthlyUpdate = (editedItem) => {
        
        
        const emptyFields = [];

        // Validation 1: Dropdown should be mandatory

            // Validate StrategicFocus
        if (/^\s*$/.test(editedItem.StrategicFocus)) {
            emptyFields.push("Please enter value for strategic focus..\n");
        }

        // Validate Description
        if (/^\s*$/.test(editedItem.Description)) {
            emptyFields.push("Please enter value for description.\n");
        }

        // Validate SupportRequired
        if (/^\s*$/.test(editedItem.SupportRequired)) {
            emptyFields.push("Please enter value for support required.\n");
        }

        // Validate ImpactOnID
        if (!editedItem.ImpactOnID) {
            emptyFields.push("Please select the impact on.\n");
        }

        // Validate ClosureDate
        if (/^\s*$/.test(editedItem.ClosureDate)) {
            emptyFields.push("Please enter value for due date.\n");
        }

        // Validate StatusID
        if (!editedItem.StatusID) {
            emptyFields.push("Please select a status.\n");
        }

        // Validate CriticalityID
        if (!editedItem.CriticalityID) {
            emptyFields.push("Please select a criticality.\n");
        }

        // Validate LatestUpdate
        if (/^\s*$/.test(editedItem.LatestUpdate)) {
            emptyFields.push(`Please enter value for update for ${monthName.toLowerCase()}'${year}\n`);
        }

        // Validate Owner
        if (/^\s*$/.test(editedItem.Owner)) {
            emptyFields.push("Please enter value for owner.\n");
        }

        // Validate CMOComments
        // if (/^\s*$/.test(editedItem.CMOComments)) {
        //     emptyFields.push("Please enter value for CMO comments.\n");
        // }   
        

        return emptyFields;
    };


    // Validation end logic





    const handleSave = (index, e) => {
        const editedItem = strategicFocusList[index];
        
        // Validation 1: Dropdown should be mandatory

        const req_body = {
            StrategicFocus: editedItem.StrategicFocus,  
            description: editedItem.Description,
            SupportRequired: editedItem.SupportRequired,
            ImpactOnID: editedItem.ImpactOnID && editedItem.ImpactOnID.value == undefined 
                      ? setSelectValue(ImpactOnList, editedItem.ImpactOnID).value
                      : editedItem.ImpactOnID.value,
            ClosureDate: editedItem.ClosureDate,
            StatusID: editedItem.StatusID && editedItem.StatusID.value === undefined 
                ? setSelectValue(getStatusList, editedItem.StatusID).value 
                : editedItem.StatusID.value,
            CriticalityID: editedItem.CriticalityID && editedItem.CriticalityID.value === undefined 
                ? setSelectValue(CriticalityList, editedItem.CriticalityID).value 
                : editedItem.CriticalityID.value,
            LatestUpdate: editedItem.LatestUpdate,  
            Owner: editedItem.Owner, 
            // CMOComments: editedItem.CMOComments, 
            
            "is_update": isAddClicked ? false : true,
            "update_id": isAddClicked ? null : editedItem.ID,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        }

         // Check if it's an add operation
         if (isAddClicked) {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addStrategicFocus(req_body, index)
                setCurrentOperation(null)
            }
        } else {
            const emptyFields = validateMonthlyUpdate(editedItem);


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addStrategicFocus(req_body, index)
                setCurrentOperation(null)
            }
        }
        // console.log('reqbosyuy---->>', req_body);
        // addStrategicFocus(req_body, index)
        // setCurrentOperation(null)
    };

    const handleEdit = (index) => {
        // const updatedMonthlyUpdateList = [...strategicFocusList];

        if (prevList.length === 0) {
            setPrevList(strategicFocusList.map(item => ({ ...item }))); // Save the original state
        }

        const updatedMonthlyUpdateList = _.cloneDeep(strategicFocusList);
        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);

        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }

        if (editableRows.length === 0 && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });

            console.log({ currentOperation });
            setCurrentOperation('edit');
            setstrategicFocusList(updatedMonthlyUpdateList);
        }
    };

    const handleClose = (index) => {
        // Remove the index from editableRows when clicking the close icon
        
        setEditableRows([]);
        setAddClick(false);
        // If the item is newly added and being closed, remove it from the list
        // if (!strategicFocusList[index].is_update) {
        //     setstrategicFocusList((prevList) => {
        //         const updatedList = [...prevList];
        //         updatedList.splice(index, 1);
        //         return updatedList;
        //     });
        // }

        // // Reset the is_edit flag
        // const updatedMonthlyUpdateList = [...strategicFocusList];
        // updatedMonthlyUpdateList[index].is_edit = false;
        // setstrategicFocusList(updatedMonthlyUpdateList);

        if (prevList.length > 0) {
            setstrategicFocusList(prevList);
        }

        if (!strategicFocusList[index].is_update  && prevList.length > 0) {
            setstrategicFocusList(prevList);

            const updatedMonthlyUpdateList = [...prevList];
            updatedMonthlyUpdateList[index].is_edit = false;
            setstrategicFocusList(prevList);
        }
         
        

        if (currentOperation === 'add') {
            setstrategicFocusList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index, 1);
                return updatedList;
            });
        }
        setCurrentOperation(null)
    };

   
    
    
    
    
    
    const handleAdd = (index) => {
        
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...strategicFocusList];
            const newItem = {
                StrategicFocus: "",
                Description: "",
                SupportRequired: "",
                ImpactOnID: "",
                ClosureDate: "",
                StatusID: "",
                CriticalityID: "",
                LatestUpdate: "",
                Owner: "",
                CMOComments: "",
                company_id: access_details['company_division_id']
            };
            setstrategicFocusList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, index + 1]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };

    const handleAddNew = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            const updatedMonthlyUpdateList = [...strategicFocusList];
            const newItem = {
                StrategicFocus: "",
                Description: "",
                SupportRequired: "",
                ImpactOnID: "",
                ClosureDate: "",
                StatusID: "",
                CriticalityID: "",
                LatestUpdate: "",
                Owner: "",
                CMOComments: "",
                company_id: access_details['company_division_id']
            };
            setstrategicFocusList((prevList) => {
                const updatedList = [...prevList];
                updatedList.splice(index + 1, 0, newItem);
                return updatedList;
            });
            setEditableRows([...editableRows, 0]);
            setCurrentOperation('add');
            setAddClick(true); // Set isAddClicked to true for add operation
        }
    };


    const handleInputChange = (index, field, value) => {
        console.log("input from date --------->>>", field, value);
        const updatedMonthlyUpdateList = [...strategicFocusList];
        // updatedMonthlyUpdateList[index][field] = value.label;
        // Check if the value is an object (dropdown) or a plain value (textarea, input, etc.)
        const updatedValue = typeof value === 'object' ? value.label : value;
        updatedMonthlyUpdateList[index][field] = updatedValue;
        setstrategicFocusList(updatedMonthlyUpdateList);
    };

    const setSelectValue = (list, value) => {
        return list.find((data) => {
            return data.label === value;
        });

    }

    function formatDateString(inputDateString) {
        const inputDate = new Date(inputDateString);

        const options = { day: "numeric", month: "short", year: "numeric" };
        const formattedDate = inputDate.toLocaleDateString("en-GB", options);

        return formattedDate;
    }

       // validation popup close

       const closePopup = () => {
        setPopupOpen(false);
    };




    // dropdown scroll close start

    // const customStyles = {
    //     control: (provided) => ({
    //         ...provided,
    //         backgroundColor: 'white',
    //         color: 'red', // Change the background color of the control
    //         marginTop: 0, // Adjust the top margin
    //     }),
    //     menu: (provided) => ({
    //         ...provided,
    //         zIndex: 0,
    //         position: 'absolute',
    //         fontSize: '11px',
    //         marginTop: 0,
    //         marginBottom: 0, // Adjust the bottom margin
    //     }),
    //     option: (provided, state) => ({
    //         ...provided,
    //         backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
    //         color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
    //         height: '45px',

    //     }),
    // };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the text color of the control
            borderColor: state.isFocused ? 'black' : provided.borderColor, // Change border color when focused
            boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow, // Add box shadow when focused
            marginTop: 0, // Adjust the top margin
            '&:hover': { // Prevent blue highlight on hover
                borderColor: state.isFocused ? 'black' : provided.borderColor,
                boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow,
            }
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
                    color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
                    height: '45px',
            // '&:hover': {
            //     color: 'white', // Change text color on hover
            // },
        }),
    };

    // Add state variables for each dropdown
    const [isOwnerDropdownOpen, setIsOwnerDropdownOpen] = useState(false);
    const [isImpactOnDropdownOpen, setIsImpactOnDropdownOpen] = useState(false);
    const [isCriticalityOnDropdownOpen, setIsCriticalityDropdownOpen] = useState(false);
    const [isStatusDropdownOpen, setIsStatusrDropdownOpen] = useState(false);

    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };


    const CloseStrategicComments = () => {
        setStrategicIDForChat(null)
        setStrategicIDForChatAssignedTo(null)
        setshowStrategicComments(false)
        setStrategicCommentsText('')
        setStrategicChatDetails([])
        setStrategicChatDetailsHeader('')

    }

    const AddStrategicComments = (StrategicID,AssignedToUserIdChat) => {
        
        setIsLoading(true)
        const API_URL = 'api/add-strategic-comment-details';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            StrategicID : StrategicID,
            EntityID: access_details['company_division_id'],
            StrategicFocusComment : StrategicCommentsText,
            AssignedToUserID : AssignedToUserIdChat,//Need To change
            ActionByUserID :  userData && 'UserId' in userData ? userData['UserId'] : ""
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    
                    setStrategicIDForChat(null)
                    setStrategicIDForChatAssignedTo(null)
                    setshowStrategicComments(false)
                    setStrategicCommentsText('')
                    setStrategicChatDetails([])
                    setIsLoading(false)
                    openStrategicComment(StrategicID)
                    
                }
                else {
                    
                    setStrategicIDForChat(null)
                    setStrategicIDForChatAssignedTo(null)
                    setshowStrategicComments(false)
                    setStrategicCommentsText('')
                    setStrategicChatDetails([])
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setStrategicIDForChat(null)
                setStrategicIDForChatAssignedTo(null)
                setshowStrategicComments(false)
                setStrategicCommentsText('')
                setStrategicChatDetails([])
                setIsLoading(false)
                console.error(error);
            });
    }

    const openStrategicComment = (StrategicID,assignedUser) => {
        

        setStrategicIDForChat(StrategicID)
        setStrategicIDForChatAssignedTo(assignedUser)
      
        setIsLoading(true)
        const API_URL = 'api/get-strategic-comment-details';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            StrategicID : StrategicID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    setStrategicChatDetails(response.data.Comments)
                    setStrategicChatDetailsHeader(response.data.StrategicHeader)
                    setshowStrategicComments(true)
                    setStrategicCommentsText('')
                    setIsLoading(false)
                    
                }
                else {
                    setshowStrategicComments(true)
                    setStrategicCommentsText('')
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const popupRef = useRef(null);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                CloseStrategicComments(); // Close popup if clicked outside
            }
        };

        // Add event listener for clicks
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
  
    // dropdown scroll close end

    return (

        <div className="parentContainer" style={{marginBottom:"20px"}}>

    {/* Comment chat For Milestone Screen */}
    {showStrategicComments && (
                <div className="popup">
                    <div ref={popupRef} className="popup-inner-Chat">
                        {/* <div className='mx-2'> */}
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between align-items-center col-12 '>
                                    <span style={{fontSize:'20px', color:'#21A8E1'}}>Comments</span>
                                    <img title="Cancel" onClick={() => CloseStrategicComments()} src={CancelBluIconComment} className='cursor-pointer' style={{height:'40px',width:'40px', padding: '0.8 rem'}} alt="Add Milestone" />
                                </div>
                            </div>
                            <div>
                                <hr style={{color:'#21A8E1'}}/>
                            </div>
                            <div className='ChatBodyDetails' style={{maxHeight:'68vh',minHeight:'68vh',overflowY:'auto'}}>
                                {StrategicChatDetails.map((item) => (
                                    <div style={{ display : 'flex',justifyContent : item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start'}}>
                                        <div className=' d-flex mb-4' style={{justifyContent: item.ActionByUserId == userData['UserId'] ? 'flex-end' : 'flex-start',width:'90%'}}>
                                            {item.ActionByUserId != userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Add Milestone" /> : null}
                                            <div className='custom-bubblewidth'>
                                            <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}} className= {item.ActionByUserId == userData['UserId'] ? 'chatbackgroundmyself me-3 w-100' : 'chatbackgroundother me-3 w-100'}>
                                                    {/* <span style={{fontSize:'14px'}}>{item.Comment}</span> */}
                                                    <div  style={{fontSize:'14px'}} dangerouslySetInnerHTML={{ __html:item.StrategicFocusComment.replace(/\n/g, '<br>')}}/>
                                                </div>
                                                <div style={{marginLeft:item.ActionByUserId != userData['UserId'] ? '1rem':'0rem'}}  className= 'd-flex  justify-content-between me-3 gap-2  w-100'>
                                                    <span style={{fontSize:'12px'}}>{item.ActionByUserIdName}</span>
                                                    <span style={{fontSize:'12px',display:'flex',flexShrink:'0'}}>{formatDateTimeForChat(item.CreatedOn)}</span>
                                                </div>
                                            </div>
                                            {item.ActionByUserId == userData['UserId'] ? <img src={ProfIcon} className='cursor-pointer' style={{height:'21px',width:'21px',marginLeft:'0.7rem'}} alt="Add Milestone" /> : null}
                                        </div>
                                    </div>
                                ))}
                                {StrategicChatDetails.length == 0? <div className='d-flex justify-content-center'>No comments</div>:null}
                            </div>
                            <div className='row m-2'>
                                <div className='d-flex justify-content-between col-12 mb-2'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={ProfIcon} className='cursor-pointer me-1' style={{height:'25px',width:'25px'}} alt="Add Comments" /> 
                                    </div>
                                    <input
                                        style={{width : '90%',padding:'0.4rem'}}
                                        type="text"
                                        name="StrategicCommentsText"
                                        placeholder='Write a comment'
                                        className='normalSelectStyle'
                                        value={StrategicCommentsText}
                                        onChange={(e) => setStrategicCommentsText(e.target.value)}
                                    />
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <img title="Send" onClick={() => AddStrategicComments(StrategicIDForChat,StrategicIDForChatAssignedTo)} src={SendChatIcon} className='cursor-pointer' style={{height:'21px',width:'21px'}} alt="Send Icon" /> 
                                        </div>
                                 
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            )}


            {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-center'>
                                <p className="head-text">Delete Item</p>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Mention Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}
                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                />
                            </label>
                            <button onClick={() => setShowConfirmationModal(!showConfirmationModal)} className="cancel-btn">Cancel</button>
                            <button type="submit" className="confirm-btn">Confirm</button>
                        </form>
                    </div>
                </div>
            )}

                 {/* Validation popup start */}
         {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text" style={{fontSize:"20px"}}>Information</p>
                            </div>
                        </div>
                        <div className='ChatBodyDetails' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto',overflowY:'auto', padding:"0rem"}}>
                                {errorList.map((item, index) => (
                                    <div style={{ display : 'flex'}}>
                                        <div className=' d-flex mb-3' style={{width:'90%'}}>
                                            {`${index+1}) ${item}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        <button className="close-btn" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}
            {/* Validation popup end */}

            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}

            <div className="monthlyHeaderWrapper d-flex justify-content-between">
                <span className="monthly-Title-Table-header">Strategic Focus</span>
         
            </div>

            {isLoading ? (<SmallSizedLoader />) :
                (<div className="table-div padStyle ">
                    <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff" , zIndex:9999999999}} />
                    <div className="table-container">
                        <table className="table-container-subdiv open-actionTable action-summary">
                            <thead className="action-head">
                                <tr>
                                <th style={{ width: "1%" }} className="column-header-style fixed-column sr-column">Sr. No.</th>
                                <th style={{ width: "5%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Strategic Focus<span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="2-3 words of the initiative being undertaken"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                </th>
                                <th style={{ width: "8%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Description<span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Short description on the focus area"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                </th>
                                <th style={{ width: "5%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Support Required<span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Enter management or internal support needed to help you deliver this focus area"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                </th>
                                <th style={{ width: "4%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Impact On<span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Choose from dropdown"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                </th>
                                <th style={{ width: "3%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Due Date<span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="DD/MM/YYYY"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                </th>
                                <th style={{ width: "3%" }} className="column-header-style">
                                <div className="d-flex justify-content-between">
                                <div>Criticality<span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Choose from dropdown"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>

                                </th>
                                    <th style={{ width: "4%" }} className="column-header-style">
                                        <div className="d-flex justify-content-between">
                                            <div>Status<span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Choose from dropdown"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                    </th>
                                    <th style={{ width: "5%" }} className="column-header-style">
                                    <div className="d-flex justify-content-between">
                                     <div>Owner<span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Initials of the person"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                    </th>
                                    <th style={{ width: "5%" }} className="column-header-style">
                                    <div className="d-flex justify-content-between">
                                    <div>Update For {monthName}'{year}<span style={{ color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                            </div>
                                            <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Short description of current update on the decision"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div>
                                        </div>
                                    </th>
                                    {/* <th style={{ width: "5%" }} className="column-header-style">
                                    <div className="d-flex justify-content-between">
                                    <div>CMO Comments</div>
                                            {/* <div>
                                                <a
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="CMO Comments"
                                                    data-tooltip-place="bottom">
                                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                                </a>
                                            </div> 
                                        </div>
                                    </th> */}
                                    <th style={{ width: "0%", display: props.ismonth ? "none" : "table-cell" }} className="column-header-style bg-wh fixed-column actions-column">Actions</th>  </tr>
                            </thead>
                            <tbody className="action-body">
                            {strategicFocusList.length > 0 ? (
                                strategicFocusList.map((item, index) =>
                                    <tr key={index} className={editableRows.includes(index) ? "editable-row" : ""}>
                                        {/* Sr.no  */}
                                        <td className="column-data-style fixed-column sr-column">
                                            {index + 1}
                                        </td>

                                        {/* STRATEGIC FOCUS  */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.StrategicFocus} onChange={(e) => handleInputChange(index, 'StrategicFocus', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_StrategicFocus}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.StrategicFocus} onChange={(e) => handleInputChange(index, 'StrategicFocus', e.target.value)} />
                                                        : ""
                                                    }
                                                </div> 
                                            )}
                                        </td>

                                        {/* DESCRIPTION */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                            
                                            <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Description} onChange={(e) => handleInputChange(index, 'Description', e.target.value)} />
                                            ) : (
                                                <div>
                                                    {item.Previous_Description && (
                                                        <pre className="preStyleFormat" >
                                                            {item.Previous_Description}
                                                        </pre>
                                                    )}
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Description} onChange={(e) => handleInputChange(index, 'Description', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                        {/* SUPPORT REQUIRED */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                            
                                            <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.SupportRequired} onChange={(e) => handleInputChange(index, 'SupportRequired', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_SupportRequired}</p>
                                                    {item.is_edit ?
                                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.SupportRequired} onChange={(e) => handleInputChange(index, 'SupportRequired', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>
                                        {/* IMPACT ON */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                               <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                               <Select
                                                   id={index}
                                                   options={ImpactOnList}
                                                   value={setSelectValue(ImpactOnList, strategicFocusList[index].ImpactOnID)}
                                                   styles={customStyles}
                                                   menuPosition={'fixed'}
                                                   maxMenuHeight={100}
                                                   onChange={
                                                       (e) => handleInputChange(index, 'ImpactOnID', e)
                                                   }
                                                   closeMenuOnScroll={() => !isSelectHovered}
                                                   onMenuOpen={() => setIsImpactOnDropdownOpen(true)}
                                                   onMenuClose={() => setIsImpactOnDropdownOpen(false)}
                                                   menuIsOpen={isImpactOnDropdownOpen}
                                                   menuPortalTarget={null}
                                               />
                                           </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_ImpactOnID}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={ImpactOnList}
                                                                value={setSelectValue(ImpactOnList, strategicFocusList[index].ImpactOnID)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'ImpactOnID', e)
                                                                }
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setIsImpactOnDropdownOpen(true)}
                                                                onMenuClose={() => setIsImpactOnDropdownOpen(false)}
                                                                menuIsOpen={isImpactOnDropdownOpen}
                                                                menuPortalTarget={null}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                        {/* DUE DATE  */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <input className="tableInput inputStyle" style={{ width: "100%" }} type="date" value={item.ClosureDate ? item.ClosureDate.slice(0, 10) : ''} onChange={(e) => handleInputChange(index, 'ClosureDate', e.target.value)} />
                                            ) : (
                                                <div>
                                                    <p>{formatDate(item.Previous_ClosureDate)}</p>
                                                    {item.is_edit ?
                                                        <input className="tableInput inputStyle" style={{ width: "100%" }} type="date" value={item.ClosureDate ? item.ClosureDate.slice(0, 10) : ''} onChange={(e) => handleInputChange(index, 'ClosureDate', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>

                                        {/* CRITICALITY */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                               <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                               <Select
                                                   id={index}
                                                   options={CriticalityList}
                                                   value={setSelectValue(CriticalityList, strategicFocusList[index].CriticalityID)}
                                                   styles={customStyles}
                                                   menuPosition={'fixed'}
                                                   maxMenuHeight={100}
                                                   onChange={
                                                       (e) => handleInputChange(index, 'CriticalityID', e)
                                                   }
                                                   closeMenuOnScroll={() => !isSelectHovered}
                                                   onMenuOpen={() => setIsCriticalityDropdownOpen(true)}
                                                   onMenuClose={() => setIsCriticalityDropdownOpen(false)}
                                                   menuIsOpen={isCriticalityOnDropdownOpen}
                                                   menuPortalTarget={null}
                                               />
                                           </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_CriticalityID}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={CriticalityList}
                                                                value={setSelectValue(CriticalityList, strategicFocusList[index].CriticalityID)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'CriticalityID', e)
                                                                }
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setIsCriticalityDropdownOpen(true)}
                                                                onMenuClose={() => setIsCriticalityDropdownOpen(false)}
                                                                menuIsOpen={isCriticalityOnDropdownOpen}
                                                                menuPortalTarget={null}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>


                                        

                                        {/* STATUSID  */}
                                        <td className="column-data-style">
                                            {editableRows.includes(index) ? (
                                                <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                    <Select
                                                        id={index}
                                                        options={getStatusList}
                                                        value={setSelectValue(getStatusList, strategicFocusList[index].StatusID)}
                                                        styles={customStyles}
                                                        menuPosition={'fixed'}
                                                        maxMenuHeight={100}
                                                        onChange={
                                                            (e) => handleInputChange(index, 'StatusID', e)
                                                        }
                                                        closeMenuOnScroll={() => !isSelectHovered}
                                                        onMenuOpen={() => setIsStatusrDropdownOpen(true)}
                                                        onMenuClose={() => setIsStatusrDropdownOpen(false)}
                                                        menuIsOpen={isStatusDropdownOpen}
                                                        menuPortalTarget={null}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <p>{item.Previous_StatusID}</p>
                                                    {item.is_edit ?
                                                        <div className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                            <Select
                                                                id={index}
                                                                options={getStatusList}
                                                                value={setSelectValue(getStatusList, strategicFocusList[index].StatusID)}
                                                                styles={customStyles}
                                                                menuPosition={'fixed'}
                                                                maxMenuHeight={100}
                                                                onChange={
                                                                    (e) => handleInputChange(index, 'StatusID', e)
                                                                }
                                                                closeMenuOnScroll={() => !isSelectHovered}
                                                                onMenuOpen={() => setIsStatusrDropdownOpen(true)}
                                                                onMenuClose={() => setIsStatusrDropdownOpen(false)}
                                                                menuIsOpen={isStatusDropdownOpen}
                                                                menuPortalTarget={null}
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>


                                        {/* OWNER */}
                                        <td className="column-data-style">
                                        {editableRows.includes(index) ? (
                                        
                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Owner} onChange={(e) => handleInputChange(index, 'Owner', e.target.value)} />
                                        ) : (
                                            <div>
                                            <p>{item.Previous_Owner}</p>
                                            {item.is_edit ?
                                                <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.Owner} onChange={(e) => handleInputChange(index, 'Owner', e.target.value)} />
                                                : ""
                                            }
                                        </div>
                                        )}
                                        </td>
                                      
                                        

                                                                                
                                        {/* UPDATE */}
                                        <td className="column-data-style update-month">
                                            {editableRows.includes(index) ? (
                                                <div>
                                                    {/* {item && item.previous_update_for_the_month && item.previous_update_for_the_month != null && item.previous_update_for_the_month != undefined && item.previous_update_for_the_month != "" ? <label className={index == 0 ? "update-message-bottom" : "update-message-top"}>{item.previous_update_for_the_month}</label> : null} */}
                                                    <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.LatestUpdate} onChange={(e) => handleInputChange(index, 'LatestUpdate', e.target.value)} />
                                                </div>
                                            ) : (
                                                <div>
                                                    {item.Previous_LatestUpdate && (
                                                        <pre className="preStyleFormat" >
                                                            {item.Previous_LatestUpdate}
                                                        </pre>
                                                    )}
                                                    {item.is_edit ?
                                                        <textarea className="textTable" spellCheck={true} placeholder="Start typing..." style={{ width: "100%" }} rows="2" cols="50" value={item.LatestUpdate} onChange={(e) => handleInputChange(index, 'LatestUpdate', e.target.value)} />
                                                        : ""
                                                    }
                                                </div>
                                            )}
                                        </td>


                                        {/* CMO Comments */}
                                        {/* <td className="column-data-style">
                                        {editableRows.includes(index) ? (
                                        
                                        <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.CMOComments} onChange={(e) => handleInputChange(index, 'CMOComments', e.target.value)} />
                                        ) : (
                                            <div>
                                                <p>{item.Previous_CMOComments}</p>
                                                {item.is_edit ?
                                                    <textarea className="tableInput" style={{ width: "100%" }} type="text" value={item.CMOComments} onChange={(e) => handleInputChange(index, 'CMOComments', e.target.value)} />
                                                    : ""
                                                }
                                            </div>
                                        )}
                                        </td> */}

                                      
                                        {/* ACTION */}
                                        <td className={index % 2 === 0 ? "column-data-style bg-ylw" : "column-data-style bg-wh"} style={{display: props.ismonth ? "none" : "column-data-style" }}>
                                            {editableRows.includes(index) ? (
                                                <div className="d-flex justify-content-around">
                                                    <div className="d-flex justify-content-center">
                                                        <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                        <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ width: "100px" }}>
                                                    {!item.is_edit ?
                                                        <div className="d-flex justify-content-around">
                                                            <div>
                                                                <img src={edit} className="edit" alt="Edit" style={{ width: "13px" }} onClick={() => handleEdit(index, "edit")} />
                                                            </div>
                                                            <div>
                                                                <img src={remove} className="delete" alt="Delete" style={{ width: "13px" }} onClick={() => handleDelete(index)} />
                                                            </div>
                                                            <div>
                                                                <img src={add} className="add" alt="Add" style={{ width: "13px" }} onClick={() => handleAdd(index, "add")} />
                                                            </div>
                                                            <div>
                                                                <img src={CommementBlue} className="comment" alt="Comment" style={{ width: "13px" }} onClick={() => openStrategicComment(item.ID,item.assignedToID)} />
                                                            </div>
                                                        
                                                        </div>
                                                        :
                                                        <div className="d-flex justify-content-around">
                                                            <div className="d-flex justify-content-center">
                                                                <span className="save-btn" onClick={(e) => handleSave(index, e)} >save</span>
                                                                <img src={close} className="close" alt="close" onClick={() => handleClose(index)} />
                                                            </div>
                                                        </div>
                                                    } 
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                    ))
                                    : (
                                        <tr>
                                            <td colSpan="12" className="text-center">
                                                <div className="add">
                                                    <div className="add-btn" onClick={handleAddNew}>
                                                        <span>Add New</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>)}
        </div>
    );
};