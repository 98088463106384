

import React, { useEffect, useRef, useState } from 'react';
import './Login.css';
import BackgroundImage from '../../assets/images/Login Screen.png';
import Logo from '../../assets/images/planetwhite_logo.png';
import MicrosoftICN from '../../assets/images/microsoftoutlook.svg';
import Lock from '../../assets/images/LogIn icon.svg';
import formheadimg from '../../assets/images/PlanetXPro.svg';
import Usericon from '../../assets/images/Profile icon.svg';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { BASE_URL, setCookie } from '../../../src/api_interceptor/api';
import Loader from '../../components/loader/Loader';
import Alert_Popup from '../../components/alert_popup/Alert_Popup';
import PdfViewerModal from '../../components/pdf_viewer_modal/PdfViewerModal';
import {  OverlayTrigger, Popover } from 'react-bootstrap';
import { setEmailIdRedux, setIsRemeberMeCheckedRedux, setPasswordRedux, setUserDetails } from '../../redux/action/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { param } from 'jquery';

const options = {
    autoClose: 2000, // Sets duration to 2 seconds
};
const Login = (props) => {
    const dispatch = useDispatch();
    const Isremember = useSelector((state) => state.user.IsRemeberMeChecked);
    const isEmailThere = useSelector((state) => state.user.EmailID);
    const isPasswordThere = useSelector((state) => state.user.Password);

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showPopup, setShowPopup] = useState(false);
    const [emailforget, setEmailForget] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setAlert] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [isRememberCheck, setisRememberCheck] = useState(false);
    const [isForgetMailTriggered, setisForgetMailTriggered] = useState(false);
    const [msg, setMsg] = useState('');
    const [type, setType] = useState('');

    // Reset Password
    const [passwordReset, setpasswordReset] = useState('');
    const [confirmpasswordReset, setconfirmpasswordReset] = useState('');
    const [showResetModal, setshowResetModal] = useState(false);
    


    const location = useLocation();

    useEffect(() => {
        // Extract the 'code' query parameter from the URL
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        const redirectURI = params.get('redirectURI');


        // If 'code' is present, make the API call
        if (code) {
            MicrosoftIndexCall(code)
        }


        if (redirectURI){
            localStorage.setItem('redirectURI', redirectURI);
            LoginWithMicrosoft()
        }
    }, [location.search]);

    

    const MicrosoftIndexCall = (code) => {
        
        setIsLoading(true)
        const API_URL = `api/auth/index?code=${code}`;

        
        api.get(API_URL)
            .then((response) => {
                
                if(response && response.data && response.data.success == true){
                    try {
                        // Decode the token
                        const decodedToken = jwtDecode(response.data.token);
                        console.log('Decoded Token:', decodedToken);

                
                        // Extract the email ID
                        const userEmail = decodedToken.upn || decodedToken.unique_name;
                
                        console.log('User Email:', userEmail);
                        loginHandler(userEmail,'pass',true)
                
                        // You can now use the email ID as needed in your application
                    } catch (error) {
                        console.error('Failed to decode token', error);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }




    useEffect(() => {
        setisRememberCheck(Isremember)
        if(isEmailThere != null && isPasswordThere!=null){

            setEmail(isEmailThere)
            setPassword(isPasswordThere)
        }
    }, [Isremember]);

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    useEffect(() => {
        if (props.showPopUpModal == true) {
            setAlert(true)
        }
    }, [props.showPopUpModal]);

    useEffect(() => {
        if (show) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [show]);

    const handleOutsideClick = (event) => {
            setShow(false);
        
    };

    const emailChangeHandler = (event) => {
        setEmail(event.target.value);
    }

    const passwordChangeHandler = (event) => {
        setPassword(event.target.value);
    }

    const sendEmailForget = (event) => {
        event.preventDefault();
        setShowPopup(!showPopup)
        setIsLoading(true)
        const API_URL = 'api/forget-password';
        const req_body = {
            EmailId: emailforget,
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                
                setIsLoading(false)
                setEmailForget("")
                // setEmail("")
                // setPassword("")
                setIsChecked(false)
                setShowPopup(!showPopup)
                if (response && response.data) {
                    if (response && response.data && response.data.StatusCode == 1) {
                        setisForgetMailTriggered(true)
                        setPassword("")
                        toast.success('OTP has been sent to your mail!', options)

                    }
                    else if(response && response.data && response.data.StatusCode == 0){
                        toast.error('Invalid User', options)
                    }
                    else{
                        toast.error('Failed to send OTP!', options)
                    }
                }
                else {
                    toast.error('Failed to send OTP!', options)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                setEmailForget("")
                toast.error('Failed to send OTP!', options)
                console.error(error);
            });
    }

    const onSignIn = (event) => {
        
        if(isForgetMailTriggered){
            event.preventDefault()
            validateOtp()
        }
        else{
            event.preventDefault();
            dispatch(setEmailIdRedux(email))
            dispatch(setPasswordRedux(password))
            loginHandler(email, password, false);
        }
    }

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    


    const handleCheckboxChange = () => {
        
        setIsChecked(!isChecked);
        setShowPopup(!showPopup);
    };

    const handleRemeberMeCheckboxChange = () => {
        
        setisRememberCheck(!isRememberCheck)
        dispatch(setIsRemeberMeCheckedRedux(!isRememberCheck));
    };

    const loginHandler = (email, password, type) => {
        
        const API_URL = 'api/login';
        setIsLoading(true)
        const req_body = {
            EmailId: email,
            Password: password,
            IsMicrosoftLogin: type
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                setIsLoading(false)
                
                if (response.data.UserDetails != null && response.data.StatusCode == 1) {
                    setCookie("isLogin", true, 1)
                    dispatch(setUserDetails(response.data.UserDetails));
                    const storedRedirectURI = localStorage.getItem('redirectURI');

                    if (storedRedirectURI) {
                        localStorage.removeItem("redirectURI");
                        window.location.href = storedRedirectURI;
                    } else {
                        window.location.href = "/";
                    }
                    

                } else if (response.data.StatusCode == 0) {
                    setAlert(true)
                    setMsg('Invalid credentials')
                    setType('Error')
                } 
                else if (response.data.StatusCode == 3) {
                    setAlert(true)
                    setMsg('Account is locked please reset the password for further use')
                    setType('Error')
                }
                else if (response.data.StatusCode == 4) {
                    setAlert(true)
                    setMsg('Invalid credentials')
                    setType('Error')
                }
                else if (response.data.StatusCode == 5) {
                    setAlert(true)
                    setMsg('You are not authorized to access this system')
                    setType('Error')
                }
                else {
                    setAlert(true)
                    setMsg('Something went wrong')
                    setType('Error')
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }
    

    const validateOtp = () => {
        
        setIsLoading(true)
        const API_URL = 'api/validate-otp';

        const req_body = {
            EmailId: email,
            Password: password
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                console.log('inside ----------------- >>> VALIDATE RESPONSE',response);
                
                setIsLoading(false)
                if (response.data && response.data.StatusCode == 1) {
                    setshowResetModal(true)
                } else {
                    setAlert(true)
                    setMsg('Invalid OTP')
                    setType('Error')
                } 
                
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const setPasswordResetAPI = (event) => {
        
        event.preventDefault()
        if(passwordReset != confirmpasswordReset){
            setAlert(true)
            setMsg('Password mismatch')
            setType('Error')
        }
        else{ setIsLoading(true)
        const API_URL = 'api/reset-password';

        const req_body = {
            EmailId: email,
            Password: confirmpasswordReset
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                
                setIsLoading(false)
                setshowResetModal(false)
                if (response.data.UserDetails != null && response.data.StatusCode == 1) {
                    loginHandler(email, confirmpasswordReset, false)
                } else {
                    setAlert(true)
                    setMsg('Failed to reset password')
                    setType('Error')
                } 
                
            })
            .catch((error) => {
                setIsLoading(false)
                setshowResetModal(false)
                console.error(error);
            });}
    }
    const LoginWithMicrosoft = (event) => {
        
        if (event!=null && event != undefined){
            event.preventDefault()
        }
        
        const API_URL = 'api/auth/redirect';

        
        api.get(API_URL, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                
                setIsLoading(false)
                const { redirectUrl } = response.data;
                window.location.href = redirectUrl;
                
            })
            .catch((error) => {
                setIsLoading(false)
                // setshowResetModal(false)
                console.error(error);
            });}

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                In case of any queries, kindly contact <a href="mailto:accountability@planetventures.com">accountability@planetventures.com</a>
            </Popover.Body>
        </Popover>
    );

    return (

        <div className="container-fluid">
            {showModal && <PdfViewerModal pdfUrl={""} showModal={showModal} onClose={handleCloseModal} />}
            <ToastContainer />
            {showAlert && <Alert_Popup msg={msg} type={type} onClosePress={() => setAlert(!showAlert)} />}
            <div className="row">
                {isLoading && (<Loader />)}
                {/* Forget Password POPUP */}
                {showPopup && (
                    <div className="popup">
                        <div className="popup-inner">
                            <div className='d-flex justify-content-between mb-3'>
                                <div className=' text-start'>
                                    <h5>Forget Pasword</h5>
                                </div>
                                <div className=' text-end'>
                                    <a onClick={() => {
                                        setEmailForget("")
                                        // setEmail("")
                                        // setPassword("")
                                        setIsChecked(false)
                                        setShowPopup(!showPopup)
                                    }}>< i class="fa fa-times" aria-hidden="true"></i></a>
                                </div>
                            </div>
                            <form onSubmit={sendEmailForget}>
                                <label className='label-text '>
                                    {/* Email */}
                                    <input className='mt-2 form-control'
                                        type="email"
                                        placeholder="Enter your email address"
                                        value={emailforget}
                                        required
                                        onChange={(e) => setEmailForget(e.target.value)}
                                    />
                                </label>
                                <div className='d-flex justify-content-center'>
                                    <button className='bg-common-blue fw-bold' type="submit">Get Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                {/* Reset Password POPUP */}
                {showResetModal && (
                    <div className="popup_Reset">
                        <div className="popup-inner">
                            <div className='d-flex justify-content-between mb-3'>
                                <div className=' text-start'>
                                    <h5>Reset Pasword</h5>
                                </div>
                                <div className=' text-end'>
                                    <a onClick={() => {
                                        setpasswordReset("")
                                        setconfirmpasswordReset("")
                                        setshowResetModal(false)
                                    }}>< i class="fa fa-times" aria-hidden="true"></i></a>
                                </div>
                            </div>
                            <form>
                                <label className='label-text '>
                                    {/* Password */}
                                    <input className='mt-2 form-control'
                                        type="password"
                                        placeholder="Password"
                                        value={passwordReset}
                                        required
                                        onChange={(e) => setpasswordReset(e.target.value)}
                                    />
                                    {/* Confirm Password */}
                                    <input className='mt-2 form-control'
                                        type="password"
                                        placeholder="Confirm password"
                                        value={confirmpasswordReset}
                                        required
                                        onChange={(e) => setconfirmpasswordReset(e.target.value)}
                                    />
                                </label>
                                <div className='d-flex justify-content-center'>
                                    <button onClick={(e) => setPasswordResetAPI(e)} className='bg-common-blue fw-bold' type="submit">Reset password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                <section className="LoginScreen ps-0 pe-4">
                    <div className='d-flex   main-container gap-5'>
                        <div className='ComponyLogo d-flex justify-content-center'>
                            <img src={Logo} className='logo-style mt-4' alt="Logo" />
                            <div className='privacyPolicy'>
                                <span className='cursor-pointer' onClick={() => {
                                    // window.open(BASE_URL + 'PrivacyPolicy/Privacy_Policy.html', '_blank'); 
                                    window.open(`/privacypolicy`, '_blank');
                                }}>Privacy Policy</span>
                            </div>
                        </div>
                        <div className='LoginForm '>
                            <div className='inner-FormDetais'>
                                <div className='row mt-3 loginformstart'>
                                    <div className='col-12'>
                                        <form onSubmit={onSignIn}>
                                            <div className='d-flex justify-content-between login-container'>
                                                <div className='Form-Head align-items-center  d-flex '>
                                                    {/* <p className='title-style mb-0'>Welcome to</p> */}
                                                    <img src={formheadimg} className='formheadimg img-fluid' alt="HeadLogo" />
                                                </div>
                                                <div className='container-Trubles d-flex align-items-center '>
                                                    <p className='Trubles-txt mb-0'>Having Troubles? &nbsp;
                                                    <OverlayTrigger
                                                                trigger="click"
                                                                placement="bottom"
                                                                overlay={popover}
                                                                show={show}
                                                                target={target}
                                                                onHide={() => setShow(false)}
                                                            >
                                                        <span onClick={handleClick} className='bluecolor' data-toggle="popover" data-html="true" data-content=" In case of any queries, kindly contact <a href='mailto:accountability@planetventures.com'>accountability@planetventures.com</a>." 
                                                        style={{ cursor: 'pointer' }}> Get Help </span>
                                                    </OverlayTrigger>
                                                        
                                                        </p>
                                                    <img src={Usericon} className='Usericon img-fluid ms-3' alt="UserLogo" />
                                                </div>
                                            </div>
                                            <div className='main-Loginform mt-4'>
                                                <div className='info-Para'>
                                                    <p className='Login-infoPara'>
                                                        {/* Log in with your email address so that your <br />information is not lost and it remains safe with us. */}
                                                        <br />
                                                        <br />
                                                    </p>
                                                </div>
                                                

                                                <div class="parent-btn fnt-btn">
                                                    <button onClick={(e) => {LoginWithMicrosoft(e)}} class="LgBtn MicrosoftLogin" tabindex="0" type="button">
                                                        <div>
                                                            <img class="wp-22" src={MicrosoftICN} />
                                                        </div>
                                                        <span>Continue with Microsoft </span>
                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-right">
                                                            <path d="M4.66667 10H16.3333" stroke="#D4D6FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            <path d="M10.5 4.16675L16.3333 10.0001L10.5 15.8334" stroke="#D4D6FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                        </svg>
                                                    </button>
                                                </div>

                                                <div class="border-container my-3">
                                                    <span class="border-text">or</span>
                                                </div>


                                                <div className=''>
                                                    <div className='col-12'>
                                                        <label className='span-style'>Email Address</label>
                                                    </div>
                                                    <div className='col-12'>
                                                        <input required className='form-control rounded-0 blue-border' type="email" placeholder="Enter email" value={email}
                                                onChange={emailChangeHandler}/>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-12'>
                                                        <label className='span-style'>{isForgetMailTriggered ? 'OTP' : 'Password'}</label>
                                                    </div>
                                                    <div className='col-12'>
                                                        <input required className='form-control rounded-0 blue-border' type="password" placeholder={isForgetMailTriggered ? 'Enter OTP' : "Enter Password"} value={password} onChange={passwordChangeHandler} />
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between mt-4 mobcolumn'>
                                                    <div className='d-flex flex-column gap-1'>
                                                        <div class="custom-checkbox-container">
                                                            <input onChange={handleRemeberMeCheckboxChange} checked={isRememberCheck}  type="checkbox" id="rememberMe" />
                                                            <label class="custom-checkbox" for="rememberMe"></label>
                                                            <span>Remember Me</span>
                                                        </div>
                                                        <div class="custom-checkbox-container">
                                                            <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange}   id="forgotPassword" />
                                                            <label class="custom-checkbox"  for="forgotPassword"></label>
                                                            <span className="bluecolor" >Forgot Password?</span>
                                                        </div>
                                                    </div>
                                                    <div className='Loginbtn'>
                                                        <button type='submit' className='sign-in-btn form-style d-flex align-items-center gap-2 '><img src={Lock} className='login-lock ' alt="Lock Icon" />Log In</button>
                                                    </div>
                                                </div>
                                                
                                                <div className='info-clearData pt-2' >
                                                    <p className='d-flex'>Request you to kindly clear the cookies or press ctrl+F5 to refresh the browser before log in.</p>
                                                </div>
                                                <div className='Copyright'>
                                                    <span>Copyright 2024 Planet Ventures Pte. Ltd. All rights reserved.</span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Login;