import React, { useEffect , useRef} from "react";
import './Header.css';
import Logo from '../../assets/images/Planet Logo@2x.png'
// import User from ' ../../assets/images/Profile icon.png';
import User from '../../assets/images/Profile icon.svg';
import Dropdown from '../../assets/images/Icon ionic-ios-arrow-down@2x.png';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import moment from 'moment';
import moment from 'moment-timezone';
import { deleteAllCookies } from "../../api_interceptor/api";
import Loader from "../loader/Loader";
import api from '../../api_interceptor/api'
import { setCompanyIDDetails, setDivisionIDDetails, setDivisionList, setEmailIdRedux, setPasswordRedux, setUserDetails } from "../../redux/action/Actions";
// import Select from 'react-select';
import Select, { components } from 'react-select';
import searchIcnDropdown from '../../assets/images/searchIcnDropdown.svg';
import arrowdownblue from '../../assets/images/arrow-down-blue.svg';
import CommementLightBlue from '../../assets/images/Comment icon light blue icon.svg'
import ProfileIcon from '../../assets/images/Profile icon.svg'
import CancelBluIconComment from '../../assets/images/Cancel icon Comments.svg'
import { formatDateTimeNotification } from "../../common_function/common_function";


import { useNavigate } from 'react-router-dom';
const formatDateInIST = (inputDate) => {
    const parsedDate = moment(inputDate, 'YYYY-MM-DDTHH:mm:ss.SSS', true).tz('Asia/Kolkata');

    if (parsedDate.isValid()) {
        // Add 5 hours and 30 minutes
        parsedDate.add(5, 'hours').add(30, 'minutes');

        return parsedDate.format('DD MMM YYYY | HH[h] mm');
    } else {
        return 'NA';
    }
};
const formatDateInISTRelease = (inputDate) => {
    const parsedDate = moment(inputDate, 'YYYY-MM-DDTHH:mm:ss.SSS', true).tz('Asia/Kolkata');

    if (parsedDate.isValid()) {
        // Add 5 hours and 30 minutes
        parsedDate.add(5, 'hours').add(30, 'minutes');

        return parsedDate.format('DD MMM YYYY');
    } else {
        return 'NA';
    }
};


const HeaderBarMonthly = (props) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [landingCompanyDetails, setlandingCompanyDetails] = useState([]);
    const [landingDivisionDetails, setlandingDivisionDetails] = useState([]);

    const [EntityList, setEntityList] = useState([]);

    const [showNotification, setshowNotification] = useState(false);
    const [showNotificationModal, setshowNotificationModal] = useState(false);
    const [ShowAdvancedFilterModal, setShowAdvancedFilterModal] = useState(false);
    const [NotificationDetailsCount, setNotificationDetailsCount] = useState(null);
    const [NotificationDetails, setNotificationDetails] = useState([]);


    const navigate = useNavigate();
    const Isremember = useSelector((state) => state.user.IsRemeberMeChecked);

       // Custom styles for react-select
       const customStyles = {
        control: (provided) => ({
            ...provided,
            borderColor: '#21a8e1',
            '&:hover': {
                borderColor: '#21a8e1'
            }
        }),
        // option: (provided, state) => ({
        //     ...provided,
        //     color: state.isSelected ? '#21a8e1' : provided.color,
        //     backgroundColor: state.isSelected ? '#eaf6fb' : provided.backgroundColor,
        //     '&:hover': {
        //         backgroundColor: '#eaf6fb'
        //     }
        // }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#21a8e1'
        }),
        container: base => ({ ...base, width: '15rem' }),
        placeholder: (provided, state) => ({
            ...provided,
            color: '#21a8e1'
          }),
          indicatorSeparator: () => ({ display: 'none' }) 
  };

    const userData = useSelector((state) => state.user.userData);
    const company_id = useSelector((state) => state.user.companyID);
    const division_id = useSelector((state) => state.user.divisionID);

    const [selectedOption, setSelectedOption] = useState('Select an option');


    const dispatch = useDispatch();
    // useEffect(() => {
    //     if (userData && userData['user_id']) {
    //         getUserCompany()
    //     }
    // }, [userData]);
    // useEffect(() => {
    //     if (landingCompanyDetails.length > 0) {
    //         setIsLoading(false)
    //         company_id == 0 ?  setSelectedOption(landingCompanyDetails[0].label) : setSelectedOption(company_id.label);
    //         getUserDivision(landingCompanyDetails);

    //     }
    // }, [landingCompanyDetails]);

    // useEffect(() => {
    //     if (landingDivisionDetails.length > 0) {
    //         dispatch(setDivisionList(landingDivisionDetails))
    //         props.onCompChange(landingDivisionDetails)
    //     }
    //     else{
    //         dispatch(setDivisionList([]))
    //         props.onCompChange([])

    //     }
    // }, [landingDivisionDetails]);
    

    // useEffect(() => {
    //     if (landingCompanyDetails.length > 0) {
    //         getUserDivision(landingCompanyDetails);
    //     }
    // }, [selectedOption]);

    const getData = () =>{
        props.onCompChange([])
    }

    const getUserCompany = () => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-user-companies';
        const req_body = {
            user_id: userData['user_id'],
        };
        api.post(API_URL, req_body,{
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data && response.data.company_list && (response.data.company_list).length > 0) {
                    if(company_id == 0){
                        dispatch(setCompanyIDDetails(response.data.company_list[0]));
                    }
                    setlandingCompanyDetails(response.data.company_list)
                    setIsLoading(false)
                }
                else{
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }
    const getUserDivision = (compId) => {
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-user-divisions';
        const req_body = {
            user_id: userData['user_id'],
            company_id : company_id['value']
        };
        api.post(API_URL, req_body,{
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data && response.data.division_list && (response.data.division_list).length > 0) {
                    if(division_id == 0){
                        dispatch(setDivisionIDDetails(response.data.division_list[0]));
                        setIsLoading(false)
                    }
                    else{
                        setIsLoading(false)
                    }
                    setlandingDivisionDetails(response.data.division_list)
                    setIsLoading(false)
                }
                else{
                    dispatch(setDivisionIDDetails(0));
                    dispatch(setDivisionList([]))
                    setIsLoading(false)
                    getData()
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }
    const logout = (event) => {
        
        setIsLoading(false)
        event.preventDefault();
        if(Isremember == false){
            dispatch(setEmailIdRedux(null))
            dispatch(setPasswordRedux(null))
        }
        dispatch(setUserDetails(null))
        // props.onLogout();

        deleteAllCookies()
        // setIsLoading(true)
        // const API_URL = '/logout';
        // const current_date = new Date();
        // const req_body = {
        //     user_id: userData.user_id,
        //     date_time: current_date
        // };
        // api.post(API_URL, req_body, {
        //     'Content-Type': 'application/json'
        // })
        //     .then((response) => {
        //         console.log('respomse --------->>', response);
        //         if (response && response.data && response.data.status) {
        //             setIsLoading(false)
        //             event.preventDefault();
        //             // props.onLogout();

        //             deleteAllCookies()
        //         }
        //         else {
        //             setIsLoading(false)
        //             event.preventDefault();
        //             // props.onLogout();
        //             deleteAllCookies()
        //         }

        //     })
        //     .catch((error) => {
        //         setIsLoading(false)
        //         event.preventDefault();
        //         // props.onLogout();
        //         deleteAllCookies()
        //         console.error(error);
        //     });
        }
    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const [isCompanyDropdownOpen, setCompanyDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const companyDropdownRef = useRef(null);

  const companyDrop = () => {
    setCompanyDropdownOpen(!isCompanyDropdownOpen);
  };

  const closeDropdownOnClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      companyDropdownRef.current &&
      !companyDropdownRef.current.contains(event.target)
    ) {
      setDropdownOpen(false);
      setCompanyDropdownOpen(false);
    }
    
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdownOnClickOutside);

    return () => {
      document.removeEventListener('click', closeDropdownOnClickOutside);
    };
  }, []);

  const selectOption = (option) => {
    console.log(option)
    dispatch(setCompanyIDDetails(option));
    setSelectedOption(option.label);
    toggleDropdown()
};

    const projeccompanyListDetailstList = props.companyListDetails || [];
    console.log('----------------------------Company List -------------->',projeccompanyListDetailstList,props.companyListDetails);
const [selectedValue, setSelectedValue] = useState(0);
  // Function to handle selection change

    const handleSelectChange = (value) => {
        setSelectedValue(value)
        props.setselectedCompany(value);
    };

    const [selectedValueDivision, setselectedValueDivision] = useState(0);
  // Function to handle selection change
    const handleSelectChangeDivision = (value) => {
        setselectedValueDivision(value)
        props.setselectedDivision(value);
    };
    
    const [selectedValueEntity, setselectedValueEntity] = useState(0);
    const handleSelectChangeEntity = (value) => {
        setselectedValueEntity(value)
        props.setselectedEntity(value);
    };


    const navigateTo = (module) =>{
        navigate('/'+ module)
    }


    const DropdownIndicator = (props) => {
        return (
            // <components.DropdownIndicator {...props}>
            //     <img src={searchIcnDropdown} alt="Search" style={{ width: '20px', height: '20px' }} />
            // </components.DropdownIndicator>
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen ? (
                    <img src={searchIcnDropdown} alt="Search" style={{ width: '20px', height: '20px' }} />

                ) : (
                    <img src={arrowdownblue} alt="Search" style={{ width: '13px', height: '20px' }} />
                )}
            </components.DropdownIndicator>
        );
    };

    useEffect(() => {
        if (props.monthlyListDetails.length === 1) {
        const entityId = props.monthlyListDetails[0].Id;
        setSelectedValue(entityId);
        // EntitySelected(entityId);
        }
    }, [props.monthlyListDetails]);

    // const EntitySelected = (val) =>{
        
    //     props.setselectedEntity(val == 1000 ? [] :  val == 0 ? [0] : [val])
    // }


    //  const getAllMasterDetails = () => {
    //     setIsLoading(true)
    //     
    //     const API_URL = 'api/get-all-master-details';
    //     const req_body = {
    //         UserId: userData['UserId'],
    //     };
    //     api.post(API_URL, req_body, {
    //         'Content-Type': 'application/json'
    //     })
    //     .then((response) => {
            
    //         if (response && response.data) {
    //             setEntityList(response.data.EntityList)
    //             setIsLoading(false)
    //         }
    //         else {
    //             setIsLoading(false)
    //         }
    //     })
    //     .catch((error) => {
    //         setIsLoading(false)
    //         console.error(error);
    //     });
    // }

    
    // useEffect(() => {
        
    //     // setTimeout(() => {
    //         getAllMasterDetails()
    //     // }, 1000);
    // }, []);


    const toggleNotification = () => {
        setshowNotification(!showNotification)
    }

    const toggleNotificationModal = () => {
        setshowNotificationModal(!showNotificationModal)
    }

    const toggleAdvancedFilter = () => {
        setShowAdvancedFilterModal(!ShowAdvancedFilterModal)
    }

    const getAllNoticationList = () => {
        setIsLoading(true)
        const API_URL = 'api/getAllNoticationByUserId';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data.NotificationList) {
                    setNotificationDetails(response.data.NotificationListMRM)
                    setNotificationDetailsCount(response.data.NotificationListMRM.length)
                    setIsLoading(false)
                    
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    
    const openNotificationPopup = (ModuleID, type,AssignedToUserId,NotificationID) => {

        
        setIsLoading(true)
        const API_URL = 'api/setNotificationSeenByID';
        const req_body = {
            UserId: userData && 'UserId' in userData ? userData['UserId'] : "",
            NotificationID : NotificationID
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    toggleNotification()

                    if(type == "Monthly/StrategicFocus"){
                        props.openStrategicComment(ModuleID)
                    }

                    if(type == "Monthly/ActionTrackerMRM"){
                        props.openActionTrackerMRMComment(ModuleID)
                    }

                    if(type == "Monthly/DecisionTracker"){
                        props.openDecisionComment(ModuleID)
                    }
                
                    getAllNoticationList()
                    setIsLoading(false)
                    
                }
                else {
                    toggleNotification()
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                toggleNotification()
                setIsLoading(false)
                console.error(error);
            });



        
    }

    useEffect(() => {
        if (userData && userData['UserId']) {
            // getAllMasterDetails()
            getAllNoticationList()
        }
    }, [userData]);

    
    return (
        <div className="row">
            {isLoading && (<Loader />)}

            {showNotification && (
                
                <div
            className={`modal right ${showNotification ? 'show' : 'hide'}`}
            id="slideModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="slideModalLabel"
            aria-hidden="true"
            onClick={toggleNotification} // Detect clicks outside the modal content
        >
            <div
            className="modal-dialog notification-modal"
            role="document"
            onClick={(e) => e.stopPropagation()} // Prevent click events inside the modal from propagating to the backdrop
            >
                        <div className="modal-content">
                            <div className="modal-header">
                                <span className="modal-title" id="slideModalLabel" style={{color:'#005273', fontSize:'20px', fontWeight:'600'}}>Comments</span>
                                <img src={CancelBluIconComment} onClick={toggleNotification} className='commonImageStyle cursor-pointer' alt="Notification" />
                            </div>
                            <div className="modal-body">
                                {NotificationDetails.map((item, index) => (
                                    <div onClick={() => {openNotificationPopup(item.ModuleID, item.ModuleType,item.AssignedToUserId,item.ID)}} className="Milestone-container-notification mt-2 cursor-pointer">
                                        <div className="mb-1">
                                            <span style={{color:'#005273', fontWeight:600, fontSize:'16px'}}>{`${item.ActionByUserIdName} ${item.Title}`}</span>
                                        </div>
                                        <div className="mb-1">
                                            <span style={{color:'#005273',  fontSize:'14px'}}>{item.Description}</span>
                                        </div>
                                        <div className="mb-1">
                                            <span style={{color:'#005273', fontWeight:600, fontSize:'14px'}}>{`Date - ${formatDateTimeNotification(item.CreatedOn)}`}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>)}

            {/* {userData && */}
                <header>
                    <nav class="navbar navbar-expand-lg ">
                        <div class="container-fluid">
                            <div style={{zIndex:100}} className="d-flex gap-4 align-items-center">
                                <a title="Home" onClick={() => {navigateTo("")}} style={{cursor:'pointer'}} class="navbar-brand"><img src={Logo} className='header-logo col-md-2 m-1' alt="Logo" /></a>
                            
                                {/* <Select
                                    onChange={(selectedOption) => handleSelectChange(selectedOption.value)}
                                    // value={props.companyListDetails.length === 1 ? null : { value: selectedValue, label: selectedValue === 0 ? 'All Entities' : props.companyListDetails.find(item => item.Id === selectedValue)?.value }}
                                    options={[ ...projeccompanyListDetailstList.map(item => ({ value: item.value, label: item.label }))]}
                                    // className="custom-advance-btn"
                                    // styles={{ container: base => ({ ...base, width: '15rem' }) }}
                                    styles={customStyles}
                                    placeholder={'Select entity'}
                                /> */}

                                {/* <Select
                                    onChange={(selectedOption) => handleSelectChangeDivision(selectedOption.value)}
                                    // value={projeccompanyListDetailstList.length === 1 ? null : { value: selectedValue, label: selectedValue === 0 ? 'All Entities' : props.companyListDetails.find(item => item.Id === selectedValue)?.value }}
                                    options={[ ...props.DivisionListDetails.map(item => ({ value: item.value, label: item.label }))]}
                                    // className="custom-advance-btn"
                                    // styles={{ container: base => ({ ...base, width: '15rem' }) }}
                                    styles={customStyles}
                                    placeholder={'Select division'}
                                /> */}



                                {/* <Select
                                    onChange={(selectedOption) => handleSelectChange(selectedOption.value)}
                                    // value={props.EntityList.length === 1 ? null : { value: selectedValue, label: selectedValue === 0 ? 'All entities' : props.EntityList.find(item => item.Id === selectedValue)?.Value }}
                                    options={[...props.entityListDetails.map(item => ({ value: item.Id, label: item.Value }))]}
                                    // className="custom-advance-btn"
                                    // styles={{ container: base => ({ ...base, width: '15rem' }) }}
                                    placeholder={'SELECT ENTITY NEW'}
                                    styles={customStyles}
                                    components={{ DropdownIndicator }}
                                /> */}

                                <Select
                                onChange={(selectedOption) => handleSelectChangeEntity(selectedOption.value)}
                                options={props.monthlyListMRMDetails.map(item => ({ value: item.value, label: item.label }))}
                                placeholder={'Select entity'}
                                styles={customStyles}
                                components={{ DropdownIndicator }}
                                />

                            </div>
                            <div>
                                        <div className="d-flex gap-3 align-items-center  position-relative">
                                            <div className="position-relative">
                                                <img title="Comments" src={CommementLightBlue} onClick={toggleNotification} style={{height:'27px'}} className=' cursor-pointer' alt="Notification" />
                                                {NotificationDetailsCount > 0 && (
                                                    <span className={NotificationDetailsCount > 9 ? "badge-count" : "badge-count-small"}>{NotificationDetailsCount}</span>
                                                )}
                                            </div>
                                            {/* <img src={Notifications} onClick={toggleNotification} className='commonImageStyle cursor-pointer' alt="Notification" />
                                             */}
                                            {/* <div className="position-relative">
                                                <img title = "Notifications" src={Notifications} onClick={toggleNotificationModal} className='commonImageStyle cursor-pointer' alt="Notification" />
                                                {NotificationDetailsCount > 0 && (
                                                    <span className={NotificationDetailsCount > 9 ? "badge-count" : "badge-count-small"}>{NotificationDetailsCount}</span>
                                                )}
                                            </div> */}
                                            <img src={ProfileIcon} onClick={toggleDropdown} className='commonImageStyle cursor-pointer' alt="UserProfile" />
                                            {isDropdownOpen &&
                                                <div style={{width:'100px'}} className="log-out  d-flex flex-column">
                                                    <div >
                                                        <span onClick={logout}  className="logout-pop-text-style"><i class="fa fa-sign-out me-2"></i>Log Out</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                            {/* <div class="heading m-auto  align-content-center">
                                <h4>Welcome, {userData.name}</h4>
                            </div> */}
                                {/* <img src={User} onClick={toggleDropdown}  ref={dropdownRef} className='user col-md-2' alt="Logo" />
                                <img src={User} onClick={toggleDropdown} className='position-relative commonImageStyle cursor-pointer' alt="UserProfile" />
                                            {isDropdownOpen &&
                                                <div className="log-out  d-flex flex-column ">
                                                    <div >
                                                        <span onClick={logout} className="logout-pop-text-style"><i class="fa fa-sign-out me-2"></i>Log Out</span>
                                                    </div>
                                                </div>
                                            } */}
                                {/* <img src={Dropdown} className={`drop col-md-2 ${isDropdownOpen ? 'rotated' : ''}`} alt="Logo" onClick={toggleDropdown}  ref={dropdownRef}/> */}
                                {/* {isDropdownOpen &&
                                    <div className="log-out d-flex flex-column" ref={companyDropdownRef}>                                      
                                        <div>
                                            <span onClick={logout} className="logout-pop-text-style ">Log Out</span>
                                        </div>
                                    </div>
                                } */}
                        </div>
                    </nav>
                    {/* <div class="collapse logout-btn" id="logoutCollapse">
                    <button onClick={handleLogout} class="btn btn-danger">Logout</button>
                </div> */}
                </header>
        </div>
    );
};

export default HeaderBarMonthly;