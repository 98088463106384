import React, { useEffect, useRef } from "react";
import Logo from '../../assets/images/Planet Logo@2x.png'
import FilterIcon from '../../assets/images/filter icon.svg'
import Notifications from '../../assets/images/Notifications.svg'
import ProfileIcon from '../../assets/images/Profile icon.svg'
import User from '../../assets/images/user.png';
import Dropdown from '../../assets/images/Icon ionic-ios-arrow-down@2x.png';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment-timezone';
import { deleteAllCookies } from "../../api_interceptor/api";
import Loader from "../loader/Loader";
import api from '../../api_interceptor/api'
import { setCompanyIDDetails, setDivisionIDDetails, setDivisionList, setEmailIdRedux, setPasswordRedux, setUserDetails } from "../../redux/action/Actions";
import CancelBluIconComment from '../../assets/images/Cancel icon Comments.svg'
import { formatDateTimeNotification } from "../../common_function/common_function";
import { useNavigate } from 'react-router-dom';


const DashboardHeader = (props) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [landingCompanyDetails, setlandingCompanyDetails] = useState([]);
    const [landingDivisionDetails, setlandingDivisionDetails] = useState([]);
    const dispatch = useDispatch();

    const userData = useSelector((state) => state.user.userData);
    const company_id = useSelector((state) => state.user.companyID);
    const division_id = useSelector((state) => state.user.divisionID);

    const Isremember = useSelector((state) => state.user.IsRemeberMeChecked);
    const isEmailThere = useSelector((state) => state.user.EmailID);
    const isPasswordThere = useSelector((state) => state.user.Password);
    const [isCompanyDropdownOpen, setCompanyDropdownOpen] = useState(false);
    const [showNotification, setshowNotification] = useState(false);
    const dropdownRef = useRef(null);
    const projectListRef = useRef(null);
    const companyDropdownRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState('Select an option');
    // const [EntityList, setEntityList] = useState([]);
    // const [ProjectList, setProjectList] = useState([]);
    // const [ProjectStatusList, setProjectStatusList] = useState([]);
    const [ShowAdvancedFilterModal, setShowAdvancedFilterModal] = useState(false);
    const [NotificationDetails, setNotificationDetails] = useState([]);
    const [NotificationDetailsCount, setNotificationDetailsCount] = useState(null);
    const [tempSelectedProject, setTempSelectedProject] = useState(0);

    

    
   

    
    
    const logout = (event) => {
        
        setIsLoading(false)
        event.preventDefault();
        if(Isremember == false){
            dispatch(setEmailIdRedux(null))
            dispatch(setPasswordRedux(null))
        }
        dispatch(setUserDetails(null))
        // props.onLogout();

        deleteAllCookies()
        
    }
    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };




    const toggleNotification = () => {
        setshowNotification(!showNotification)
    }

    const toggleAdvancedFilter = () => {
        setShowAdvancedFilterModal(!ShowAdvancedFilterModal)
    }



    // const getAllMasterDetails = () => {
    //     setIsLoading(true)
    //     const API_URL = 'api/get-all-master-details';
    //     const req_body = {
    //         UserId: userData['UserId'],
    //     };
    //     api.post(API_URL, req_body, {
    //         'Content-Type': 'application/json'
    //     })
    //     .then((response) => {
            
    //         if (response && response.data) {
    //             dispatch(setCompanyIDDetails(response.data.EntityList));
    //             setEntityList(response.data.EntityList)
    //             setProjectList(response.data.ProjectList)
    //             setProjectStatusList(response.data.ProjectStatusList)
    //             setIsLoading(false)
    //         }
    //         else {
    //             setIsLoading(false)
    //         }
    //     })
    //     .catch((error) => {
    //         setIsLoading(false)
    //         console.error(error);
    //     });
    // }
    

    



 


   
    const navigate = useNavigate();

    const navigateTo = (module) =>{
        navigate('/'+ module)
    }
    return (
        <div className="row">
            {isLoading && (<Loader />)}
            

        
            {userData &&
                <section className="p-3 borderShadowStyle">
                    <div>
                        <div class="navbar py-0">
                            <div class="container-fluid">
                                <div className="d-flex justify-content-between align-items-center headermainContainer w-100">
                                    <div className="d-flex gap-3">
                                        <a onClick={() => {navigateTo("")}}><img src={Logo} className='header-logo cursor-pointer' alt="Logo" /></a>
                                        <div className="d-flex gap-3">
                                            

                                            
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex gap-3 align-items-center  position-relative">
                                            <span onClick={toggleNotification} className="cursor-pointer">
                                                {userData['Name'] &&  `Welcome, ${userData['Name']}`}
                                            </span>
                                            {/* <img src={Notifications} onClick={toggleNotification} className='commonImageStyle cursor-pointer' alt="Notification" />
                                             */}
                                            <div className="position-relative">
                                                {/* <img src={Notifications} onClick={toggleNotification} className='commonImageStyle cursor-pointer' alt="Notification" /> */}
                                                {/* {NotificationDetailsCount > 0 && (
                                                    <span className={NotificationDetailsCount > 9 ? "badge-count" : "badge-count-small"}>{NotificationDetailsCount}</span>
                                                )} */}
                                                </div>
                                            <img src={ProfileIcon} onClick={toggleDropdown} className='commonImageStyle cursor-pointer' alt="UserProfile" />
                                            {isDropdownOpen &&
                                                <div className="log-out  d-flex flex-column">
                                                    <div >
                                                        <span onClick={logout} className="logout-pop-text-style"><i class="fa fa-sign-out me-2"></i>Log Out</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
        </div>
    );
};

export default DashboardHeader;