import React, { useEffect } from "react";
import "./CustomStepper.css";
import { useNavigate, useLocation } from 'react-router-dom'

const stepperMapping ={
    "Operations" : ["is_preview_operations"],
    "Competition and Market" : ["is_preview_competition","is_preview_market","is_preview_market_graph"],
    "Open Action Items" : ["is_preview_open_action_item"],
    // "Historical Financial Performance" : ["is_preview_historical_financial_performance_graph"],
    "Financials" : ["is_preview_financial_performance","is_preview_financial_performance_ytd"],
    "Clients" : ["is_preview_new_clients","is_preview_lost_and_fold","is_preview_upcoming_clients_preview"],
    "Decisions" : ["is_preview_descision_summary"]
}

export default function CustomStepper(props) {
    const location = useLocation();
    
    const navigate = useNavigate();
    var pathList = props.stepperData.map((data) => {
        return data.sub_module_path
    })
    // const handleNavigation = (event,sub_module_path) => {
    //     if(event && event != undefined) {
    //         event.stopPropagation();
    //     } 
    //     var currentPath = location.pathname;
    //     var substring = pathList.find((substring) => currentPath.includes(substring))
    //     if(substring){
    //         currentPath = currentPath.replace(substring,sub_module_path)
    //     }
    //     else{
    //         currentPath = currentPath + sub_module_path
    //     }
    //     navigate(currentPath);
    // }
    const handleNavigation = (event, sub_module_path) => {
        if (event && event !== undefined) {
            event.stopPropagation();
        } 
    
        var currentPath = location.pathname;
        var substring = pathList.find((substring) => currentPath.includes(substring));
    
        if (substring) {
            currentPath = currentPath.replace(substring, sub_module_path);
        } else {
            currentPath = currentPath + sub_module_path;
        }
    
        const searchParams = location.search; // Get the current query params
        navigate(currentPath + searchParams); // Navigate while keeping query params
    };
    useEffect((event) => {
        handleNavigation(event,pathList[0])
    }, []);

    const isAllPreviewsEnabled = (submoduleName) => {
        const mappingKeys = stepperMapping[submoduleName];
        if (!mappingKeys || mappingKeys.length === 0) {
            return true;
        }

        return mappingKeys.every(key => props.previewList[key]);
    };
    return (

        <div>
            <nav class="navbar navbar-expand-lg parentContainerStepper">   
                <div class="container-fluid">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse mainDivStepper" id="navbarSupportedContent">
                        {props.stepperData.map((item, index) =>
                            <>
                                <div className="d-flex align-items-center">
                                    <div onClick={(event) => handleNavigation(event,item.sub_module_path)} className={location.pathname.includes(item.sub_module_path) ? "subModuleBlock active" : isAllPreviewsEnabled(item.submodule_name) == true  ? "subModuleBlockGreen": "subModuleBlock"}>
                                        <span className="blockTitle">{item.submodule_name}</span>
                                    </div>                       
                                </div>
                                {index+1 == props.stepperData.length  ? null :  
                                    <div className="setting d-flex align-items-center">
                                        <div className="lineStepper"></div>
                                    </div>
                                }
                            </>
                            
                        )}    
                    </div>
                </div>
            </nav>
        </div>
    );
}