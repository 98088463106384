import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer } from 'redux-persist';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user'], // specify which reducers you want to persist
};

export const persistedReducer = (rootReducer) =>
    persistReducer(persistConfig, rootReducer);

export default persistConfig;