import React from 'react';
import { useLocation } from 'react-router-dom';

const ReportViewer = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const reportUrl = queryParams.get('reportUrl');
    console.log('----------Report URL With Params----------->>',reportUrl);

    return (
        <div>
            <iframe 
                src={`https://uxp.planetcp.com:1115/${reportUrl}`} 
                title="Report Viewer" 
                style={{ width: '100%', height: '100vh', border: 'none' }}
            />
        </div>
    );
};

export default ReportViewer;
