import React, { useState, useEffect } from "react";
import "./HistoricalFinancialPerformance.css";
import HelpSidePanel from "../../../components/help_side_panel/HelpSidePanel";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    LineElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from "react-redux";
import api from '../../../api_interceptor/api'
import { updateState } from "../../../common_function/common_function";
import HistoricalGraphComponent from "./HistoricalGraphComponent";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
);


const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const actualData = [5, 5, 3, 6, 4, 6, 7, 8, 12, 10, 8, 14];
const budgetData = [5, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
const varianceData = actualData.map((value, index) => ((actualData[index] - budgetData[index]) / budgetData[index]) * 100);
// const data = {
//     labels,
//     datasets: [
//         {
//             label:  graphDetailslabel,
//             data: budgetData,
//             backgroundColor: 'rgba(23, 162, 235, 0.5)',
//         },
//         {
//             label: 'Actual',
//             data: actualData,
//             backgroundColor: 'rgba(32, 99, 132, 0.5)',
//         },
//         {
//             label: 'Variance (%)',
//             type: 'line',
//             data: varianceData,
//             borderColor: 'rgba(255, 206, 86, 1)',
//             backgroundColor: 'rgba(255, 206, 86, 0.2)',
//             yAxisID: 'variance-y-axis',
//         },
//     ],
// };
const data = {
    labels,
    datasets: [
        {
            label: 'Budget',
            data: budgetData,
            backgroundColor: 'rgba(23, 162, 235, 0.5)',
        },
        {
            label: 'Actual',
            data: actualData,
            backgroundColor: 'rgba(32, 99, 132, 0.5)',
        },
        {
            label: 'Variance (%)',
            type: 'line',
            data: varianceData,
            borderColor: 'rgba(255, 206, 86, 1)',
            backgroundColor: 'rgba(255, 206, 86, 0.2)',
            yAxisID: 'variance-y-axis',
        },
    ],
};

const getCurrentDate = () => {
    const currentDate = new Date();
    const options = { month: 'short', year: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);
    return formattedDate;
};

export default function HistoricalFinancialPerformance(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [helpDetails, sethelpDetails] = useState("");
    const userData = useSelector((state) => state.user.userData);
    const [getCountDataShow, setgetCountDatadshow] = useState(false);
    const [getCountData, setgetCountData] = useState(null);
    const access_details = useSelector((state) => state.user.access_details);
    const company_id = useSelector((state) => state.user.companyID);
    const [monthlyUpdateList, setmonthlyUpdateList] = useState({});
    const [graphDetails, getGraphDetails] = useState({})
    const [isGraph, setGraph] = useState(false);
    const [labelGraph, getGraphLabel] = useState([]);



    useEffect(() => {
        if (userData && userData['UserId']) {
            getMonthlyUpdate(userData['UserId'])
        }
    }, [userData]);

    useEffect(() => {
        setgetCountDatadshow(!getCountDataShow)
    }, [getCountData]);

    const getMonthlyUpdate = (UID) => {
        updateState(props.setPreviewEnabled, userData['UserId'], access_details['company_division_id'], props.setPreviewList, access_details['company_division_id'])
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/get-historical-financial-performance';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    if (response.data) {
                        setgetCountData(response.data)
                        setmonthlyUpdateList(response.data.historical_financial_performance_lists)
                        sethelpDetails(response.data.help_details)
                        getGraphDetails(response.data.graph_data)
                        getGraphLabel(response.data.graph_data.label)
                        setGraph(true)
                        setIsLoading(false)
                        // 
                    }
                    else {

                        sethelpDetails(response.data.help_details)
                        setGraph(false)
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    // const handleChangeInput = (state, value) => {
    //     var stateValue = revenuedetail;
    //     stateValue.budgeted = parseInt(value);
    //     setRevenue(stateValue)
    // }
    const handleChangeInput = (newValue, setFunction, objectKey = "", performance_type) => {
        setFunction(prevState => ({
            ...prevState,
            [objectKey]: newValue,
            performance_type: performance_type
        }));
    };
    const saveData = (revenuedetail) => {
        var is_update = revenuedetail.id != 0;
        updateState(props.setPreviewEnabled, userData['UserId'], access_details['company_division_id'], props.setPreviewList)
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/add-historical-financial-performance';
        const req_body = {
            ...revenuedetail,
            is_update: is_update,
            update_id: revenuedetail.id,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                if (response && response.data) {
                    if (response.data) {
                        getMonthlyUpdate(userData['UserId'])
                        setGraph(true)
                        setIsLoading(false)

                    }
                    else {
                        getMonthlyUpdate(userData['UserId'])
                        sethelpDetails(response.data.help_details)
                        setGraph(false)
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                    getMonthlyUpdate(userData['UserId'])
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }
    const returnCurrentMonthData = (data) => {
        var returnData = data.filter(item => item.reported_on === getCurrentDate());
        return returnData.length > 0
            ? returnData[0]
            : {
                id: 0,
                performance_type: '',
                reported_on: getCurrentDate(),
                budgeted: null,
                actual: null
            };
    }

    return (
        <div className="parentContainer ">
            <div className="monthlyHeaderWrapper">
                <p className="monthly-Title-Table-header">Historic Financial Performance <span className="monthlyInside">(as reported month on month)</span></p>
                {!isLoading && <HelpSidePanel content={helpDetails} />}
            </div>
            {!isLoading ?
                <div className="row">
                    {monthlyUpdateList && Object.keys(monthlyUpdateList).length > 0 && Object.keys(monthlyUpdateList).map((item) => (
                        <HistoricalGraphComponent key={item} handleChangeInput={handleChangeInput} monthlyUpdateList={monthlyUpdateList} returnCurrentMonthData={returnCurrentMonthData} graphDetails={graphDetails[item]} saveData={saveData} monthlyUpdateKey={item} isLoading={isLoading} viewSetMonth={props.ismonth} />
                    ))}
                        {/* <HistoricalGraphComponent key={0} handleChangeInput={handleChangeInput} monthlyUpdateList={monthlyUpdateList} returnCurrentMonthData={returnCurrentMonthData} graphDetails={graphDetails[0]} saveData={saveData} monthlyUpdateKey={0} isLoading={isLoading} viewSetMonth={props.ismonth} /> */}

                </div>
                : null}
        </div>
    )
}