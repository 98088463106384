import React, { useEffect, useState } from "react";
import "./MainTable.css";
import imageURL from '../../../assets/images/infoIcon.svg';
import edit from '../../../assets/images/edit-icon.svg';
import remove from '../../../assets/images/remove-icon.svg';
import add from '../../../assets/images/add-icon.svg';
import HelpSidePanel from "../../../components/help_side_panel/HelpSidePanel";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import SmallSizedLoader from "../../../components/loader/SmallSizedLoader";
import { useSelector } from "react-redux";
import api from '../../../api_interceptor/api'
import Select from 'react-select';
import Alert_Popup from "../../../components/alert_popup/Alert_Popup";
import AnalyticsImage from '../../../assets/images/Warning icon.svg';
import close from '../../../assets/images/close.png';
import { calculateDuration, updateState } from "../../../common_function/common_function";


export default function MainTable(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [minutesOfMeeting, setminutesOfMeeting] = useState([]);
    const [helpDetails, sethelpDetails] = useState("");
    const [editableRows, setEditableRows] = useState([]);
    const userData = useSelector((state) => state.user.userData);
    const access_details = useSelector((state) => state.user.access_details);
    const [getStatusList, setStatusList] = useState([]);
    const [ImpactOnList, setImpactOnList] = useState([]);
    const [CriticalityList, setCriticalityList] = useState([]);
    const [isAddClicked, setAddClick] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMsg, setShowModalMsg] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deletingIndex, setDeletingIndex] = useState(null);
    const [getCountData, setgetCountData] = useState(null);
    const [getCountDataShow, setgetCountDatadshow] = useState(false);
    const [currentOperation, setCurrentOperation] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const company_id = useSelector((state) => state.user.companyID);
    
    const [meetingName, setMeetingName] = useState("");
    const [company, setCompany] = useState("");
    const [meetingDate, setMeetingDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [duration, setDuration] = useState("");
    const [location, setLocation] = useState("");
    const [processChecker, setProcessChecker] = useState("");
    const [timeChecker, setTimeChecker] = useState("");
    const [scribe, setScribe] = useState("");
    const [division, setDivision] = useState("");
    const [organizedBy, setOrganizedBy] = useState("");
    const [meetingType, setMeetingType] = useState("");
    const [meetingCadence, setMeetingCadence] = useState("");
    const [attendees, setAttendees] = useState("");
    const [agenda, setAgenda] = useState("");
    const [isEditable, setIsEditable] = useState(false); 
    const [isEditVisible, setIsEditVisible] = useState(true); // State to control the visibility
    const [getMeetingCadenceList, setMeetingCadenceList] = useState([]);
    const [getMeetingTypeList, setMeetingTypeList] = useState([]);


    
    const [isMeetingTypeDropdownOpen, setIsMeetingTypeDropdownOpen] = useState(false);
    const [isMeetingCadenceDropdownOpen, setIsMeetingCadenceDropdownOpen] = useState(false);


    useEffect(() => {
        if (userData && userData['UserId']) {
            getMinutesOfMeeting(userData['UserId'])
        }
    }, [userData]);

    useEffect(() => {
        setgetCountDatadshow(!getCountDataShow)
    }, [getCountData]);

    const getAreaOfUpdateDetails = (UID, valdata) => {
        
        const API_URL = 'monthlymodule/api/get-open-action-items-dropdown-details';
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {
                

                if (response && response.data) {
                    if (response.data) {

                        setMeetingTypeList(response.data.meeting_type_list);
                        setMeetingCadenceList(response.data.meeting_cadence_list);
                        setIsLoading(false)
                    }
                    else {
                        setIsLoading(false)
                    }
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    }

    const getMinutesOfMeeting = (UID) => {
        updateState(
            props.setPreviewEnabled,
            userData['UserId'],
            access_details['company_division_id'],
            props.setPreviewList,
            access_details['company_division_id']
        );
        
        setIsLoading(true);
        
        const API_URL = 'monthlymodule/api/get-minutes-of-meeting';
    
        const req_body = {
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
    
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
        .then((response) => {
            if (response && response.data) {
                if (response.data && response.data.Minutes_Of_Meeting) {
                    console.log("respdata0000--->", response.data);
                    

                    if (response.data.Minutes_Of_Meeting.startTime != null && response.data.Minutes_Of_Meeting.endTime != null )
                        {
                            const start = new Date(response.data.Minutes_Of_Meeting.startTime);
                            const end = new Date(response.data.Minutes_Of_Meeting.endTime);
                            
                            // Format the time as HH:mm
                            setStartTime(start.toTimeString().slice(0, 5)); // Get HH:mm
                            setEndTime(end.toTimeString().slice(0, 5)); // Get HH:mm 
                        }

                  
                    setgetCountData(response.data);
                    setMeetingName(response.data.Minutes_Of_Meeting.meetingName);
                    setCompany(response.data.Minutes_Of_Meeting.company);
                    // setStartTime(response.data.Minutes_Of_Meeting.startTime);
                    // setEndTime(response.data.Minutes_Of_Meeting.endTime);
                    setDuration(response.data.Minutes_Of_Meeting.duration);
                    setMeetingDate(response.data.Minutes_Of_Meeting.meetingDate);
                    setLocation(response.data.Minutes_Of_Meeting.location);
                    setProcessChecker(response.data.Minutes_Of_Meeting.processChecker);
                    setTimeChecker(response.data.Minutes_Of_Meeting.timeChecker);
                    setScribe(response.data.Minutes_Of_Meeting.scribe);
                    setDivision(response.data.Minutes_Of_Meeting.division);
                    setOrganizedBy(response.data.Minutes_Of_Meeting.organizedBy);
                    setMeetingType(response.data.Minutes_Of_Meeting.meetingType);
                    setMeetingCadence(response.data.Minutes_Of_Meeting.meetingCadence);
                    setAttendees(response.data.Minutes_Of_Meeting.attendees);
                    setAgenda(response.data.Minutes_Of_Meeting.agenda);

                    setIsEditVisible(false); 
                    setIsEditable(false); 
                    


                    sethelpDetails(response.data.help_details);
    
                    getAreaOfUpdateDetails(userData['UserId'], response.data.Minutes_Of_Meeting);
                } else {
                    sethelpDetails(response.data.help_details);
                    getAreaOfUpdateDetails(userData['UserId'], response.data.Minutes_Of_Meeting);
                }
            } else {
                sethelpDetails(response.data.help_details);
                getAreaOfUpdateDetails(userData['UserId'], response.data.Minutes_Of_Meeting);
                setIsLoading(false);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.error(error);
        });
    }
    
    

    const handleEdit = (index) => {
        const updatedMonthlyUpdateList = [...minutesOfMeeting];
        // Check if any item is already in edit mode
        const isAnyItemInEditMode = updatedMonthlyUpdateList.some(item => item.is_edit);

        // If any item is in edit mode, return without opening the edit mode for the current item
        if (isAnyItemInEditMode) {
            return;
        }

        if (editableRows.length === 0 && !currentOperation) {
            updatedMonthlyUpdateList.forEach((list, i) => {
                if (i === index) {
                    list.is_edit = !list.is_edit;
                } else {
                    list.is_edit = false;
                }
            });

            console.log({ currentOperation });
            setCurrentOperation('edit');
            setminutesOfMeeting(updatedMonthlyUpdateList);
        }
    };

    const handleDelete = (index) => {
        if (editableRows.length === 0 && !currentOperation) {
            setDeleteReason(null)
            setDeletingIndex(index);
            setShowConfirmationModal(true);
        }
    };

    const handleDeleteConfirmation = () => {
        if (deleteReason.trim() === '') {
        }
        else {
            setShowConfirmationModal(false);
            setDeletingIndex(null);
            setDeleteReason('');
            handleDeleteAPI(deletingIndex, deleteReason);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
        setDeletingIndex(null);
        setDeleteReason('');
    };

    const handleDeleteAPI = (index, reason) => {
        const editedItem = minutesOfMeeting[index];
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/delete-people-KPI';
        const req_body = {
            "id": editedItem.ID,
            "deleted_reason": reason,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        };
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    getMinutesOfMeeting(userData['UserId'])
                    setminutesOfMeeting(prevList => prevList.filter((item, i) => i !== index));
                }
                else {
                    getMinutesOfMeeting(userData['UserId'])
                }

            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error);
            });
    };

    const addMinutesOfMeeting = (req_body,index) => {
        
        setIsLoading(true)
        const API_URL = 'monthlymodule/api/add-minutes-of-meeting';
        api.post(API_URL, req_body, {
            'Content-Type': 'application/json'
        })
            .then((response) => {

                if (response && response.data) {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMinutesOfMeeting(userData['UserId'])
                }
                else {
                    setAddClick(false)
                    setEditableRows(editableRows.filter((editableIndex) => editableIndex !== index));
                    getMinutesOfMeeting(userData['UserId'])
                }

            })
            .catch((error) => {
                setAddClick(false)
                setIsLoading(false)
                console.error(error);
            });
    }

      // Validation start logic

      const validateMonthlyUpdate = () => {
        
        const emptyFields = [];

       
          
        if (/^\s*$/.test(meetingName)) {
            emptyFields.push("Please enter value for meeting name.\n");
          }
          
          if (/^\s*$/.test(company)) {
            emptyFields.push("Please enter value for company name.\n");
          }
          
          if (/^\s*$/.test(meetingDate)) {
            emptyFields.push("Please select meeting date.\n");
          }
          
        //   if (/^\s*$/.test(division)) {
        //     emptyFields.push("Please enter value for division.\n");
        //   }
          
          if (/^\s*$/.test(startTime)) {
            emptyFields.push("Please select start time.\n");
          }
          
          if (/^\s*$/.test(endTime)) {
            emptyFields.push("Please select end time.\n");
          }
          
          if (/^\s*$/.test(processChecker)) {
            emptyFields.push("Please enter value for process checker.\n");
          }
          
          if (/^\s*$/.test(timeChecker)) {
            emptyFields.push("Please enter value for time checker.\n");
          }
          
          if (/^\s*$/.test(organizedBy)) {
            emptyFields.push("Please enter value for organized by.\n");
          }
          
          if (/^\s*$/.test(scribe)) {
            emptyFields.push("Please enter value for scribe.\n");
          }
          
          if (/^\s*$/.test(meetingType)) {
            emptyFields.push("Please enter value for meeting type.\n");
          }
          

          if (/^\s*$/.test(location)) {
            emptyFields.push("Please enter value for location.\n");
          }
          
          if (/^\s*$/.test(meetingCadence)) {
            emptyFields.push("Please enter value for meeting cadence.\n");
          }
          
          if (/^\s*$/.test(attendees)) {
            emptyFields.push("Please enter value for attendees.\n");
          }
          
          if (/^\s*$/.test(agenda)) {
            emptyFields.push("Please enter value for agenda.\n");
          }
          
          
            
        

        return emptyFields;
    };


    // Validation end logic





    const handleSave = (index, e) => {
        // Validation 1: Dropdown should be mandatory
        

        setIsEditVisible(true); // Show the edit button after saving
        setIsEditable(true); // Exit editing mode
        
    
        
        const duration = calculateDuration(startTime, endTime);

        const req_body = {
            MeetingName: meetingName,
            Company: company,
            MeetingDate: meetingDate,
            Division: division,
            StartTime: startTime,
            EndTime: endTime,
            ProcessChecker: processChecker,
            TimeChecker: timeChecker,
            OrganizedBy: organizedBy,
            Scribe: scribe,
            MeetingType: meetingType && meetingType.value === undefined 
            ? setSelectValue(getMeetingTypeList, meetingType).value 
            : (meetingType ? meetingType.value : null),
            Location: location,
            MeetingCadence: meetingCadence && meetingCadence.value === undefined 
            ? setSelectValue(getMeetingCadenceList, meetingCadence).value 
            : (meetingCadence ? meetingCadence.value : null),
            Attendees: attendees,
            Agenda: agenda,
            
            // "is_update": isAddClicked ? false : true,
            // "update_id": editedItem.ID,
            user_id: userData['UserId'],
            company_division_id: access_details['company_division_id'],
            company_id: access_details['company_division_id']
        }

         // Check if it's an add operation
         if (isAddClicked) {
            const emptyFields = validateMonthlyUpdate();


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addMinutesOfMeeting(req_body, index)
                setCurrentOperation(null)
            }
        } else {
            const emptyFields = validateMonthlyUpdate();


            if (emptyFields.length > 0) {
                console.log("inside empty field ------>", emptyFields);
                // setShowModal(true);
                setShowModalMsg(emptyFields.join("\n"));
                setErrorList(emptyFields)
                setPopupOpen(true);
                return;
            } else {
                addMinutesOfMeeting(req_body, index)
                setCurrentOperation(null)
            }
        }
        // console.log('reqbosyuy---->>', req_body);
        // addStrategicFocus(req_body, index)
        // setCurrentOperation(null)
    };


    const handleClose = () => {
        const hasUnsavedChanges = meetingName || company || meetingDate || processChecker || division || startTime || timeChecker || organizedBy || endTime || scribe || meetingType || location || meetingCadence || attendees || agenda;
        setAddClick(false)
    
        setEditableRows([]);

        setminutesOfMeeting((prevList) => {
            return prevList.filter(item => !item.is_update);
        });
    
        const updatedMonthlyUpdateList = minutesOfMeeting.map(item => ({
            ...item,
            is_edit: false
        }));
        setminutesOfMeeting(updatedMonthlyUpdateList);
    
        if (currentOperation === 'add') {
            setminutesOfMeeting((prevList) => {
                return prevList.filter(item => !item.is_update);
            });
        }
        setCurrentOperation(null);

        setIsEditVisible(true);
        setIsEditable(false);
        getMinutesOfMeeting();
    };
    


   

   


    const handleInputChange = (index, field, value) => {
        console.log("input from date --------->>>", field, value);
        const updatedMonthlyUpdateList = [...minutesOfMeeting];
        // updatedMonthlyUpdateList[index][field] = value.label;
        // Check if the value is an object (dropdown) or a plain value (textarea, input, etc.)
        const updatedValue = typeof value === 'object' ? value.label : value;
        updatedMonthlyUpdateList[index][field] = updatedValue;
        setminutesOfMeeting(updatedMonthlyUpdateList);
    };

    const setSelectValue = (list, value) => {
        return list.find((data) => {
            return data.label === value;
        });

    }

    function formatDateString(inputDateString) {
        const inputDate = new Date(inputDateString);

        const options = { day: "numeric", month: "short", year: "numeric" };
        const formattedDate = inputDate.toLocaleDateString("en-GB", options);

        return formattedDate;
    }

       // validation popup close

       const closePopup = () => {
        setPopupOpen(false);
    };

    
  




    // dropdown scroll close start

    // const customStyles = {
    //     control: (provided) => ({
    //         ...provided,
    //         backgroundColor: 'white',
    //         marginLeft: '4px', // Change the background color of the control
    //         marginTop: 0, // Adjust the top margin
    //     }),
    //     menu: (provided) => ({
    //         ...provided,
    //         zIndex: 0,
    //         position: 'absolute',
    //         fontSize: '11px',
    //         marginTop: 0,
    //         marginBottom: 0, // Adjust the bottom margin
    //     }),
    //     option: (provided, state) => ({
    //         ...provided,
    //         backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
    //         color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
    //         height: '45px',

    //     }),
    // };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'red', // Change the text color of the control
            borderColor: state.isFocused ? 'black' : provided.borderColor, // Change border color when focused
            boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow, // Add box shadow when focused
            marginTop: 0, // Adjust the top margin
            '&:hover': { // Prevent blue highlight on hover
                borderColor: state.isFocused ? 'black' : provided.borderColor,
                boxShadow: state.isFocused ? '0 0 0 1px black' : provided.boxShadow,
            }
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 0,
            position: 'absolute',
            fontSize: '11px',
            marginTop: 0,
            marginBottom: 0, // Adjust the bottom margin
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#005273' : 'white', // Change the background color on hover
                    color: state.isFocused ? '#ffff' : 'black', // Change the text color on hover
                    height: '45px',
            // '&:hover': {
            //     color: 'white', // Change text color on hover
            // },
        }),
    };

    // Add state variables for each dropdown
    const [isOwnerDropdownOpen, setIsOwnerDropdownOpen] = useState(false);
    const [isImpactOnDropdownOpen, setIsImpactOnDropdownOpen] = useState(false);
    const [isCriticalityOnDropdownOpen, setIsCriticalityDropdownOpen] = useState(false);
    const [isStatusDropdownOpen, setIsStatusrDropdownOpen] = useState(false);

    //   const dropdownRef = useRef(null);
    const [isSelectHovered, setIsSelectHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsSelectHovered(true);
    };

    const handleMouseLeave = () => {
        setIsSelectHovered(false);
    };

    const handleToggleEdit = () => {
        setIsEditable((prev) => !prev);
        setIsEditVisible(false); // Hide the edit button when clicked
        setIsEditable(true); // Assuming you toggle editing mode here
      };

    
    const handleEndTimeChange = (e) => {
        const newEndTime = e.target.value;
    
        if (newEndTime < startTime) {
            alert("End Time cannot be earlier than Start Time");
        } else {
            setEndTime(newEndTime);
        }
    };
    



      

    // dropdown scroll close end

    return (

        <div className="parentContainer">
            {showConfirmationModal && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-center'>
                                <p className="head-text">Delete Item</p>
                            </div>
                        </div>
                        <form onSubmit={handleDeleteConfirmation}>
                            <label className='label-text'>
                                Mention Reason:
                                <input className='mt-2'
                                    type="text"
                                    placeholder="Enter your reason"
                                    value={deleteReason}
                                    required
                                    onChange={(e) => setDeleteReason(e.target.value)}
                                    
                                />
                            </label>
                            <button onClick={() => setShowConfirmationModal(!showConfirmationModal)} className="cancel-btn">Cancel</button>
                            <button type="submit" className="confirm-btn">Confirm</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Validation popup start */}
            {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className='row mb-3'>

                            <div className='col-12 text-start'>
                                <p className="delete-head-text" style={{fontSize:"20px"}}>Information</p>
                            </div>
                        </div>
                        <div className='ChatBodyDetails' style={{maxHeight:'49vh',minHeight:'49vh',minHeight:'auto',overflowY:'auto', padding:"0rem"}}>
                                {errorList.map((item, index) => (
                                    <div style={{ display : 'flex'}}>
                                        <div className=' d-flex mb-3' style={{width:'90%'}}>
                                            {`${index+1}) ${item}`}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        {/* <form>
                            <ol>
                                {errorList.map((item) =>
                                    <li className='error-text'>{item}</li>
                                )}
                            </ol>
                            <button className="close-btn" onClick={closePopup}>Close</button>
                        </form> */}
                        <button className="close-btn" onClick={closePopup}>Close</button>
                    </div>
                </div>
            )}

            {/* Validation popup end */}

            {showModal && <Alert_Popup msg={showModalMsg} type={""} onClosePress={() => setShowModal(!showModal)} />}

            <div className="monthlyHeaderWrapper d-flex justify-content-between">
              
            
            </div>

            {isLoading ? (<SmallSizedLoader />) :
                (<div className="table-div padStyle" style={{ maxWidth: "85%"}}>
                    <span className="monthly-Title-Table" style={{ fontSize: "20px" }}>
                        <strong>Minutes Of Meeting</strong>
                    </span>

                      {isEditVisible && (
                            <div style={{textAlign: "right" }}>
                                <div style={{textAlign:"left"}}>
                                <span>Confidential | For limited circulation only</span>
                                </div>
                              
                            </div>
                        )}
                  

                    <Tooltip id="my-tooltip" style={{ backgroundColor: "#21a8e1", color: "#ffff" , zIndex:9999999999}} />
                    <div className="table-container">
                 
                        
                    <table className="table-container-subdiv open-actionTable action-summary" style={{ width: "100%", tableLayout: "fixed" }}>
                            <tbody>
                            
    
                            <tr>
                            <th className="main-table-header" colSpan="2">
                            <div className="d-flex justify-content-between">
                            <div>Meeting Name 
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                        
                            <div>
                                <a
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Standard name if applicable else a short subject"
                                    data-tooltip-place="bottom">
                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                </a>
                            </div>
                            </div>
                            </th>

                            <td className="main-table-content" colSpan="9.5">
                                <input
                                    type="text"
                                    value={meetingName}
                                    className={`no-border-input ${isEditable ? "editable" : ""}`}
                                    onChange={(e) => setMeetingName(e.target.value)}
                                    readOnly={!isEditable}
                                />
                                </td>
                            <td className="main-table-content" colSpan="0.5" style={{ textAlign: 'center' }}>
                                <button onClick={handleToggleEdit} style={{ border: 'none', backgroundColor: 'white', display: 'inline-block' }}>
                                    <img 
                                        src={edit}
                                        className="edit" 
                                        alt="Edit" 
                                        style={{ width: '13px', marginRight: '5px' }} 
                                    />
                                </button>
                            </td>

                            </tr>
                            <tr>
                            <th className="main-table-header" colSpan="2"> 
                            <div className="d-flex justify-content-between">
                                <div>Company  
                                    <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                                </div>
                                <div>
                                    <a
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Name of the company hosting the meeting"
                                        data-tooltip-place="bottom">
                                        <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                    </a>
                                </div>
                                </div>
                            </th>
                            <td className="main-table-content" colSpan="3">
                                <input
                                type="text"
                                value={company}
                                className={`no-border-input ${isEditable ? "editable" : ""}`}
                                onChange={(e) => setCompany(e.target.value)}
                                style={{ width: "100%" }}
                                readOnly={!isEditable}
                                />
                            </td>
                            <th className="main-table-header" colSpan="1">
                    
                                
                            <div>Meeting date 
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                            </th>
                            <td className="main-table-content" colSpan="2">
                                <input
                                type="date"
                                value={meetingDate? meetingDate.slice(0, 10) : ''}
                                className={`no-border-input ${isEditable ? "editable" : ""}`}
                                onChange={(e) => setMeetingDate(e.target.value)}
                                style={{ width: "100%" }}
                                readOnly={!isEditable}
                                />
                            </td>
                            <th className="main-table-header" colSpan="2">
                            <div className="d-flex justify-content-between">
                            <div>Process Checker  
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                            <div>
                                <a
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Mention initals"
                                    data-tooltip-place="bottom">
                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                </a>
                            </div>
                            </div>
                            </th>
                            <td className="main-table-content" colSpan="2">
                                <input
                                type="text"
                                value={processChecker}
                                className={`no-border-input ${isEditable ? "editable" : ""}`}
                                onChange={(e) => setProcessChecker(e.target.value)}
                                style={{ width: "100%" }}
                                readOnly={!isEditable}
                                />
                            </td>
                            </tr>
                            <tr>
                            <th className="main-table-header" colSpan="2">
                            <div className="d-flex justify-content-between">
                            <div>Division  
                               
                            </div>
                            <div>
                                <a
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Leave blank if not applicable"
                                    data-tooltip-place="bottom">
                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                </a>
                            </div>
                            </div>
                            </th>
                            <td className="main-table-content" colSpan="3">
                                <input
                                type="text"
                                value={division}
                                className={`no-border-input ${isEditable ? "editable" : ""}`}
                                onChange={(e) => setDivision(e.target.value)}
                                style={{ width: "100%" }}
                                readOnly={!isEditable}
                                />
                            </td>
                            <th className="main-table-header" colSpan="1">
                
                            <div>Start Time  
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                            </th>
                            <td className="main-table-content" colSpan="2">
                                <input
                                type="time"
                                value={startTime}
                                className={`no-border-input ${isEditable ? "editable" : ""}`}
                                onChange={(e) => setStartTime(e.target.value)}
                                style={{ width: "100%" }}
                                readOnly={!isEditable}
                                />
                            </td>
                            <th className="main-table-header" colSpan="2">
                            <div className="d-flex justify-content-between">
                            <div>Time Checker  
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                            <div>
                                <a
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Mention initals"
                                    data-tooltip-place="bottom">
                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                </a>
                            </div>
                            </div>
                            </th>
                            <td className="main-table-content" colSpan="2">
                                <input
                                type="text"
                                value={timeChecker}
                                className={`no-border-input ${isEditable ? "editable" : ""}`}
                                onChange={(e) => setTimeChecker(e.target.value)}
                                style={{ width: "100%" }}
                                readOnly={!isEditable}
                                />
                            </td>
                            </tr>
                            <tr>
                            <th className="main-table-header" colSpan="2">
                            <div className="d-flex justify-content-between">
                            <div>Organized By  
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                            <div>
                                <a
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Mention name of team or department"
                                    data-tooltip-place="bottom">
                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                </a>
                            </div>
                            </div>
                            </th>
                            <td className="main-table-content" colSpan="3">
                                <input
                                type="text"
                                value={organizedBy}
                                className={`no-border-input ${isEditable ? "editable" : ""}`}
                                onChange={(e) => setOrganizedBy(e.target.value)}
                                style={{ width: "100%" }}
                                readOnly={!isEditable}
                                />
                            </td>
                            <th className="main-table-header" colSpan="1">
                      
                            <div>End Time  
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                            </th>
                            <td className="main-table-content" colSpan="2">
                                <input
                                type="time"
                                value={endTime}
                                className={`no-border-input ${isEditable ? "editable" : ""}`}
                                // onChange={(e) => setEndTime(e.target.value)}
                                onChange={handleEndTimeChange}  // Use the validation function here
                                style={{ width: "100%" }}
                                readOnly={!isEditable}
                                />
                            </td>
                            <th className="main-table-header" colSpan="2">
                            <div className="d-flex justify-content-between">
                            <div>Scribe  
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                            <div>
                                <a
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Mention initals"
                                    data-tooltip-place="bottom">
                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                </a>
                            </div>
                            </div>
                            </th>
                            <td className="main-table-content" colSpan="2">
                                <input
                                type="text"
                                value={scribe}
                                className={`no-border-input ${isEditable ? "editable" : ""}`}
                                onChange={(e) => setScribe(e.target.value)}
                                style={{ width: "100%" }}
                                readOnly={!isEditable}
                                />
                            </td>
                            </tr>

                            <tr>
                            <th className="main-table-header" colSpan="2">
                            <div className="d-flex justify-content-between">
                            <div>Meeting Type  
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                            <div>
                                <a
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Choose from dropdown"
                                    data-tooltip-place="bottom">
                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                </a>
                            </div>
                            </div>
                            </th>

                            {/* Meeting Type */}

                        
                            <td className="main-table-content" colSpan="3">
                                {isEditable ? (
                                    <div
                                    className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    >
                                    <Select
                                        options={getMeetingTypeList}
                                        value={setSelectValue(getMeetingTypeList, meetingType)}
                                        placeholder="Select Meeting Type"
                                        onChange={setMeetingType}
                                        isDisabled={!isEditable}
                                        styles={customStyles
                                        // menu: (provided) => ({
                                        //     ...provided,
                                        //     zIndex: 9999, // Ensure it appears on top of other elements
                                        // }),
                                        }
                                        menuPortalTarget={document.body} // Render dropdown outside the container
                                        menuPosition="fixed" // Ensure it is positioned freely
                                    />
                                    </div>
                                ) : (
                                    // When not editable, display the meeting type directly
                                    <div className="non-editable-text">
                                    {meetingType}
                                    </div>
                                )}
                                </td>

                                        

                            
                            <th className="main-table-header" colSpan="1">
                            <div>Duration  
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                            </th>
                            <td className="main-table-content" colSpan="2">
                                <span>
                                {calculateDuration(startTime,endTime)}
                                </span>
                                {/* <input
                                type="text"
                                value={duration}
                               className={`no-border-input ${isEditable ? "editable" : ""}`}
                                onChange={(e) => setDuration(e.target.value)}
                                style={{ width: "100%" }}
                                readOnly={!isEditable}
                                /> */}
                            </td>
                            <th className="main-table-header" colSpan="2">
                            <div className="d-flex justify-content-between">
                            <div>Location  
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                            <div>
                                <a
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Enter name of location of in-person meeting else mention online"
                                    data-tooltip-place="bottom">
                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                </a>
                            </div>
                            </div>
                            </th>
                            <td className="main-table-content" colSpan="2">
                                <input
                                type="text"
                                value={location}
                                className={`no-border-input ${isEditable ? "editable" : ""}`}
                                onChange={(e) => setLocation(e.target.value)}
                                style={{ width: "100%" }}
                                readOnly={!isEditable}
                                />
                            </td>
                            </tr>
                            <tr>
                            <th className="main-table-header" colSpan="2">
                            <div className="d-flex justify-content-between">
                            <div>Meeting Cadence  
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                            <div>
                                <a
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Choose from dropdown"
                                    data-tooltip-place="bottom">
                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                </a>
                            </div>
                            </div>
                            </th>


                            <td className="main-table-content" colSpan="3" style={{ borderRight: "none" }}>
                            {isEditable ? (
                                <div
                                className={`drop-down-style ${isSelectHovered ? 'hovered' : ''}`}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                >
                                <Select
                                    options={getMeetingCadenceList}
                                    value={setSelectValue(getMeetingCadenceList, meetingCadence)}
                                    placeholder="Select Meeting Cadence"
                                    onChange={setMeetingCadence}
                                    isDisabled={!isEditable}
                                    styles={customStyles
                                    // menu: (provided) => ({
                                    //     ...provided,
                                    //     zIndex: 9999, // Ensure it appears on top of other elements
                                    //     maxWidth: "100%", // Set maximum width for the dropdown
                                    //     width: "100%", // Ensure the dropdown takes full width up to maxWidth
                                    // }),
                                    }
                                    menuPortalTarget={document.body} // Render dropdown outside the container
                                    menuPosition="fixed" // Ensure it is positioned freely
                                />
                                </div>
                            ) : (
                                // When not editable, display the meeting type directly
                                <div className="non-editable-text">
                                {meetingCadence}
                                </div>
                            )}
                            </td>
                            <td colSpan="7" style={{ borderLeft: "none" }}>
                                <div  style={{width:"100%",background:"white"}}></div>
                            </td>
                           

                              

                            </tr>
                            <tr>
                            <th className="main-table-header" colSpan="2">
                            <div className="d-flex justify-content-between">
                            <div>Attendees  
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                            <div>
                                <a
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Mention initials of all attendees"
                                    data-tooltip-place="bottom">
                                    <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                                </a>
                            </div>
                            </div>
                            </th>
                            <td className="main-table-content" colSpan="10">
                                <input
                                type="text"
                                value={attendees}
                                className={`no-border-input ${isEditable ? "editable" : ""}`}
                                onChange={(e) => setAttendees(e.target.value)}
                                style={{ width: "100%" }}
                                readOnly={!isEditable}
                                />
                            </td>
                            </tr>
                            <tr>
                            <th className="main-table-header" colSpan="2">
                            <div className="d-flex justify-content-between">
                            <div>Agenda  
                                <span style={{color: 'red', marginLeft: '4px' }} aria-label="required">*</span>
                            </div>
                            <div>
                            <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Write in crisp couple of lines"
                                data-tooltip-place="bottom">
                                <img className="me-1" src={imageURL} alt="info" data-tip="This is a custom tooltip" data-for="customTooltip" style={{ width: "15px" }}></img>
                            </a>
                            </div>
                            </div>
                            </th>
                            <td className="main-table-content" colSpan="10">
                                <input
                                type="text"
                                value={agenda}
                                className={`no-border-input ${isEditable ? "editable" : ""}`}
                                onChange={(e) => setAgenda(e.target.value)}
                                style={{ width: "100%" }}
                                readOnly={!isEditable}
                                />
                            </td>
                            </tr>
                            </tbody>
                            </table>
                            
                        
                  
                    </div>
                    {isEditable && (
                   <div className="d-flex justify-content-end" style={{ marginTop: "20px", marginBottom: "20px", textAlign: "right" }}>
                        <button onClick={handleSave} className="d-flex align-items-center" style={{border:"none"}}>
                            
                        <span class="save-btn">save</span>
                        </button>
                        <img 
                            src={close}
                            className="close" 
                            alt="close" 
                            style={{ marginRight: "5px" }} 
                            onClick={() => handleClose()}
                            />
                   </div>
                 
               
                )}
                </div>)}
        </div>
    );
};